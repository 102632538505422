export const POLLING_FIRST_TRIGGER_IN_SECONDS = 20000;
export const POLLING_END_IN_SECONDS = 50000;
export const POLLING_INTERVAL_IN_SECONDS = 10000;

export const DIVISION_ID = "7c5fc8889b6e3c8756abbd76353d68e7";
export const SITE_REFERENCE_ID = "c8f4261f9f46e6465709e17ebea7a92b";

export const OUTGOING_DOC_STATUS = {
  AVAILABLE: "available",
  GENERATED: "generated",
  SENT: "sent",
  ASSIGNED: "assigned",
};

export const MASS_BALANCE_MODAL = {
  ASSIGNED_TO_SALE: "Assign to sale",
};

export const INCOMING_DOC_STATUS = {
  READY_TO_ACCEPT: "ready_to_accept",
  REQUIRES_ATTENTION: "requires_attention",
  READY_TO_ASSIGN: "ready_to_assign",
  REJECTED: "rejected",
};
