import {Fragment} from "react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import {
  ADD_BATCH_FORM,
  STEPS,
  TITLES,
  OP_CONDITIONS,
  COLD_RECIRC_OPEN_NOTIFICATION,
} from "modules/co-processing/constants/batches";
import parseFieldValue from "../parseFieldValue";

const formKeys = ADD_BATCH_FORM.keys;
const formLabels = ADD_BATCH_FORM.labels;

const invertedFormKeys = Object.entries(formKeys).reduce(
  (acc, [labelKey, formKey]) => {
    acc[formKey] = labelKey;
    return acc;
  },
  {},
);

const stepFieldsMapping = {
  [STEPS.SECOND]: [formKeys.tankNo, formKeys.clearHeel],
  [STEPS.THIRD]: [
    formKeys.tankNo,
    formKeys.clearHeel,
    formKeys.opCondition,
    formKeys.splitBatch,
  ],
  [`${STEPS.SAVE}_default`]: [
    formKeys.tankNo,
    formKeys.clearHeel,
    formKeys.opCondition,
    formKeys.batchStartTime,
    formKeys.batchEndTime,
    formKeys.splitBatch,
    formKeys.batchId,
    formKeys.tankCertifiedDate,
  ],
  [`${STEPS.SAVE}_split`]: [
    formKeys.tankNo,
    formKeys.clearHeel,
    formKeys.opCondition,
    formKeys.splitBatch,
    "og_batch_id",
    "batch_id",
  ],
};

const getSummaryFieldsByStep = (formStep, getValues, isSplit) => {
  let stepKeySuffix = "";
  if (formStep === STEPS.SAVE) {
    stepKeySuffix = isSplit ? "_split" : "_default";
  }
  const stepKey = `${formStep}${stepKeySuffix}`;
  const fieldsForStep = stepFieldsMapping[stepKey];
  const values = getValues();

  return fieldsForStep
    ?.map((formKey) => {
      const labelKey = invertedFormKeys[formKey] || formKey;
      let label = formLabels[labelKey] || formKey;
      let value = values[formKey] ?? null;

      // Handle "Split batch ID" label and value if the batch is split
      if (isSplit) {
        if (formKey === formKeys.batchId) {
          label = `Split ${values?.batchIdSplitPrefix} batch ID`;
          value = values?.batch_code;
        }

        if (formKey === "og_batch_id") {
          label = formLabels.ogBatchId;
          value = values?.og_batch_id?.split(".")?.[1];
        }
      }

      return {
        key: formKey,
        label,
        value: parseFieldValue(formKey, value),
      };
    })
    .filter((field) => field.value !== null && field.value !== ""); // filter out fields with null or empty values;
};

const FormSummary = ({formStep}) => {
  const {getValues} = useFormContext();
  const isSplit = getValues(formKeys.splitBatch, false);
  const isValveOpen = getValues("valveOpen", false);

  const summaryFields = getSummaryFieldsByStep(formStep, getValues, isSplit);

  return summaryFields?.length ? (
    <div className="addBatch__summary" data-test="batch-summary">
      <div className="addBatch__summary-title">{TITLES.SUMMARY}</div>
      <div className="addBatch__summary-list">
        {summaryFields.map(({key, label, value}) => (
          <Fragment key={key}>
            {key === "operating_condition" && isValveOpen && (
              <div className="addBatch__summary-notification">
                {COLD_RECIRC_OPEN_NOTIFICATION}
              </div>
            )}

            <div className="flex justify-between">
              <label className="text-gray-700">{label}</label>
              <span>{value}</span>
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  ) : null;
};

FormSummary.propTypes = {formStep: PropTypes.number.isRequired};

export default FormSummary;
