import PropTypes from "prop-types";
import Section from "./Section";

const SectionType = ({items, isDashboard}) => {
  return (
    <div data-test="section-type">
      {!isDashboard && (
        <>
          <div className="flex items-center gap-x-4">
            <h4
              data-test="document-type-title"
              className="text-xl font-bold text-indigo-500"
            >
              File uploader
            </h4>
            <div className="w-3/5 border border-gray-100" />
          </div>
          <p data-test="instructions" className="mt-3 text-indigo-500">
            Please select one of the document type
          </p>
        </>
      )}
      <div className="grid grid-cols-1 gap-2 mt-5 gap-y-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4">
        {items.map((item, index) => (
          <Section
            key={index}
            link={item.link}
            img={item.img}
            title={item.title}
            description={item.description}
            redirectToExternalLink={item.redirectToExternalLink}
            redirectToExternalLinkURL={item.redirectToExternalLinkURL}
          />
        ))}
      </div>
    </div>
  );
};

SectionType.propTypes = {
  items: PropTypes.array.isRequired,
  isDashboard: PropTypes.bool,
};

export default SectionType;
