import {useState, useMemo} from "react";
import Fuse from "fuse.js";
import {
  GeneralActionsMenu,
  DownloadCSVItem,
  EditionItem,
} from "modules/common/Table/ActionsMenu";
import {
  useUserSettings,
  applyDateFormat,
  convertToNumber,
} from "providers/userSettings";
import fileNames from "utils/helpers/uniqueFileName.js";
import {useQuery} from "@apollo/client";
import {
  NETS_CO2_COSTS_LIST,
  createNetCO2CostApi,
} from "graphql/gy-enablement/masterDetails/NETSCO2Costs";
import useRowSelection from "modules/common/Table/useRowSelection";
import {
  BasePageLayout,
  BasePageLayoutContent,
} from "modules/common/BasePageLayout";
import {HEADER_MASTER_SETTINGS} from "../../../constants/Headers";
import {getmasterNetsCO2CostsColumns} from "../../../content/masterDetailsColumns";
import {gyMasterTabData} from "../../../content/tabData";
import MOTValidationReportFilter from "../../common/MOTValidationReportFilter";
import DashboardTable from "../../../../common/DashboardTable";
import {masterNETSCO2CostsSchemaAddEdit} from "../../../schemas/masterNETSCO2CostsSchema";
import MasterNETSCO2CostsForm from "./NETSCO2CostsForm";
import DashboardLayout from "../../../../common/DashboardLayout";

const fuseOptions = {
  keys: ["companyCode", "complianceYear", "certificateCosts"],
  threshold: 0.0,
  includeScore: true,
  ignoreLocation: true,
  distance: 100,
};

const NETSCO2CostsDashBoard = () => {
  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();
  const {data, loading} = useQuery(NETS_CO2_COSTS_LIST, {
    fetchPolicy: "network-only",
  });

  const masterNetsCO2CostsColumns = getmasterNetsCO2CostsColumns(decimalFormat);

  const netsCO2CostList = useMemo(
    () => data?.getBehgCo2Costs?.data ?? [],
    [data?.getBehgCo2Costs?.data],
  );

  const [selectedRows, rowSelection] = useRowSelection(netsCO2CostList, "PK");

  // ------------------------------------------------------
  // search section
  const [search, setSearch] = useState("");

  const items = useMemo(() => {
    const dataFuse = new Fuse(netsCO2CostList, fuseOptions);
    return search
      ? dataFuse.search(search).map(({item}) => item)
      : netsCO2CostList;
  }, [netsCO2CostList, search]);
  // ------------------------------------------------------

  const formatedSelectedDataItems = useMemo(() => {
    return applyDateFormat(
      selectedRows,
      [],
      dateFormat,
      "DD-MM-YYYY",
      ["certificateCosts"],
      decimalFormat,
      2,
    );
  }, [selectedRows, decimalFormat, dateFormat]);

  const batchActionsContent = useMemo(
    () => (
      <GeneralActionsMenu>
        <EditionItem
          mode="add"
          schema={masterNETSCO2CostsSchemaAddEdit}
          modalTitle="NETS CO2 Costs"
          formContent={<MasterNETSCO2CostsForm />}
          onSubmit={async (values) => {
            const complianceYear = new Date(
              values.complianceYear,
            ).getFullYear();
            const PK = values.companyCode + "_" + complianceYear;
            const {
              data: {createBehgCo2Costs: response},
            } = await createNetCO2CostApi({
              ...values,
              PK,
              complianceYear,
              certificateCosts: convertToNumber(
                values.certificateCosts,
                decimalFormat,
              ),
            });
            return response;
          }}
        />
        <DownloadCSVItem
          columns={masterNetsCO2CostsColumns}
          data={formatedSelectedDataItems}
          filename={fileNames.fileNameTypes.FILE_MASTER_CO2_COSTS_REPORT}
        />
      </GeneralActionsMenu>
    ),
    [masterNetsCO2CostsColumns, decimalFormat, formatedSelectedDataItems],
  );

  return (
    <BasePageLayout
      pageTitle={HEADER_MASTER_SETTINGS}
      tabsData={gyMasterTabData}
      data-test="master-details-nets-co2-costs"
    >
      <BasePageLayoutContent>
        <MOTValidationReportFilter
          batchActionsContent={batchActionsContent}
          setSearch={setSearch}
        />
        <DashboardTable
          data-test="gy-master-settings-page-nets-co2-costs-table"
          columns={masterNetsCO2CostsColumns}
          data={items ?? []}
          rowSelection={rowSelection}
          rowKey="PK"
          isLoading={loading}
        />
      </BasePageLayoutContent>
    </BasePageLayout>
  );
};

export default NETSCO2CostsDashBoard;
