import PropTypes from "prop-types";
import {Card, CardBody, CardTitle, Label, Input} from "reactstrap";
import {
  C14_CARD_COLUMNS,
  C14_NOT_APPLICABLE,
} from "modules/co-processing/constants/batches";
import {useState} from "react";
import {Edit24} from "@bphxd/ds-core-react/lib/icons";
import DataGrid from "./DataGrid";
import EditC14Modal from "./EditC14Modal";

const C14Card = ({title, testId, data, batchId, refetch}) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const isNotC14Applicable = data?.c14_pmc !== null;
  return (
    <div className="mb-8" data-test={testId}>
      <Card className="card-border rounded-6">
        <CardBody>
          <CardTitle className="text-[26px]">{title}</CardTitle>
          <div className="w-[45%]">
            <DataGrid data={data} title="" dataColumn={C14_CARD_COLUMNS} />
            {data?.missing_c14 && (
              <div className="mt-5">
                <Input
                  type="checkbox"
                  disabled
                  checked={isNotC14Applicable}
                  className="mr-3 align-middle"
                />
                <Label className="align-middle mb-0">
                  {C14_NOT_APPLICABLE}
                </Label>
                <Edit24
                  data-test="edit-c14"
                  className="d-inline"
                  onClick={() => setOpenEditModal(true)}
                />
              </div>
            )}
          </div>
        </CardBody>
      </Card>
      {openEditModal && (
        <EditC14Modal
          onClose={() => setOpenEditModal(false)}
          field={C14_NOT_APPLICABLE}
          batchId={batchId}
          refetch={refetch}
          previousValue={isNotC14Applicable}
        />
      )}
    </div>
  );
};

C14Card.propTypes = {
  title: PropTypes.string,
  testId: PropTypes.string,
  data: PropTypes.object,
  batchId: PropTypes.string,
  refetch: PropTypes.func,
};

export default C14Card;
