import PropTypes from "prop-types";
import {
  CircleCheck24,
  Edit24,
  Clock24,
  Alert24,
} from "@bphxd/ds-core-react/lib/icons";
import {PARCEL_STATUS} from "../constants/receipts";

const StatusWithIcon = ({value}) => {
  return (
    <div data-test="copro-status-icon" className="flex items-center">
      {value === PARCEL_STATUS.ACTUALIZED && (
        <CircleCheck24 className="text-success" />
      )}
      {value === PARCEL_STATUS.DRAFT && <Edit24 />}
      {value === PARCEL_STATUS.SCHEDULED && <Clock24 />}
      {value === PARCEL_STATUS.PENDING && <Alert24 className="text-danger" />}
      {value}
    </div>
  );
};

StatusWithIcon.propTypes = {
  value: PropTypes.string,
};

export default StatusWithIcon;
