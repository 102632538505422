import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from "reactstrap";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {Close, Chip} from "@bphxd/ds-core-react";
import Form from "modules/common/FormNew";
import {Upload48, Attach32} from "@bphxd/ds-core-react/lib/icons";
import {useDropzone} from "react-dropzone";
import {checkFileTooLarge, checkFileType} from "utils/helpers/checkData";
import {SAF_DEFAULT_COUNTRY} from "constants/pos";

const countryData = [
  {code: "se", name: "Sweden"},
  {code: "no", name: "Norway"},
];

const UploadDocuments = ({
  showUploadModal,
  setUploadModal,
  setUploadFile,
  setShowPreview,
  country,
  setCountry,
  handleUpload,
}) => {
  const [acceptedFiles, setAcceptedFiles] = useState([]);

  const filesType = "pdf";

  const maxSize = 52428800;

  const onDrop = (acceptedFiles) => {
    setAcceptedFiles([...acceptedFiles]);
  };

  const {fileRejections, getRootProps, getInputProps} = useDropzone({
    accept: checkFileType(filesType),
    maxFiles: 0,
    maxSize,
    multiple: false,
    onDrop,
  });

  const file = acceptedFiles[0];

  const handleRemove = (id) => {
    const newFiles = [...acceptedFiles];
    newFiles.splice(id, 1);
    setAcceptedFiles(newFiles);
  };

  const files = acceptedFiles.map((item, index) => (
    <Chip
      style={{width: "244px", height: "27px"}}
      data-test="file"
      icon={Attach32}
      color="neutral"
      id={index}
      label={item.path}
      key={index}
      onRemove={handleRemove}
    />
  ));

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setUploadFile(acceptedFiles[0]);
    }
  }, [acceptedFiles, setUploadFile]);

  const isFileTooLarge = checkFileTooLarge(fileRejections, maxSize);

  return (
    <div>
      <Modal
        isOpen={showUploadModal}
        className="modal-dialog-centered"
        style={{width: "344px"}}
      >
        <ModalHeader
          className="border-b-2 border-gray-200 mb-2 text-xl"
          close={
            <Close
              onClick={() => {
                setUploadModal(false);
                setCountry(SAF_DEFAULT_COUNTRY);
              }}
            />
          }
        >
          Add document
        </ModalHeader>
        <ModalBody className="pt-0">
          <div className="text-left">
            <Label for="country" className="mb-4" style={{fontSize: "12px"}}>
              Select a country you are uploading documents for
            </Label>
            <Input
              type="select"
              id="country"
              data-test="country"
              onChange={(e) => setCountry(e.target.value)}
              style={{width: "200px"}}
              defaultValue={country}
            >
              {countryData.map((country) => (
                <option key={country.code} value={country.name}>
                  {country.name}
                </option>
              ))}
            </Input>
          </div>

          <Form id="upload-form" onSubmit={() => handleUpload()}>
            <div className="mt-8">
              <div {...getRootProps({className: "dropzone w-full mt-1"})}>
                <input
                  data-test="document-uploader"
                  id="documents"
                  {...getInputProps()}
                />
                <div className="flex flex-col items-center justify-center px-2 py-1 h-48 border-2 border-dashed gap-3">
                  <div className="flex flex-row w-full items-center justify-center">
                    <Button
                      data-test="copro-filter-btn"
                      id="filterButton"
                      name="filterButton"
                      color="standard-tertiary rounded-0 bg-white border-0"
                      type="button"
                    >
                      <Upload48 />
                    </Button>
                  </div>
                  <div className="flex flex-row w-full items-center justify-center">
                    <p className="text-sm text-indigo-500">
                      Drag and drop or{" "}
                      <u className="font-medium">choose file to upload</u>
                    </p>
                  </div>
                  <div className="flex flex-row w-full items-center justify-center">
                    <label
                      data-test="file-type-label"
                      className="text-xs text-gray-400"
                      htmlFor="documents"
                    >
                      File type supported: PDF
                    </label>
                  </div>
                </div>
              </div>

              {isFileTooLarge && (
                <p className="block text-red-500">File is larger than 50MB</p>
              )}
            </div>
          </Form>
          {country && (
            <aside className="flex mt-3 items-start justify-start">
              <div className="flex flex-col flex-start">
                {files}
                <span className="mt-3">{`Please make sure that you are only uploading documents for ${country}`}</span>
              </div>
            </aside>
          )}
        </ModalBody>
        {files.length > 0 && (
          <ModalFooter className="p-0 d-block">
            <div className="row g-0 m-0 modal-footer-row">
              <div className="col-6 d-grid">
                <Button
                  color="darker-tertiary"
                  size="lg"
                  className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
                  onClick={() => {
                    setUploadModal(false);
                    setCountry(SAF_DEFAULT_COUNTRY);
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div className="col-6 d-grid">
                <Button
                  color="darker-tertiary"
                  size="lg"
                  className="border-0 rounded-0 py-4 bg-transparent text-default"
                  onClick={() => {
                    setShowPreview(true);
                    setUploadModal(false);
                  }}
                  disabled={!file && file.type !== "application/pdf"}
                >
                  Upload
                </Button>
              </div>
            </div>
          </ModalFooter>
        )}
      </Modal>
    </div>
  );
};

UploadDocuments.propTypes = {
  showUploadModal: PropTypes.bool,
  setUploadModal: PropTypes.func,
  setUploadFile: PropTypes.func,
  setShowPreview: PropTypes.func,
  country: PropTypes.string,
  setCountry: PropTypes.func,
  handleUpload: PropTypes.func,
};

export default UploadDocuments;
