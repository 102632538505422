import {Check24, Remove24, Remove48} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import {useUserSettings, formatNumber} from "providers/userSettings";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  InputGroup,
  Label,
  Input,
} from "reactstrap";

import {INPUT_METRIC} from "modules/co-processing/constants/shipments";

import "./index.scss";

import _ from "lodash";
import {toast} from "react-toastify";
import {COPRO_US_SHIPMENTS_API_SPLIT_BOL} from "graphql/coprocessing/shipments";
import {useLazyQuery} from "@apollo/client";

const SplitBOLModal = ({bol, onClose, onSave}) => {
  const [splitBol] = useLazyQuery(COPRO_US_SHIPMENTS_API_SPLIT_BOL, {
    fetchPolicy: "network-only",
  });

  const onSplitBol = async (volume_a, volume_b) => {
    const response = await splitBol({
      variables: {
        request: {
          dtn_bol_layer_id: bol.dtn_bol_layer_id,
          volume_a,
          volume_b,
        },
      },
    });
    const data = response?.data?.bioLcCoproUsShipmentsApi;
    if (data?.error || data?.statusCode !== 200) {
      toast.error(data.error);
      return;
    }
    toast.success("Split successful.");
    onSave();
  };

  const {
    userSettings: {decimalFormat},
  } = useUserSettings();
  return (
    // z-index is necessary to keep bp-header visible at the top with the fullscreen
    <Modal id="split-bol-modal" size="xl" isOpen contentClassName="rounded-0">
      <ModalHeader
        id="split-bol-modal-header"
        className="text-xl ml-7"
        close={<Remove48 onClick={onClose} />}
      >
        Split BOL
      </ModalHeader>
      <ModalBody id="split-bol-modal-body">
        <div id="split-bol-modal-info" className="h3">
          BOL #{bol.outgoing_bol_number} - volume gal{" "}
          {formatNumber(bol.truck_lifting_volume, decimalFormat, 2)}
        </div>
        <div id="split-bol-modal-form">
          <Form
            id="form-split-bol"
            onSubmit={(event) => {
              event.preventDefault();
              const partAStr = event.target.partA.value || "";
              const partBStr = event.target.partB.value || "";
              if (!partAStr || !partBStr) {
                toast.error("Both volumes must be set");
                return;
              }
              const partA = parseFloat(partAStr);
              const partB = parseFloat(partBStr);
              onSplitBol(partA, partB);
            }}
          >
            {/* TODO - check metricInput.js */}
            <FormGroup className="mb-10">
              <div className="part-text">Part A</div>
              <div className="part-border"></div>
              <Label> Volume </Label>
              <InputGroup className="w-25">
                <Input type="number" name="partA"></Input>
                <div
                  data-test="copro-metric-appended"
                  className="input-group-append"
                >
                  <div className="input-group-text">{INPUT_METRIC}</div>
                </div>
              </InputGroup>
            </FormGroup>
            <FormGroup className="mb-10">
              <div className="part-text"> Part B </div>
              <div className="part-border"></div>
              <Label> Volume </Label>
              <InputGroup className="w-25">
                <Input type="number" name="partB"></Input>
                <div
                  data-test="copro-metric-appended"
                  className="input-group-append"
                >
                  <div className="input-group-text">{INPUT_METRIC}</div>
                </div>
              </InputGroup>
            </FormGroup>
          </Form>
        </div>
      </ModalBody>
      <div
        className="d-flex justify-content-end m-5"
        id="split-bol-modal-buttons"
      >
        <Button className="btn btn-light rounded-0 mr-2" onClick={onClose}>
          <Remove24 /> Cancel
        </Button>
        <Button type="submit" form="form-split-bol" className="btn rounded-0">
          <Check24 /> Save
        </Button>
      </div>
    </Modal>
  );
};

SplitBOLModal.propTypes = {
  bol: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default SplitBOLModal;
