import PropTypes from "prop-types";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
  Modal,
  ModalBody,
  Spinner,
  Button,
  InputGroup,
  InputGroupText,
  Input,
} from "reactstrap";
import {toast} from "react-toastify";
import moment from "moment";
import {useUserSettings} from "providers/userSettings";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import MARK_AS_COMPLETE from "graphql/es-co-processing/MarkAsComplete";
import {useAccount, useMsal} from "@azure/msal-react";
import {Add24, Filter24} from "@bphxd/ds-core-react/lib/icons";
import SvgSearch24 from "@bphxd/ds-core-react/lib/icons/interface/Search24";
import {DOC_STATUS_TRACKER, uploadFile} from "graphql/saf/UploadDocument";
import SAF_GET_INCOMING_DOCUMENTS from "graphql/saf/IncomingDocuments";
import {SAF_DEFAULT_COUNTRY} from "constants/pos";
import {
  POLLING_END_IN_SECONDS,
  POLLING_FIRST_TRIGGER_IN_SECONDS,
  POLLING_INTERVAL_IN_SECONDS,
} from "modules/SAF-dashboard/constants/documents";
import getColumns from "./Columns";
import CustomizeTable from "../CustomizeTable/CustomizeTable";
import SafFilter from "../SafFilter";
import UploadDocuments from "../UploadDocuments";
import PreviewDocumentModal from "../UploadDocuments/PreviewDocumentModal";
import ResponseModal from "../UploadDocuments/ResponseModal";
import DocumentTable from "../OutgoingDocuments/DocumentTable";

const countryData = [
  {code: "se", name: "Sweden"},
  {code: "no", name: "Norway"},
];

const IncomingDocumentComponent = () => {
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);
  const [showUploadModal, setUploadModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const [isErrorModalVisible, setErrorModalVisible] = useState(false);
  const [isUploadSuccessful, setIsUploadSuccessful] = useState(false);
  const [uploadFileData, setUploadFileData] = useState(null);
  const [country, setCountry] = useState(SAF_DEFAULT_COUNTRY);
  const [isMarkAsCompleteOpen, setIsMarkAsCompleteOpen] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [supplierList, setSupplierList] = useState([]);
  const [isDuplicate, setIsDuplicate] = useState(false);

  const [highlightedRows, setHighlightedRows] = useState([]);
  const [filtering, setFiltering] = useState("");
  const [columnFilter, setColumnFilter] = useState([]);

  const [supplierName, setSupplierName] = useState(undefined);
  const [dateFrom, setDateFrom] = useState(undefined);
  const [dateTo, setDateTo] = useState(undefined);

  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();

  const {data, loading, refetch} = useQuery(SAF_GET_INCOMING_DOCUMENTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data && data?.bioLcSafGetIncomingDocs) {
      setSupplierList(data?.bioLcSafGetSuppliers?.supplier_names);
    }
  }, [data, setSupplierList]);

  const [markAsComplete] = useMutation(MARK_AS_COMPLETE, {
    onCompleted: ({bioLcCoproEuMarkAsComplete}) => {
      setIsMarkAsCompleteOpen(false);
      if (bioLcCoproEuMarkAsComplete?.statusCode === 200) {
        toast.success(
          bioLcCoproEuMarkAsComplete?.message ??
            "Document successfully marked as completed",
        );
        refetch();
      } else {
        toast.error(
          bioLcCoproEuMarkAsComplete?.message ?? "Failed to mark as completed",
        );
      }
    },
  });

  const handleMarkAsComplete = useCallback(
    (sdNumber) => {
      setIsMarkAsCompleteOpen(true);
      markAsComplete({
        variables: {
          event: {
            changedBy: account?.username,
            sdNumber,
            userAction: "mark_complete",
          },
        },
      });
    },
    [account?.username, markAsComplete],
  );

  const tableData = useMemo(() => {
    /* Fields to display
  Status (Toggle should be disabled and fixed to on)
  Certificate ID (Toggle should be disabled and fixed to on) : certificate_number / certificate_inbound_eu_id
  Product : product_type
  Raw material : raw_material_type
  Quantity : total
  Supplier / Customer : supplier_name
  Receiving point : recipient_receipt_address
  Date of issuance : date_of_issuance
  Contract number : contract_number
  GHG total : ghg_total
  Date of physical receipt : user_installation_date
  Mass balance
  */
    return (data?.bioLcSafGetIncomingDocs?.documents ?? []).map((document) => ({
      sdNumber: document.sd_number,
      certificateInboundEuId: document.certificate_inbound_eu_id,
      previousVersions: document.version_history_data,
      previousVersionsCount: document.version_count,
      status: document.state,
      certificateNumber: document.sd_number,
      product: document.product_type,
      rawMaterialType: document.raw_material_type,
      dateOfIssuance: document.date_of_issuance,
      supplierName: document.supplier_name,
      recipientReceiptAddress: document.recipient_receipt_address,
      materialCode: document.raw_material_type,
      quantity: document.product_qty_uom === "MT" && document.product_qty,
      quantinInm3: document.product_qty_uom === "M3" && document.product_qty,
      physicalReceiptDate:
        document.user_installation_date != null
          ? moment(new Date(document.user_installation_date)).format(dateFormat)
          : "",
    }));
  }, [dateFormat, data]);

  const cols = useMemo(
    () => getColumns(dateFormat, decimalFormat, handleMarkAsComplete),
    [dateFormat, decimalFormat, handleMarkAsComplete],
  );

  // const cols = getColumns(handleMarkAsComplete);
  const [columns, setColumns] = useState(cols);
  const filteredColumns = useMemo(
    () => columns.filter(({visible}) => visible),
    [columns],
  );

  const handleFilterSubmit = (data) => {
    if (data) {
      setSupplierName(data.supplierName);
      setDateFrom(data.dateOfIssuance ? data.dateOfIssuance[0] : undefined);
      setDateTo(data.dateOfIssuance ? data.dateOfIssuance[1] : undefined);
      const convertedObjects = Object.entries(data)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => ({id: key, value}));

      setColumnFilter(convertedObjects);
    } else {
      setSupplierName(undefined);
      setDateFrom(undefined);
      setDateTo(undefined);
    }
  };

  const [pollingTracker, {data: trackerData, stopPolling, called}] =
    useLazyQuery(DOC_STATUS_TRACKER, {
      pollInterval: POLLING_INTERVAL_IN_SECONDS,
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    const handleTracking = async () => {
      if (trackerData && trackerData.bioLcSafDocStatusTracker) {
        const {statusCode} = trackerData.bioLcSafDocStatusTracker;
        switch (statusCode) {
          case 400: // Duplicate document
            setIsUploading(false);
            setIsDuplicate(true);
            setErrorModalVisible(true);
            stopPolling();
            break;
          case 500: // Textract failed
            setIsUploading(false);
            setErrorModalVisible(true);
            stopPolling();
            break;
          case 201: // Successfully parsed
            setIsUploading(false);
            setIsUploadSuccessful(true);
            stopPolling();
            refetch();
            break;
          default:
            break;
        }
      }
    };

    handleTracking();
  }, [trackerData, stopPolling, refetch]);

  useEffect(() => {
    let pollingTimeout;
    if (called) {
      pollingTimeout = setTimeout(() => {
        // After polling 5 times, if the result is still in progress, then terminate the polling manually.
        if (trackerData?.bioLcSafDocStatusTracker?.statusCode === 202) {
          setIsUploading(false);
          setErrorModalVisible(true);
          stopPolling();
        }
      }, POLLING_END_IN_SECONDS);
    }
    return () => clearTimeout(pollingTimeout);
  }, [called, stopPolling, trackerData?.bioLcSafDocStatusTracker?.statusCode]);

  const handleUpload = async () => {
    if (uploadFileData !== null && uploadFileData !== undefined) {
      setIsUploading(true);
      setShowResponse(true);
      try {
        const uploadStatusCode = await uploadFile(
          uploadFileData,
          countryData.filter((item) => item.name === country)[0].code,
        );
        if (uploadStatusCode === 200) {
          // First polling will start after 30 seconds of uploading the PDF.
          setTimeout(async () => {
            pollingTracker({variables: {doc_name: uploadFileData.name}});
          }, POLLING_FIRST_TRIGGER_IN_SECONDS);
        } else {
          setIsUploading(false);
          setErrorModalVisible(true);
        }
      } catch (err) {
        console.error(err);
        // Unexpected error
      }
    }
    return false;
  };

  return (
    <>
      <Modal
        size="sm"
        isOpen={isMarkAsCompleteOpen}
        className="modal-dialog-centered"
      >
        <ModalBody>
          <div className="flex flex-row pl-4 justify-start items-center gap-4">
            <Spinner />
            <span>Processing...</span>
          </div>
        </ModalBody>
      </Modal>
      <div className="flex flex-col justify-stretch w-full items-start px-7">
        <div className=" flex flex-row justify-end w-full mb-5">
          <Button
            color="primary"
            type="secondary"
            className="float-right rounded-0 text-gray-400"
            id="addDocumentButton"
            size="md"
            onClick={() => {
              setCountry(SAF_DEFAULT_COUNTRY);
              setUploadModal(!showUploadModal);
            }}
          >
            Add document
            <Add24 className="btn-icon-suffix" />
          </Button>
        </div>
      </div>

      <div className="flex flex-col justify-stretch w-full items-start px-7">
        <div className=" flex flex-row justify-between w-full p-4 bg-white">
          <div>
            <CustomizeTable
              columns={columns}
              onColumnChange={(cols) => setColumns(cols)}
              showApplyButton={false}
            />
          </div>
          <div className="flex flex-none flex-row items-center mx-5">
            <div className="mr-2">
              <Button
                data-test="copro-filter-btn"
                color="standard-tertiary rounded-0"
                onClick={() => setShowFilter(!showFilter)}
              >
                Filters
                <Filter24 className="btn-icon-suffix" />
              </Button>
            </div>
            <div>
              <InputGroup className="input-group-merge" size="">
                <Input
                  className="form-control-appended"
                  placeholder="Search"
                  value={filtering}
                  onChange={(e) => setFiltering(e.target.value)}
                />
                <div className="input-group-append">
                  <InputGroupText>
                    <SvgSearch24 />
                  </InputGroupText>
                </div>
              </InputGroup>
            </div>
          </div>
        </div>

        {showFilter && (
          <div className="flex flex-col justify-stretch w-full items-start px-7 border-secondary border-top">
            <div className=" flex flex-row w-full my-6">
              <SafFilter
                supplierName={supplierName}
                dateFrom={dateFrom}
                dateTo={dateTo}
                onSubmit={handleFilterSubmit}
                supplierList={supplierList}
                setColumnFilter={setColumnFilter}
              />
            </div>
          </div>
        )}

        <div className="border-secondary border-top w-full">
          <DocumentTable
            columns={filteredColumns}
            data={tableData}
            className="incoming-docs-table"
            highlightedRows={highlightedRows}
            loading={loading}
            filtering={filtering}
            setFiltering={setFiltering}
            columnFilter={columnFilter}
            setColumnFilter={setColumnFilter}
          />
          {showUploadModal && (
            <UploadDocuments
              showUploadModal={showUploadModal}
              setUploadModal={setUploadModal}
              setUploadFile={setUploadFileData}
              setShowPreview={setShowPreview}
              country={country}
              setCountry={setCountry}
              handleUpload={handleUpload}
            />
          )}

          {showPreview && (
            <PreviewDocumentModal
              showPreview={showPreview}
              setShowPreview={setShowPreview}
              uploadFile={uploadFileData}
              setIsUploading={setIsUploading}
              handleUpload={handleUpload}
              setShowResponse={setShowResponse}
              setCountry={setCountry}
            />
          )}

          {showResponse && (
            <ResponseModal
              showResponse={showResponse}
              setShowResponse={setShowResponse}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              isErrorModalVisible={isErrorModalVisible}
              setErrorModalVisible={setErrorModalVisible}
              setUploadModal={setUploadModal}
              setCountry={setCountry}
              bodyClassNames={
                isErrorModalVisible || isUploadSuccessful ? "h-40 pt-0" : "h-48"
              }
              isUploadSuccessful={isUploadSuccessful}
              setIsUploadSuccessful={setIsUploadSuccessful}
              isDuplicate={isDuplicate}
              setIsDuplicate={setIsDuplicate}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default IncomingDocumentComponent;
