import {gql} from "@apollo/client";

const COPRO_EU_VIEW_DOCUMENT_QUERY = gql`
  query viewDocumentQuery($docStatusId: String!) {
    bioLcCoproEuGetCountries {
      code
      name
    }
    bioLcCoproRawMaterialAPI {
      Records {
        issc_desc
        material_code
      }
    }
    bioLcCoproConfigCenterLocationDetails {
      Records {
        location_id
        location_code
        location_name
      }
    }
    bioLcCoproConfigCenterTransportationEmission {
      Records {
        transportation_emission_id
        sending_location_name
        receiving_location_name
        receiving_location_code
        mot_name
      }
    }
    bioLcCoproEuDownloadFile(event: {docStatusId: $docStatusId}) {
      downloadUrl
    }
    bioLcCoproEuGetInboundDoc(event: {docStatusId: $docStatusId}) {
      additionalInfo
      agriculturalBiomassIntermediateCropFlag
      agriculturalBiomassLowRiskFlag
      availableBioProductTons
      productEnergyContent
      certificateNumber
      certificationSystem
      chainOfCustodyOption
      contractNumber
      customerRef
      dealNotes
      deliveryNotes
      density
      densityUom
      differenceVol
      documentCurrentStatus
      euRedCompliantFlag
      expiryDate
      feedstockCountryCode
      feedstockIssueDate
      feedstockMonth
      feedstockQty
      feedstockQtyUom
      feedstockQuarter
      fossilNotes
      ghgEccr
      ghgEccs
      ghgEec
      ghgEee
      ghgEl
      ghgEmissionIn
      ghgEmissionOut
      ghgEmissionPercentageIn
      ghgEmissionPercentageOut
      ghgEp
      ghgEsca
      ghgEtd
      ghgEu
      ghgTotal
      importedInvoiceVol
      invoiceProviderName
      isccCompliantFlag
      materialDispatchDate
      materialSustainabilityCriteriaFlag
      mbLocation
      motDistanceKm
      motFromRefinery
      motToRefinery
      observationNotes
      operationDesc
      orderNumber
      originDestinationDesc
      physicalReceiptDate
      processType
      processedFeedstockTons
      productConcludedQty
      productInvoiceVol
      productNetVol
      productType
      productionLossTons
      rawExpiryDate
      rawIssueDate
      rawMaterialCultivationCountryName
      rawMaterialDeliveryDate
      rawMaterialOriginCountryName
      rawMaterialType
      rawMaterialWasteOrResidueFlag
      recipientAddress
      recipientName
      recipientReceiptAddress
      recipientReceiptAddressSameFlag
      refineryDischargeVol
      refineryLoadedVol
      sdNumber
      supplierAddress
      supplierDispatchAddress
      supplierDispatchAddressSameFlag
      supplierInvoiceVol
      supplierName
      thirdPartyName
      transportationEmissionId
      transportLossTons
      vesselAtHuelva
      vesselAtTespa
      defaultValueAppliedFlag
      diseggregatedDefaultValueOilFlag
      diseggregatedDefaultValueSoiln2oFlag
      bonusCo245gManureUsedFlag
      bonusCo229gManureUsedFlag
      supplychainIncentiveReceivedFlag
      supplychainIncentiveReceivedName
      nuts2Region
      splitDetails {
        defaultValueAppliedFlag
        feedstockQty
        physicalReceiptDate
        originCountryName
        ghgEccr
        ghgEccs
        ghgEee
        ghgEl
        ghgEp
        ghgEsca
        ghgEtd
        ghgEu
        ghgTotal
        transportationEmissionId
      }
    }
  }
`;

export default COPRO_EU_VIEW_DOCUMENT_QUERY;
