import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import PropTypes from "prop-types";
import SpinnerLoading from "modules/common/SpinnerLoading";
import {Fragment, useCallback, useState} from "react";
import {Table} from "reactstrap";
import {INCOMING_COLUMN_IDS, OUTGOING_COLUMN_IDS} from "./Column";
import "./index.css";

const SplitTable = ({data, columns, loading}) => {
  const [selectedRow, setSelectedRow] = useState("");
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const buildRows = useCallback(
    (row, flexRender) => {
      const outgoingRecords = row.original?.outgoingRecords ?? [{}];
      const rowSpan = outgoingRecords.length;
      return outgoingRecords.map((record, index) => {
        const rowKey = `${row.id}_${index}`;
        return (
          <tr key={rowKey}>
            {row.getVisibleCells().map((cell) => {
              if (INCOMING_COLUMN_IDS.includes(cell.column.id)) {
                return (
                  <td
                    key={cell.id}
                    className={`${index > 0 ? "d-none" : ""} ${
                      cell.getContext().column.columnDef.dataType ===
                        "number" && "text-right"
                    }`}
                    rowSpan={rowSpan}
                    style={{padding: "10px 15px"}}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              }
              if (!INCOMING_COLUMN_IDS.includes(cell.column.id)) {
                const {row, ...context} = cell.getContext();
                return (
                  <td
                    key={cell.id}
                    style={{verticalAlign: "middle", padding: "10px 15px"}}
                    className={`${
                      selectedRow === rowKey ? "bg-success-subtle" : ""
                    } ${
                      cell.getContext().column.columnDef.dataType ===
                        "number" && "text-right"
                    }`}
                  >
                    {flexRender(cell.column.columnDef.cell, {
                      ...context,
                      row: {
                        ...row,
                        original: {
                          ...row.original,
                          outgoingRecords: record,
                          rowSpan,
                        },
                        highlight: () => {
                          setSelectedRow(rowKey);
                        },
                      },
                    })}
                  </td>
                );
              }
              return null;
            })}
          </tr>
        );
      });
    },
    [selectedRow],
  );

  return (
    <Table className="bp-core table mass-balance-table">
      <thead>
        {table.getHeaderGroups().map((headerGroup, groupIndex) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header, headerIndex) => {
              let groupColspan = 1;
              if (groupIndex === 0) {
                groupColspan =
                  headerIndex === 0
                    ? (INCOMING_COLUMN_IDS?.length ?? 0) - 2
                    : (OUTGOING_COLUMN_IDS?.length ?? 0) + 1;
              }
              return (
                <th
                  key={header.id}
                  colSpan={header.colSpan > 1 ? groupColspan : header.colSpan}
                  style={{
                    verticalAlign: "middle",
                    padding: "10px 15px",
                    width:
                      headerGroup.id === "0"
                        ? "50%"
                        : headerGroup.id === "1" &&
                          header.id === "certificate_number" &&
                          "35%",
                  }}
                  className={
                    header.getContext().column.columnDef.dataType ===
                      "number" && "text-right"
                  }
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {loading && (
          <tr>
            <td colSpan="100%">
              <div className="flex items-center justify-center">
                <SpinnerLoading />
              </div>
            </td>
          </tr>
        )}
        {!loading &&
          data?.length > 0 &&
          table
            .getRowModel()
            .rows.map((row) => (
              <Fragment key={row.id}>{buildRows(row, flexRender)}</Fragment>
            ))}
        {!loading && (!data || data.length === 0) && (
          <tr>
            <td colSpan="100%">
              <div className="flex items-center justify-center">No Data</div>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};
SplitTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  loading: PropTypes.bool.isRequired,
};
export default SplitTable;
