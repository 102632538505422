import {ApolloLink, ApolloProvider, createHttpLink} from "@apollo/client";
import {InMemoryCache} from "@apollo/client/cache";
import {ApolloClient} from "@apollo/client/core";
import ApolloLinkTimeout from "apollo-link-timeout";
import {setContext} from "@apollo/client/link/context";
import {onError} from "@apollo/client/link/error";
import apolloLogger from "apollo-link-logger";
import PropTypes from "prop-types";
import {toast} from "react-toastify";
import {LOGIN_REQUEST, msalInstance} from "./Msal";
import {history} from "./routerHistory";

export const errorLink = onError(({networkError, graphQLErrors}) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({message, locations, path, errorType}) => {
      let errorMessage = "";
      switch (errorType) {
        case "UnauthorizedException":
          if (message === "Token has expired.") {
            toast.error(
              "Your session has expired. Please refresh the page and try again.",
            );
          } else {
            errorMessage =
              "Sorry... You are not authorized to perform this operation";
            history.push(`/api-error-page/${encodeURIComponent(errorMessage)}`);
          }
          break;
        case "Lambda:ExecutionTimeoutException":
          toast.error(
            "Execution timed out. Request was taking too long to process. Recommend to refine the data with more filters",
          );
          break;
        case "Unhandled":
          if (message?.includes("Task timed out")) {
            toast.error(
              "Execution timed out. Request was taking too long to process. Recommend to refine the data with more filters",
            );
          } else {
            errorMessage =
              "Sorry... Something went wrong. Please try again later.";
            history.push(`/api-error-page/${encodeURIComponent(errorMessage)}`);
          }
          break;
        default:
          errorMessage =
            "Sorry... Something went wrong. Please try again later.";
          history.push(`/api-error-page/${encodeURIComponent(errorMessage)}`);
      }
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
    });
  }
  if (networkError) {
    toast.error(networkError);
    console.error(`[Network error]: ${networkError}`);
  }
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

const authLink = setContext(async (_, {headers}) => {
  let token;

  // Get account (Assuming there is always one)
  const account = msalInstance.getAllAccounts()?.[0];

  // Get Token
  const requestObject = {
    ...LOGIN_REQUEST,
    account,
  };

  try {
    // Get the Stored token or a new one refreshing it)
    const {accessToken} = await msalInstance.acquireTokenSilent(requestObject);
    token = accessToken;
  } catch {
    // If refresh token expired or other reasons, do full login  by popup
    const {accessToken} = await msalInstance.acquireTokenPopup(requestObject);
    token = accessToken;
  }

  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
});

const timeoutLink = new ApolloLinkTimeout(60000); // 60 second timeout

const timeoutHttpLink = timeoutLink.concat(httpLink);

const link =
  process.env.NODE_ENV !== "production"
    ? ApolloLink.from([
        apolloLogger,
        errorLink,
        authLink.concat(timeoutHttpLink),
      ])
    : ApolloLink.from([errorLink, authLink.concat(timeoutHttpLink)]);

const merge = (existing, incoming) => {
  if (!incoming) {
    return existing;
  }
  if (!existing) {
    return incoming;
  } // existing will be empty the first time

  const {items, ...rest} = incoming;

  const result = rest;
  result.items = [...existing.items, ...items]; // Merge existing items with the items from incoming

  return result;
};

const newMerge = (existing, incoming) => {
  if (!incoming) {
    return existing;
  }
  if (!existing) {
    return incoming;
  }

  const {data, ...rest} = incoming;
  const result = rest;

  result.data = {items: [...existing.data.items, ...data.items]};
  return result;
};

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          listTankUsageShipment: {
            keyArgs: false,
            merge,
          },
          getTankUsageBiofeedstockReceipt: {
            keyArgs: false,
            newMerge,
          },
          getTankUsageBatches: {
            keyArgs: false,
            newMerge,
          },
          getTruckRackOutboundRecordList: {
            keyArgs: false,
            merge: newMerge,
          },
          getLCFSRecordList: {
            keyArgs: false,
            newMerge,
          },
          getInventoryRecordList: {
            keyArgs: false,
            newMerge,
          },
        },
      },
    },
  }),
});

export const Apollo = ({children}) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

Apollo.propTypes = {
  children: PropTypes.node.isRequired,
};

export default {Apollo, client, errorLink};
