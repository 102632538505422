import React, {useMemo} from "react";
import PropTypes from "prop-types";
import {useQuery} from "@apollo/client";
import {Spinner} from "reactstrap";
import MassBalanceStatus from "./MassBalanceStatus";
import {COPRO_EU_GET_AVAILABLE_QTY} from "../../../../graphql/es-co-processing/EuLandingPage";

const LocationDetails = ({location, mbCycle, materials}) => {
  const {data, loading} = useQuery(COPRO_EU_GET_AVAILABLE_QTY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      event: {
        mbCycle,
        mbLocation: location.location_code,
      },
    },
  });
  const quantities = useMemo(() => {
    if (
      data?.bioLcCoproEuMassBalanceAvailableQuantity != null &&
      data?.bioLcCoproEuMassBalanceAvailableQuantity.length > 0
    ) {
      const qty = data?.bioLcCoproEuMassBalanceAvailableQuantity[0].data.reduce(
        (acc, {materialCode, quantity, materialStatus, closingDate}) => {
          acc[materialCode] = {quantity, materialStatus, closingDate};
          return acc;
        },
        {},
      );
      return qty;
    }
    return {};
  }, [data?.bioLcCoproEuMassBalanceAvailableQuantity]);

  const qtyMaterialCodes =
    data?.bioLcCoproEuMassBalanceAvailableQuantity[0]?.data.map(
      (a) => a.materialCode,
    );

  return (
    <>
      <h4 className="fs-4 fw-light m-0">{location.location_name}</h4>
      <div className="flex flex-row flex-wrap justify-start items-center mb-4 gap-2.5">
        {loading && <Spinner size="lg" />}
        {!loading &&
          data?.bioLcCoproEuMassBalanceAvailableQuantity?.length === 0 && (
            <p className="fs-6">No data found for this quarter</p>
          )}
        {!loading &&
          data?.bioLcCoproEuMassBalanceAvailableQuantity?.length > 0 &&
          materials
            .filter(({material_type}) => material_type === "Raw Material")
            .map((material) => {
              if (qtyMaterialCodes?.includes(material.material_code)) {
                return (
                  <MassBalanceStatus
                    material={{
                      code: material.material_code,
                      shortName: material.material_short_name,
                      longName: material.material_long_name,
                    }}
                    state={quantities[material.material_code]}
                    mbCycle={mbCycle}
                    locationCode={location.location_code}
                    locationName={location.location_name}
                    key={material.material_id}
                  />
                );
              }
              return null;
            })}
      </div>
    </>
  );
};

LocationDetails.propTypes = {
  location: PropTypes.shape({
    location_id: PropTypes.string,
    location_code: PropTypes.string,
    location_name: PropTypes.string,
    mass_balance_cycle: PropTypes.string,
  }).isRequired,
  materials: PropTypes.arrayOf(
    PropTypes.shape({
      material_id: PropTypes.string,
      material_code: PropTypes.string,
      material_short_name: PropTypes.string,
      material_long_name: PropTypes.string,
      material_type: PropTypes.string,
    }),
  ).isRequired,
  mbCycle: PropTypes.string.isRequired,
};

export default LocationDetails;
