import PropTypes from "prop-types";
import {formatNumber, useUserSettings} from "providers/userSettings";
import {parseAndRoundToThreeDecimal} from "utils/numberUtil";
import {UncontrolledTooltip} from "reactstrap";
import {useState} from "react";
import "./index.css";

const formatNumberIfExists = (value, decimalFormatNew) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  if (value) {
    return formatNumber(parseAndRoundToThreeDecimal(value), decimalFormat, 3);
  }
  return value;
};

const renderBreakdownDetails = (breakdownDetails, uom, decimalFormat) =>
  breakdownDetails?.map((breakdownItem, index) => (
    <div key={`breakdown-item-${index}`} className="flex flex-col gap-4 py-2">
      <div className="flex flex-col text-left">
        <div className="border-b text-left breakdown-item-header">
          <span className="">{breakdownItem?.status_type} </span>
          <span>{"\u00A0"}</span>
          {uom === "t" && <span>mt</span>}
          {uom !== "t" && (
            <span>
              m<sup>3</sup>
            </span>
          )}
        </div>
        <div className="flex flex-row pt-2 justify-between breakdown-item-value">
          <span className="py-1 w-1/2 text-left   truncate ...">
            {formatNumberIfExists(breakdownItem?.value, decimalFormat)}
          </span>
          <span className="py-1 w-1/2 pl-2 text-left  truncate ...">
            {`${formatNumberIfExists(
              breakdownItem?.percentage,
              decimalFormat,
            )} %`}
          </span>
        </div>
      </div>
    </div>
  ));

const BreakdownPopover = ({row, popoverId, uom, decimalFormat}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    (row?.original?.outgoingQtyMtBreakdown?.length > 0 ||
      row?.original?.outgoingQtyM3Breakdown?.length > 0) && (
      <UncontrolledTooltip
        placement="right"
        popperClassName="tooltip-light tooltip-lg"
        className="outgoing-breakdown-popover "
        target={`outgoingQty${row.id}${popoverId}`}
        autohide
      >
        <div className="text-left w-32 pb-2 breakdown-header">Breakdown</div>
        <div className=" w-36">
          {" "}
          {renderBreakdownDetails(
            uom === "t"
              ? row?.original?.outgoingQtyMtBreakdown
              : row?.original?.outgoingQtyM3Breakdown,
            uom,
            decimalFormat,
          )}
        </div>
      </UncontrolledTooltip>
    )
  );
};

BreakdownPopover.propTypes = {
  row: PropTypes.object,
  popoverId: PropTypes.string,
  uom: PropTypes.string,
  decimalFormat: PropTypes.string,
};
export default BreakdownPopover;
