import PropTypes from "prop-types";
import {toast} from "react-toastify";
import React, {useState} from "react";
import {Button} from "reactstrap";
import {OpenBalanceAPI} from "graphql/es-co-processing/mass-balance/openBalance.js";
import ConfirmationPopup from "./ConfirmationPopup";

const OpenBalance = ({
  locationCode,
  activeMaterial,
  account,
  chosenCycle,
  refereshChosenCycleData = () => {},
}) => {
  const [isOpenConfirmModalVisible, setOpenConfirmModalVisible] =
    useState(false);
  return (
    <>
      <Button
        size="sm"
        className="h-10 leading-5 rounded-0 ms-0 text-gray-400 bg-black"
        onClick={() => setOpenConfirmModalVisible(true)}
      >
        Open balance
      </Button>
      <ConfirmationPopup
        isOpen={isOpenConfirmModalVisible}
        title="Confirm Re-Open"
        confirmLabel="Confirm"
        message={
          <span>
            <p>{`Are you sure you want to re-open the Quarter ${
              chosenCycle
                ? ` - ${chosenCycle.mb_quarter} ${chosenCycle.mb_year}`
                : ""
            }?`}</p>
          </span>
        }
        onCancel={() => setOpenConfirmModalVisible(false)}
        onConfirm={async (values) => {
          const {data: response} = await OpenBalanceAPI({
            year: chosenCycle ? chosenCycle.mb_year : "",
            quarter: chosenCycle ? chosenCycle.mb_quarter : "",
            materialCode: activeMaterial,
            locationCode,
            createdBy: account.username,
          });

          if (response?.bioLcCoproEuMassBalanceOpen?.statusCode === 200) {
            setOpenConfirmModalVisible(false);
            refereshChosenCycleData();
            toast.success(
              response?.bioLcCoproEuMassBalanceOpen?.message ??
                "Successfully re-opened the quarter",
            );
          } else {
            toast.error(
              response?.bioLcCoproEuMassBalanceOpen?.message ??
                "Something went wrong, failed to re-open",
            );
          }
          return response;
        }}
      />
    </>
  );
};

OpenBalance.propTypes = {
  chosenCycle: PropTypes.any.isRequired,
  activeMaterial: PropTypes.string.isRequired,
  locationCode: PropTypes.string.isRequired,
  account: PropTypes.any.isRequired,
  refereshChosenCycleData: PropTypes.func.isRequired,
};

export default OpenBalance;
