import moment from "moment";
import {Down24, Up24} from "@bphxd/ds-core-react/lib/icons";
import {Button} from "reactstrap";
import {formatNumberIfExists} from "utils/numberUtil";

const measuringUnits = "mt";

export const META_COLUMNS = (dateFormat, decimalFormat, uom) => {
  return [
    {
      header: "",
      id: "Meta",
      columns: [
        {
          header: "Date of physical receipt",
          accessorKey: "dateOfPhysicalReceipt",
          cell: ({row}) => {
            return (
              <div className="whitespace-nowrap">
                {moment(new Date(row?.original?.dateOfPhysicalReceipt)).format(
                  dateFormat,
                )}
              </div>
            );
          },
        },
        {
          header: "Type",
          accessorKey: "type",
        },
        {
          header: "Deal number",
          accessorKey: "dealNumber",
          cell: ({row, table}) => {
            return (
              <Button
                onClick={() => {
                  if (!row.getIsExpanded()) {
                    table.resetExpanded();
                  }
                  setTimeout(() => row.getToggleExpandedHandler()(), 100);
                }}
                color="standard-quartenary"
                className="!px-0 w-full"
              >
                <div className="flex w-full justify-between gap-x-4 !no-underline">
                  <div>{row?.original?.dealNumber} </div>
                  <div>{row.getIsExpanded() ? <Up24 /> : <Down24 />}</div>
                </div>
              </Button>
            );
          },
        },
      ],
    },
  ];
};

export const OPENING_COLUMNS = (
  dateFormat,
  decimalFormat,
  uom,
  weightedAverage,
) => {
  return [
    {
      header: "Opening",
      columns: [
        {
          header: () => {
            return (
              <div>
                {`${formatNumberIfExists(
                  (uom === "t"
                    ? weightedAverage?.openingQtyMt
                    : weightedAverage?.openingQtyM3) ?? "0.00",
                  decimalFormat,
                  3,
                )} `}
                <span>{"\u00A0"}</span>
                {uom === "t" ? (
                  `${measuringUnits}`
                ) : (
                  <span>
                    m<sup>3</sup>
                  </span>
                )}
              </div>
            );
          },

          id: "openingQty",
          accessorFn: () => (uom === "t" ? "openingQtyMt" : "openingQtyM3"),
          dataType: "number",
          cell: ({row}) => {
            return formatNumberIfExists(
              uom === "t"
                ? row?.original?.openingQtyMt
                : row?.original?.openingQtyM3,
              decimalFormat,
              3,
            );
          },
        },
        {
          header: () => {
            return (
              <div>
                {`${formatNumberIfExists(
                  (uom === "t"
                    ? weightedAverage?.openingRateMt
                    : weightedAverage?.openingRateM3) ?? "0.00",
                  decimalFormat,
                  2,
                )} `}
                <span>{"\u00A0"}</span>
                {uom === "t" ? (
                  `€/${measuringUnits}`
                ) : (
                  <span>
                    €/m<sup>3</sup>
                  </span>
                )}
              </div>
            );
          },
          id: "openingRate",
          accessorFn: () => (uom === "t" ? "openingRateMt" : "openingRateM3"),
          dataType: "number",
          cell: ({row}) => {
            return formatNumberIfExists(
              uom === "t"
                ? row?.original?.openingRateMt
                : row?.original?.openingRateM3,
              decimalFormat,
              2,
            );
          },
        },
      ],
    },
  ];
};

export const INCOMING_COLUMNS = (
  dateFormat,
  decimalFormat,
  uom,
  weightedAverage,
) => {
  return [
    {
      header: "Incoming",
      columns: [
        {
          header: () => {
            return (
              <div>
                {`${formatNumberIfExists(
                  (uom === "t"
                    ? weightedAverage?.incomingQtyMt
                    : weightedAverage?.incomingQtyM3) ?? "0.00",
                  decimalFormat,
                  3,
                )} `}
                <span>{"\u00A0"}</span>
                {uom === "t" ? (
                  `${measuringUnits}`
                ) : (
                  <span>
                    m<sup>3</sup>
                  </span>
                )}
              </div>
            );
          },
          id: "incomingQty",
          accessorFn: () => (uom === "t" ? "incomingQtyMt" : "incomingQtyM3"),
          dataType: "number",
          cell: ({row}) => {
            return formatNumberIfExists(
              uom === "t"
                ? row?.original?.incomingQtyMt
                : row?.original?.incomingQtyM3,
              decimalFormat,
              3,
            );
          },
        },
        {
          header: () => {
            return (
              <div>
                {`${formatNumberIfExists(
                  (uom === "t"
                    ? weightedAverage?.incomingRateMt
                    : weightedAverage?.incomingRateM3) ?? "0.00",
                  decimalFormat,
                  2,
                )} `}
                <span>{"\u00A0"}</span>
                {uom === "t" ? (
                  `€/${measuringUnits}`
                ) : (
                  <span>
                    €/m<sup>3</sup>
                  </span>
                )}
              </div>
            );
          },
          id: "incomingRate",
          accessorFn: () => (uom === "t" ? "incomingRateMt" : "incomingRateM3"),
          dataType: "number",
          cell: ({row}) => {
            return formatNumberIfExists(
              uom === "t"
                ? row?.original?.incomingRateMt
                : row?.original?.incomingRateM3,
              decimalFormat,
              2,
            );
          },
        },
      ],
    },
  ];
};

export const OUTGOING_COLUMNS = (
  dateFormat,
  decimalFormat,
  uom,
  weightedAverage,
) => {
  return [
    {
      header: "Outgoing",
      columns: [
        {
          header: () => {
            return (
              <div>
                {`${formatNumberIfExists(
                  (uom === "t"
                    ? weightedAverage?.outgoingQtyMt
                    : weightedAverage?.outgoingQtyM3) ?? "0.00",
                  decimalFormat,
                  3,
                )} `}
                <span>{"\u00A0"}</span>
                {uom === "t" ? (
                  `${measuringUnits}`
                ) : (
                  <span>
                    m<sup>3</sup>
                  </span>
                )}
              </div>
            );
          },
          accessorKey: "outgoingQtyMt",
          dataType: "number",
          cell: ({row}) => {
            return formatNumberIfExists(
              uom === "t"
                ? row?.original?.outgoingData?.outgoingQtyMt
                : row?.original?.outgoingData?.outgoingQtyM3,
              decimalFormat,
              3,
            );
          },
        },
        {
          header: () => {
            return (
              <div>
                {`${formatNumberIfExists(
                  (uom === "t"
                    ? weightedAverage?.outgoingRateMt
                    : weightedAverage?.outgoingRateM3) ?? "0.00",
                  decimalFormat,
                  2,
                )} `}
                <span>{"\u00A0"}</span>
                {uom === "t" ? (
                  `€/${measuringUnits}`
                ) : (
                  <span>
                    €/m<sup>3</sup>
                  </span>
                )}
              </div>
            );
          },
          accessorKey: "outgoingRateMt",
          dataType: "number",
          cell: ({row}) => {
            return formatNumberIfExists(
              uom === "t"
                ? row?.original?.outgoingData?.outgoingRateMt
                : row?.original?.outgoingData?.outgoingRateM3,
              decimalFormat,
              2,
            );
          },
        },
        {
          header: "Status",
          accessorKey: "status",
          cell: ({row}) => {
            return (
              row?.original?.outgoingData?.status && (
                <span className="status-badge">
                  {row?.original?.outgoingData?.status}
                </span>
              )
            );
          },
        },
      ],
    },
  ];
};

export const CLOSING_COLUMNS = (
  dateFormat,
  decimalFormat,
  uom,
  weightedAverage,
) => {
  return [
    {
      header: "Closing",
      columns: [
        {
          header: () => {
            return (
              <div>
                {`${formatNumberIfExists(
                  (uom === "t"
                    ? weightedAverage?.closingQtyMt
                    : weightedAverage?.closingQtyM3) ?? "0.00",
                  decimalFormat,
                  3,
                )} `}
                <span>{"\u00A0"}</span>
                {uom === "t" ? (
                  `${measuringUnits}`
                ) : (
                  <span>
                    m<sup>3</sup>
                  </span>
                )}
              </div>
            );
          },
          id: "closingQty",
          accessorFn: () => (uom === "t" ? "closingQtyMt" : "closingQtyM3"),
          dataType: "number",
          cell: ({row}) => {
            return formatNumberIfExists(
              uom === "t"
                ? row?.original?.closingQtyMt
                : row?.original?.closingQtyM3,
              decimalFormat,
              3,
            );
          },
        },
        {
          header: () => {
            return (
              <div>
                {`${formatNumberIfExists(
                  (uom === "t"
                    ? weightedAverage?.closingRateMt
                    : weightedAverage?.closingRateM3) ?? "0.00",
                  decimalFormat,
                  2,
                )} `}
                <span>{"\u00A0"}</span>
                {uom === "t" ? (
                  `€/${measuringUnits}`
                ) : (
                  <span>
                    €/m<sup>3</sup>
                  </span>
                )}
              </div>
            );
          },
          id: "closingRate",
          accessorFn: () => (uom === "t" ? "closingRateMt" : "closingRateM3"),
          dataType: "number",
          cell: ({row}) => {
            return formatNumberIfExists(
              uom === "t"
                ? row?.original?.closingRateMt
                : row?.original?.closingRateM3,
              decimalFormat,
              2,
            );
          },
        },
      ],
    },
  ];
};

// Format doesn't matter here, since we are only interested in accessor keys
export const META_COLUMN_IDS = META_COLUMNS(
  "DD/MM/YYYY",
  "0.00",
)[0].columns.map((col) => col.accessorKey);
export const OPENING_COLUMN_IDS = OPENING_COLUMNS(
  "DD/MM/YYYY",
  "0.00",
)[0].columns.map((col) => col.accessorKey);
export const INCOMING_COLUMN_IDS = INCOMING_COLUMNS(
  "DD/MM/YYYY",
  "0.00",
)[0].columns.map((col) => col.accessorKey);
export const OUTGOING_COLUMN_IDS = OUTGOING_COLUMNS(
  "DD/MM/YYYY",
  "0.00",
)[0].columns.map((col) => col.accessorKey);
export const CLOSING_COLUMN_IDS = CLOSING_COLUMNS(
  "DD/MM/YYYY",
  "0.00",
)[0].columns.map((col) => col.accessorKey);
