import Logout from "assets/images/svg/log-out.svg";
import Logo from "assets/images/logo-horizontal.svg";
import Settings from "assets/images/svg/settings.svg";
import "./index.scss";
import {Link, useNavigate} from "react-router-dom";
import {useMsal, useIsAuthenticated} from "@azure/msal-react";
import PropTypes from "prop-types";
import MenuSvg from "assets/images/svg/drawer-icon.svg";
import {useCallback, useMemo, useEffect, useState} from "react";
import {Dropdown, Menu} from "antd";
import graphAPICommonMethods from "utils/helpers/graphAPICommonMethods";
import Avatar from "../Avatar";
import SettingsModal from "../StatusModals/SettingsModal";

const Header = ({onMenuClick, onLinkClick, className, onSideMenuItemClick}) => {
  const [showSettings, setShowSettings] = useState(false);

  const {instance, accounts} = useMsal();
  const navigate = useNavigate();
  let isAuthenticated = false;
  isAuthenticated = useIsAuthenticated();

  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    async function fetchGraphAccessToken() {
      const accessTokenGraph = await graphAPICommonMethods.getMSGraphAPIToken();
      const profilePhotoURL =
        await graphAPICommonMethods.getProfilePhoto(accessTokenGraph);
      setImageUrl(profilePhotoURL);
    }
    fetchGraphAccessToken();
  }, []);

  const handleLogout = useCallback(() => {
    const logoutRequest = {
      account: instance.getAccountByHomeId(accounts[0].homeAccountId),
    };
    instance.logoutRedirect(logoutRequest);
    navigate("/");
  }, [accounts, instance, navigate]);

  const userInitials = useMemo(
    () =>
      isAuthenticated
        ? accounts[0]?.name
            .toUpperCase()
            .split(",")
            .reverse()
            .map((str) => str.trim()[0])
            .join("")
        : "U",
    [accounts, isAuthenticated],
  );

  const Items = useCallback(
    () => (
      <Menu className="bp-avatar-container">
        <Menu.Item key="1" data-test="mobile-profile-text">
          <div className="avatar-content">
            <Avatar
              initials={userInitials}
              size="large"
              data-test="mobile-avatar"
              photoSrc={imageUrl}
            />
            <div>
              <label>Welcome</label>
              <label className="bp-account-name">{accounts[0]?.name}</label>
            </div>
          </div>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            setShowSettings(true);
          }}
          key="2"
          data-test="settings"
        >
          <div className="flex items-center pt-2 pb-2">
            <span className="flex-1">Settings</span>
            <img
              src={Settings}
              className="w-6 h-6 mx-auto sm:mx-0 flex-none"
              alt="Settings"
            />
          </div>
        </Menu.Item>
        <Menu.Item
          key="3"
          onClick={(event) => handleLogout(event)}
          data-test="mobile-logout-button"
        >
          <div className="flex items-center pt-2 pb-2">
            <span className="flex-1">Log Out</span>
            <img
              src={Logout}
              className="w-6 h-6 mx-auto sm:mx-0 flex-none"
              alt="Logout"
            />
          </div>
        </Menu.Item>
      </Menu>
    ),
    [handleLogout, accounts, userInitials, imageUrl],
  );

  return (
    <div className={["bp-header", className].join(" ")}>
      <NavBarIcon onMenuClick={onMenuClick} />
      {showSettings && (
        <SettingsModal
          title="Settings"
          visible={showSettings}
          onCancel={() => {
            setShowSettings(false);
          }}
        />
      )}
      <div className="bp-header-container">
        <Link
          to="/"
          data-test="home-button"
          onClick={() => {
            onLinkClick();
            onSideMenuItemClick("");
          }}
        >
          <img src={Logo} className="bp-logo" alt="BP Logo" />
        </Link>
        <Profile
          isAuthenticated
          Items={Items}
          userInitials={userInitials}
          photoSrc={imageUrl}
        />
      </div>
    </div>
  );
};

const Profile = ({isAuthenticated, Items, userInitials, photoSrc}) => {
  return (
    <div className="bp-avatar-item">
      {isAuthenticated ? (
        <Dropdown
          className="avatar-dropdown"
          overlay={Items}
          placement="bottomRight"
          arrow
          overlayStyle={{
            width: "285px",
          }}
        >
          <Avatar
            initials={userInitials}
            size="large"
            data-test="mobile-avatar"
            photoSrc={photoSrc}
          />
        </Dropdown>
      ) : (
        <Link to="/login" data-test="login-button" className="bp-login-button">
          Log in
        </Link>
      )}
    </div>
  );
};

const NavBarIcon = ({onMenuClick}) => {
  return (
    <button
      type="button"
      className="bp-navbar-icon"
      onClick={onMenuClick}
      data-test="menu-button"
    >
      <img src={MenuSvg} alt="Menu" />
    </button>
  );
};

Header.propTypes = {
  onMenuClick: PropTypes.func,
  onLinkClick: PropTypes.func,
  onSideMenuItemClick: PropTypes.func,
  className: PropTypes.string,
};

NavBarIcon.propTypes = {
  onMenuClick: PropTypes.func,
};

Profile.propTypes = {
  isAuthenticated: PropTypes.bool,
  Items: PropTypes.any,
  userInitials: PropTypes.any,
  photoSrc: PropTypes.string,
};

export default Header;
