import React from "react";
import PropTypes from "prop-types";
import {Card, CardBody, CardTitle, CardText} from "reactstrap";
import {ExternalLink24, Lock16} from "@bphxd/ds-core-react/lib/icons";
import {formatNumber, useUserSettings} from "providers/userSettings";
import {Link} from "react-router-dom";
import moment from "moment";
import roundToTwoDecimal from "utils/helpers/roundNumber";
import "./index.scss";

const MB_STATUS_CLOSED = "CLOSED";

const MassBalanceStatus = ({
  material,
  state,
  mbCycle,
  locationCode,
  locationName,
}) => {
  const {
    userSettings: {decimalFormat, dateFormat},
  } = useUserSettings();

  return (
    <Link
      to={`/copro-spain/mass-balance/${locationCode}/${encodeURIComponent(
        locationName,
      )}/${material.code}/${mbCycle.substring(4, 6)}/${mbCycle.substring(
        0,
        4,
      )}`}
    >
      <Card className="!min-w-[280px] max-w=[300px] min-h-[198px] hover-shadow">
        <CardBody className="!py-0 flex flex-row justify-between items-center w-full card-header-border">
          <CardTitle tag="h5" className="!mb-0 text-black">
            {material.shortName}
          </CardTitle>

          <span className="flex flex-row gap-1 items-center py-3 fs-6 text-black opacity-84">
            View balance
            <ExternalLink24 />
          </span>
        </CardBody>
        <CardBody className="!pb-2 card-body-border">
          <CardText className="fw-light text-base text-color">
            {state.materialStatus === MB_STATUS_CLOSED
              ? "Closing stock"
              : "Available stock"}
          </CardText>
          <span className="text-[32px] text-black fw-light">
            {formatNumber(
              parseFloat(roundToTwoDecimal(state.quantity)),
              decimalFormat,
              0,
            )}
            t
          </span>
          {state.materialStatus === MB_STATUS_CLOSED ? (
            <div className="mt-4 flex flex-row items-center gap-2">
              <Lock16 className="ml-[-2px]" />
              <span className="fs-6">Closed</span>
              <span className="fs-6">
                {moment(new Date(state.closingDate)).format(dateFormat)}
              </span>
            </div>
          ) : (
            <div className="mt-4 flex flex-row items-center gap-2 h-4" />
          )}
        </CardBody>
      </Card>
    </Link>
  );
};

MassBalanceStatus.propTypes = {
  material: PropTypes.shape({
    code: PropTypes.string,
    shortName: PropTypes.string,
    longName: PropTypes.string,
  }).isRequired,
  state: PropTypes.shape({
    quantity: PropTypes.string,
    materialStatus: PropTypes.string,
    closingDate: PropTypes.string,
  }).isRequired,
  mbCycle: PropTypes.string.isRequired,
  locationCode: PropTypes.string.isRequired,
  locationName: PropTypes.string.isRequired,
};

export default MassBalanceStatus;
