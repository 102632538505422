import {Nav, NavItem, NavLink} from "reactstrap";
import PropTypes from "prop-types";
import {NavItemAccordion} from "@bphxd/ds-core-react";
import {useLocation} from "react-router-dom";

import "./index.scss";

const NavigationMenu = ({menuConfig}) => {
  return (
    <div className="d-flex h-100">
      <div className="nav-sidebar d-flex flex-column flex-shrink-0">
        <ul className="list-unstyled mb-auto">
          <li>
            <Nav vertical>
              {menuConfig?.map((item) => {
                const location = useLocation();
                const {pathname} = location;
                if (!item.isVisible) {
                  return null;
                }
                if (item?.subItems) {
                  let level2AccordionOpen = false;
                  const level1AccordionOpen = item?.subItems?.find(
                    (element) => element.link === pathname,
                  );
                  return (
                    <NavItemAccordion
                      key={item.id}
                      title={item.title}
                      data-test={item.id}
                      active={level1AccordionOpen || level2AccordionOpen}
                      open={level1AccordionOpen || level2AccordionOpen}
                    >
                      <Nav vertical>
                        {item?.subItems?.map((child) => {
                          if (!child.isVisible) {
                            return null;
                          }
                          level2AccordionOpen = child?.subItems?.find(
                            (element) => element.link === pathname,
                          );
                          if (child?.subItems) {
                            return (
                              <NavItemAccordion
                                key={child.id}
                                title={child.title}
                                data-test={child.id}
                                active={level2AccordionOpen}
                                open={level2AccordionOpen}
                              >
                                <Nav vertical>
                                  {child?.subItems?.map((subChild, index) => {
                                    if (!subChild.isVisible) {
                                      return null;
                                    }

                                    return (
                                      <NavItem key={subChild.id}>
                                        <NavLink
                                          key={index}
                                          href={subChild.link}
                                          active={subChild.link === pathname}
                                        >
                                          {subChild.title}
                                        </NavLink>
                                      </NavItem>
                                    );
                                  })}
                                </Nav>
                              </NavItemAccordion>
                            );
                          }
                          return (
                            <NavItem key={child.id}>
                              <NavLink
                                href={child.link}
                                active={child.link === pathname}
                              >
                                {child.title}
                              </NavLink>
                            </NavItem>
                          );
                        })}
                      </Nav>
                    </NavItemAccordion>
                  );
                }

                return (
                  <NavItem key={item.id}>
                    <NavLink href={item.link} active={item.link === pathname}>
                      {item.title}
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
          </li>
        </ul>
      </div>
    </div>
  );
};

NavigationMenu.propTypes = {
  menuConfig: PropTypes.any,
};

export default NavigationMenu;
