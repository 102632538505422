import {Kebab32, Remove48} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import {displayDate} from "modules/co-processing/helpers/dateHelper";
import {useUserSettings, formatNumber} from "providers/userSettings";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import "./index.scss";
import {
  getSortedRowModel,
  useReactTable,
  flexRender,
  getCoreRowModel,
} from "@tanstack/react-table";
import {useMemo, useState} from "react";
import _ from "lodash";
import {
  COPRO_US_SHIPMENTS_API_ALLOCATE_BOL,
  COPRO_US_SHIPMENTS_API_GET_BOL_DATA,
  COPRO_US_SHIPMENTS_API_RESET_ALLOCATE_BOL,
} from "graphql/coprocessing/shipments";
import {ERRORS} from "modules/co-processing/constants/coProcessing";
import {toast} from "react-toastify";
import {useLazyQuery, useQuery} from "@apollo/client";
import moment from "moment";

import SplitBOLModal from "./SplitBOLModal";
import LoadingSpinner from "../../LoadingSpinner";

const BOLColumns = (decimalFormat, setSelectedBOLForSplit) => [
  {
    id: "select-col",
    header: ({table}) => (
      <Input
        type="checkbox"
        checked={table.getIsAllRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({row}) => (
      <Input
        type="checkbox"
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
    size: 60,
  },
  {
    accessorKey: "start_load_date",
    header: "BOL date",
    cell: (cell) => displayDate(cell.getValue(), true),
    size: 200,
  },
  {
    accessorKey: "outgoing_bol_number",
    header: "Bill of landing (BOL)",
    size: 200,
  },
  {
    accessorKey: "sap_material_name",
    header: "Material name",
    size: 200,
  },
  {
    accessorKey: "credits_qualified",
    header: "RINs Qualified",
    cell: (cell) => (cell.getValue() ? "Yes" : "No"),
    size: 100,
  },
  {
    accessorKey: "truck_lifting_volume",
    header: "Truck lifting volume gal",
    cell: (cell) => `( ${formatNumber(cell.getValue(), decimalFormat, 2)} )`,
    size: 200,
  },
  {
    accessorKey: "options",
    header: (
      <div>
        <Kebab32 />
      </div>
    ),
    id: "options",
    cell: ({row}) => (
      <UncontrolledDropdown>
        <DropdownToggle tag="div">
          <Kebab32 />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => setSelectedBOLForSplit(row.original)}>
            Split BOL
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    size: 60,
  },
];

const renderVolumeSummaryRow = (text, volume, isFirst, decimalFormat) => (
  <tr className={`summary-row ${isFirst ? "first" : ""}`}>
    <td colSpan="4"> </td>
    <td colSpan="2">
      <div className="d-flex">
        <span className="first-text mr-auto w-[60%]">{text}</span>
        <span className="summary-volume">
          {formatNumber(volume, decimalFormat, 2)}
        </span>
      </div>
    </td>
    <td> </td>
  </tr>
);

const renderShipmentInfo = (text, value) => (
  <p className="flex w-[30%]">
    <span className="mr-auto bol-modal-top-text">{text}</span>
    <span className="bol-modal-top-value">{value}</span>
  </p>
);

const displayStartEndDate = (startEndDate, shipmentDate) =>
  moment(startEndDate || shipmentDate)
    .utc()
    .format("HH:mm A");

const BOLModal = ({onClose, shipmentId, shipmentData, onSave}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  const [selectedBOLForSplit, setSelectedBOLForSplit] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const columns = useMemo(
    () => BOLColumns(decimalFormat, setSelectedBOLForSplit),
    [decimalFormat, setSelectedBOLForSplit],
  );
  const {loading, data, refetch} = useQuery(
    COPRO_US_SHIPMENTS_API_GET_BOL_DATA,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        const response = data?.bioLcCoproUsShipmentsApi?.body;
        if (!response) {
          toast.error(ERRORS.FAILED_LOAD);
          onClose();
        }
      },
    },
  );

  const [resetAllocateBol] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_RESET_ALLOCATE_BOL,
    {
      fetchPolicy: "network-only",
      onCompleted: (response) => {
        const data = response?.bioLcCoproUsShipmentsApi;
        if (data?.error || data?.statusCode !== 200) {
          toast.error(data.error);
          return;
        }
        toast.success("Allocation reset successful.");
        onSave();
      },
    },
  );

  const BOLData = data?.bioLcCoproUsShipmentsApi?.body?.bols;

  const [allocateBol] = useLazyQuery(COPRO_US_SHIPMENTS_API_ALLOCATE_BOL, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      const data = response?.bioLcCoproUsShipmentsApi;
      if (data?.error || data?.statusCode !== 200) {
        toast.error(data.error);
        return;
      }
      toast.success("Allocation successful.");
      onSave();
    },
  });

  const table = useReactTable({
    data: BOLData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
  });

  let sum = 0;
  Object.keys(rowSelection).forEach((index) => {
    sum += BOLData[index].truck_lifting_volume;
  });
  return loading ? (
    <LoadingSpinner />
  ) : (
    <>
      <Modal
        id="bol-modal"
        fullscreen
        isOpen
        contentClassName="rounded-0"
        // z-index is necessary to keep bp-header visible at the top with the fullscreen
        zIndex="10"
      >
        <ModalHeader
          id="bol-modal-header"
          className="text-xl mt-11"
          close={<Remove48 onClick={onClose} />}
        >
          Allocate BOLs
        </ModalHeader>
        <ModalBody id="bol-modal-body">
          <p className="h2">
            Add BOLs to your truck rack shipment: {shipmentId}
          </p>
          {renderShipmentInfo(
            "Shipment date",
            displayDate(shipmentData.shipment_date, true),
          )}
          {renderShipmentInfo(
            "Start time",
            displayStartEndDate(
              shipmentData.shipment_start_date,
              shipmentData.shipment_date,
            ),
          )}
          {renderShipmentInfo(
            "End time",
            displayStartEndDate(
              shipmentData.shipment_end_date,
              shipmentData.shipment_date,
            ),
          )}
          <div className="bol-modal-table w-[60%]">
            <Table>
              <thead>
                <tr>
                  {table.getFlatHeaders().map((header) => (
                    <th
                      key={header.id}
                      style={{
                        width:
                          header.getSize() === 150 ? "auto" : header.getSize(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.id} className="standard-row">
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            key={cell.id}
                            style={{
                              backgroundColor: row.getIsSelected()
                                ? "#f6f8fd"
                                : "",
                              width:
                                cell.column.getSize() === 150
                                  ? "auto"
                                  : cell.column.getSize(),
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                {renderVolumeSummaryRow(
                  "Actual volume",
                  sum + shipmentData.parts[0].total_actual_volume,
                  true,
                  decimalFormat,
                )}
                {renderVolumeSummaryRow(
                  "Estimated shipment volume",
                  shipmentData.total_estimated_volume,
                  false,
                  decimalFormat,
                )}
              </tbody>
            </Table>
          </div>
          <div className="bol-modal-buttons flex w-[60%]">
            <Button
              onClick={() => resetAllocateBol({variables: {shipmentId}})}
              className="btn btn-light rounded-0 mr-auto"
            >
              Reset allocation
            </Button>
            <Button className="btn btn-light rounded-0 mr-2" onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={_.isEmpty(rowSelection)}
              className="btn btn-light rounded-0"
              onClick={() =>
                allocateBol({
                  variables: {
                    request: {
                      dtn_bol_layer_id: Object.keys(rowSelection).map(
                        (index) => BOLData[index].dtn_bol_layer_id,
                      ),
                      dtn_shipment_id: shipmentData.shipment_id,
                    },
                  },
                })
              }
            >
              Add BOLs
            </Button>
          </div>
        </ModalBody>
      </Modal>
      {!_.isEmpty(selectedBOLForSplit) && (
        <SplitBOLModal
          onClose={() => setSelectedBOLForSplit({})}
          onSave={() => {
            setSelectedBOLForSplit({});
            refetch();
          }}
          bol={selectedBOLForSplit}
        />
      )}
    </>
  );
};

BOLModal.propTypes = {
  onClose: PropTypes.func,
  shipmentId: PropTypes.string,
  shipmentData: PropTypes.object,
  onSave: PropTypes.func,
};

export default BOLModal;
