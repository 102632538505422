import {gql} from "@apollo/client";

export const COPRO_EU_GET_NOTIFICATION = gql`
  query GetEuNotification {
    bioLcCoproEuGetNotification {
      attention
      readyForMb
      additionalInfo
    }
  }
`;
export default COPRO_EU_GET_NOTIFICATION;
