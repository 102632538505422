import React, {useMemo, useState, useCallback, useEffect} from "react";
import {Button} from "reactstrap";
import {toast} from "react-toastify";
import {useQuery} from "@apollo/client";
import {COPRO_EU_GET_CERTIFICATE_OUTBOUND} from "graphql/es-co-processing/CertificateOutbound";
import {SendCertificateEmailAPI} from "graphql/es-co-processing/mass-balance/sendCertificatesEmail";
import {useUserSettings} from "providers/userSettings";
import {useAccount, useMsal} from "@azure/msal-react";
import CustomizeTable from "../components/CustomizeTable/CustomizeTable";
import Layout from "../components/Layout";
import getColumns from "../components/outgoing-documents/Columns";
import DocumentTable from "../components/DocumentTable/DocumentTable";

const OutgoingDocumentPage = () => {
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);
  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();

  const [highlightedRows, setHighlightedRows] = useState([]);
  const [rowSelection, setRowSelection] = useState({});

  const handleSendCertificate = useCallback(
    async (outboundEuQtyId) => {
      await SendCertificateEmailAPI({
        changedBy: account?.username,
        recordsToBeSent: [outboundEuQtyId],
      });
      setHighlightedRows([outboundEuQtyId]);
      setRowSelection({});
      toast.success("Certificates sent");
    },
    [account?.username],
  );

  const baseColumns = useMemo(
    () => getColumns(dateFormat, decimalFormat, handleSendCertificate),
    [dateFormat, decimalFormat, handleSendCertificate],
  );

  const [invisibleCols, setInvisibleCols] = useState([]);

  const {data, loading} = useQuery(COPRO_EU_GET_CERTIFICATE_OUTBOUND);

  const tableData = loading ? [] : data?.bioLcCoproEuGetCertificateOutbound;

  const filteredColumns = baseColumns.filter(
    ({key}) => !invisibleCols.includes(key),
  );

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: "Co-processing", link: "/copro-spain"},
    {text: "Document manager", link: "/copro-spain"},
    {
      text: "Outgoing",
      link: "/copro-spain/document-manager/outgoing-documents",
    },
  ];

  return (
    <Layout title="Outgoing documents" breadcrumbs={breadcrumbItems}>
      <div className="flex flex-col justify-stretch w-full items-start px-7">
        <div className="my-3 bg-white">
          <CustomizeTable
            columns={baseColumns}
            onColumnChange={(cols) =>
              setInvisibleCols(
                cols
                  .filter((col) => col.visible === false)
                  .map((col) => col.key),
              )
            }
          />
        </div>
        <div className="border-secondary border-top w-full">
          <DocumentTable
            columns={filteredColumns}
            data={tableData}
            enableRowSelection
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            className="outgoing-docs-table"
            highlightedRows={highlightedRows}
            loading={loading}
          />
        </div>
        <div>
          <Button
            className="rounded-0 mb-40"
            type=""
            onClick={async () => {
              const selectedRecords = Object.keys(rowSelection)
                .map((index) => Number(index))
                .map((index) => tableData[index]);

              if (
                selectedRecords
                  .map(({saleStatus}) => saleStatus)
                  .includes("VOIDED")
              ) {
                toast.error(
                  "Cannot send certificate for removed assignment, please remove those and try again.",
                );
                return;
              }

              const selectedIds = selectedRecords.map(
                ({outboundEuQtyId}) => outboundEuQtyId,
              );

              await SendCertificateEmailAPI({
                changedBy: account?.username,
                recordsToBeSent: selectedIds,
              });
              setHighlightedRows(selectedIds);
              setRowSelection({});
              toast.success("Certificates sent");
            }}
          >
            Send certificate
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default OutgoingDocumentPage;
