import {useState} from "react";
import {toast} from "react-toastify";
import {useLazyQuery} from "@apollo/client";
import {Close} from "@bphxd/ds-core-react";
import {
  Button,
  ModalBody,
  ModalFooter,
  Modal,
  ModalHeader,
  Spinner,
} from "reactstrap";
import {
  BUTTONS,
  ADD_BATCH_FORM,
  STEPS,
  INITIAL_BATCH,
} from "modules/co-processing/constants/batches";
import {SAVE_BATCH} from "graphql/coprocessing/batches";
import {FormSummary, Form} from "./FormElements";
import {
  StepIndicator,
  StepTwo,
  StepThree,
  SaveBatch,
  TankNumber,
} from "./Steps";
import SuccessView from "./SuccessView";
import "./index.scss";
import useBatchModal from "./useModal";

const CURRENT_FORM_STEP = {
  [STEPS.INITIAL]: <TankNumber />,
  [STEPS.SECOND]: <StepTwo />,
  [STEPS.THIRD]: <StepThree />,
  [STEPS.SAVE]: <SaveBatch />,
};

const AddBatchModal = () => {
  const {isModalOpen, openModal, closeModal} = useBatchModal();

  const [batch, setBatch] = useState(INITIAL_BATCH);
  const [formStep, setFormStep] = useState(STEPS.INITIAL);

  const onNext = () => setFormStep(formStep + 1);
  const onBack = () => setFormStep(formStep - 1);

  const [saveBatch, {called, loading: saving}] = useLazyQuery(SAVE_BATCH, {
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (result?.bioLcCoproUsBatchesApi?.statusCode === 200) {
        setBatch(INITIAL_BATCH);
        onNext();
      } else {
        toast.error("Something went wrong, failed to save batch");
      }
    },
  });

  const handleSubmit = async (formData) => {
    try {
      const batchDetails = {...batch, ...formData};
      if (formStep === STEPS.SAVE) {
        await saveBatch({
          variables: {batch_details: batchDetails},
          onError: (error) => {
            closeModal();
            toast.error(`Failed to add batch: ${error.message}`);
          },
        });
      } else {
        setBatch((prev) => ({...prev, ...batchDetails}));
        onNext();
      }
    } catch (error) {
      // Handle exceptions
      console.error("Failed to add batch", error);
      toast.error("An unexpected error occurred.");
    }
  };

  return (
    <>
      <Button
        color="tertiary"
        className="bg-white rounded-0 ml-auto"
        onClick={openModal}
      >
        {BUTTONS.ADD_BATCH}
      </Button>

      {isModalOpen() && (
        <Modal
          className="lg:px-40"
          contentClassName="rounded-0"
          isOpen={isModalOpen()}
          size="lg"
        >
          <ModalHeader
            className="border border-b rounded-0"
            close={<Close onClick={closeModal} />}
          >
            <div className="pl-3 fs-5">{ADD_BATCH_FORM.title}</div>
          </ModalHeader>

          <ModalBody className="p-0">
            {formStep === STEPS.SUCCESS ? (
              <SuccessView
                onReset={() => setFormStep(STEPS.INITIAL)}
                closeModal={closeModal}
              />
            ) : (
              <Form defaultValues={batch} onSubmit={handleSubmit}>
                <StepIndicator currentStep={formStep} />
                <FormSummary formStep={formStep} />
                {CURRENT_FORM_STEP[formStep]}
              </Form>
            )}
          </ModalBody>

          {formStep < STEPS.SUCCESS ? (
            <ModalFooter>
              <div className="addBatch__btn-group">
                {formStep > STEPS.INITIAL ? (
                  <Button color="light" onClick={onBack}>
                    {BUTTONS.BACK}
                  </Button>
                ) : null}

                <Button
                  type="submit"
                  form="add_batch_form"
                  color="primary"
                  disabled={saving} // Disable button when mutation is in progress
                >
                  {called && saving && (
                    <Spinner size="sm" className="btn-icon-prefix" />
                  )}
                  {formStep < STEPS.SAVE
                    ? BUTTONS.CONTINUE
                    : BUTTONS.SAVE_BATCH}
                </Button>
              </div>
            </ModalFooter>
          ) : null}
        </Modal>
      )}
    </>
  );
};

export default AddBatchModal;
