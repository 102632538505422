import "../common/index.scss";
import Fuse from "fuse.js";
import {useState, useMemo} from "react";
import {
  GeneralActionsMenu,
  DownloadCSVItem,
  DownloadXLSXItem,
  DeleteItemCoPro,
} from "modules/common/Table/ActionsMenu";
import DetailedModal from "modules/common/Table/ActionsMenu/DetailedModal";
import {useUserSettings, applyDateFormat} from "providers/userSettings";
import fileNames from "utils/helpers/uniqueFileName.js";
import {GET_MANUAL_ADJUSTMENT} from "graphql/gy-enablement/MOT/manualAdjustment";
import {paginationSizeMOT, paginationPageSizeOptions} from "constants/config";
import {useQuery} from "@apollo/client";
import {HEADER_MOT_MANUAL_ADJUSTMENT} from "modules/gy-enablement/constants/Headers";
import useRowSelection from "modules/common/Table/useRowSelection";
import {
  createManualAdjustmentApi,
  deleteManualAdjustmentApi,
  manualAdjustmentExportApi,
} from "graphql/gy-enablement/MOT/manualAdjustmentHelpers";
import {
  BasePageLayout,
  BasePageLayoutContent,
} from "modules/common/BasePageLayout";
import DownloadAnnualItem from "modules/common/Table/ActionsMenu/DownloadAnnualItem";
import {getmotManualAdjustmentColumns} from "../../content/motManualAdjustmentColumns";
import {motTabData} from "../../content/tabData";
import MOTValidationReportFilter from "../common/MOTValidationReportFilter";
import TableWidget from "../../../common/Table/TableWidget";
import ManualAdjustmentForm from "./ManualAdjustmentForm";
import {editionFormSchema} from "../../schemas/MOTManualAdjustment";

const fuseOptions = {
  keys: [
    "companyCode",
    "loadPlantName",
    "eDGroupName",
    "commodityCode",
    "category",
    "taxLaw",
    "uoM",
    "formatDeclaration",
    "formatFisc",
    "calEDQty",
    "mainCustomsOffice",
    "description",
  ],
  threshold: 0.0,
  includeScore: true,
  ignoreLocation: true,
};

const ManualAdjustmentDashboard = () => {
  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();
  const {data, loading} = useQuery(GET_MANUAL_ADJUSTMENT, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const motManualAdjustmentColumns =
    getmotManualAdjustmentColumns(decimalFormat);

  const manualAdjustmentSummary = useMemo(
    () => data?.getManualAdjustmentAurora?.data ?? [],
    [data?.getManualAdjustmentAurora?.data],
  );

  const [selectedRows, rowSelection] = useRowSelection(manualAdjustmentSummary);

  // ------------------------------------------------------
  // search section
  const [search, setSearch] = useState("");

  const items = useMemo(() => {
    const dataFuse = new Fuse(manualAdjustmentSummary, fuseOptions);
    return search
      ? dataFuse.search(search).map(({item}) => item)
      : manualAdjustmentSummary;
  }, [manualAdjustmentSummary, search]);

  const formatedDashboardData = applyDateFormat(
    items,
    [],
    dateFormat,
    "",
    [],
    decimalFormat,
    0,
  );
  // ------------------------------------------------------

  // ------------------------------------------------

  const batchActionsContent = useMemo(
    () => (
      <GeneralActionsMenu>
        <DownloadAnnualItem
          pageTitle="Please select the year for which you need to download the Manual Adjustment data"
          format="csv"
          onDownload={async (year) => {
            const {
              data: {manualAdjustmentExport: response},
            } = await manualAdjustmentExportApi("CSV", year, []);
            return response;
          }}
        />
        <DownloadAnnualItem
          format="xlsx"
          pageTitle="Please select the year for which you need to download the Manual Adjustment data"
          onDownload={async (year) => {
            const {
              data: {manualAdjustmentExport: response},
            } = await manualAdjustmentExportApi("XLSX", year, []);
            return response;
          }}
        />
        <DownloadXLSXItem
          data={selectedRows}
          onDownload={async (rows) => {
            const isAll =
              selectedRows.length === manualAdjustmentSummary.length;
            const {
              data: {manualAdjustmentExport: response},
            } = await manualAdjustmentExportApi(
              "XLSX",
              undefined,
              isAll ? [] : rows,
            );
            return response;
          }}
        />
        <DownloadCSVItem
          data={selectedRows}
          onDownload={async (rows) => {
            const isAll =
              selectedRows.length === manualAdjustmentSummary.length;
            const {
              data: {manualAdjustmentExport: response},
            } = await manualAdjustmentExportApi(
              "CSV",
              undefined,
              isAll ? [] : rows,
            );
            return response;
          }}
        />
        <DetailedModal
          mode="add"
          formContent={<ManualAdjustmentForm />}
          enableNextPrev={false}
          tableState={{data: []}}
          record={{}}
          schema={editionFormSchema}
          getModalTitle={() => "Add Following Item"}
          getDataWithDates={(dataitems) => ({...dataitems})}
          onSubmit={async (values) => {
            const {
              data: {createManualAdjustment: response},
            } = await createManualAdjustmentApi(values, decimalFormat);
            return response;
          }}
        />
        <DetailedModal
          disabled={!(selectedRows && selectedRows?.length === 1)}
          mode="clone"
          formContent={<ManualAdjustmentForm data={selectedRows?.[0]} />}
          enableNextPrev={false}
          tableState={{data: []}}
          record={{}}
          schema={editionFormSchema}
          getModalTitle={() => "Clone Following Item"}
          getDataWithDates={(dataitems) => ({...dataitems})}
          onSubmit={async (values) => {
            const {
              data: {createManualAdjustmentAurora: response},
            } = await createManualAdjustmentApi(values, decimalFormat);
            return response;
          }}
        />
        <DeleteItemCoPro
          disabled={!(selectedRows && selectedRows?.length === 1)}
          columns={motManualAdjustmentColumns}
          data={selectedRows?.[0]}
          onSubmit={async (values) => {
            const {
              data: {deleteManualAdjustmentAurora: response},
            } = await deleteManualAdjustmentApi({
              id: selectedRows?.[0].id,
              ...values,
            });
            return response;
          }}
        />
      </GeneralActionsMenu>
    ),
    [
      manualAdjustmentSummary,
      selectedRows,
      decimalFormat,
      motManualAdjustmentColumns,
    ],
  );

  return (
    <BasePageLayout
      pageTitle={HEADER_MOT_MANUAL_ADJUSTMENT}
      tabsData={motTabData}
    >
      <BasePageLayoutContent>
        <MOTValidationReportFilter
          batchActionsContent={batchActionsContent}
          setSearch={setSearch}
          search={search}
        />
        <TableWidget
          data-test="manual-adjustment-dashboard"
          columns={motManualAdjustmentColumns}
          data={formatedDashboardData}
          loading={loading}
          rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            showSizeChanger: true,
            total: formatedDashboardData?.length,
            showTotal: (total) => `Total ${total} items`,
            position: ["bottomLeft"],
            defaultPageSize: paginationSizeMOT,
            pageSizeOptions: paginationPageSizeOptions,
          }}
        />
      </BasePageLayoutContent>
    </BasePageLayout>
  );
};

export default ManualAdjustmentDashboard;
