import {
  Check24,
  CircleClose24,
  Email24,
  Kebab24,
} from "@bphxd/ds-core-react/lib/icons";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {Link} from "react-router-dom";
import moment from "moment";
import {formatNumber} from "providers/userSettings";
import HighLightedText from "./HighLightedText";

const showStatus = (status, globalFilter) => {
  switch (status) {
    case "Generated":
      return (
        <div className="flex items-center">
          <Check24 />
          <span className="ml-2">
            <HighLightedText value={status} globalFilter={globalFilter} />
          </span>
        </div>
      );
    case "Sent":
      return (
        <div className="flex items-center">
          <Email24 />
          <span className="ml-2">
            <HighLightedText value={status} globalFilter={globalFilter} />
          </span>
        </div>
      );
    default:
      return (
        <div className="flex items-center">
          <CircleClose24 />
          <span className="ml-2">
            <HighLightedText value={status} globalFilter={globalFilter} />
          </span>
        </div>
      );
  }
};

const getColumns = (dateFormat, decimalFormat, handleSendCertificate) => [
  {
    header: "Status",
    key: "status",
    accessorKey: "status",
    visible: true,
    disabled: true,
    size: 100,
    cell: ({
      cell,
      row: {
        original: {status},
      },
    }) => showStatus(status, cell.getContext().table.getState().globalFilter),
  },
  {
    header: "Certificate ID",
    accessorKey: "sd_number",
    key: "sd_number",
    visible: true,
    disabled: true,
    cell: ({cell}) => {
      return (
        <Link
          to="/copro-spain/document-manager/outgoing-documents/view"
          state={{
            sd_number: cell.getValue(),
            certificate_pdf_url: cell.row?.original?.certificate_pdf_url,
          }}
          className="link-dark"
        >
          <u>
            <HighLightedText
              value={cell.getValue()}
              globalFilter={cell.getContext().table.getState().globalFilter}
            />
          </u>
        </Link>
      );
    },
  },
  {
    header: "Date of issuance",
    key: "date_of_issuance",
    accessorKey: "date_of_issuance",
    filterFn: "dateRangeFilterFn",
    cell: ({cell}) => {
      return (
        <HighLightedText
          value={moment(cell.getValue()).format(dateFormat)}
          globalFilter={cell.getContext().table.getState().globalFilter}
        />
      );
    },
    visible: true,
  },
  {
    header: "Product",
    accessorKey: "product_type",
    key: "product_type",
    visible: false,
    cell: ({cell}) => {
      return (
        <HighLightedText
          value={cell.getValue()}
          globalFilter={cell.getContext().table.getState().globalFilter}
        />
      );
    },
  },
  {
    header: "Raw Material",
    accessorKey: "raw_material_type",
    key: "raw_material_type",
    size: 110,
    visible: true,
    cell: ({cell}) => {
      return (
        <HighLightedText
          value={cell.getValue()}
          globalFilter={cell.getContext().table.getState().globalFilter}
        />
      );
    },
  },
  {
    header: "Quantity m3",
    accessorKey: "product_qty",
    key: "product_qty",
    size: 100,
    visible: true,
    cell: ({
      cell,
      row: {
        original: {product_qty_uom},
      },
    }) => {
      return (
        <HighLightedText
          value={
            product_qty_uom === "M3" &&
            formatNumber(cell.getValue(), decimalFormat, 2)
          }
          globalFilter={cell.getContext().table.getState().globalFilter}
        />
      );
    },
  },
  {
    header: "Quantity mt",
    accessorKey: "product_qty",
    key: "product_qty",
    size: 100,
    visible: false,
    cell: ({
      cell,
      row: {
        original: {product_qty_uom},
      },
    }) => {
      return (
        <HighLightedText
          value={
            product_qty_uom === "MT" &&
            formatNumber(cell.getValue(), decimalFormat, 2)
          }
          globalFilter={cell.getContext().table.getState().globalFilter}
        />
      );
    },
  },
  {
    header: "Recipient",
    accessorKey: "recipient_name",
    key: "recipient_name",
    size: 200,
    visible: true,
    cell: ({cell}) => {
      return (
        <HighLightedText
          value={cell.getValue()}
          globalFilter={cell.getContext().table.getState().globalFilter}
        />
      );
    },
  },
  {
    header: "Receiving point",
    accessorKey: "recipient_receipt_address",
    key: "recipient_receipt_address",
    size: 120,
    visible: false,
    cell: ({cell}) => {
      return (
        <HighLightedText
          value={cell.getValue()}
          globalFilter={cell.getContext().table.getState().globalFilter}
        />
      );
    },
  },
  {
    header: "Contract Number",
    accessorKey: "contract_number",
    key: "contract_number",
    size: 200,
    visible: false,
    cell: ({cell}) => {
      return (
        <HighLightedText
          value={cell.getValue()}
          globalFilter={cell.getContext().table.getState().globalFilter}
        />
      );
    },
  },
  {
    header: "GHG total",
    accessorKey: "ghg_total",
    key: "ghg_total",
    size: 110,
    visible: false,
    cell: ({cell}) => {
      return (
        <HighLightedText
          value={formatNumber(cell.getValue(), decimalFormat, 2)}
          globalFilter={cell.getContext().table.getState().globalFilter}
        />
      );
    },
  },
  {
    header: "GRN",
    key: "grn",
    accessorKey: "grn",
    visible: false,
    cell: ({cell}) => {
      return (
        <HighLightedText
          value={cell.getValue()}
          globalFilter={cell.getContext().table.getState().globalFilter}
        />
      );
    },
  },
  {
    header: "PO number",
    accessorKey: "po_number",
    key: "po_number",
    visible: false,
    cell: ({cell}) => {
      return (
        <HighLightedText
          value={formatNumber(cell.getValue(), decimalFormat, 2)}
          globalFilter={cell.getContext().table.getState().globalFilter}
        />
      );
    },
  },
  {
    header: "Mass Balance",
    accessorKey: "mb_location",
    cell: ({cell}) => (
      <Link
        to={`/saf-sweden/mass-balance/${cell.row.original.sd_number}`}
        className="link-dark"
      >
        <u>
          <HighLightedText
            value={`Mass-${cell.row.original.sd_number}`}
            globalFilter={
              cell?.getContext()?.table?.getState()?.globalFilter || ""
            }
          />
        </u>
      </Link>
    ),
    key: "mb_location",
    size: 200,
    visible: true,
  },
  {
    header: "",
    accessorKey: "actions",
    key: "actions",
    size: 25,
    visible: true,
    enableSorting: false,
    cell: ({
      row: {
        original: {sd_number, certificate_outbound_eu_id, status},
      },
    }) => {
      return (
        <UncontrolledDropdown direction="up">
          <DropdownToggle color="standard-quartenary" className="!px-0">
            <Kebab24></Kebab24>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem>
              <Link
                to={`/saf-sweden/mass-balance/${sd_number}`}
                className="link-dark"
              >
                <span>View document</span>
              </Link>
            </DropdownItem>
            <DropdownItem
              onClick={() => handleSendCertificate(certificate_outbound_eu_id)}
              disabled={status === "Removed"}
            >
              Send certificate
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    },
  },
];

export default getColumns;
