import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

export const REMOVE = gql`
  mutation Remove(
    $inboundEuQtyId: String!
    $sdNumber: String!
    $remarks: String!
    $createdBy: String!
  ) {
    bioLcCoproEuMassBalanceRemove(
      event: {
        inboundEuQtyId: $inboundEuQtyId
        sdNumber: $sdNumber
        remarks: $remarks
        createdBy: $createdBy
      }
    ) {
      message
      status
      statusCode
    }
  }
`;

export const RemoveAPI = (data) =>
  client.mutate({
    mutation: REMOVE,
    variables: {
      ...data,
    },
    refetchQueries: [
      "bioLcCoproEuMassBalanceAllRecords",
      "bioLcCoproEuMassBalanceGetCycleStatus",
    ],
  });

export default {
  REMOVE,
  RemoveAPI,
};
