import PropTypes from "prop-types";

import Tabs from "modules/common/Tabs";
import {Helmet} from "react-helmet";
import Heading from "DLS/Typography/Heading";

export const BasePageLayout = ({
  pageTitle,
  tabsData,
  rightContent,
  children,
  ...props
}) => (
  <div {...props}>
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
    {/* Sub gray bar */}
    <div className="flex items-center justify-between px-6 bg-gray-100 h-14 lg:px-10 gap-x-2">
      <Heading level={5} data-test="page-title">
        {pageTitle}
      </Heading>
      {rightContent}
    </div>
    {tabsData ? (
      <div className="w-full bg-gray-50">
        <Tabs className="px-6 py-8 lg:px-10" data={tabsData} />
      </div>
    ) : null}

    {children}
  </div>
);

export const BasePageLayoutContent = ({children}) => (
  <div className="px-6 lg:px-10">{children}</div>
);

BasePageLayout.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  tabsData: PropTypes.arrayOf(PropTypes.object),
  rightContent: PropTypes.node,
  children: PropTypes.node.isRequired,
};

BasePageLayoutContent.propTypes = {
  children: PropTypes.node.isRequired,
};
