import {Button} from "reactstrap";
import {Down24, Download24, Filter24} from "@bphxd/ds-core-react/lib/icons";
import {useQuery} from "@apollo/client";
import {COPRO_US_API_DATA} from "graphql/coprocessing/receipts";
import {useDateRange} from "modules/co-processing/context/DateRangeContext";
import {feedstockHeaders} from "../constants/receipts";
import {BUTTONS} from "../constants/coProcessing";
import buildColumns from "../helpers/buildColumns";
import buildExportFn from "../helpers/exportDataExcel";
import Table from "../common/Table";
import TableSearch from "./TableSearch";

const FeedstockTracking = () => {
  const {dateRange} = useDateRange();

  const initialVariables = {
    tracking_type: "feedstock_table",
    date_range: {
      start_date: dateRange.from,
      end_date: dateRange.to,
    },
    search_text: "",
  };

  const {loading, data, refetch} = useQuery(COPRO_US_API_DATA, {
    variables: initialVariables,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const dataColumns = buildColumns(feedstockHeaders);

  let feedstockData = null;
  let exportDataExcel = null;
  let exportSheets = null;

  if (!loading) {
    feedstockData = data?.bioLcCoproUsApi?.data ?? [];
    exportSheets = data?.bioLcCoproUsApi?.sheets_data ?? [];
    exportDataExcel = buildExportFn(
      feedstockData,
      exportSheets,
      "FeedstockTracking.xlsx",
    );
  }

  return (
    <div data-test="copro-feedstock-tracking" className="bp-core">
      <div className="copro-table">
        <div className="copro-table-header flex flex-row">
          <div className="pl-2 grow justify-start">
            <Button
              data-test="copro-supplies-btn"
              color="standard-tertiary rounded-0"
              disabled
            >
              {BUTTONS.ALL_SUPPLIERS}
              <Down24 className="btn-icon-suffix" />
            </Button>
          </div>
          <div className="flex flex-none flex-row items-center mx-5">
            <div className="mr-2">
              {/* TODO: Ability to filter data - STORY 323958  */}
              <Button
                data-test="copro-filter-btn"
                color="standard-tertiary rounded-0"
                disabled
              >
                {BUTTONS.FILTER}
                <Filter24 className="btn-icon-suffix" />
              </Button>
            </div>
            <div className="mr-2">
              <Button
                data-test="copro-export-btn"
                color="standard-tertiary rounded-0"
                size="sm"
                onClick={exportDataExcel}
              >
                {BUTTONS.EXPORT}
                <Download24 className="btn-icon-suffix" />
              </Button>
            </div>
            <TableSearch refetch={refetch} variables={initialVariables} />
          </div>
        </div>
        <Table columns={dataColumns} data={feedstockData} loading={loading} />
      </div>
    </div>
  );
};

export default FeedstockTracking;
