import {gql} from "@apollo/client";
import axios from "axios";
import {client} from "providers/Apollo";

export const UPLOAD_FILE = gql`
  query bioLcSafUploadDocument($doc_name: String!) {
    bioLcSafUploadDocument(
      event: {
        division_id: "7c5fc8889b6e3c8756abbd76353d68e7"
        doc_name: $doc_name
        site_reference_id: "c8f4261f9f46e6465709e17ebea7a92b"
      }
    ) {
      error
      statusCode
      upload_url
    }
  }
`;

export const DOC_STATUS_TRACKER = gql`
  query bioLcSafDocStatusTracker($doc_name: String!) {
    bioLcSafDocStatusTracker(
      event: {
        division_id: "7c5fc8889b6e3c8756abbd76353d68e7"
        doc_name: $doc_name
        site_reference_id: "c8f4261f9f46e6465709e17ebea7a92b"
      }
    ) {
      error
      status
      statusCode
    }
  }
`;

export const uploadFile = async (file, countryCode) => {
  const isResponseSuccess = (statusCode) => [200, 204].includes(statusCode);

  const buildFormData = (fileData, fields) => {
    const formData = new FormData();
    if (fields) {
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
    }
    formData.append("file", fileData);
    return formData;
  };

  let statusCode;

  try {
    // Allocate s3 bucket
    const {
      data: {bioLcSafUploadDocument: responseFileUpload},
    } = await client.query({
      query: UPLOAD_FILE,
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        doc_name: file.name,
      },
    });

    statusCode = responseFileUpload?.statusCode;
    if (!isResponseSuccess(statusCode)) {
      return statusCode;
    }

    // Upload binary file
    const body = JSON.parse(responseFileUpload?.upload_url);
    const formData = buildFormData(file, body.fields);
    const uploadBinaryResponse = await axios.post(body.url, formData);
    statusCode = uploadBinaryResponse.status;
    if (!isResponseSuccess(statusCode)) {
      return statusCode;
    }

    // All good
    return 200;
  } catch (error) {
    // Handle errors here
    console.error("Error uploading file:", error);
    return error.response?.status || 500;
  }
};
