import _ from "lodash";

const CHAR_CODE_Z = "Z".charCodeAt(0);
const START_LETTER = "A";

// Generates the next character in the alphabet or throws an error if it's 'Z'
function nextChar(c) {
  const charCode = c.charCodeAt(0);

  if (charCode === CHAR_CODE_Z) {
    throw new Error("No more letters available for suffix.");
  }

  // Compute the next ASCII code and convert it to a character
  return String.fromCharCode(charCode + 1);
}

// Finds the next available suffix
export const findNextAvailableSuffix = (existingSuffixes) => {
  let suffix = START_LETTER;
  while (existingSuffixes.includes(suffix)) {
    suffix = nextChar(suffix);
  }
  return suffix;
};

// Generates the next available batch ID with a suffix letter starting from 'A',
// skipping those already in use.
export const generateSplitBatchId = (selectedBatchId, existingIds) => {
  const [tankNumber, batchCode] = selectedBatchId.split(".");

  // Extract suffixes from existing IDs and sort them
  const existingSuffixes = existingIds
    .filter(
      (id) => id.startsWith(`${selectedBatchId}_`) || id === selectedBatchId,
    )
    .map((id) => id.split("_").pop())
    .sort();

  // Find the next available suffix
  const nextSuffix = findNextAvailableSuffix(existingSuffixes);

  return `${tankNumber}.${batchCode}_${nextSuffix}`;
};
