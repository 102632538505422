import {Input, Label} from "reactstrap";
import {useEffect} from "react";
import {useUserSettings, formatNumber} from "providers/userSettings";
import {useFormContext, Controller} from "react-hook-form";
import PropTypes from "prop-types";
import FormField from "modules/common/FormFieldNew.js";

const WriteOffDetails = ({data}) => {
  const {
    formState: {errors},
    trigger,
  } = useFormContext();

  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  return (
    <div className="grid sm:grid-cols-1 text-left md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-x-4 gap-y-4 ">
      <FormField label="Quantity" errorMessage={errors.quantity?.message}>
        <Controller
          name="quantity"
          defaultValue={data?.quantity || undefined}
          render={({field}) => (
            <Input
              {...field}
              type="number"
              id="quantity"
              data-test="quantity"
              invalid={!!errors.quantity}
            />
          )}
        />
        <Label className="text-sm font-light text-gray-600">{`You have ${formatNumber(
          parseFloat(data?.outboundRecords?.qtyAllocated),
          decimalFormat,
          2,
        )} t available`}</Label>
      </FormField>
      <FormField
        label="Reason for write off"
        errorMessage={errors.writeOffreason?.message}
      >
        <Controller
          name="writeOffreason"
          defaultValue={data?.writeOffreason || undefined}
          render={({field}) => (
            <Input
              {...field}
              type="textarea"
              id="writeOffreason"
              data-test="writeOffreason"
              invalid={!!errors.writeOffreason}
              maxLength={100}
            />
          )}
        />
      </FormField>
    </div>
  );
};

WriteOffDetails.propTypes = {
  data: PropTypes.object,
};

export default WriteOffDetails;
