import React, {useCallback, useMemo, useState} from "react";
import {Button, InputGroup, InputGroupText, Input} from "reactstrap";
import {Filter24} from "@bphxd/ds-core-react/lib/icons";
import SvgSearch24 from "@bphxd/ds-core-react/lib/icons/interface/Search24";
import {useUserSettings} from "providers/userSettings";
import {toast} from "react-toastify";
import SAF_GET_OUTGOING_DOCUMENTS from "graphql/saf/OutgoingDocuments";
import {useQuery} from "@apollo/client";
import DocumentTable from "./DocumentTable";
import getColumns from "./columns";
import CustomizeTable from "../CustomizeTable/CustomizeTable";
import OutgoingFilters from "../OutgoingFilters";

const OutgoingDocuments = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [highlightedRows, setHighlightedRows] = useState([]);
  const [filtering, setFiltering] = useState("");
  const [columnFilter, setColumnFilter] = useState([]);

  const [customerName, setCustomerName] = useState(undefined);
  const [dateFrom, setDateFrom] = useState(undefined);
  const [dateTo, setDateTo] = useState(undefined);

  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();

  const {data, loading} = useQuery(SAF_GET_OUTGOING_DOCUMENTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const handleSendCertificate = useCallback(
    async (certificate_outbound_eu_id) => {
      // This function will be updated in future stories, at that time, this log can be removed.
      console.log("handleSendCertificate", certificate_outbound_eu_id);
    },
    [],
  );

  const baseColumns = useMemo(
    () => getColumns(dateFormat, decimalFormat, handleSendCertificate),
    [dateFormat, decimalFormat, handleSendCertificate],
  );

  const [columns, setColumns] = useState(baseColumns);

  const filteredColumns = useMemo(
    () => columns.filter(({visible}) => visible),
    [columns],
  );

  const tableData = loading ? [] : data?.bioLcSafGetOutgoingDocs?.documents;

  const handleFilterSubmit = (data) => {
    if (data) {
      setCustomerName(data.recipient_name);
      setDateFrom(data.date_of_issuance ? data.date_of_issuance[0] : undefined);
      setDateTo(data.date_of_issuance ? data.date_of_issuance[1] : undefined);
      const convertedObjects = Object.entries(data)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => ({id: key, value}));

      setColumnFilter(convertedObjects);
    } else {
      setCustomerName(undefined);
      setDateFrom(undefined);
      setDateTo(undefined);
    }
  };

  return (
    <div className="flex flex-col justify-stretch w-full items-start px-7 pt-16">
      <div className=" flex flex-row justify-between w-full p-4 bg-white">
        <div>
          <CustomizeTable
            columns={columns}
            onColumnChange={(cols) => setColumns(cols)}
            showApplyButton={false}
          />
        </div>
        <div className="flex flex-none flex-row items-center mx-5">
          <div className="mr-2">
            <Button
              data-test="copro-filter-btn"
              color="standard-tertiary rounded-0"
              onClick={() => setShowFilter(!showFilter)}
            >
              Filters
              <Filter24 className="btn-icon-suffix" />
            </Button>
          </div>
          <div>
            <InputGroup className="input-group-merge" size="">
              <Input
                className="form-control-appended"
                placeholder="Search"
                value={filtering}
                onChange={(e) => setFiltering(e.target.value)}
              />
              <div className="input-group-append">
                <InputGroupText>
                  <SvgSearch24 />
                </InputGroupText>
              </div>
            </InputGroup>
          </div>
        </div>
      </div>
      {showFilter && (
        <div className="flex flex-col justify-stretch w-full items-start px-7 border-secondary border-top">
          <div className=" flex flex-row w-full my-6">
            <OutgoingFilters
              onSubmit={handleFilterSubmit}
              customerList={[
                {customer_name: "Shubham"},
                {customer_name: "khatri"},
              ]}
              setColumnFilter={setColumnFilter}
              customerName={customerName}
              dateFrom={dateFrom}
              dateTo={dateTo}
            />
          </div>
        </div>
      )}
      <div className="border-secondary border-top w-full">
        <DocumentTable
          columns={filteredColumns}
          data={tableData}
          enableRowSelection
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          className="outgoing-docs-table"
          highlightedRows={highlightedRows}
          loading={loading}
          filtering={filtering}
          setFiltering={setFiltering}
          columnFilter={columnFilter}
          setColumnFilter={setColumnFilter}
        />
      </div>
      <div>
        <Button className="rounded-0 mb-40" type="">
          Send email
        </Button>
      </div>
    </div>
  );
};

export default OutgoingDocuments;
