import {useState} from "react";
import {toast} from "react-toastify";
import {useLazyQuery} from "@apollo/client";
import {Close, Step, Steps} from "@bphxd/ds-core-react";
import {Button, ModalBody, ModalFooter, Modal, ModalHeader} from "reactstrap";
import {COPRO_US_SHIPMENTS_API_SAVE_DTN as SAVE_DTN} from "graphql/coprocessing/shipments";
import {
  CREATE_SHIPMENT,
  SHIPMENT_TYPE,
} from "modules/co-processing/constants/shipments";
import {ShipmentType, EnterDetails, Review} from "./Steps";
import {INITIAL_FORM_STATE, FIRST_STEP} from "./formConfigs";
import ShipmentForm from "./Form";
import {formatDTNShipmentFormData} from "./formUtils";
import "./index.scss";

const StepConfig = [
  {id: "SHIPMENT_TYPE", label: "Shipment type", Component: ShipmentType},
  {id: "ENTER_DETAILS", label: "Shipment details", Component: EnterDetails},
  {id: "REVIEW", label: "Review", Component: Review},
];

const CreateShipment = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(FIRST_STEP);
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const resetForm = () => {
    setFormData(INITIAL_FORM_STATE);
    setCurrentStep(FIRST_STEP);
    toggleModal();
  };

  const [saveShipment, {loading: saving}] = useLazyQuery(SAVE_DTN, {
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (result?.bioLcCoproUsShipmentsApi?.statusCode === 200) {
        resetForm();
        toast.success("Shipment created successfully!");
      } else {
        // Shipment creation failure logic but the GraphQL query itself did not fail
        toast.error("Something went wrong, failed to shipment for truck rack");
      }

      toggleModal();
    },
    onError: (error) => {
      // Triggered on  network error or GraphQL error
      console.error("Error when running saveShipment:", error);
      toast.error(
        error.message || "An error occurred. Failed to create shipment.",
      );
      toggleModal();
    },
  });

  const isFullscreen = StepConfig[currentStep]?.id === "ENTER_DETAILS";

  const handleNext = () => {
    const isEnteringDetails = StepConfig[currentStep]?.id === "ENTER_DETAILS";

    if (
      formData.shipment_type === SHIPMENT_TYPE.BACK_TO_UNIT &&
      isEnteringDetails
    ) {
      setFormData({shipment_type: ""});
      setCurrentStep(FIRST_STEP);
      toggleModal();
      return;
    }

    setCurrentStep((prev) => Math.min(prev + 1, StepConfig.length - 1));
  };

  const isLastStep = currentStep === StepConfig.length - 1;

  const handleShipmentFormSubmit = async (formData) => {
    setFormData((currentState) => ({...currentState, ...formData}));

    if (isLastStep) {
      saveShipment({
        variables: {
          dtn_shipment_details: formatDTNShipmentFormData(formData),
        },
      });
    } else {
      handleNext();
    }
  };

  const CurrentFormStep = StepConfig?.[currentStep]?.Component;

  return (
    <>
      <Button
        color="primary"
        className="hover-shadow-elevated rounded-0 ml-auto h-10 text-nowrap"
        onClick={toggleModal}
      >
        {CREATE_SHIPMENT}
      </Button>

      {isModalOpen && (
        <Modal
          contentClassName="rounded-0 shadow-xl overflow-hidden"
          className="lg:px-40"
          isOpen={isModalOpen}
          fullscreen={isFullscreen}
          size="lg"
        >
          <ModalHeader
            className="border border-b rounded-0"
            close={<Close onClick={toggleModal} />}
          >
            <div className="pl-3 fs-5">{CREATE_SHIPMENT}</div>
          </ModalHeader>

          <ModalBody className="p-0 position-relative">
            <ShipmentForm
              defaultValues={formData}
              onSubmit={handleShipmentFormSubmit}
            >
              <Steps activeStep={currentStep} size="sm">
                {StepConfig.map((step) => (
                  <Step data-test={step.id} key={step.id} label={step?.label} />
                ))}
              </Steps>

              <CurrentFormStep
                shipmentType={formData.shipment_type}
                formData={formData}
              />
            </ShipmentForm>
          </ModalBody>

          <ModalFooter>
            <div className="createShipment__btn-group">
              {currentStep > FIRST_STEP && (
                <Button
                  color="light"
                  onClick={() =>
                    setCurrentStep((step) => Math.max(step - 1, FIRST_STEP))
                  }
                >
                  Back
                </Button>
              )}

              <Button
                color="primary"
                className="createShipment__btn"
                disabled={saving}
                form="create_shipment_form"
                type={isLastStep ? "submit" : ""}
              >
                {isLastStep ? CREATE_SHIPMENT : "Continue"}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default CreateShipment;
