export const CREATE_SHIPMENT = "Create shipment";
export const INPUT_METRIC = "bbl";
export const ITEMS_PER_PAGE = 10;
export const MAX_BBL_VOLUME = 400000;
export const VOLUME_PER_HOUR = 1500;
export const CLIPBOARD_TOOLTIP_TIMER = 2000;
export const COPIED = "Copied!";

export const TITLES = {
  SHIPMENTS: "Shipments",
  ACTIVE_BATCHES: "Active batches",
};

export const ACTIVE_BATCH_LABELS = {
  TANK_NO: "Tank no",
  TOTAL_VOLUME: "Total volume",
  REMAINING_VOLUME: "Remaining volume",
  ACTUAL_ENDING_INV: "Ending inventory",
  SHIPMENT: "Shipment",
  SHIPMENT_VOLUME: "Shipment volume",
  SHIPMENT_ID: "Shipment ID",
};

export const ACTIVE_BATCH_PLACEHOLDERS = {
  INVENTORY: "Enter ending inventory",
  ID: "Enter shipment ID",
};

export const DATA_TABS = {
  PI_DATA: "Pi data",
  ORION_DATA: "Orion data",
};

export const ACTIVE_BATCH_KEYS = {
  TANK_NO: "tank_number",
  TOTAL_VOLUME: "total_volume",
  REMAINING_VOLUME: "remaining_volume",
  ACTUAL_ENDING_INV: "ending_inventory",
  MISSING_C14: "missing_c14",
};

export const ACTIVE_BATCH_FIELDS = [
  {
    label: ACTIVE_BATCH_LABELS.TANK_NO,
    key: ACTIVE_BATCH_KEYS.TANK_NO,
    type: "text",
  },
  {
    label: ACTIVE_BATCH_LABELS.TOTAL_VOLUME,
    key: ACTIVE_BATCH_KEYS.TOTAL_VOLUME,
    type: "text",
  },
  {
    label: ACTIVE_BATCH_LABELS.REMAINING_VOLUME,
    key: ACTIVE_BATCH_KEYS.REMAINING_VOLUME,
    type: "text",
    metric: "bbl",
  },
  {
    label: ACTIVE_BATCH_LABELS.ACTUAL_ENDING_INV,
    key: ACTIVE_BATCH_KEYS.ACTUAL_ENDING_INV,
    type: "input",
    placeholder: ACTIVE_BATCH_PLACEHOLDERS.INVENTORY,
    metric: "bbl",
    max: "remaining_volume",
  },
];

export const ACTION_OPTIONS = {
  VIEW_DETAILS: "View details",
  ALLOCATE_BOLS: " Allocate BOLs",
  SPLIT_SHIPMENT: "Split shipment",
};

export const SHIPMENT_TYPE = {
  PIPELINE: "PIPELINE",
  VESSEL: "VESSEL",
  BACK_TO_UNIT: "BACK_TO_UNIT",
  TRUCK_RACK: "TRUCK_RACK",
};

export const SPLIT_SUB_TYPE = {
  EMPTY: "",
  LINE_SET: "LINE_SET",
};

export const SPLIT_LABELS = {
  SPLIT_SUB_TYPE: "Sub-type",
  ACTUAL_VOLUME: "Actual shipment volume",
  RINS_QUALIFIED: "RINS qualified",
  RENEWABLE_SPLIT_FLAG: "Renewable",
  ADDITIONAL_INFO: "Notes",
};

export const SHIPMENTS_LABELS = {
  SHIPMENT_TYPE: "Shipment type",
  SHIPMENT_DATE: "Shipment date",
  BATCH_ID: "Batch ID",
  TOTAL_ESTIMATED_SHIP_VOL: "Total estimated shipment volume",
  TOTAL_ACTUAL_SHIP_VOL: "Total actual shipment volume",
};

export const METRICS = {
  BARRELS: "barrels",
};

export const SHIPMENTS_FIELDS_LEFT_COLUMN = [
  {
    label: SHIPMENTS_LABELS.SHIPMENT_TYPE,
    key: "shipment_type",
    type: "formattedText",
  },

  {
    label: SHIPMENTS_LABELS.SHIPMENT_DATE,
    key: "shipment_date",
    type: "date",
  },

  {
    label: SHIPMENTS_LABELS.BATCH_ID,
    key: "batch_id",
    type: "input",
    placeholder: "Enter batch ID",
  },
];

export const SHIPMENTS_FIELDS_RIGHT_COLUMN = [
  {
    label: SHIPMENTS_LABELS.TOTAL_ESTIMATED_SHIP_VOL,
    key: "total_estimated_volume",
    type: "text",
    metric: "bbl",
  },
  {
    label: SHIPMENTS_LABELS.TOTAL_ACTUAL_SHIP_VOL,
    key: "total_actual_volume",
    type: "input",
    placeholder: "Enter actual volume",
    metric: "bbl",
  },
];

export const BUTTONS = {
  SAVE: "Save",
  ACTIONS: "Actions",
  LOAD_MORE_BATCHES: "Load more batches",
  LOAD_MORE_SHIPMENTS: "Load more shipments",
  CANCEL: "Cancel",
};

// Fields from the DTNShipment schema
export const dtnShipmentKeys = {
  SHIPMENT_ID: "dtn_shipment_id",
  SHIPMENT_TYPE: "shipment_type",

  SHIPMENT_DATE: "shipment_datez",
  SHIPMENT_START_DATE: "shipment_start_datez",
  SHIPMENT_END_DATE: "shipment_end_datez",

  MAX_VOLUME: "max_volume",
  SOURCE_TANK: "source_tank",
  ESTIMATED_VOLUME: "estimated_volume",
  QUALIFIED_VOLUME: "qualified_volume",
  ADJUSTED_VOLUME: "adjusted_volume",

  // Additional Info and Flags
  ADDITIONAL_INFO: "additional_info",
  CREDITS_QUALIFIED: "credits_qualified",
  RENEWABLE_SHIPMENT_FLAG: "renewable_shipment_flag", // Boolean

  // Shipment Status and Audit
  SHIPMENT_STATUS: "shipment_status",
};

export const splitShipmentKeys = {
  SPLIT_SUB_TYPE: "shipment_sub_type",
  ACTUAL_VOLUME: "adjusted_volume_bbl",
  RINS_QUALIFIED: "credits_qualified",
  RENEWABLE_SPLIT_FLAG: "renewable_shipment_flag",
  ADDITIONAL_INFO: "additional_info",
  OWB_AGG_SHIPMENT_ID: "owb_agg_shipment_id",
};

export const BATCH_BAR_LABELS = {
  REMAINING_VOLUME: "Remaining volume",
  ENDING_INVENTORY: "Ending inventory",
};

export const SHIPMENT_TYPES_DROPDOWN = [
  {value: SHIPMENT_TYPE.PIPELINE, label: "Pipeline"},
  {value: SHIPMENT_TYPE.VESSEL, label: "Vessel"},
  {value: SHIPMENT_TYPE.BACK_TO_UNIT, label: "Back to unit misc"},
  {value: SHIPMENT_TYPE.TRUCK_RACK, label: "Truck rack"},
];

export const SHIPMENT_LABELS = {
  STATUS: "Shipment status",
  DETAILS: "Shipping details",
};

export const DETAIL_HEADERS = {
  SHIPMENT_SUMMARY: "Shipment summary",
};

const SELECT_OPTION = {label: "Select an option", value: "", setDisable: true};

export const SHIPMENTS_WITH_SUBTYPES = [
  SHIPMENT_TYPE.PIPELINE,
  SHIPMENT_TYPE.VESSEL,
];

export const SHIPMENTS_WITH_ACTUAL_VOLUME = [
  SHIPMENT_TYPE.PIPELINE,
  SHIPMENT_TYPE.VESSEL,
  SHIPMENT_TYPE.BACK_TO_UNIT,
];

export const SHIPMENTS_WITH_PART_EDITABLE = [
  SHIPMENT_TYPE.PIPELINE,
  SHIPMENT_TYPE.VESSEL,
  SHIPMENT_TYPE.TRUCK_RACK,
];

export const SHIPMENTS_WITH_NOTES = [
  SHIPMENT_TYPE.PIPELINE,
  SHIPMENT_TYPE.VESSEL,
  SHIPMENT_TYPE.TRUCK_RACK,
  SHIPMENT_TYPE.BACK_TO_UNIT,
];

export const SHIPMENT_WITH_SOURCE_TANK = [SHIPMENT_TYPE.TRUCK_RACK];

export const SUB_TYPES = {
  LINE_SET: "Line Set",
  NA: "N/A",
  NONE: "None",
};

export const PIPELINE_SUB_TYPE_OPTIONS = [
  SELECT_OPTION,
  {label: SUB_TYPES.NA, value: SUB_TYPES.NA},
];

export const VESSEL_SUB_TYPE_OPTIONS = [
  SELECT_OPTION,
  {label: SUB_TYPES.NONE, value: SUB_TYPES.NONE},
  {label: SUB_TYPES.LINE_SET, value: SUB_TYPES.LINE_SET},
];

export const RINS_QUALIFIED_OPTIONS = [
  SELECT_OPTION,
  {label: "True", value: "True"},
  {label: "False", value: "False"},
  {label: "Partial", value: "Partial"},
];

export const RENEWABLE_OPTIONS = [
  SELECT_OPTION,
  {label: "Yes", value: true},
  {label: "No", value: false},
];

export const SHIPMENT_SUMMARY_LABELS = {
  SHIPMENT_TYPE: "Shipment type",
  SUB_TYPE: "Sub-type",
  BATCH_ID: "Batch ID",
  SHIPMENT_DATE: "Shipment date",
  TOTAL_ESTIMATED_SHIP_VOL: "Total estimated shipment volume",
  TOTAL_ACTUAL_SHIP_VOL: "Total actual shipment volume",
  LOAD_START_TIME: "Load start time",
  LOAD_END_TIME: "Load end time",
  DESTINATION: "Destination",
  RINS_QUALIFIED: "RINS qualified",
  RENEWABLE: "Renewable",
  SOURCE_TANK: "Source Tank",
  NOTES: "Notes",
};

export const SHIPMENT_SUMMARY_FIELDS_HEADER = [
  {
    label: SHIPMENT_SUMMARY_LABELS.SHIPMENT_TYPE,
    key: "shipment_type",
    isEditable: false,
    dataType: "formattedText",
  },
  {
    label: SHIPMENT_SUMMARY_LABELS.SUB_TYPE,
    key: "sub_type",
    isEditable: true,
    inputType: "dropdown",
  },
  {
    label: SHIPMENT_SUMMARY_LABELS.BATCH_ID,
    key: "batch_id",
    isEditable: false,
  },
  {
    label: SHIPMENT_SUMMARY_LABELS.SHIPMENT_DATE,
    key: "shipment_date",
    isEditable: false,
    dataType: "date",
  },
  {
    label: SHIPMENT_SUMMARY_LABELS.TOTAL_ESTIMATED_SHIP_VOL,
    key: "total_estimated_volume",
    isEditable: false,
    metric: METRICS.BARRELS,
    dataType: "number",
  },
  {
    label: SHIPMENT_SUMMARY_LABELS.TOTAL_ACTUAL_SHIP_VOL,
    key: "total_actual_volume",
    isEditable: true,
    metric: METRICS.BARRELS,
    dataType: "number",
    inputType: "text",
  },
];

export const SHIPMENT_SUMMARY_FIELDS_BODY = [
  {
    label: SHIPMENT_SUMMARY_LABELS.LOAD_START_TIME,
    key: "load_start_time",
    isEditable: false,
    dataType: "time",
  },
  {
    label: SHIPMENT_SUMMARY_LABELS.LOAD_END_TIME,
    key: "load_end_time",
    isEditable: false,
    dataType: "time",
  },
  {
    label: SHIPMENT_SUMMARY_LABELS.DESTINATION,
    key: "destination",
    isEditable: false,
    dataType: "formattedText",
  },
  {
    label: SHIPMENT_SUMMARY_LABELS.RINS_QUALIFIED,
    key: "credits_qualified",
    isEditable: true,
    inputType: "dropdown",
  },
  {
    label: SHIPMENT_SUMMARY_LABELS.RENEWABLE,
    key: "renewable_shipment_flag",
    isEditable: true,
    dataType: "boolean",
    inputType: "dropdown",
  },
  {
    label: SHIPMENT_SUMMARY_LABELS.SOURCE_TANK,
    key: "source_tank",
    isEditable: true,
    inputType: "textarea",
  },
  {
    label: SHIPMENT_SUMMARY_LABELS.NOTES,
    key: "notes",
    isEditable: true,
    inputType: "textarea",
  },
];

export const DELETE_POPOVER = {
  HEADER: "Delete shipment",
  MESSAGE: "Are you sure you want to delete the shipment?",
};

export const BANNER_TYPE = {
  WARNING: "warning",
  ERROR: "error",
};

export const ERRORS = {
  ENDING_INVENTORY_VALUE: "Ending Inventory does not equal remaining balance",
  FAILED_DELETE: "Error: Failed to delete shipment",
};

export const WARNINGS = {
  MISSING_C14: "C14 Not Received.",
};

export const DELETE_SUCCESS = "Shipment deleted.";
