import PropTypes from "prop-types";
import {ERRORS} from "../constants/coProcessing";

const DocumentViewer = ({url}) => {
  return (
    <div className="w-full h-full pr-4" data-test="copro-document-viewer">
      <object data={url} type="application/pdf" width="100%" height="100%">
        <p>{ERRORS.FAILED_PDF}</p>
      </object>
    </div>
  );
};
export default DocumentViewer;

DocumentViewer.propTypes = {url: PropTypes.string};
