import {useAccount, useMsal} from "@azure/msal-react";
import {ReactPlugin} from "@microsoft/applicationinsights-react-js";
import {ApplicationInsights} from "@microsoft/applicationinsights-web";
import {AwsRum} from "aws-rum-web";
import {createBrowserHistory} from "history";
import CoProcessing from "modules/co-processing";
import APIErrorPage from "modules/common/ErrorPages/APIErrorPage";
import NotFoundPage from "modules/common/ErrorPages/NotFoundPage";
import ComplianceDashboard from "modules/compliance/components";
import ComplianceMassBalance from "modules/compliance/components/massBalance/ComplianceMassBalance";
import CoprocessingSettingLandingPage from "modules/config-center/pages/CoprocessingSettingLandingPage";
import Dashboard from "modules/dashboard";
import GenerateCertificateDetails from "modules/es-co-processing/components/GenerateCertificate/GenerateCertificateDetails";
import EsLandingPage from "modules/es-co-processing/pages/EsLandingPage";
import IncomingDocumentPage from "modules/es-co-processing/pages/IncomingDocumentPage";
import ManualEntryPage from "modules/es-co-processing/pages/ManualEntryPage";
import OutgoingDocumentPage from "modules/es-co-processing/pages/OutgoingDocumentPage";
import ViewDocumentPage from "modules/es-co-processing/pages/ViewDocumentPage";
import ViewOutgoingDocumentPage from "modules/es-co-processing/pages/ViewOutgoingDocumentPage";
import FeedstockLanding from "modules/fifo/pages/FeedstockLanding";
import Forecasting from "modules/forecasting/index";
import ManualAdjustmentDashboard from "modules/gy-enablement/components/MOT/ManualAdjustmentDashboard";
import CeemasValidation from "modules/gy-enablement/components/MOT/motSummary/CeemasValidation";
import CeemasValidationBalanceGroupLevel from "modules/gy-enablement/components/MOT/motSummary/CeemasValidationBalanceGroupLevel";
import CeemasValidationDayLevel from "modules/gy-enablement/components/MOT/motSummary/CeemasValidationDayLevel";
import CeemasValidationEDGroupLevel from "modules/gy-enablement/components/MOT/motSummary/CeemasValidationEDGroupLevel";
import CeemasValidationMaterialLevel from "modules/gy-enablement/components/MOT/motSummary/CeemasValidationMaterialLevel";
import CeemasValidationPlantLevel from "modules/gy-enablement/components/MOT/motSummary/CeemasValidationPlantLevel";
import CeemasValidationTransactionDetails from "modules/gy-enablement/components/MOT/motSummary/CeemasValidationTransactionDetails";
import GYMasterNETSCO2CostsDashboard from "modules/gy-enablement/components/masterDetails/NETSCO2Costs/NETSCO2CostsDashBoard";
import GYMasterTaxPlantDashboard from "modules/gy-enablement/components/masterDetails/TaxPlantRegistry/TaxPlantRegistryDashboard";
import MassBalanceForLocation from "modules/mass-balancing/pages/MassBalanceForLocation";
import PoSDashboard from "modules/pos-management/PoSDashboard";
import PoSLandingPage from "modules/pos-management/PoSLandingPage";
import PoSManagement from "modules/pos-management/PoSManagement";
import PoSNLNabisyDashboard from "modules/pos-management/PoSNLNabisyDashboard";
import PoSPolandDashboard from "modules/pos-management/PoSPolandDashboard";
import UploadPOSCertificate from "modules/pos-management/UploadPOSCertificate";
import ProductMetrices from "modules/reports/components/ProductMetrices";
import ProductMetCeemasMOTValidation from "modules/reports/components/ProductMetrices/CeemasMOTValidation";
import ProductMetMOTManualAdjustment from "modules/reports/components/ProductMetrices/MOTManualAdjustment";
import TankVolume from "modules/reports/components/ProductMetrices/TankVolume";
import BVCReport from "modules/reports/powerBIReports/BVCReport";
import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {removeUnUsedLocalStorageforLogin} from "utils/helpers/removeUnUsedCache";
import SAFLandingPage from "modules/SAF-dashboard/pages/SAFLandingPage";
import SAFDocumentPage from "modules/SAF-dashboard/pages/SAFDocumentsPage";
import SAFDocumentViewPage from "modules/SAF-dashboard/pages/SAFDocumentViewPage";
import SAFMassBalancePage from "modules/SAF-dashboard/pages/SAFMassBalancePage";
import MassBalanceDetailViewPage from "modules/SAF-dashboard/pages/MassBalanceDetailViewPage";

const App = () => {
  removeUnUsedLocalStorageforLogin();
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  if (
    ["bioverse.bpglobal.com", "dev.bioverse.bpglobal.com"].includes(
      window.location.hostname,
    )
  ) {
    // Send Data to Azure app insights for TMA report
    const browserHistory = createBrowserHistory({basename: ""});
    const {REACT_APP_InstrumentationKey} = process.env;
    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: `InstrumentationKey=${REACT_APP_InstrumentationKey};IngestionEndpoint=https://northeurope-2.in.applicationinsights.azure.com/;LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/`,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {history: browserHistory},
        },
      },
    });
    appInsights.loadAppInsights();
    appInsights.setAuthenticatedUserContext(account?.username);
    appInsights.context.user.id = account?.username;
  }

  if (window.location.hostname !== "localhost") {
    // Send data to AWS RUM
    try {
      const {
        REACT_APP_POOL_ID,
        REACT_APP_AWS_SPOKE,
        REACT_APP_AWS_ACCOUNT_ID,
        REACT_APP_AWS_REGION,
        REACT_APP_RUM_ID,
      } = process.env;

      const config = {
        sessionSampleRate: 1,
        guestRoleArn: `arn:aws:iam::${REACT_APP_AWS_ACCOUNT_ID}:role/${REACT_APP_AWS_SPOKE}-role_RUM`,
        identityPoolId: `${REACT_APP_AWS_REGION}:${REACT_APP_POOL_ID}`,
        endpoint: `https://dataplane.rum.${REACT_APP_AWS_REGION}.amazonaws.com`,
        telemetries: ["errors", "performance", "http"],
        allowCookies: true,
        enableXRay: true,
        sessionAttributes: {
          userId: account?.localAccountId,
        },
      };

      const APPLICATION_ID = `${REACT_APP_RUM_ID}`;
      const APPLICATION_VERSION = "1.0.0";
      const APPLICATION_REGION = `${REACT_APP_AWS_REGION}`;

      const awsRum = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config,
      );
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }
  }

  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/forecasting/*" element={<Forecasting />} />
      <Route path="/co-processing/*" element={<CoProcessing />} />
      {/* <Route path="/pos-management/nl" element={<PoSNLDashboard />} /> */}
      <Route path="/pos-management/uk" element={<PoSDashboard />} />
      <Route path="/pos-management/pl" element={<PoSPolandDashboard />} />
      {/* <Route path="/pos-management/lux" element={<LuxembourgDashboard />} /> */}
      <Route path="/pos-management/landing" element={<PoSLandingPage />} />
      <Route
        path="/pos-management/nl/nabisy"
        element={<PoSNLNabisyDashboard />}
      />
      <Route
        path="/pos-management/upload-documents/:country"
        element={<PoSManagement />}
      />
      <Route
        path="/pos-management/upload-documents/pos-certificate/:country"
        element={<UploadPOSCertificate />}
      />
      <Route path="/product-metrics" element={<ProductMetrices />} />
      <Route path="/product-metrics/tank-volume" element={<TankVolume />} />
      <Route
        path="/product-metrics/ceemas-mot-validation"
        element={<ProductMetCeemasMOTValidation />}
      />
      <Route
        path="/product-metrics/mot-manual-adjustment"
        element={<ProductMetMOTManualAdjustment />}
      />
      <Route path="/reporting/bvc-report" element={<BVCReport />} />

      {/* START GY-Enablement */}
      <Route
        path="/gy-enablement"
        element={<Navigate to="/gy-enablement/mot-manual-adjustment" replace />}
      />
      <Route
        path="/gy-enablement/mot-manual-adjustment"
        element={<ManualAdjustmentDashboard />}
      />
      <Route
        path="/gy-enablement/ceemas-mot-validation/:filterDetails"
        element={<CeemasValidation />}
      />
      <Route
        path="/gy-enablement/ceemas-mot-validation-plant-level/:filterDetails"
        element={<CeemasValidationPlantLevel />}
      />
      <Route
        path="/gy-enablement/ceemas-mot-validation-edgroup-level/:filterDetails"
        element={<CeemasValidationEDGroupLevel />}
      />
      <Route
        path="/gy-enablement/ceemas-mot-validation-balancegroup-level/:filterDetails"
        element={<CeemasValidationBalanceGroupLevel />}
      />
      <Route
        path="/gy-enablement/ceemas-mot-validation-material-level/:filterDetails"
        element={<CeemasValidationMaterialLevel />}
      />
      <Route
        path="/gy-enablement/ceemas-mot-validation-day-level/:filterDetails"
        element={<CeemasValidationDayLevel />}
      />
      <Route
        path="/gy-enablement/ceemas-mot-validation-transaction-level/:filterDetails"
        element={<CeemasValidationTransactionDetails />}
      />
      {/* END GY-Enablement */}

      {/* Compliance */}
      <Route path="/compliance" element={<ComplianceDashboard />}>
        <Route path="monitor" element={<ComplianceMassBalance />} />
        <Route index element={<Navigate to="monitor" />} />
      </Route>
      <Route
        path="/gy-enablement/master/tax-plant-registry"
        element={<GYMasterTaxPlantDashboard />}
      />
      <Route
        path="/gy-enablement/master/NETS-CO2-Costs"
        element={<GYMasterNETSCO2CostsDashboard />}
      />
      {/* Spain Co-processing */}
      <Route path="/copro-spain/" element={<EsLandingPage />} />
      <Route
        path="/copro-spain/document-manager/incoming-documents"
        element={<IncomingDocumentPage />}
      />
      <Route
        path="/copro-spain/document-manager/outgoing-documents"
        element={<OutgoingDocumentPage />}
      />
      <Route
        path="/copro-spain/document-manager/outgoing-documents/view"
        element={<ViewOutgoingDocumentPage />}
      />
      <Route
        path="/copro-spain/document-manager/incoming-documents/:docId"
        element={<ViewDocumentPage />}
      />
      <Route
        path="/copro-spain/document-manager/incoming-documents/manual/:fileName/:countryCode"
        element={<ManualEntryPage />}
      />
      <Route
        path="/copro-spain/document-manager/generate-certificate"
        element={<GenerateCertificateDetails />}
      />

      <Route
        path="/config-center/coprocessing-spain"
        element={<CoprocessingSettingLandingPage />}
      />
      <Route
        path="/copro-spain/mass-balance/:locationCode/:locationName/:materialCode?/:quarter?/:year?"
        element={<MassBalanceForLocation />}
      />

      <Route
        path="/copro-spain/fifo-feedstock"
        element={<FeedstockLanding />}
      />

      {/* SAF Routes */}

      <Route path="/SAFLandingPage/" element={<SAFLandingPage />} />
      <Route path="/SAFDocumentPage/" element={<SAFDocumentPage />} />
      <Route
        path="/saf/document-manager/incoming-documents/:docId"
        element={<SAFDocumentViewPage />}
      />
      <Route
        path="/saf-sweden/mass-balance/"
        element={<SAFMassBalancePage />}
      />
      <Route
        path="/saf-sweden/mass-balance/:docId"
        element={<MassBalanceDetailViewPage />}
      />

      <Route path="*" element={<NotFoundPage />} />
      <Route path="/api-error-page/:errorMessage" element={<APIErrorPage />} />
    </Routes>
  );
};

export default App;
