import {useQuery} from "@apollo/client";
import {useMemo, useEffect} from "react";
import {useFormContext} from "react-hook-form";
import {FormGroup} from "reactstrap";
import {
  OP_CONDITIONS,
  ADD_BATCH_FORM,
} from "modules/co-processing/constants/batches";
import {GET_BATCH_IDS, GET_VALVE_STATUS} from "graphql/coprocessing/batches";
import {convertToISO} from "modules/co-processing/helpers/formBuilder";
import {displayDate} from "modules/co-processing/helpers/dateHelper";
import {DHDSDateTime, Notes, Form} from "../FormElements";

const fieldKeys = ADD_BATCH_FORM.keys;
const fieldLabels = ADD_BATCH_FORM.labels;

const toISOString = (date) => (!date ? null : convertToISO(date));

export const BatchID = () => {
  const {getValues, setValue, watch} = useFormContext();
  const tankNumber = getValues(fieldKeys.tankNo);
  const selectedBatchId = watch(fieldKeys.batchId);

  const startTime = convertToISO(getValues(fieldKeys.batchStartTime));
  const endTime = convertToISO(getValues(fieldKeys.batchEndTime));

  const {data, loading} = useQuery(GET_BATCH_IDS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {startTime, endTime, tankNumber},
  });

  const batchOptions = useMemo(() => {
    return (
      data?.bioLcCoproUsBatchesApi?.body?.batch_ids?.map((batch) => ({
        value: batch?.batch_id,
        label: `${batch?.batch_id} ${displayDate(batch?.tank_certified_date)}`,
        batchCode: batch?.batch_code,
        tankCertifiedDate: batch?.tank_certified_date,
        sourceSampleCode: batch.source_sample_code || [],
      })) || []
    );
  }, [data]);

  useEffect(() => {
    const batchInfo = batchOptions.find(
      (option) => option.value === selectedBatchId,
    );

    if (batchInfo) {
      setValue(fieldKeys.batchCode, batchInfo.batchCode);
      setValue(fieldKeys.tankCertifiedDate, batchInfo.tankCertifiedDate);
      setValue(fieldKeys.sourceSampleCode, batchInfo.sourceSampleCode);
    }
  }, [batchOptions, setValue, selectedBatchId]);

  return (
    <FormGroup>
      <Form.Select
        className="col-sm-8"
        isLoading={loading}
        label={fieldLabels.batchId}
        name={fieldKeys.batchId}
        options={batchOptions}
      />
    </FormGroup>
  );
};

const SaveBatch = () => {
  const {getValues, setValue} = useFormContext();
  const isSplit = getValues(fieldKeys.splitBatch, false);

  const start_time = toISOString(getValues(fieldKeys.batchStartTime));
  const end_time = toISOString(getValues(fieldKeys.batchEndTime));

  useQuery(GET_VALVE_STATUS, {
    fetchPolicy: "network-only",
    variables: {start_time, end_time},
    onCompleted: (data) => {
      const valveOpen = data?.bioLcCoproUsBatchesApi?.body?.valve_open; // True implies valve is open.

      if (valveOpen) {
        setValue(fieldKeys.opCondition, OP_CONDITIONS.NORMAL_UNIT_REC, {
          shouldDirty: true,
        });

        setValue("valveOpen", true, {shouldDirty: true});
      }
    },
    skip: !start_time || !end_time,
  });

  if (getValues(fieldKeys.opCondition) === OP_CONDITIONS.BY_PASSED) {
    return <Notes />;
  }

  // If batch is split, render DHDSDateTime with split batch id prefix
  if (isSplit) {
    return (
      <>
        <DHDSDateTime prefix={`Split ${getValues("batchIdSplitPrefix")} `} />
        <Notes />
      </>
    );
  }

  return (
    <>
      <BatchID />
      <Notes />
    </>
  );
};

export default SaveBatch;
