import React, {useState} from "react";
import SAF_INCOMING_AND_OUTGOING_COUNT from "graphql/saf/GetDocumentsCount";
import {useQuery} from "@apollo/client";

import Layout from "../components/Layout";
import SafTabs from "../components/SafTabs";
import IncomingDocumentComponent from "../components/incoming-documents/IncomingDocumentComponent";
import OutgoingDocuments from "../components/OutgoingDocuments";

const SAFIncomingDocumentPage = () => {
  const [activeTab, setActiveTab] = useState("1");

  const {data, loading} = useQuery(SAF_INCOMING_AND_OUTGOING_COUNT, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: "SAF Sweden", link: "/saflandingpage"},
    {text: "Document manager", link: "/saflandingpage"},
    {
      text: "Incoming documents",
      link: "/documentPage",
    },
  ];

  return (
    <Layout title="Documents" breadcrumbs={breadcrumbItems}>
      <div className="flex flex-col justify-stretch w-full items-start px-7">
        <div className=" flex flex-row justify-between w-full my-3">
          <SafTabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            incomingDocCount={data?.bioLcSafGetInOutDocsCount?.inbound_count}
            outgoingDocCount={data?.bioLcSafGetInOutDocsCount?.outbound_count}
            loading={loading}
          />
        </div>
      </div>
      {activeTab === "1" && <IncomingDocumentComponent />}
      {activeTab === "2" && <OutgoingDocuments />}
    </Layout>
  );
};

export default SAFIncomingDocumentPage;
