import React from "react";
import ShipmentForm from "../Form";

const ShipmentType = () => {
  return (
    <div className="row g-4 mb-5" data-test="shipment_type">
      <h5>Select the shipment type</h5>

      <ShipmentForm.Field fieldName="SHIPMENT_TYPE" />
    </div>
  );
};

export default ShipmentType;
