import {
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Button,
} from "reactstrap";
import {isEmpty} from "lodash";
import {useState, useEffect, useMemo} from "react";
import {toast} from "react-toastify";
import PropTypes from "prop-types";
import {Close} from "@bphxd/ds-core-react";
import {useForm, FormProvider} from "react-hook-form";
import {useMsal, useAccount} from "@azure/msal-react";
import {UpdateFeedstockAPI} from "graphql/es-co-processing/fifo/feedstock";
import "./index.css";

const UpdateMOTForm = ({
  isVisibleModal,
  rowData,
  setModalAddMOTDetailsVisible,
  motDetails,
}) => {
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);
  const [isProcessing, setProcessing] = useState(false);

  const defaultValues = {
    motTransport: motDetails?.mot?.toLowerCase(),
    motName: motDetails?.motName,
  };

  const methods = useForm({
    defaultValues,
    mode: "onBlur",
    shouldFocusError: true,
  });
  const {
    register,
    formState: {errors},
    getValues,
    setError,
    trigger,
    setFocus,
  } = methods;

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const isModalVisible = useMemo(() => {
    return isVisibleModal;
  }, [isVisibleModal]);

  const handleSubmit = async () => {
    const {motTransport, motName} = getValues();

    const valid = await trigger();
    if (valid && !isEmpty(motTransport) && !isEmpty(motName)) {
      setProcessing(true);
      const moreData = rowData?.moreDetailsData[0];
      const {data: response} = await UpdateFeedstockAPI({
        documentNumber: moreData?.documentNumber,
        mot: motTransport,
        motName,
      });

      if (response?.bioLcCoproFIFOPutRouteDetail?.statusCode === 200) {
        setProcessing(false);
        setModalAddMOTDetailsVisible(false);
        toast.success(
          response?.bioLcCoproFIFOPutRouteDetail?.message ??
            "Mode of transport is updated successfully",
        );
      } else {
        setProcessing(false);
        toast.error(
          response?.bioLcCoproFIFOPutRouteDetail?.message ??
            "Something went wrong, failed to update mode of transport",
        );
      }
    }
  };

  return (
    <div>
      <Modal
        size="sm"
        isOpen={isModalVisible}
        className="modal-dialog-centered"
      >
        <ModalHeader
          className="border-b-2 border-gray-200 mb-5 text-xl"
          close={<Close onClick={() => setModalAddMOTDetailsVisible(false)} />}
        >
          Add mode of transport
        </ModalHeader>
        <ModalBody className="text-center pt-0">
          <FormProvider {...methods}>
            <Form className="flex flex-col gap-5" id="update-mot-form">
              <div className="flex flex-col gap-5 pb-6">
                <FormGroup>
                  <Label
                    for="motTransport"
                    className="fw-normal w-full text-start"
                  >
                    Mode of transport
                  </Label>
                  <Input
                    type="select"
                    id="motTransport"
                    data-test="motTransport"
                    {...computeProps("motTransport", {
                      required: "Please select mode of transport",
                    })}
                    invalid={!!errors.motTransport}
                    maxLength={100}
                    placeholder="Please choose from list"
                  >
                    <option value="">Please choose from list</option>
                    <option value="truck">Truck</option>
                    <option value="vessel">Vessel</option>
                  </Input>
                  {errors.motTransport && (
                    <FormFeedback>{errors.motTransport.message}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="motName" className="fw-normal w-full text-start">
                    Name
                  </Label>

                  <Input
                    {...computeProps("motName", {
                      required: "Please enter name",
                    })}
                    type="text"
                    id="motName"
                    data-test="motName"
                    invalid={!!errors.motName}
                    maxLength={100}
                  />

                  {errors.motName && (
                    <FormFeedback>{errors.motName.message}</FormFeedback>
                  )}
                </FormGroup>
                <label className="mot-popup-warning-text">
                  Please note this change will only appear on BioVerse and not
                  in SAP
                </label>
              </div>
            </Form>
          </FormProvider>
        </ModalBody>
        <ModalFooter className="p-0 d-block">
          <div className="row g-0 m-0 modal-footer-row">
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
                onClick={() => setModalAddMOTDetailsVisible(false)}
              >
                Cancel
              </Button>
            </div>
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 py-4 bg-transparent text-default"
                type="button"
                disabled={isProcessing}
                onClick={handleSubmit}
              >
                {isProcessing && (
                  <Spinner size="sm" className="btn-icon-prefix" />
                )}
                Save
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

UpdateMOTForm.propTypes = {
  isVisibleModal: PropTypes.bool,
  rowData: PropTypes.object,
  setModalAddMOTDetailsVisible: PropTypes.func.isRequired,
  motDetails: PropTypes.object,
};

export default UpdateMOTForm;
