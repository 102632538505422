import {ReactTable} from "@bphxd/ds-core-react";
import PropTypes from "prop-types";
import {Spinner} from "reactstrap";

const Table = ({columns, data, loading, enableSorting}) => {
  if (loading)
    return (
      <div className="flex mx-auto">
        <Spinner className="flex mx-auto" />
      </div>
    );
  return (
    <ReactTable
      responsive
      columns={columns}
      data={data}
      enableSorting={enableSorting}
    />
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  loading: PropTypes.bool,
  enableSorting: PropTypes.bool,
};

export default Table;
