import {Form, Button, Spinner} from "reactstrap";
import React, {useState, useMemo, useEffect} from "react";
import moment from "moment";
import {useForm, FormProvider} from "react-hook-form";
import {useQuery} from "@apollo/client";
import {useUserSettings} from "providers/userSettings";
import {useMsal} from "@azure/msal-react";
import {
  COPRO_EU_FIFO_FILTER,
  COPRO_EU_FIFO_ALL_RECORDS,
  COPRO_EU_FIFO_ALL_RECORDS_AGGREGATED_VIEW,
} from "graphql/es-co-processing/fifo/feedstock";
import {downloadExportApi} from "graphql/es-co-processing/fifo/downloadtoexcelhelper";
import {Download24} from "@bphxd/ds-core-react/lib/icons";
import Layout from "../components/Layout";
import FeedstockFilterContent from "../components/FeedstockFilterContent";
import ReportSettingsDetail from "../components/ReportSettingsDetail";
import {
  META_COLUMNS,
  OPENING_COLUMNS,
  INCOMING_COLUMNS,
  OUTGOING_COLUMNS,
  CLOSING_COLUMNS,
} from "../components/Table/Column.js";
import {
  AGGREGATED_COLUMNS,
  OPENING_COLUMNS_AGGREGATED,
  INCOMING_COLUMNS_AGGREGATED,
  OUTGOING_COLUMNS_AGGREGATED,
  CLOSING_COLUMNS_AGGREGATED,
} from "../components/Table/AggregatedColumns.js";
import SplitTable from "../components/Table/index.js";
import SplitTableAggregated from "../components/Table/SplitTableAggregated";
import "./index.css";

const seperationText = "#-#";

const FeedstockLanding = () => {
  const {accounts} = useMsal();
  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();

  const defaultPageSize = 10;

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: defaultPageSize,
  });

  const handlePaginationChange = ({pageIndex, pageSize}) => {
    setPagination({pageIndex, pageSize});
  };

  const methods = useForm({
    mode: "onBlur",
  });

  const {getValues, setValue} = methods;

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: "Co-processing", link: "/copro-spain"},
    {text: "Feedstock FIFO", link: "/copro-spain"},
  ];

  const {data: filterData} = useQuery(COPRO_EU_FIFO_FILTER, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const currentYear = new Date().getFullYear();
  const currentMonthNumber = new Date().getMonth() + 1;

  const [defaultValues, setFilterDefaultValues] = useState({
    location: "db4990de2a99aa47a56b87371a9d55cc#-#Castellón",
    periodYear: "2023",
    periodMonth: "3",
    materialView: "sap_code",
    uom: "t",
    feedstock: null,
    viewType: "aggregated",
  });

  const [currentFilterValues, setCurrentFilterValues] = useState(defaultValues);

  const feedstockDetails =
    currentFilterValues?.feedstock?.split(seperationText);
  const locationDetails = currentFilterValues?.location?.split(seperationText);

  const {
    data: allRecords,
    loading: allRecordsLoading,
    refetch: refetchAllRecords,
  } = useQuery(COPRO_EU_FIFO_ALL_RECORDS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      locationId: locationDetails[0],
      materialId: feedstockDetails && feedstockDetails[0],
      feedstockType: currentFilterValues?.materialView,
      fifoMonth: currentFilterValues?.periodMonth,
      fifoYear: currentFilterValues?.periodYear,
      uom: currentFilterValues?.uom,
      viewType: currentFilterValues?.viewType,
      sapMaterialCode:
        currentFilterValues?.materialView === "sap_code"
          ? feedstockDetails && feedstockDetails[1]
          : feedstockDetails && feedstockDetails[2], // remove this once we have aggregated view
    },
    skip: currentFilterValues?.viewType === "aggregated",
  });

  const {
    data: allRecordsAggregated,
    loading: allRecordsAggregatedLoading,
    refetch: refetchAllRecordsAggregated,
  } = useQuery(COPRO_EU_FIFO_ALL_RECORDS_AGGREGATED_VIEW, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      locationId: locationDetails[0],
      fifoMonth: currentFilterValues?.periodMonth,
      fifoYear: currentFilterValues?.periodYear,
      uom: currentFilterValues?.uom,
      viewType: currentFilterValues?.viewType,
    },
    skip: currentFilterValues?.viewType === "detailed",
  });

  const handleFilterSubmit = async () => {
    const {location, feedstock, periodYear, periodMonth} = getValues();

    setCurrentFilterValues({
      ...defaultValues,
      viewType: feedstock ? "detailed" : "aggregated",
      location,
      feedstock,
      periodYear,
      periodMonth,
    });

    const feedstockDetails = feedstock?.split(seperationText);
    const locationDetails = location?.split(seperationText);
    setPagination({
      pageIndex: 0,
      pageSize: defaultPageSize,
    });

    if (feedstock) {
      await refetchAllRecords({
        locationId: locationDetails[0],
        materialId: feedstockDetails && feedstockDetails[0],
        feedstockType: defaultValues?.materialView,
        fifoMonth: periodMonth,
        fifoYear: periodYear,
        uom: defaultValues?.uom,
        viewType: feedstock ? "detailed" : "aggregated",
        sapMaterialCode:
          defaultValues?.materialView === "sap_code"
            ? feedstockDetails && feedstockDetails[1]
            : feedstockDetails && feedstockDetails[2],
      });
    } else {
      await refetchAllRecordsAggregated({
        locationId: locationDetails[0],
        fifoMonth: periodMonth,
        fifoYear: periodYear,
        uom: defaultValues?.uom,
        viewType: feedstock ? "detailed" : "aggregated",
      });
    }
  };

  const handleFilterClear = async () => {
    setValue("location", defaultValues?.location);
    setValue("periodMonth", defaultValues?.periodMonth);
    setValue("periodYear", defaultValues?.periodYear);
    setValue("feedstock", defaultValues?.feedstock);

    setCurrentFilterValues({
      ...defaultValues,
    });

    const feedstockDetails = defaultValues?.feedstock?.split(seperationText);
    const locationDetails = defaultValues?.location?.split(seperationText);
    setPagination({
      pageIndex: 0,
      pageSize: defaultPageSize,
    });

    await refetchAllRecordsAggregated({
      locationId: locationDetails[0],
      fifoMonth: defaultValues?.periodMonth,
      fifoYear: defaultValues?.periodYear,
      uom: defaultValues?.uom,
      viewType: "aggregated",
    });
  };

  const onReportSettingSave = async (settings) => {
    const {location, feedstock, periodYear, periodMonth, materialView, uom} =
      getValues();

    setCurrentFilterValues({
      ...currentFilterValues,
      materialView: settings?.materialView,
      uom: settings?.uom,
    });

    const feedstockDetails = feedstock?.split(seperationText);
    const locationDetails = location?.split(seperationText);
    if (feedstock) {
      await refetchAllRecords({
        locationId: locationDetails[0],
        materialId: feedstockDetails && feedstockDetails[0],
        feedstockType: settings?.materialView,
        fifoMonth: periodMonth,
        fifoYear: periodYear,
        uom: settings?.uom,
        viewType: "detailed",
        sapMaterialCode:
          defaultValues?.materialView === "sap_code"
            ? feedstockDetails && feedstockDetails[1]
            : feedstockDetails && feedstockDetails[2],
      });
    } else {
      await refetchAllRecordsAggregated({
        locationId: locationDetails[0],
        fifoMonth: periodMonth,
        fifoYear: periodYear,
        uom: settings?.uom,
        viewType: "aggregated",
      });
    }
  };

  const columns = useMemo(
    () =>
      META_COLUMNS(dateFormat, decimalFormat, defaultValues?.uom).concat(
        OPENING_COLUMNS(
          decimalFormat,
          decimalFormat,
          defaultValues?.uom,
          allRecords?.bioLcCoproFIFODetailedView?.data?.weightedAverage[0],
        ),
        INCOMING_COLUMNS(
          decimalFormat,
          decimalFormat,
          defaultValues?.uom,
          allRecords?.bioLcCoproFIFODetailedView?.data?.weightedAverage[0],
        ),
        OUTGOING_COLUMNS(
          decimalFormat,
          decimalFormat,
          defaultValues?.uom,
          allRecords?.bioLcCoproFIFODetailedView?.data?.weightedAverage[0],
        ),
        CLOSING_COLUMNS(
          decimalFormat,
          decimalFormat,
          defaultValues?.uom,
          allRecords?.bioLcCoproFIFODetailedView?.data?.weightedAverage[0],
        ),
      ),
    [
      dateFormat,
      decimalFormat,
      defaultValues?.uom,
      allRecords?.bioLcCoproFIFODetailedView?.data?.weightedAverage,
    ],
  );
  const [isProcessing, setProcessing] = useState(false);

  const handleDownloadClick = async () => {
    const variables = {
      download: true,
      locationId: locationDetails[0],
      materialId: "",
      feedstockType: defaultValues?.materialView,
      fifoMonth: currentFilterValues?.periodMonth,
      fifoYear: currentFilterValues?.periodYear,
      uom: defaultValues?.uom,
      viewType: defaultValues?.viewType,
      sapMaterialCode:
        defaultValues?.materialView === "sap_code"
          ? feedstockDetails && feedstockDetails[1]
          : feedstockDetails && feedstockDetails[2],
    };
    setProcessing(true);
    const {data} = await downloadExportApi(variables);

    const downloadUrl = data?.bioLcCoproFIFODetailedView?.url;

    if (downloadUrl) {
      const fileNameMatch = downloadUrl.match(/\/([^/?]+)\?/);
      const fileName = fileNameMatch ? fileNameMatch[1] : "report.xlsx";
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Download URL is undefined.");
    }
    setProcessing(false);
  };

  const columnsAggregated = useMemo(
    () =>
      AGGREGATED_COLUMNS(dateFormat, decimalFormat, defaultValues?.uom).concat(
        OPENING_COLUMNS_AGGREGATED(
          decimalFormat,
          decimalFormat,
          defaultValues?.uom,
          allRecords?.bioLcCoproFIFODetailedView?.data?.weightedAverage[0],
        ),
        INCOMING_COLUMNS_AGGREGATED(
          decimalFormat,
          decimalFormat,
          defaultValues?.uom,
          allRecords?.bioLcCoproFIFODetailedView?.data?.weightedAverage[0],
        ),
        OUTGOING_COLUMNS_AGGREGATED(
          decimalFormat,
          decimalFormat,
          defaultValues?.uom,
          allRecords?.bioLcCoproFIFODetailedView?.data?.weightedAverage[0],
        ),
        CLOSING_COLUMNS_AGGREGATED(
          decimalFormat,
          decimalFormat,
          defaultValues?.uom,
          allRecords?.bioLcCoproFIFODetailedView?.data?.weightedAverage[0],
        ),
      ),
    [
      dateFormat,
      decimalFormat,
      defaultValues?.uom,
      allRecords?.bioLcCoproFIFODetailedView?.data?.weightedAverage,
    ],
  );

  const appliedFilters = useMemo(() => {
    const feedstockDetails =
      currentFilterValues.feedstock?.split(seperationText);
    const locationDetails = currentFilterValues.location?.split(seperationText);

    return {
      ...currentFilterValues,
      locationName: locationDetails[1],
      feedtstockName: feedstockDetails && feedstockDetails[3],
    };
  }, [currentFilterValues]);

  return (
    <Layout
      title="Feedstock FIFO"
      breadcrumbs={breadcrumbItems}
      settingsContent={
        <div className="flex px-7 pt-7">
          <ReportSettingsDetail
            onSave={onReportSettingSave}
            onCancel={{}}
            defaultValues={defaultValues}
            setFilterDefaultValues={setFilterDefaultValues}
          />
          <div>
            {currentFilterValues?.viewType === "aggregated" && (
              <Button
                data-test="copro-fifo-download-to-xlsx-button"
                id="downloadToxlsx"
                name="downloadToxlsx"
                color="standard-tertiary rounded-0 bg-white"
                className="h-9"
                onClick={handleDownloadClick}
              >
                {isProcessing ? (
                  <Spinner size="sm" className="btn-icon-prefix" />
                ) : (
                  <Download24 className="btn-icon-prefix" />
                )}
                Download report
              </Button>
            )}
          </div>
        </div>
      }
    >
      <div className="flex flex-col justify-stretch w-full items-start px-7">
        <FormProvider {...methods}>
          <Form id="fifo-feedstock-filter-form">
            <FeedstockFilterContent
              defaultValues={currentFilterValues}
              filterDynamicData={
                filterData?.bioLcCoproFIFODynamicFilter?.data ?? []
              }
              handleFilterSubmit={handleFilterSubmit}
              handleFilterClear={handleFilterClear}
            ></FeedstockFilterContent>
          </Form>
        </FormProvider>
      </div>
      <div className="flex flex-col  justify-stretch w-full items-start px-7 pt-7">
        <div className="heading-section pb-4">
          {`Currently showing ${
            appliedFilters.feedtstockName ?? "aggregated view"
          } at ${appliedFilters.locationName} for ${moment(
            appliedFilters.periodMonth,
            "MM",
          ).format("MMMM")} ${appliedFilters.periodYear}`}
        </div>
      </div>
      <div className="fifo-feedstock-table flex flex-col  justify-stretch w-full items-start px-7 py-2">
        {currentFilterValues?.viewType === "detailed" && (
          <SplitTable
            columns={columns}
            data={
              allRecords?.bioLcCoproFIFODetailedView?.data?.transactionData ??
              []
            }
            loading={allRecordsLoading}
            onPaginationChange={handlePaginationChange}
            pagination={pagination}
            uom={defaultValues?.uom}
            // keyName={`fifo-feedstock-table-${defaultValues?.uom}`}
          />
        )}

        {currentFilterValues?.viewType === "aggregated" && (
          <SplitTableAggregated
            columns={columnsAggregated}
            data={
              allRecordsAggregated?.bioLcCoproFIFOAggregatedView?.data ?? []
            }
            loading={allRecordsAggregatedLoading}
            onPaginationChange={handlePaginationChange}
            pagination={pagination}
            uom={defaultValues?.uom}
            // keyName={`fifo-feedstock-table-${defaultValues?.uom}`}
          />
        )}
      </div>
    </Layout>
  );
};

export default FeedstockLanding;
