import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

export const COPRO_EU_FIFO_FILTER = gql`
  query filterQuery {
    bioLcCoproConfigCenterLocationDetails {
      Records {
        location_id
        location_code
        location_name
      }
    }

    bioLcCoproFIFODynamicFilter {
      data {
        fifoMonth
        fifoYear
        locationId
        locationName
        materialId
        materialShortName
        sapMaterialCode
        sapNCCode
      }
      message
      status
      statusCode
    }
    bioLcCoproFIFODynamicFilter {
      data {
        fifoMonth
        fifoYear
        locationId
        locationName
        materialId
        materialShortName
        sapMaterialCode
        sapNCCode
      }
      message
      status
      statusCode
    }
  }
`;

export const COPRO_EU_FIFO_ALL_RECORDS = gql`
  query bioLcCoproFIFODetailedView(
    $locationId: String!
    $materialId: String!
    $feedstockType: String!
    $fifoMonth: String!
    $fifoYear: String!
    $uom: String!
    $viewType: String!
    $sapMaterialCode: String
    $download: Boolean
  ) {
    bioLcCoproFIFODetailedView(
      event: {
        locationId: $locationId
        materialId: $materialId
        feedstockType: $feedstockType
        fifoMonth: $fifoMonth
        fifoYear: $fifoYear
        uom: $uom
        viewType: $viewType
        sapMaterialCode: $sapMaterialCode
        download: $download
      }
    ) {
      data {
        transactionData {
          dateOfPhysicalReceipt
          type
          dealNumber
          openingQtyKg
          openingRateKg
          incomingQtyKg
          incomingRateKg
          closingQtyKg
          closingRateKg
          openingQtyMt
          openingRateMt
          incomingQtyMt
          incomingRateMt
          closingQtyMt
          closingRateMt
          openingQtyL15
          openingRateL15
          incomingQtyL15
          incomingRateL15
          closingQtyL15
          closingRateL15
          openingQtyM3
          openingRateM3
          incomingQtyM3
          incomingRateM3
          closingQtyM3
          closingRateM3
          outgoingData {
            outgoingQtyKg
            outgoingRateKg
            outgoingQtyMt
            outgoingRateMt
            outgoingQtyL15
            outgoingRateL15
            outgoingQtyM3
            outgoingRateM3
            status
          }
          moreDetailsData {
            purchase {
              purchaseDifferenceCostKg
              purchaseDifferenceCostMt
              purchaseDifferenceCostL15
              purchaseDifferenceCostM3
              purchaseDifferenceRateKg
              purchaseDifferenceRateL15
              purchaseDifferenceRateM3
              purchaseExpectedCostKg
              purchaseDifferenceRateMt
              purchaseExpectedCostL15
              purchaseExpectedCostM3
              purchaseExpectedCostMt
              purchaseExpectedRateKg
              purchaseExpectedRateL15
              purchaseExpectedRateM3
              purchaseExpectedRateMt
              purchaseInvoicedCostKg
              purchaseInvoicedCostL15
              purchaseInvoicedCostM3
              purchaseInvoicedCostMt
              purchaseInvoicedRateKg
              purchaseInvoicedRateL15
              purchaseInvoicedRateM3
              purchaseInvoicedRateMt
            }
            dischargeQtyKg
            dischargeQtyL15
            dischargeQtyM3
            dischargeQtyMt
            documentItemNumber
            documentNumber
            incoterm
            loadQtyKg
            loadQtyL15
            loadQtyM3
            loadQtyMt
            route {
              mot
              motName
              plantName
              seqNumber
              bioverseFlag
            }
            sapContractNumber
            sapContractNumberItem
            supplier
          }
        }
        weightedAverage {
          closingQtyKg
          closingQtyL15
          closingQtyM3
          closingQtyMt
          closingRateKg
          closingRateM3
          closingRateL15
          closingRateMt
          incomingQtyKg
          incomingQtyL15
          incomingQtyM3
          incomingQtyMt
          incomingRateKg
          incomingRateL15
          incomingRateM3
          incomingRateMt
          openingQtyKg
          openingQtyL15
          openingQtyM3
          openingQtyMt
          openingRateKg
          openingRateL15
          openingRateM3
          openingRateMt
          outgoingQtyKg
          outgoingQtyL15
          outgoingQtyM3
          outgoingQtyMt
          outgoingRateKg
          outgoingRateL15
          outgoingRateM3
          outgoingRateMt
        }
      }
      message
      status
      statusCode
      url
    }
  }
`;

export const UPDATE_FEEDSTOCK = gql`
  mutation UpdateFeedstock(
    $documentNumber: String!
    $mot: String!
    $motName: String!
  ) {
    bioLcCoproFIFOPutRouteDetail(
      event: {documentNumber: $documentNumber, mot: $mot, motName: $motName}
    ) {
      message
      status
      statusCode
    }
  }
`;

export const COPRO_EU_FIFO_ALL_RECORDS_AGGREGATED_VIEW = gql`
  query bioLcCoproFIFOAggregatedView(
    $locationId: String!
    $fifoMonth: String!
    $fifoYear: String!
    $uom: String!
    $viewType: String!
  ) {
    bioLcCoproFIFOAggregatedView(
      event: {
        locationId: $locationId
        fifoMonth: $fifoMonth
        fifoYear: $fifoYear
        uom: $uom
        viewType: $viewType
      }
    ) {
      data {
        closingQtyKg
        closingQtyL15
        closingQtyM3
        closingQtyMt
        closingRateKg
        closingRateL15
        closingRateM3
        closingRateMt
        incomingQtyKg
        incomingQtyL15
        incomingQtyMt
        incomingQtyM3
        incomingRateKg
        incomingRateL15
        incomingRateM3
        incomingRateMt
        materialId
        materialShortName
        openingQtyKg
        openingQtyL15
        openingQtyM3
        openingQtyMt
        openingRateL15
        openingRateKg
        openingRateM3
        openingRateMt
        outgoingQtyKg
        outgoingQtyL15
        outgoingQtyM3
        outgoingQtyMt
        outgoingRateKg
        outgoingRateL15
        outgoingRateM3
        outgoingRateMt
        locationName
        outgoingQtyKgBreakdown {
          percentage
          status_type
          value
        }
        outgoingQtyL15Breakdown {
          percentage
          status_type
          value
        }
        outgoingQtyM3Breakdown {
          percentage
          status_type
          value
        }
        outgoingQtyMtBreakdown {
          percentage
          status_type
          value
        }
      }
      message
      status
      statusCode
    }
  }
`;

export const UpdateFeedstockAPI = (data) =>
  client.mutate({
    mutation: UPDATE_FEEDSTOCK,
    variables: {
      ...data,
    },
    refetchQueries: ["bioLcCoproFIFODetailedView"],
  });

export default {COPRO_EU_FIFO_ALL_RECORDS, COPRO_EU_FIFO_FILTER};
