import {formatNumberIfExists} from "utils/numberUtil";

import "./index.css";
import BreakdownPopover from "./BreakdownPopover";

export const AGGREGATED_COLUMNS = (dateFormat) => {
  return [
    {
      header: "",
      id: "Meta",
      columns: [
        {
          header: "Location",
          accessorKey: "locationName",
          key: "locationName",
          visible: true,
          defaultOption: true,
          enableSorting: false,
        },
        {
          header: "Product",
          accessorKey: "materialShortName",
          key: "materialShortName",
          visible: true,
          defaultOption: true,
          enableSorting: true,
        },
      ],
    },
  ];
};

export const OPENING_COLUMNS_AGGREGATED = (
  dateFormat,
  decimalFormat,
  uom,
  weightedAverage,
) => {
  return [
    {
      header: "Opening",
      columns: [
        {
          header: () => {
            return (
              <div>
                {uom === "t" ? (
                  "mt"
                ) : (
                  <span>
                    m<sup>3</sup>
                  </span>
                )}
              </div>
            );
          },
          accessorKey: "openingQtyMt",
          key: "openingQtyMt",
          visible: true,
          defaultOption: true,
          enableSorting: true,

          dataType: "number",
          cell: ({row}) => {
            return formatNumberIfExists(
              uom === "t"
                ? row?.original?.openingQtyMt
                : row?.original?.openingQtyM3,
              decimalFormat,
              3,
            );
          },
        },
        {
          header: () => {
            return (
              <div>
                {uom === "t" ? (
                  "€/mt"
                ) : (
                  <span>
                    €/m<sup>3</sup>
                  </span>
                )}
              </div>
            );
          },
          accessorKey: "openingRateMt",
          key: "openingRateMt",
          visible: true,
          defaultOption: true,
          enableSorting: true,

          dataType: "number",
          cell: ({row}) => {
            return formatNumberIfExists(
              uom === "t"
                ? row?.original?.openingRateMt
                : row?.original?.openingRateM3,
              decimalFormat,
              2,
            );
          },
        },
      ],
    },
  ];
};

export const INCOMING_COLUMNS_AGGREGATED = (
  dateFormat,
  decimalFormat,
  uom,
  weightedAverage,
) => {
  return [
    {
      header: "Incoming",
      columns: [
        {
          header: () => {
            return (
              <div>
                {uom === "t" ? (
                  "mt"
                ) : (
                  <span>
                    m<sup>3</sup>
                  </span>
                )}
              </div>
            );
          },
          accessorKey: "incomingQtyMt",
          key: "incomingQtyMt",
          visible: true,
          defaultOption: true,
          enableSorting: true,

          dataType: "number",
          cell: ({row}) => {
            return formatNumberIfExists(
              uom === "t"
                ? row?.original?.incomingQtyMt
                : row?.original?.incomingQtyM3,
              decimalFormat,
              3,
            );
          },
        },
        {
          header: () => {
            return (
              <div>
                {uom === "t" ? (
                  "€/mt"
                ) : (
                  <span>
                    €/m<sup>3</sup>
                  </span>
                )}
              </div>
            );
          },
          accessorKey: "incomingRateMt",
          key: "incomingRateMt",
          visible: true,
          defaultOption: true,
          enableSorting: true,
          dataType: "number",
          cell: ({row}) => {
            return formatNumberIfExists(
              uom === "t"
                ? row?.original?.incomingRateMt
                : row?.original?.incomingRateM3,
              decimalFormat,
              2,
            );
          },
        },
      ],
    },
  ];
};

export const OUTGOING_COLUMNS_AGGREGATED = (
  dateFormat,
  decimalFormat,
  uom,
  weightedAverage,
) => {
  return [
    {
      header: "Outgoing",
      columns: [
        {
          header: () => {
            return (
              <div>
                {uom === "t" ? (
                  "mt"
                ) : (
                  <span>
                    m<sup>3</sup>
                  </span>
                )}
              </div>
            );
          },
          accessorKey: "outgoingQtyMt",
          key: "outgoingQtyMt",
          visible: true,
          defaultOption: true,
          enableSorting: true,
          dataType: "number",
          cell: ({row}) => {
            const popoverId = `tooltip-${Math.random()
              .toString(36)
              .substring(2, 9)}`;
            return (
              <div className="cursor-pointer">
                <div id={`outgoingQty${row.id}${popoverId}`}>
                  {formatNumberIfExists(
                    uom === "t"
                      ? row?.original?.outgoingQtyMt
                      : row?.original?.outgoingQtyM3,
                    decimalFormat,
                    3,
                  )}
                </div>
                <BreakdownPopover
                  row={row}
                  popoverId={popoverId}
                  uom={uom}
                  decimalFormat={decimalFormat}
                />
              </div>
            );
          },
        },
        {
          header: () => {
            return (
              <div>
                {uom === "t" ? (
                  "€/mt"
                ) : (
                  <span>
                    €/m<sup>3</sup>
                  </span>
                )}
              </div>
            );
          },
          accessorKey: "outgoingRateMt",
          key: "outgoingRateMt",
          visible: true,
          defaultOption: true,
          enableSorting: true,
          dataType: "number",
          cell: ({row}) => {
            return formatNumberIfExists(
              uom === "t"
                ? row?.original?.outgoingRateMt
                : row?.original?.outgoingRateM3,
              decimalFormat,
              2,
            );
          },
        },
      ],
    },
  ];
};

export const CLOSING_COLUMNS_AGGREGATED = (
  dateFormat,
  decimalFormat,
  uom,
  weightedAverage,
) => {
  return [
    {
      header: "Closing",
      columns: [
        {
          header: () => {
            return (
              <div>
                {uom === "t" ? (
                  "mt"
                ) : (
                  <span>
                    m<sup>3</sup>
                  </span>
                )}
              </div>
            );
          },
          accessorKey: "closingQtyMt",
          key: "closingQtyMt",
          visible: true,
          defaultOption: true,
          enableSorting: true,
          dataType: "number",
          cell: ({row}) => {
            return formatNumberIfExists(
              uom === "t"
                ? row?.original?.closingQtyMt
                : row?.original?.closingQtyM3,
              decimalFormat,
              3,
            );
          },
        },
        {
          header: () => {
            return (
              <div>
                {uom === "t" ? (
                  "€/mt"
                ) : (
                  <span>
                    €/m<sup>3</sup>
                  </span>
                )}
              </div>
            );
          },
          accessorKey: "closingRateMt",
          key: "closingRateMt",
          visible: true,
          defaultOption: true,
          enableSorting: true,
          dataType: "number",
          cell: ({row}) => {
            return formatNumberIfExists(
              uom === "t"
                ? row?.original?.closingRateMt
                : row?.original?.closingRateM3,
              decimalFormat,
              2,
            );
          },
        },
      ],
    },
  ];
};

// Format doesn't matter here, since we are only interested in accessor keys
export const META_COLUMN_IDS = AGGREGATED_COLUMNS(
  "DD/MM/YYYY",
  "0.00",
)[0].columns.map((col) => col.accessorKey);
export const OPENING_COLUMN_IDS = OPENING_COLUMNS_AGGREGATED(
  "DD/MM/YYYY",
  "0.00",
)[0].columns.map((col) => col.accessorKey);
export const INCOMING_COLUMN_IDS = INCOMING_COLUMNS_AGGREGATED(
  "DD/MM/YYYY",
  "0.00",
)[0].columns.map((col) => col.accessorKey);
export const OUTGOING_COLUMN_IDS = OUTGOING_COLUMNS_AGGREGATED(
  "DD/MM/YYYY",
  "0.00",
)[0].columns.map((col) => col.accessorKey);
export const CLOSING_COLUMN_IDS = CLOSING_COLUMNS_AGGREGATED(
  "DD/MM/YYYY",
  "0.00",
)[0].columns.map((col) => col.accessorKey);
