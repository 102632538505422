import React, {useEffect, useMemo, useState} from "react";
import {useQuery} from "@apollo/client";
import GET_MASS_BALANCE_INCOMING_DOCS, {
  GET_MASS_BALANCE_OUTGOING_DOCS,
} from "graphql/saf/MassBalance";
import Layout from "../components/Layout";
import SplitTable from "../components/SplitTable";
import {
  INCOMING_COLUMNS,
  OUTGOING_COLUMNS,
} from "../components/SplitTable/Column";
import {OUTGOING_DOC_STATUS} from "../constants/documents";

const SAFMassBalancePage = () => {
  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: "SAF Sweden", link: "/saflandingpage"},
    {text: "Mass balance", link: "/saf-sweden/mass-balance"},
  ];

  const [selectedIncomingDoc, setSelectedIncomingDoc] = useState(null);
  const [selectedIncomingDocId, setSelectedIncomingDocId] = useState(null);

  const [outData, setOutData] = useState([]);

  const {data: incomingDocs, loading: incomingLoading} = useQuery(
    GET_MASS_BALANCE_INCOMING_DOCS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    },
  );

  const {
    data: outgoingDocs,
    loading: outgoingLoading,
    refetch: refetchOutgoing,
  } = useQuery(GET_MASS_BALANCE_OUTGOING_DOCS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      sd_number: selectedIncomingDoc,
    },
    skip: !selectedIncomingDoc,
  });

  useEffect(() => {
    if (
      incomingDocs &&
      incomingDocs?.bioLcSafMBGetIncomingDocs &&
      incomingDocs?.bioLcSafMBGetIncomingDocs?.documents
    ) {
      setSelectedIncomingDoc(
        incomingDocs?.bioLcSafMBGetIncomingDocs?.documents[0]?.sd_number,
      );
      setSelectedIncomingDocId(
        incomingDocs?.bioLcSafMBGetIncomingDocs?.documents[0]
          ?.certificate_inbound_eu_id,
      );
    }
  }, [incomingDocs]);

  useEffect(() => {
    if (
      selectedIncomingDoc &&
      outgoingDocs &&
      outgoingDocs?.bioLcSafMBGetOutgoingDocs &&
      outgoingDocs?.bioLcSafMBGetOutgoingDocs?.outgoingRecords
    ) {
      setOutData([
        {
          incomingRecords: [
            {
              sd_number: selectedIncomingDoc,
              certificate_inbound_eu_id: selectedIncomingDocId,
            },
          ],
          outgoingRecords:
            outgoingDocs?.bioLcSafMBGetOutgoingDocs?.outgoingRecords?.length > 0
              ? [
                  {
                    status: OUTGOING_DOC_STATUS.AVAILABLE,
                    refetchOutgoing,
                  },
                  ...outgoingDocs.bioLcSafMBGetOutgoingDocs.outgoingRecords,
                ]
              : [
                  {
                    status: OUTGOING_DOC_STATUS.AVAILABLE,
                    refetchOutgoing,
                  },
                ],
        },
      ]);
    }
  }, [
    incomingDocs,
    selectedIncomingDoc,
    selectedIncomingDocId,
    outgoingDocs,
    refetchOutgoing,
  ]);

  const columns = useMemo(() => {
    const IncomingColsList = INCOMING_COLUMNS(
      incomingDocs &&
        incomingDocs?.bioLcSafMBGetIncomingDocs &&
        incomingDocs?.bioLcSafMBGetIncomingDocs?.documents,
      selectedIncomingDoc,
      setSelectedIncomingDoc,
      setSelectedIncomingDocId,
    );
    return IncomingColsList.concat(OUTGOING_COLUMNS());
  }, [selectedIncomingDoc, incomingDocs]);

  return (
    <Layout title="Mass balance" breadcrumbs={breadcrumbItems}>
      <div className="min-h-[40vh] px-7 mt-16">
        <SplitTable
          columns={columns}
          data={outData}
          loading={incomingLoading || outgoingLoading}
        />
      </div>
    </Layout>
  );
};

export default SAFMassBalancePage;
