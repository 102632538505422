import React from "react";
import moment from "moment";
import {Datepicker, Timepicker} from "@bphxd/ds-core-react";
import {Label, Input, InputGroup, InputGroupText, FormGroup} from "reactstrap";
import parseAndRoundToTwoDecimal from "utils/numberUtil";
import {
  dtnShipmentKeys,
  VOLUME_PER_HOUR,
} from "modules/co-processing/constants/shipments";

export const formatTimePicker = (time) => {
  const formatted = moment(time, "HH:mm").format("hh:mm A");

  return formatted;
};

export function calculateMaxVolume(startTime, endTime) {
  if (!startTime || !endTime) {
    return null;
  }

  // Times are received in format "YYYY-MM-DDTHH:mm:ss", interested only in "HH:mm" part for calculation.
  const format = "HH:mm";
  const startMoment = moment(startTime, format);
  const endMoment = moment(endTime, format);

  // Adjust for times that cross midnight
  if (endMoment.isBefore(startMoment)) {
    endMoment.add(1, "days"); // End time is never more than a day after start time.
  }

  const diffHours = endMoment.diff(startMoment, "hours", true);

  const estimatedVolume = diffHours * VOLUME_PER_HOUR;

  return parseAndRoundToTwoDecimal(estimatedVolume);
}

export function displayFieldInfo(fieldName, fieldValue, fieldConfig) {
  if (fieldName === dtnShipmentKeys.RENEWABLE_SHIPMENT_FLAG) {
    return fieldValue === false;
  }
  return !!fieldConfig.info;
}

// Combines date and time into a single string,
const formatShipmentDateTime = (date, time) => {
  const isValidDate = moment(date, "YYYY-MM-DD", true).isValid();
  const isValidTime = moment(time, "HH:mm", true).isValid();

  if (isValidDate && isValidTime) {
    return `${date} ${time}`;
  }

  return date;
};

export function formatDTNShipmentFormData(formData) {
  if (!formData) {
    return null;
  }

  const {
    shipment_datez,
    shipment_start_datez,
    shipment_end_datez,
    // Exclude field on form submit, field needs to be defined in query.
    source_tank,
    ...rest
  } = formData;

  return {
    ...rest,
    shipment_datez, // YYYY-MM-DD
    shipment_start_datez: formatShipmentDateTime(
      shipment_datez,
      shipment_start_datez,
    ),
    shipment_end_datez: formatShipmentDateTime(
      shipment_datez,
      shipment_end_datez,
    ),
  };
}

export const renderInputs = {
  input: ({addOn, error, field, placeholder, type}) => (
    <InputGroup className="bg-white">
      <Input
        {...field}
        invalid={!!error}
        placeholder={placeholder}
        type={type}
      />
      {addOn && (
        <InputGroupText className="bg-transparent">{addOn}</InputGroupText>
      )}
    </InputGroup>
  ),

  date: ({error, field}) => {
    return (
      <Datepicker
        name={field.name}
        required
        className="createShipment__datePicker"
        invalid={!!error}
        options={{
          altFormat: "m/j/Y",
          dateFormat: "Y-m-d",
          defaultDate: field?.value,
          onChange: (_, dateStr) => field?.onChange(dateStr),
        }}
      />
    );
  },

  time: ({field, error, startTime}) => {
    const options = {
      altInput: true,
      enableTime: true,
      noCalendar: true,
      altFormat: "h:i K", // Display format for user
      defaultDate: field?.value,
      dateFormat: "H:i",
      minTime:
        field.name === dtnShipmentKeys.SHIPMENT_END_DATE
          ? startTime
          : undefined,
      onChange: (selectedDates, dateStr) => {
        field.onChange(dateStr);
      },
    };

    return (
      <Timepicker
        required
        id={field?.name}
        name={field?.name}
        invalid={!!error}
        options={options}
        className="createShipment__timePicker form-control-prepended"
      />
    );
  },

  select: ({field, error, options, placeholder = "Select an option"}) => {
    return (
      <Input type="select" {...field} invalid={!!error}>
        <option key="none" disabled hidden value="">
          {`-- ${placeholder} --`}
        </option>

        {options?.map((option) => (
          <option key={option?.value || option} value={option?.value || option}>
            {option?.label || option}
          </option>
        ))}
      </Input>
    );
  },

  switch: ({field, error, info}) => {
    return (
      <FormGroup switch>
        <Input
          {...field}
          checked={field.value ?? false}
          id={`input-${field.name}`}
          invalid={!!error}
          type="switch"
        />
        <Label className="text-sm/4" color="muted" for={`input-${field.name}`}>
          {field.value ? "Yes" : "No"}
        </Label>
      </FormGroup>
    );
  },
};
