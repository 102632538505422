import PropTypes from "prop-types";
import {Link} from "react-router-dom";

const Section = ({
  link,
  img,
  title,
  description,
  redirectToExternalLink,
  redirectToExternalLinkURL,
}) => {
  return (
    <div data-test={`document-${title}`}>
      {redirectToExternalLink ? (
        <a
          target="_blank"
          rel="noreferrer"
          href={redirectToExternalLinkURL}
          data-test="external-link"
          className="flex flex-col items-center justify-center h-48 px-4 py-5 text-center border border-gray-200 rounded-md hover:no-underline hover:border-transparent hover:bg-bp-green-50"
        >
          <img data-test="img" src={img} alt={title} />
          <h3
            data-test="title"
            className="mt-3 text-lg font-bold text-center text-indigo-500"
          >
            {title}
          </h3>
          <p data-test="description" className="hidden mt-1 text-gray-600">
            {description}
          </p>
        </a>
      ) : (
        <Link
          data-test="link"
          to={link}
          className="flex flex-col items-center justify-center h-48 px-4 py-5 text-left border border-gray-200 rounded-md hover:no-underline hover:border-transparent hover:bg-bp-green-50"
        >
          <img data-test="img" src={img} alt={title} />
          <h3
            data-test="title"
            className="mt-3 text-lg font-bold text-center text-indigo-500"
          >
            {title}
          </h3>
          <p data-test="description" className="hidden mt-1 text-gray-600">
            {description}
          </p>
        </Link>
      )}
    </div>
  );
};

Section.propTypes = {
  link: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  redirectToExternalLink: PropTypes.bool,
  redirectToExternalLinkURL: PropTypes.string,
};

export default Section;
