import tw, {styled} from "twin.macro";
import PropTypes from "prop-types";
import MetricInput from "modules/co-processing/components/Shipments/MetricInput";
import {displayDate} from "modules/co-processing/helpers/dateHelper";
import {
  SHIPMENTS_FIELDS_RIGHT_COLUMN,
  SHIPMENTS_FIELDS_LEFT_COLUMN,
  MAX_BBL_VOLUME,
} from "modules/co-processing/constants/shipments";
import CopyToClipboard from "modules/co-processing/components/Shipments/CopyToClipboard";
import {useEffect, useState} from "react";
import shipmentDataFormatter from "modules/co-processing/helpers/shipmentDataFormatter";
import _ from "lodash";
import {shipmentLabelFormatter} from "modules/co-processing/helpers/valueFormatter";

const ShipmentLabel = styled.div(({className}) => [
  className && tw`${className}`,
]);

const ShipmentField = styled.div(({className}) => [
  tw`flex items-baseline text-[13px] font-[400]`,
  className && tw`${className}`,
]);
const ShipmentValue = tw.div`text-[#111111] pl-3 text-opacity-[0.64]`;

const ShipmentFieldWrapper = ({index, shipment, field, fieldFunc}) => {
  const modifiedField = {...field, errorKey: `${field.key}_${index}`};
  return (
    <div key={field.label} className="min-w-[50%] px-3 py-1">
      <ShipmentField key={field.label} data-test="copro-shipment-fields">
        <ShipmentLabel className="min-w-[120px] w-[120px] pb-3">
          {field.label}
        </ShipmentLabel>
        {fieldFunc(shipment, modifiedField)}
      </ShipmentField>
    </div>
  );
};

ShipmentFieldWrapper.propTypes = {
  index: PropTypes.number,
  shipment: PropTypes.object,
  field: PropTypes.object,
  fieldFunc: PropTypes.func,
};

const ShipmentCard = ({data, handleChange, handleErrors}) => {
  const getMaxShipmentVolume = (shipmentData) => {
    if (shipmentData.batch_id) {
      // make API call to get batch's remaining volume based on batch_id
      // return remaining volume as max value
    }
    return MAX_BBL_VOLUME;
  };

  const formattedData = shipmentDataFormatter(data);
  const [actualVolumes, setActualVolumes] = useState(
    formattedData.map((shipment) => shipment.total_actual_volume ?? 0),
  );

  useEffect(() => {
    if (_.sum(actualVolumes) > data?.total_estimated_volume) {
      handleErrors(
        "shipment_actual_sum",
        "Invalid volume amounts. Shipment volumes should not exceed total estimated volume.",
      );
    } else {
      handleErrors("shipment_actual_sum", "");
    }
  }, [actualVolumes, handleErrors, data]);

  const getFieldType = (shipmentData, fieldDetails) => {
    switch (fieldDetails.type) {
      case "input":
        return (
          <ShipmentValue>
            <MetricInput
              value={shipmentData[fieldDetails.key]}
              dataKey={fieldDetails.key}
              errorKey={fieldDetails.errorKey}
              placeholder={fieldDetails.placeholder}
              showMetric={!!fieldDetails.metric}
              maxValue={getMaxShipmentVolume(shipmentData)}
              onChange={handleChange}
              reportErrors={handleErrors}
              shipmentId={shipmentData.shipment_id}
              updateActualVolume={(value) => {
                const newVolumes = actualVolumes.map((elem, index) => {
                  if (index === fieldDetails.index) {
                    return parseFloat(value);
                  }
                  return elem;
                });
                setActualVolumes(newVolumes);
              }}
            />
          </ShipmentValue>
        );
      case "date":
        return (
          <ShipmentValue>
            {displayDate(shipmentData[fieldDetails.key], true)}
          </ShipmentValue>
        );
      case "formattedText": {
        const value = shipmentData[fieldDetails.key];
        return <ShipmentValue>{shipmentLabelFormatter(value)}</ShipmentValue>;
      }
      default: {
        const value = shipmentData[fieldDetails.key];
        const formattedValue =
          value !== null
            ? `${value?.toLocaleString()} ${fieldDetails.metric ?? ""}`
            : "N/A";

        return <ShipmentValue>{formattedValue}</ShipmentValue>;
      }
    }
  };
  const isSplit = formattedData?.length > 1;

  const totalField = SHIPMENTS_FIELDS_RIGHT_COLUMN.find(
    (field) => field.key === "total_estimated_volume",
  );

  return (
    <div data-test="copro-shipment-card" className="flex flex-col">
      <div data-test="copro-shipment-part" className="pt-3">
        {isSplit && (
          <div className="px-3">
            <ShipmentField
              className="pb-6"
              data-test="copro-shipment-field-total"
            >
              <ShipmentLabel>{totalField.label}</ShipmentLabel>
              {getFieldType(formattedData[0], totalField)}
            </ShipmentField>
          </div>
        )}
        {formattedData?.map((shipment, shipmentIndex) => {
          return (
            <div
              data-test={`copro-shipment-part-item-${shipmentIndex}`}
              key={shipmentIndex}
            >
              {isSplit && (
                <div
                  data-test="copro-shipment-part-header"
                  className="flex flex-row items-center border-b px-3"
                >
                  <div data-test="copro-shipment-part-title">
                    {shipment.shipment_id}
                  </div>
                  <CopyToClipboard text={shipment.shipment_id} />
                </div>
              )}
              <div key={shipmentIndex} className="flex flex-wrap py-5">
                <div className="w-full flex-1">
                  {SHIPMENTS_FIELDS_LEFT_COLUMN.map((field) => (
                    <ShipmentFieldWrapper
                      index={shipmentIndex}
                      shipment={shipment}
                      field={field}
                      fieldFunc={getFieldType}
                    />
                  ))}
                </div>
                <div className="w-full flex-1">
                  {SHIPMENTS_FIELDS_RIGHT_COLUMN.map((field) => {
                    if (isSplit && field.key === "total_estimated_volume") {
                      return null;
                    }
                    // Logic notes - for Truck rack, total_actual_volume should be text and not input
                    const modifiedField =
                      field.key === "total_actual_volume" &&
                      shipment.shipment_type?.toLowerCase().includes("truck")
                        ? {...field, type: "text"}
                        : field;
                    const indexedField = {
                      ...modifiedField,
                      index: shipmentIndex,
                    };
                    return (
                      <ShipmentFieldWrapper
                        index={shipmentIndex}
                        shipment={shipment}
                        field={indexedField}
                        fieldFunc={getFieldType}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

ShipmentCard.propTypes = {
  data: PropTypes.any,
  handleChange: PropTypes.func,
  handleErrors: PropTypes.func,
};

export default ShipmentCard;
