import PropTypes from "prop-types";
import {BATCH_STATUS} from "../constants/batches";

const BatchesStatusChip = ({value, small = false}) => {
  const upperBatchStatus = BATCH_STATUS[value?.toUpperCase()];
  const fontSize = small ? "text-[10px]" : "";

  const statusToChipClassMap = {
    [BATCH_STATUS.ACTIVE]: "blue-chip",
    [BATCH_STATUS.TRACKED_OUT]: "blue-chip",
    [BATCH_STATUS.DRAFT]: "blue-chip",
    [BATCH_STATUS.READY_FOR_ALLOCATION]: "blue-chip",
    [BATCH_STATUS.READY_FOR_SUBMISSION]: "blue-chip",
    [BATCH_STATUS.SUBMITTED_FOR_CREDITS]: "green-chip",
    [BATCH_STATUS.NOT_QUALIFIED]: "green-chip",
  };

  const chipClass = statusToChipClassMap[upperBatchStatus];

  return (
    <div data-test="copro-batch-chip" className="flex items-center">
      {chipClass && (
        <div className={`${chipClass} batches-chip ${fontSize}`}>
          {upperBatchStatus}
        </div>
      )}
    </div>
  );
};

BatchesStatusChip.propTypes = {
  value: PropTypes.string,
  small: PropTypes.bool,
};

export default BatchesStatusChip;
