import {useEffect, useMemo} from "react";
import {useQuery} from "@apollo/client";
import {FormGroup} from "reactstrap";
import {useFormContext} from "react-hook-form";
import {
  OP_CONDITIONS,
  ADD_BATCH_FORM,
} from "modules/co-processing/constants/batches";
import {COPRO_US_BATCHES_DETAILS_API} from "graphql/coprocessing/batches";
import {DHDSDateTime, BatchIDTextField, Form} from "../FormElements";
import {generateSplitBatchId} from "../generateSplitBatchId";

const fieldKeys = ADD_BATCH_FORM.keys;
const fieldLabels = ADD_BATCH_FORM.labels;
const originalBatchIdKey = `og_${fieldKeys.batchId}`;

const StepThree = () => {
  const {getValues, setValue, watch} = useFormContext();
  const isSplit = getValues(fieldKeys.splitBatch, false);
  const isByPassed =
    getValues(fieldKeys.opCondition) === OP_CONDITIONS.BY_PASSED;
  const tankNumber = getValues(fieldKeys.tankNo);
  const userSelectedId = watch(originalBatchIdKey, "");

  // Query for active batches,
  // Will be replaced api as part of user-story #35...
  const {loading, data} = useQuery(COPRO_US_BATCHES_DETAILS_API, {
    variables: {op: "get_batches", batch_tab: "active"},
    skip: !isSplit, // Skip query unless split_batch is enabled.
  });

  const activeBatches = useMemo(
    () => data?.bioLcCoproUsBatchesApi?.body?.batches ?? [],
    [data],
  );
  const activeBatchesByTank = useMemo(
    () => activeBatches.filter((b) => b.tank_number === tankNumber),
    [activeBatches, tankNumber],
  );

  // Updates form fields when `isSplit` is true and when selected batch Id changes.
  useEffect(() => {
    if (isSplit && userSelectedId) {
      // Generate new split batch ID using batch ID selected by the user and existing batch IDs.
      const originalBatchIds = activeBatchesByTank.map((b) => b?.batch_id);

      const newBatchId = generateSplitBatchId(userSelectedId, originalBatchIds);
      setValue(fieldKeys.batchId, newBatchId);

      // Extract and set additional form fields related to new batch ID.
      const newBatchCode = newBatchId.split(".")?.[1];
      setValue(fieldKeys.batchCode, newBatchCode);

      const tankCertifiedDate =
        activeBatchesByTank.find((batch) => batch.batch_id === userSelectedId)
          ?.tank_certified_datez ?? null;

      setValue(ADD_BATCH_FORM.keys.tankCertifiedDate, tankCertifiedDate);

      const prefix = newBatchId?.split("_").at(-1) || ""; // batch_id format: "{CP12_89}_{suffix}"
      setValue("batchIdSplitPrefix", prefix);
    }
  }, [isSplit, userSelectedId, activeBatchesByTank, setValue]);

  if (isByPassed) {
    return (
      <FormGroup>
        <Form.DateTimePicker
          label={fieldLabels.tankCertifiedDate}
          name={fieldKeys.tankCertifiedDate}
        />

        <BatchIDTextField />
      </FormGroup>
    );
  }

  return isSplit ? (
    <div className="row g-6 mb-4">
      <Form.Select
        isLoading={loading}
        label={fieldLabels.ogBatchId}
        options={activeBatchesByTank.map((b) => b?.batch_id)}
        name={originalBatchIdKey}
      />

      <Form.Field className="col-sm-12" label="Split A batch ID">
        {/* Display currently set split batch ID */}
        <p>{watch(fieldKeys.batchId)}</p>
      </Form.Field>
    </div>
  ) : (
    <div className="row g-6 mb-4">
      <DHDSDateTime />
    </div>
  );
};

export default StepThree;
