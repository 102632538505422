import {gql} from "@apollo/client";

export const SAF_INCOMING_AND_OUTGOING_COUNT = gql`
  query bioLcSafGetInOutDocsCount {
    bioLcSafGetInOutDocsCount(
      event: {
        division_id: "7c5fc8889b6e3c8756abbd76353d68e7"
        site_reference_id: "c8f4261f9f46e6465709e17ebea7a92b"
      }
    ) {
      error
      statusCode
      inbound_count
      outbound_count
    }
  }
`;

export default SAF_INCOMING_AND_OUTGOING_COUNT;
