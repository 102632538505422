import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import PropTypes from "prop-types";
import {Close} from "@bphxd/ds-core-react";
import Form from "modules/common/FormNew";
import dateFunctions from "utils/helpers/dateFunctions";
import {useMsal, useAccount} from "@azure/msal-react";
import {AssignToTransferAPI} from "graphql/es-co-processing/mass-balance/assignToTransfer";
import {SchemaAssigntoTransfer} from "./schemaAssignToTransfer";
import TransferDetails from "./TransferDetails";

const AssignToTransferModal = ({isVisibleModal, rowData}) => {
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);
  const [isModalVisible, setModalVisible] = useState(isVisibleModal);
  const [isProcessing, setProcessing] = useState(false);

  useEffect(() => {
    setModalVisible(isVisibleModal);
  }, [isVisibleModal]);

  return (
    <div>
      <Modal
        size="lg"
        isOpen={isModalVisible}
        className="modal-dialog-centered"
      >
        <ModalHeader
          className="border-b-2 border-gray-200 mb-5 text-xl"
          close={<Close onClick={() => setModalVisible(false)} />}
        >
          Assign to transfer
        </ModalHeader>
        <ModalBody className="text-center pt-0">
          <Form
            id="assign-to-transfer-form"
            schema={SchemaAssigntoTransfer}
            onSubmit={async (values) => {
              setProcessing(true);
              const motDetails = values?.modeOfTransportation?.split("#-#");
              const {data: response} = await AssignToTransferAPI({
                ...values,
                inboundEuQtyId: rowData?.inboundRecord?.inboundEuQtyId,
                sdNumber: rowData?.inboundRecord?.sdNumber,
                fromLocationCode: rowData?.inboundRecord?.mbLocationCode,
                toLocationCode: values.transferLocation,
                motName: motDetails[0],
                quantity: values?.quantity,
                dispatchDate: dateFunctions.formatDatePickerDate(
                  values?.dispatchDate,
                ),
                vesselName: values?.vesselName,
                transportationEmissionId: motDetails[1],
                createdBy: account.username,
              });

              if (
                response?.bioLcCoproEuMassBalanceTransfer?.statusCode === 200
              ) {
                setModalVisible(false);
                setProcessing(false);
                toast.success(
                  response?.bioLcCoproEuMassBalanceTransfer?.message ??
                    "Transferred successfully",
                );
              } else {
                setProcessing(false);
                toast.error(
                  response?.bioLcCoproEuMassBalanceTransfer?.message ??
                    "Something went wrong, failed to transfer",
                );
              }
              return response;
            }}
          >
            <TransferDetails data={rowData} />
          </Form>
        </ModalBody>
        <ModalFooter className="p-0 d-block">
          <div className="row g-0 m-0 modal-footer-row">
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
                onClick={() => setModalVisible(false)}
              >
                Cancel
              </Button>
            </div>
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 py-4 bg-transparent text-default"
                form="assign-to-transfer-form"
                type="submit"
                disabled={isProcessing}
              >
                {isProcessing && (
                  <Spinner size="sm" className="btn-icon-prefix" />
                )}
                Confirm Transfer
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

AssignToTransferModal.propTypes = {
  isVisibleModal: PropTypes.bool,
  rowData: PropTypes.object,
};

export default AssignToTransferModal;
