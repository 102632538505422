import PropTypes from "prop-types";
import React from "react";

const SingleDocumentViewer = ({document}) => {
  return (
    <div className="flex flex-col flex-1 items-start justify-stretch bg-light-gray-100">
      {document.download_url === null ? (
        <div className="flex flex-col p-5 w-full text-center">
          <span className="fs-4 text-danger">Unable to load file</span>
          <code className="fs-6 text-danger">File not found</code>
        </div>
      ) : (
        <iframe
          className="w-full min-h-screen"
          title="View document"
          border="0"
          src={document.download_url}
        ></iframe>
      )}
    </div>
  );
};

SingleDocumentViewer.propTypes = {
  document: PropTypes.object,
};

export default SingleDocumentViewer;
