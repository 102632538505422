import PropTypes from "prop-types";
import {useState} from "react";
import {Card, CardBody, Button} from "reactstrap";
import {
  BATCH_LABELS,
  BATCH_SUMMARY_LABELS,
  METRICS,
  BUTTONS,
  BATCH_SUMMARY_COLUMNS,
} from "modules/co-processing/constants/batches";
import {displayDate} from "modules/co-processing/helpers/dateHelper";
import {setInitialData} from "modules/co-processing/helpers/formBuilder";
import {formatNumber} from "providers/userSettings";
import BatchSummaryDisplay from "./BatchSummaryDisplay";

const BatchSummarySection = ({data, isEditable, daysRemaining}) => {
  const [displayData, setDisplayData] = useState(data);
  const [editState, setEditState] = useState(isEditable);
  const [formData, setFormData] = useState(
    setInitialData(data, BATCH_SUMMARY_COLUMNS),
  );

  const handleSave = (event) => {
    event.preventDefault();
    console.log("get submit event", formData);
    // TODO: 358948 - add edit graphql query
    setDisplayData(formData);
    setEditState(false);
  };

  const renderButtons = () => {
    if (editState) {
      return (
        <div>
          <Button
            className="pb-2 rounded-1 bg-dark mr-2"
            data-test="copro-save-btn"
            onClick={handleSave}
          >
            {BUTTONS.SAVE}
          </Button>
          <Button
            className="pb-2 rounded-1 bg-white text-black"
            data-test="copro-exit-edit-btn"
            onClick={() => setEditState(false)}
          >
            {BUTTONS.EXIT_EDIT}
          </Button>
        </div>
      );
    }
    return (
      <div>
        <Button
          className="pb-2 rounded-1 bg-dark"
          data-test="copro-edit-btn"
          onClick={() => setEditState(true)}
        >
          {BUTTONS.EDIT}
        </Button>
      </div>
    );
  };

  return (
    <div className="pb-8 w-3/4" data-test="batch-summary-wrapper">
      <Card className="card-border rounded-6">
        <CardBody>
          <div className="flex flex-row justify-between">
            <div className="text-[26px] pb-10">{BATCH_LABELS.SUMMARY}</div>
            {renderButtons()}
          </div>
          <div>
            <div className="grid grid-cols-3 justify-items-center text-center border-b-[1.5px]">
              <div className="pb-7">
                <div className="text-xs pb-3">
                  {BATCH_SUMMARY_LABELS.DAYS_SUBMISSION}
                </div>
                <div className="text-3xl">{daysRemaining ?? "N/A"}</div>
                <div className="text-secondary text-xs">{METRICS.DAYS}</div>
              </div>
              <div>
                <div className="text-xs pb-3">
                  {BATCH_SUMMARY_LABELS.TANK_CERTIFICATION}
                </div>
                <div className="text-3xl">
                  {formatNumber(data?.tank_volume, "Imperial Style")}
                </div>
                <div className="text-secondary text-xs">{METRICS.BARRELS}</div>
              </div>
              <div>
                <div className="text-xs pb-3">
                  {BATCH_SUMMARY_LABELS.TANK_CERTIFIED}
                </div>
                <div className="text-xl">
                  {displayDate(data?.tank_certified_datez)}
                </div>
              </div>
            </div>
            <BatchSummaryDisplay
              batchDetails={displayData ?? data}
              editState={editState}
              formData={formData}
              setFormData={setFormData}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

BatchSummarySection.propTypes = {
  data: PropTypes.object,
  isEditable: PropTypes.bool,
  daysRemaining: PropTypes.number,
};

export default BatchSummarySection;
