import tw from "twin.macro";
import {ReactTable} from "@bphxd/ds-core-react";
import {Button} from "reactstrap";
import {useDateRange} from "modules/co-processing/context/DateRangeContext";
import {useQuery, useLazyQuery} from "@apollo/client";
import {
  COPRO_US_API_CONTRACT_DATA,
  COPRO_US_API_SAVE_DRAFT_DATA,
} from "graphql/coprocessing/receipts";
import {useMemo, useState, useEffect} from "react";
import {
  CONTRACT_SUMMARY_GRAPH,
  TABLE_TITLE,
  contractSummaryHeaders,
  CONTRACT_STATUS,
} from "../constants/receipts";
import {useStatus} from "../context/StatusContext";
import SummaryBarChart from "./SummaryBarChart";
import buildColumns, {filterDraftColumns} from "../helpers/buildColumns";

const GraphContainer = tw.div`mt-12 xl:w-[445px]`;
const GraphBody = tw.div`flex bg-white py-4 w-full justify-center`;
const ContainerTitle = tw.div`h-[52px] flex bg-white items-center pl-[15px] border-b`;

const ContractSummary = () => {
  const {dateRange, isScheduled, setIsScheduled} = useDateRange();
  const {
    isDraft,
    draftChangeHandler,
    rowChangeHandler,
    schedulableRecordHandler,
  } = useStatus();
  const [selectedRows, setSelectedRows] = useState({});

  const initialVariables = {
    tracking_type: isDraft
      ? CONTRACT_STATUS.STATUS.toLowerCase()
      : CONTRACT_STATUS.SUMMARY.toLowerCase(),
    date_range: {
      start_date: dateRange.from,
      end_date: dateRange.to,
    },
  };

  const {data} = useQuery(COPRO_US_API_CONTRACT_DATA, {
    variables: initialVariables,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    rowChangeHandler(Object.keys(selectedRows).length > 0);
  }, [rowChangeHandler, selectedRows]);

  const [scheduleQuery] = useLazyQuery(COPRO_US_API_SAVE_DRAFT_DATA, {
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      setIsScheduled(false);
      setSelectedRows({});
      draftChangeHandler(false);
    },
  });

  useEffect(() => {
    if (isScheduled) {
      const filteredDraftColumns = () => {
        if (Object.keys(selectedRows).length === 0 || !isDraft) return {};
        return filterDraftColumns(data?.bioLcCoproUsApi?.data, selectedRows);
      };
      const draftVariables = {
        tracking_type: "insert_drafts",
        data_to_insert: filteredDraftColumns(),
        date_range: {
          start_date: dateRange.from,
          end_date: dateRange.to,
        },
      };
      scheduleQuery({variables: draftVariables});
    }
  }, [isScheduled, scheduleQuery, data, dateRange, isDraft, selectedRows]);

  useEffect(() => {
    schedulableRecordHandler(data?.bioLcCoproUsApi?.schedulable_records);
  }, [data, schedulableRecordHandler]);

  const contractData = useMemo(() => {
    const dataArray = data?.bioLcCoproUsApi?.data;
    if (!Array.isArray(dataArray)) return [];

    return dataArray.map((item) => ({
      ...item,
      feedstock_qty_col: item.feedstock_qty
        ? `${item.feedstock_qty.toLocaleString()} ${item.feedstock_qty_uom}`
        : 0,
    }));
  }, [data]);

  const dataColumns = buildColumns(contractSummaryHeaders);

  return (
    <div
      data-test="copro-contract-summary"
      className="flex flex-wrap justify-between pb-[100px] w-full"
    >
      <div
        data-test="copro-contract-summary-table"
        className="copro-table pr-7"
      >
        <div data-test="copro-status-pills" className="pb-3">
          <Button
            className="mr-2 text-gray-400 active:bg-black"
            data-test="copro-draft-pill"
            active={isDraft}
            onClick={() => draftChangeHandler(true)}
          >
            {CONTRACT_STATUS.DRAFT}
          </Button>
          <Button
            className="text-gray-400 active:bg-black"
            data-test="copro-summary-pill"
            active={!isDraft}
            onClick={() => draftChangeHandler(false)}
          >
            {CONTRACT_STATUS.SUMMARY}
          </Button>
        </div>
        <ContainerTitle
          data-test="copro-table-container-title"
          className="copro-container-title"
        >
          {TABLE_TITLE.CONTRACTS}{" "}
        </ContainerTitle>

        <ReactTable
          responsive
          columns={dataColumns}
          data={contractData}
          enableSorting={false}
          rowSelection={isDraft ? selectedRows : false}
          setRowSelection={setSelectedRows}
        />
      </div>

      <GraphContainer
        className="contract-summary-graph"
        data-test="copro-contract-summary-graph"
      >
        <ContainerTitle
          data-test="copro-chart-container-title"
          className="copro-container-title"
        >
          {CONTRACT_SUMMARY_GRAPH}
        </ContainerTitle>
        <GraphBody>
          <SummaryBarChart summaryData={contractData}></SummaryBarChart>
        </GraphBody>
      </GraphContainer>
    </div>
  );
};

export default ContractSummary;
