import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

export const CLOSE_BALANCE = gql`
  mutation CloseBalance(
    $year: String!
    $quarter: String!
    $materialCode: String!
    $locationCode: String!
    $createdBy: String!
  ) {
    bioLcCoproEuMassBalanceClose(
      event: {
        year: $year
        quarter: $quarter
        materialCode: $materialCode
        locationCode: $locationCode
        createdBy: $createdBy
      }
    ) {
      message
      status
      statusCode
    }
  }
`;

export const CloseBalanceAPI = (data) =>
  client.mutate({
    mutation: CLOSE_BALANCE,
    variables: {
      ...data,
    },
    refetchQueries: [
      "bioLcCoproEuMassBalanceAllRecords",
      "bioLcCoproEuMassBalanceGetCycleStatus",
    ],
  });

export default {
  CloseBalanceAPI,
};
