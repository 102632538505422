import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {
  useFormContext,
  Controller,
  FormProvider,
  useForm,
} from "react-hook-form";
import {
  FormText,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import {dtnShipmentKeys} from "modules/co-processing/constants/shipments";
import {dtnShipmentFields} from "./formConfigs";
import {calculateMaxVolume, renderInputs} from "./formUtils";

const Field = ({fieldName, validate}) => {
  const {
    control,
    formState: {errors},
    watch,
  } = useFormContext();
  const fieldConfig = dtnShipmentFields[fieldName] ?? {};
  const {key} = fieldConfig;

  if (!fieldConfig || !key) return null;

  const error = errors[key];

  const renderInput = renderInputs[fieldConfig?.type] || renderInputs.input;

  let shouldDisplayInfo = false;
  if (key === dtnShipmentKeys.RENEWABLE_SHIPMENT_FLAG) {
    const fieldValue = watch(key);
    shouldDisplayInfo = fieldValue === false;
  } else {
    shouldDisplayInfo = !!fieldConfig?.info;
  }

  const startTime = watch(dtnShipmentKeys.SHIPMENT_START_DATE);

  return (
    <FormGroup
      className={`createShipment__field ${fieldConfig?.width || "col-md-6"}`}
      onBlur={() => {
        if (validate) {
          validate();
        }
      }}
    >
      <Label
        className={`text-nowrap ${
          fieldConfig?.isOptional ? "label-optional" : ""
        }`}
        htmlFor={key}
      >
        {fieldConfig.label}
      </Label>
      <Controller
        name={key}
        control={control}
        rules={{
          ...(fieldConfig.type !== "switch" && {
            required: !fieldConfig.isOptional,
          }),
        }}
        render={({field}) =>
          renderInput({field, error, startTime, ...fieldConfig})
        }
      />

      {error && (
        <FormFeedback style={{display: "block"}} invalid="true">
          {error.message || "Expected information missing"}
        </FormFeedback>
      )}

      {shouldDisplayInfo && <FormText>{fieldConfig?.info}</FormText>}
    </FormGroup>
  );
};

const EstVolumeDisplay = ({startTime, endTime}) => {
  const {register, setValue} = useFormContext();
  const estVolumeKey = dtnShipmentKeys.ESTIMATED_VOLUME;
  const volume = calculateMaxVolume(startTime, endTime) ?? 0;

  setValue(estVolumeKey, volume, {shouldDirty: true});

  return (
    <FormGroup className="createShipment__field col-md-6">
      <Label htmlFor={estVolumeKey}>Estimated shipment max volume</Label>
      <span
        className="label-info pl-1 text-[#002bbc]"
        href="#"
        id={estVolumeKey}
      >
        What is this?
      </span>
      <UncontrolledTooltip
        popperClassName="tooltip-light"
        placement="top"
        target={estVolumeKey}
      >
        This number is calculated by the amount of time X 1,500 bbl/hour
      </UncontrolledTooltip>

      <div className="input-group bg-transparent">
        <Input {...register(estVolumeKey)} type="hidden" />
        <span className="display-5 fs-5 form-text">{`${volume?.toLocaleString()} bbl`}</span>
      </div>
    </FormGroup>
  );
};

const ShipmentForm = ({onSubmit, children, defaultValues}) => {
  const {handleSubmit, reset, ...methods} = useForm({
    defaultValues,
    mode: "onSubmit",
  });

  useEffect(() => reset(defaultValues), [defaultValues, reset]);

  return (
    <FormProvider {...methods}>
      <form
        className="createShipment__form"
        id="create_shipment_form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {children}
      </form>
    </FormProvider>
  );
};

const fieldPropTypes = {fieldName: PropTypes.string, validate: PropTypes.func};

Field.propTypes = fieldPropTypes;
ShipmentForm.propTypes = {
  children: PropTypes.node.isRequired,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};
EstVolumeDisplay.propTypes = {
  startTime: PropTypes.string,
  endTime: PropTypes.string,
};

ShipmentForm.Field = Field;
ShipmentForm.EstVolumeDisplayField = EstVolumeDisplay;

export default ShipmentForm;
