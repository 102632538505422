import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import {client} from "providers/Apollo";
import {INBOUND_RECORDS} from "graphql/es-co-processing/InboundRecords";
import {toast} from "react-toastify";
import {useState} from "react";
import {Close} from "@bphxd/ds-core-react";
import Form from "modules/common/FormNew";
import {useMsal, useAccount} from "@azure/msal-react";
import {uploadFile} from "graphql/es-co-processing/UploadDocuments";
import {Upload48} from "@bphxd/ds-core-react/lib/icons";
import {useDropzone} from "react-dropzone";
import {checkFileTooLarge, checkFileType} from "utils/helpers/checkData";
import UploadErrorModal from "./UploadErrorModal";

const UploadDocuments = () => {
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const [isProcessing, setProcessing] = useState(false);
  const [isModalVisible, setModalVisible] = useState(true);
  const [isErrorModalVisible, setErrorModalVisible] = useState(false);

  const [isFileExisting, setFileExisting] = useState(true);

  const filesType = "pdf";
  const countryCode = "esp";

  const maxSize = 52428800;

  const {fileRejections, acceptedFiles, getRootProps, getInputProps} =
    useDropzone({
      accept: checkFileType(filesType),
      maxFiles: 1,
      maxSize,
    });

  const file = acceptedFiles[0];

  const files = acceptedFiles.map((item, index) => (
    <li data-test="file" className="text-sm font-small" key={index}>
      {item.path}
    </li>
  ));

  const isFileTooLarge = checkFileTooLarge(fileRejections, maxSize);

  return (
    <div>
      <Modal
        size="md"
        isOpen={isModalVisible}
        className="modal-dialog-centered"
      >
        <ModalHeader
          className="border-b-2 border-gray-200 mb-2 text-xl"
          close={<Close onClick={() => setModalVisible(false)} />}
        >
          Add Document
        </ModalHeader>
        <ModalBody className="text-center pt-0">
          <Form
            id="upload-form"
            onSubmit={async () => {
              if (file !== null && file !== undefined) {
                setProcessing(true);
                setFileExisting(true);
                try {
                  // Must return just a status code (number)
                  const statusCode = await uploadFile("###", file, countryCode);

                  switch (statusCode) {
                    case 200:
                    case 204:
                      // Succesfully
                      setModalVisible(false);
                      toast.success("Document successfully uploaded");
                      await client.refetchQueries({
                        include: [INBOUND_RECORDS],
                      });
                      break;
                    default:
                      // Failed
                      setModalVisible(false);
                      setErrorModalVisible(true);
                  }
                } catch (err) {
                  console.error(err);
                  // Unexpected error
                }
              } else {
                setFileExisting(false);
              }
              return false;
            }}
          >
            <div className="mt-8">
              <div {...getRootProps({className: "dropzone w-full mt-1"})}>
                <input
                  data-test="document-uploader"
                  id="documents"
                  {...getInputProps()}
                />
                <div className="flex flex-col items-center justify-center px-2 py-1 h-48 border-2 border-dashed gap-3">
                  <div className="flex flex-row w-full items-center justify-center">
                    <Button
                      data-test="copro-filter-btn"
                      id="filterButton"
                      name="filterButton"
                      color="standard-tertiary rounded-0 bg-white border-0"
                      type="button"
                    >
                      <Upload48 />
                    </Button>
                  </div>
                  <div className="flex flex-row w-full items-center justify-center">
                    <p className="text-sm text-indigo-500">
                      Drag and drop or choose file to upload
                    </p>
                  </div>
                  <div className="flex flex-row w-full items-center justify-center">
                    <label
                      data-test="file-type-label"
                      className="text-xs text-gray-400"
                      htmlFor="documents"
                    >
                      Files supported: PDF
                    </label>
                  </div>
                </div>
              </div>

              <aside className="flex mt-3 items-center justify-center">
                <ul>{files}</ul>
              </aside>
              {isFileTooLarge && (
                <p className="block text-red-500">File is larger than 50MB</p>
              )}
              {!isFileExisting && files?.length === 0 && (
                <p className="block text-red-500">Please select a file</p>
              )}
            </div>
          </Form>
        </ModalBody>
        <ModalFooter className="p-0 d-block">
          <div className="row g-0 m-0 modal-footer-row">
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
                onClick={() => setModalVisible(false)}
              >
                Cancel
              </Button>
            </div>
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 py-4 bg-transparent text-default"
                form="upload-form"
                type="submit"
                disabled={isProcessing}
              >
                {isProcessing && (
                  <Spinner size="sm" className="btn-icon-prefix" />
                )}
                Upload Document
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
      {isErrorModalVisible && (
        <UploadErrorModal fileName={file?.name} countryCode={countryCode} />
      )}
    </div>
  );
};

UploadDocuments.propTypes = {};

export default UploadDocuments;
