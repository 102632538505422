import {gql} from "@apollo/client";

export const SAF_GET_INCOMING_DOCUMENTS = gql`
  query MyQuery {
    bioLcSafGetSuppliers(
      event: {division_id: "7c5fc8889b6e3c8756abbd76353d68e7"}
    ) {
      error
      statusCode
      supplier_names {
        supplier_name
      }
    }

    bioLcSafGetIncomingDocs(
      event: {
        division_id: "7c5fc8889b6e3c8756abbd76353d68e7"
        site_reference_id: "c8f4261f9f46e6465709e17ebea7a92b"
      }
    ) {
      total
      statusCode
      error
      documents {
        additional_info
        agricultural_biomass_intermediate_crop_flag
        agricultural_biomass_low_risk_flag
        allocated_electricity
        bio_product_producer
        bv_audit_changed_datez
        allocated_heat
        certificate_number
        certificate_inbound_eu_id
        bio_product_user
        chain_of_custody_option
        certification_system
        contract_number
        date_of_issuance
        default_value_applied_flag
        eu_red_compliant_flag
        ghg_eccr
        ghg_eccs
        ghg_eec
        ghg_el
        ghg_emission_percentage_in
        ghg_ep
        ghg_esca
        ghg_etd
        ghg_eu
        ghg_total
        iscc_compliant_flag
        material_dispatch_datez
        material_sustainability_criteria_flag
        nuts2_region
        producer_installation_date
        product_energy_content
        product_qty
        product_qty_uom
        raw_material_origin_country_name
        product_type
        raw_material_type
        recipient_address
        raw_material_waste_or_residue_flag
        recipient_name
        recipient_receipt_address
        sd_number
        state
        supplier_address
        supplier_dispatch_address
        supplier_name
        user_installation_date
        version_count
        version_history_data {
          linked_cert
          linked_cert_status_id
          bv_audit_changed_datez
          sd_number
        }
      }
    }
  }
`;

// We have to use this in future for document file

// division_id: "7c5fc8889b6e3c8756abbd76353d68e7"
// site_reference_id: "c8f4261f9f46e6465709e17ebea7a92b"

export const SAF_GET_INCOMING_DOCUMENTS_SINGLE = gql`
  query viewDocumentQuery($certificate_inbound_eu_id: String) {
    bioLcCoproEuGetCountries {
      code
      name
    }

    bioLcSafGetMaterials(event: {}) {
      error
      statusCode
      product_names {
        product_name
      }
      raw_material_names {
        raw_material_name
      }
    }

    bioLcSafGetVersioningDocs(
      event: {
        division_id: "7c5fc8889b6e3c8756abbd76353d68e7"
        site_reference_id: "c8f4261f9f46e6465709e17ebea7a92b"
      }
    ) {
      error
      statusCode
      documents {
        sd_number
      }
    }

    bioLcSafGetDocumentFile(
      event: {
        division_id: "7c5fc8889b6e3c8756abbd76353d68e7"
        certificate_inbound_eu_id: $certificate_inbound_eu_id
        site_reference_id: "c8f4261f9f46e6465709e17ebea7a92b"
      }
    ) {
      download_url
      error
      statusCode
    }

    bioLcSafGetIncomingDocs(
      event: {
        division_id: "7c5fc8889b6e3c8756abbd76353d68e7"
        site_reference_id: "c8f4261f9f46e6465709e17ebea7a92b"
        certificate_inbound_eu_id: $certificate_inbound_eu_id
      }
    ) {
      documents {
        additional_info
        agricultural_biomass_intermediate_crop_flag
        agricultural_biomass_low_risk_flag
        allocated_electricity
        allocated_heat
        bio_product_producer
        bio_product_user
        bv_audit_changed_datez
        certificate_inbound_eu_id
        certificate_number
        certification_system
        chain_of_custody_option
        contract_number
        date_of_issuance
        default_value_applied_flag
        eu_red_compliant_flag
        ghg_eccr
        ghg_eccs
        ghg_eec
        ghg_el
        ghg_emission_percentage_in
        ghg_ep
        ghg_esca
        ghg_etd
        ghg_eu
        ghg_total
        iscc_compliant_flag
        material_dispatch_datez
        material_sustainability_criteria_flag
        nuts2_region
        producer_installation_date
        product_energy_content
        product_qty
        product_qty_uom
        product_type
        raw_material_origin_country_name
        raw_material_type
        raw_material_waste_or_residue_flag
        recipient_address
        recipient_name
        recipient_receipt_address
        sd_number
        state
        supplier_address
        supplier_dispatch_address
        supplier_name
        user_installation_date
        previous_version_sd_number
      }
      error
      statusCode
    }
  }
`;

export const SAF_GET_INCOMING_DOCUMENTS_SINGLE_INACTIVE = gql`
  query viewDocumentQuery(
    $certificate_inbound_eu_id: String
    $inbound_doc_status_eu_id: String
  ) {
    bioLcCoproEuGetCountries {
      code
      name
    }

    bioLcSafGetMaterials(
      event: {division_id: "7c5fc8889b6e3c8756abbd76353d68e7"}
    ) {
      error
      statusCode
      product_names {
        product_name
      }
      raw_material_names {
        raw_material_name
      }
    }

    bioLcSafGetVersioningDocs(
      event: {
        division_id: "7c5fc8889b6e3c8756abbd76353d68e7"
        site_reference_id: "c8f4261f9f46e6465709e17ebea7a92b"
      }
    ) {
      error
      statusCode
      documents {
        sd_number
      }
    }

    bioLcSafGetDocumentFile(
      event: {inbound_doc_status_eu_id: $inbound_doc_status_eu_id}
    ) {
      download_url
      error
      statusCode
    }

    bioLcSafGetIncomingDocs(
      event: {
        certificate_inbound_eu_id: $certificate_inbound_eu_id
        inbound_doc_status_eu_id: $inbound_doc_status_eu_id
      }
    ) {
      documents {
        additional_info
        agricultural_biomass_intermediate_crop_flag
        agricultural_biomass_low_risk_flag
        allocated_electricity
        allocated_heat
        bio_product_producer
        bio_product_user
        bv_audit_changed_datez
        certificate_inbound_eu_id
        certificate_number
        certification_system
        chain_of_custody_option
        contract_number
        date_of_issuance
        default_value_applied_flag
        eu_red_compliant_flag
        ghg_eccr
        ghg_eccs
        ghg_eec
        ghg_el
        ghg_emission_percentage_in
        ghg_ep
        ghg_esca
        ghg_etd
        ghg_eu
        ghg_total
        iscc_compliant_flag
        material_dispatch_datez
        material_sustainability_criteria_flag
        nuts2_region
        producer_installation_date
        product_energy_content
        product_qty
        product_qty_uom
        product_type
        raw_material_origin_country_name
        raw_material_type
        raw_material_waste_or_residue_flag
        recipient_address
        recipient_name
        recipient_receipt_address
        sd_number
        state
        supplier_address
        supplier_dispatch_address
        supplier_name
        user_installation_date
        previous_version_sd_number
      }
      error
      statusCode
      total
    }
  }
`;

export const UPDATE_SAF_DOCUMENT = gql`
  mutation updateSafDocumentMutation($event: SafUpdateIncomingDocRequest!) {
    bioLcSafUpdateIncomingDocData(event: $event) {
      error
      errors {
        field
        message
      }
      statusCode
    }
  }
`;

export default SAF_GET_INCOMING_DOCUMENTS;
