/* eslint-disable prettier/prettier */
import {gql} from "@apollo/client";

export const COPRO_US_SHIPMENTS_API_GET_SHIPMENTS = gql`
  query GetCoproUsShipmentsApiDataQuery(
    $offset: Int
    $dateRange: [String]
    $shipmentType: String
  ) {
    bioLcCoproUsShipmentsApi(
      event: {
        op: "get_shipments"
        offset: $offset
        shipment_filters: {date_range: $dateRange, shipment_type: $shipmentType}
      }
    ) {
      body {
        shipments {
          parts {
            batch_id
            batch_shipment_type
            total_actual_volume
          }
          shipment_id
          shipment_status
          shipment_date
          shipment_start_date
          shipment_end_date
          total_estimated_volume
          shipment_type
        }
      }
      statusCode
      error
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_GET_BATCHES = gql`
  query GetCoproUsShipmentsApiDataQuery($offset: Int) {
    bioLcCoproUsShipmentsApi(event: {op: "get_batches", offset: $offset}) {
      body {
        batches {
          actual_ending_inventory
          batch_id
          batch_status
          estimated_ending_inventory
          remaining_volume
          total_volume
          tank_number
          shipments {
            shipment_id
            shipment_volume
          }
        }
      }
      statusCode
      error
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_GET_BOL_DATA = gql`
  query GetCoproUsShipmentsBolApiDataQuery {
    bioLcCoproUsShipmentsApi(event: {op: "get_bols"}) {
      statusCode
      error
      body {
        bols {
          dtn_bol_layer_id
          start_load_date
          sap_material_name
          truck_lifting_volume
          credits_qualified
          outgoing_bol_number
        }
      }
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_SPLIT_BOL = gql`
  query GetCoproUsShipmentsSplitBolApiDataQuery(
    $request: BioLcCoproUsShipmentsApiDataRequestSplitBol
  ) {
    bioLcCoproUsShipmentsApi(event: {op: "split_bol", split_bol: $request}) {
      statusCode
      error
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_ALLOCATE_BOL = gql`
  query GetCoproUsShipmentsAllocateBolsApiDataQuery(
    $request: BioLcCoproUsShipmentsApiDataRequestAllocateBol
  ) {
    bioLcCoproUsShipmentsApi(
      event: {op: "allocate_bol", allocate_bol: $request}
    ) {
      statusCode
      error
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_SAVE_DTN = gql`
  query SaveDTNShipment(
    $dtn_shipment_details: BioLcCoproUsShipmentsApiDataRequestSavedDTNShipment
  ) {
    bioLcCoproUsShipmentsApi(
      event: {
        op: "save_dtn_shipment"
        dtn_shipment_details: $dtn_shipment_details
      }
    ) {
      error
      statusCode
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_CHECK_SHIPMENT_ID = gql`
  query GetCoproUsShipmentsCheckShipmentIdApiDataQuery(
    $shipmentId: String
    $batchId: String
  ) {
    bioLcCoproUsShipmentsApi(
      event: {
        op: "check_shipment_id"
        shipment_id: $shipmentId
        batch_id: $batchId
      }
    ) {
      statusCode
      error
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_CHECK_BATCH_ID = gql`
  query GetCoproUsShipmentsCheckBatchIdApiDataQuery($batchId: String) {
    bioLcCoproUsShipmentsApi(
      event: {op: "check_batch_id", batch_id: $batchId}
    ) {
      statusCode
      error
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_EDIT_SHIPMENT_ID = gql`
  query GetCoproUsShipmentsShipmentIdApiDataQuery(
    $shipmentId: String
    $update_field: BioLcCoproUsShipmentsApiDataRequestUpdateField
  ) {
    bioLcCoproUsShipmentsApi(
      event: {
        op: "edit_shipment_field"
        shipment_id: $shipmentId
        update_field: $update_field
      }
    ) {
      statusCode
      error
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_SHIPMENT_ID = gql`
  query GetCoproUsShipmentsShipmentIdApiDataQuery($shipmentId: String) {
    bioLcCoproUsShipmentsApi(
      event: {op: "get_shipment_details", shipment_id: $shipmentId}
    ) {
      body {
        shipment_details {
          shipment_id
          shipment_status
          shipment_date
          shipment_start_date
          shipment_end_date
          total_estimated_volume
          shipment_type
          destination
          source_tank
          parts {
            batch_shipment_type
            batch_id
            total_actual_volume
            allocated_shipment_id
            sub_type
            credits_qualified
            renewable_shipment_flag
            notes
          }
        }
      }
      error
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_DELETE_SHIPMENT = gql`
  query CoproUsShipmentsDeleteShipmentApiDataQuery($shipmentId: String) {
    bioLcCoproUsShipmentsApi(
      event: {
        op: "delete_shipment"
        delete_shipment: {dtn_shipment_id: $shipmentId}
      }
    ) {
      statusCode
      error
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_ALLOCATE_BATCHES_AND_SHIPMENTS = gql`
  query CoproUsShipmentsApiAllocateMutation(
    $batches_list: [BatchesUsAllocationDetails]
  ) {
    bioLcCoproUsShipmentsApi(
      event: {op: "allocate_batches_and_shipments", batches_list: $batches_list}
    ) {
      statusCode
      error
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_RESET_ALLOCATE_BOL = gql`
  query CoproUsShipmentsApiResetAllocateBol($shipmentId: String) {
    bioLcCoproUsShipmentsApi(
      event: {
        op: "reset_allocate_bol"
        reset_allocate_bol: {dtn_shipment_id: $shipmentId}
      }
    ) {
      statusCode
      error
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_CHECK_SHIPMENT_ID_EXISTS = gql`
  query CoproUsShipmentsApiCheckShipmentIdExists($shipmentId: String) {
    bioLcCoproUsShipmentsApi(
      event: {op: "check_shipment_id_exists", dtn_shipment_id: $shipmentId}
    ) {
      statusCode
      error
    }
  }
`;
