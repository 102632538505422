import React, {useEffect} from "react";
import PropTypes from "prop-types";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  FormFeedback,
  DropdownItem,
  Label,
} from "reactstrap";
import {Check24, Down24} from "@bphxd/ds-core-react/lib/icons";
import "./index.scss";

const FormDropdown = ({
  options,
  values,
  label,
  hasAllOption,
  onChange,
  value,
  hookToBody,
  placeholder,
  invalid,
  errors,
  menuClassName,
  menuItemClassName,
  onSelect,
}) => {
  const actualPlaceholder = placeholder || (hasAllOption ? "All" : "");
  const invalidClassName = invalid && "is-invalid form-control";
  const className = menuClassName + " rounded-0 bg-white";
  const hasValue = value && options[values.indexOf(value)];

  // Use black color for options and "All" option, and grey for placeholders
  const optionColor = hasValue || !placeholder ? "black" : "#b4b4b4";
  return (
    <>
      <Label className="mb-3">{label}</Label>
      <UncontrolledDropdown>
        <DropdownToggle
          color="standard-tertiary"
          className={`rounded-0 w-100 text-left bg-white form-dropdown-button ${invalidClassName}`}
        >
          <div className="d-flex mr-auto" style={{color: optionColor}}>
            {hasValue ? options[values.indexOf(value)] : actualPlaceholder}
          </div>
          <Down24 className="btn-icon-suffix" />
        </DropdownToggle>
        <DropdownMenu
          className={className}
          container={hookToBody ? "body" : ""}
        >
          {hasAllOption && (
            <DropdownItem
              style={menuItemClassName}
              onClick={() => onChange("")}
            >
              All
            </DropdownItem>
          )}
          {options?.map((option, index) => (
            <DropdownItem
              style={{
                ...menuItemClassName,
                fontWeight: hasValue === values[index] && "bold",
                color: hasValue === values[index] && "black",
              }}
              onClick={() => {
                onChange(values[index]);
                onSelect(values[index]);
              }}
            >
              {option}
              {hasValue === values[index] && (
                <div className="dropdown-suffix">
                  <Check24 />
                </div>
              )}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      {errors && (
        <FormFeedback className="d-block"> {errors.message}</FormFeedback>
      )}
    </>
  );
};

FormDropdown.propTypes = {
  options: PropTypes.array,
  values: PropTypes.array,
  label: PropTypes.string,
  hasAllOption: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  hookToBody: PropTypes.bool,
  placeholder: PropTypes.string,
  invalid: PropTypes.bool,
  errors: PropTypes.object,
  menuClassName: PropTypes.string,
  menuItemClassName: PropTypes.object,
  onSelect: PropTypes.func,
};
export default FormDropdown;
