import React from "react";
import {useFormContext} from "react-hook-form";
import {dtnShipmentFields} from "../formConfigs";

const fieldOrder = [
  "SHIPMENT_TYPE",
  "SHIPMENT_ID",
  "SHIPMENT_DATE",
  "SHIPMENT_START_DATE",
  "SHIPMENT_END_DATE",
  "ESTIMATED_VOLUME",
  "SOURCE_TANK",
  "NOTES",
];

const Review = () => {
  const {getValues} = useFormContext();
  const formValues = getValues();

  const reviewItems = fieldOrder.map((identifier) => {
    const {key, label, format} = dtnShipmentFields?.[identifier] || {};
    const value = formValues[key];
    const displayValue = format
      ? format(value)
      : (value ?? "-").toLocaleString();

    return (
      <div className="detail-item flex justify-between" key={key}>
        <label className="text-gray-700">{label}</label>
        <span>{displayValue}</span>
      </div>
    );
  });

  return (
    <div className="review-details w-full space-y-4">
      <h5 className="display-6">Review the shipment details</h5>
      <div className="createShipment__summary" data-test="shipment-summary">
        <div className="createShipment__summary-title">Summary</div>
        <div className="createShipment__summary-list">{reviewItems}</div>
      </div>
    </div>
  );
};

export default Review;
