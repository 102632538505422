import {useMutation, useQuery} from "@apollo/client";
import {useAccount, useMsal} from "@azure/msal-react";
import {Alert32, Check24, Remove24} from "@bphxd/ds-core-react/lib/icons";
import {useCallback, useMemo, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import {
  COPRO_EU_MANUAL_ENTRY_QUERY,
  ADD_DOCUMENT,
} from "../../../graphql/es-co-processing/ManualEntry";
import Layout from "../components/Layout";
import GHGEmissionInfo from "../components/view-document/GHGEmissionInfo";
import GeneralInformation from "../components/view-document/GeneralInformation";
import ScopeOfCertification from "../components/view-document/ScopeOfCertification";
import SupplierAndRecipientInfo from "../components/view-document/SupplierAndRecipientInfo";
import AdditionalInfo from "../components/view-document/AdditionalInfo";
import {DDV, TDV, parseToFloatNumber} from "../components/view-document/utils";
import "./view-document.css";

const defaultValues = {
  additionalInfo: "",
  agriculturalBiomassIntermediateCropFlag: null,
  agriculturalBiomassLowRiskFlag: null,
  availableBioProductTons: 0,
  productEnergyContent: 0,
  certificateNumber: "",
  certificationSystem: "",
  chainOfCustodyOption: "",
  contractNumber: "",
  customerRef: "",
  dealNotes: "",
  deliveryNotes: "",
  euRedCompliantFlag: null,
  expiryDate: "",
  feedstockCountryCode: "",
  feedstockIssueDate: "",
  feedstockMonth: "",
  feedstockQty: 0,
  feedstockQtyUom: "",
  feedstockQuarter: "",
  fossilNotes: "",
  ghgEccr: 0,
  ghgEccs: 0,
  ghgEec: 0,
  ghgEee: 0,
  ghgEl: 0,
  ghgEp: 0,
  ghgEsca: 0,
  ghgEtd: 0,
  ghgEu: 0,
  ghgTotal: 0,
  importedInvoiceVol: 0,
  invoiceProviderName: "",
  isccCompliantFlag: null,
  materialDispatchDate: "",
  materialSustainabilityCriteriaFlag: null,
  mbLocation: "",
  motFromRefinery: "",
  motToRefinery: "",
  observationNotes: "",
  operationDesc: "",
  orderNumber: "",
  originDestinationDesc: "",
  physicalReceiptDate: "",
  processedFeedstockTons: 0,
  processType: "",
  productConcludedQty: 0,
  productInvoiceVol: 0,
  productionLossTons: 0,
  productNetVol: 0,
  productType: "",
  rawExpiryDate: "",
  rawIssueDate: "",
  rawMaterialCultivationCountryName: "",
  rawMaterialDeliveryDate: "",
  rawMaterialOriginCountryName: "",
  rawMaterialType: "",
  rawMaterialWasteOrResidueFlag: null,
  recipientAddress: "",
  recipientName: "",
  recipientReceiptAddress: "",
  recipientReceiptAddressSameFlag: false,
  refineryDischargeVol: 0,
  refineryLoadedVol: 0,
  sdNumber: "",
  supplierAddress: "",
  supplierDispatchAddress: "",
  supplierDispatchAddressSameFlag: false,
  supplierInvoiceVol: 0,
  supplierName: "",
  thirdPartyName: "",
  transportationEmissionId: "",

  vesselAtHuelva: "",
  vesselAtTespa: "",
  emissionForTransport: false,
  defaultValueAppliedFlag: null,
  diseggregatedDefaultValueOilFlag: null,
  diseggregatedDefaultValueSoiln2oFlag: null,
  bonusCo245gManureUsedFlag: null,
  bonusCo229gManureUsedFlag: null,
  supplychainIncentiveReceivedFlag: null,
  supplychainIncentiveReceivedName: "",
  nuts2Region: "",
  splitDetails: [],
};

const tabFields = {
  supplier: [
    "feedstockIssueDate",
    "supplierName",
    "supplierAddress",
    "supplierDispatchAddress",
    "certificationSystem",
    "certificateNumber",
    "materialDispatchDate",
    "recipientName",
    "recipientAddress",
    "recipientReceiptAddress",
    "contractNumber",
  ],
  general: [
    "productType",
    "rawMaterialType",
    "additionalInfo",
    "rawMaterialOriginCountryName",
    "feedstockQty",
    "feedstockQtyUom",
    "isccCompliantFlag",
    "euRedCompliantFlag",
    "chainOfCustodyOption",
  ],
  emission: [
    "ghgEec",
    "ghgEl",
    "ghgEp",
    "ghgEtd",
    "ghgEu",
    "ghgEsca",
    "ghgEccs",
    "ghgEccr",
    "ghgTotal",
    "motFromRefinery",
    "motToRefinery",
    "motDistanceKm",
    "physicalReceiptDate",
    "splitDetails",
  ],
  certification: [
    "materialSustainabilityCriteriaFlag",
    "agriculturalBiomassIntermediateCropFlag",
    "agriculturalBiomassLowRiskFlag",
    "rawMaterialWasteOrResidueFlag",
  ],
  additionalInfo: [
    "emissionForTransport",
    "mbLocation",
    "invoiceNumber",
    "vesselName",
    "observationNotes",
  ],
};
const Subtitle = () => (
  <div className="flex flex-col justify-between items-start gap-2 small pb-6 px-7">
    <span className="mb-2">
      As we have not been able to capture this declaration please review and
      enter details as they appear on the document.
    </span>
    <span className="mb-2">
      If you are happy that all information is correct and meets your criteria
      click save and the declaration will be added to your incoming documents.
    </span>
    <span className="mb-2">
      If you do not see the value you wish to select, then please contact the
      Data Enablement team to request a revision.
    </span>
  </div>
);
const ManualEntryPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);
  const {fileName, countryCode} = useParams();
  const [open, setOpen] = useState([
    "supplier",
    "general",
    "certification",
    "emission",
    "additionalInfo",
  ]);

  const [payload, setPayload] = useState(null);

  const toggle = (id) => {
    if (open.includes(id)) {
      setOpen(open.filter((item) => item !== id));
    } else {
      setOpen([...open, id]);
    }
  };

  const {data, loading} = useQuery(COPRO_EU_MANUAL_ENTRY_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {countryCode, fileName},
  });
  const [updateDocument, {loading: isSaving}] = useMutation(ADD_DOCUMENT, {
    onCompleted: ({bioLcCoproEuInsertInboundRecord}) => {
      if (bioLcCoproEuInsertInboundRecord?.statusCode === 200) {
        toast.success(
          bioLcCoproEuInsertInboundRecord?.message ??
            "Document added successfully",
        );

        setTimeout(
          () =>
            navigate({
              pathname: "/copro-spain/document-manager/incoming-documents",
              search: `?pageNo=${searchParams.get("pageNo") ?? 0}&docId=${
                bioLcCoproEuInsertInboundRecord?.docId
              }`,
            }),
          1000,
        );
      } else {
        toast.error(
          bioLcCoproEuInsertInboundRecord?.message ?? "Failed to add document",
        );
      }
    },
  });

  const productTypes = useMemo(
    () =>
      (data?.bioLcCoproConfigCenterMaterialMaster?.Records ?? []).filter(
        (v) => v.material_type === "Product",
      ),
    [data?.bioLcCoproConfigCenterMaterialMaster],
  );

  const [invalidTabs, setInvalidTabs] = useState({
    supplier: false,
    general: false,
    emission: false,
    certification: false,
    additionalInfo: false,
  });

  const methods = useForm({
    defaultValues,
    mode: "onBlur",
    shouldFocusError: true,
  });

  const {
    register,
    formState: {errors},
    getValues,
    setError,
    trigger,
    setFocus,
  } = methods;

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: "Co-processing", link: "/copro-spain"},
    {text: "Document manager", link: "/copro-spain"},
    {
      text: "Incoming",
      link: "/copro-spain/document-manager/incoming-documents",
    },
    {
      text: "Manual entry",
      link: `/copro-spain/document-manager/incoming-documents/manual/${fileName}/${countryCode}`,
    },
  ];

  const validate = useCallback(async () => {
    const valid = await trigger();
    const values = getValues();
    let isOthersValid = true;
    if (values.splitDetails.length === 0 && values.physicalReceiptDate === "") {
      setError("physicalReceiptDate", {
        type: "value",
        message: "Please enter a physical receipt date",
      });
      isOthersValid = false;
    }
    if (values.materialDispatchDate === "") {
      setError("materialDispatchDate", {
        type: "value",
        message: "Please enter date of dispatch",
      });
    }
    if (
      values.splitDetails?.length === 0 &&
      values.defaultValueAppliedFlag !== true &&
      parseFloat(values.ghgEccr ?? "0") === 0 &&
      parseFloat(values.ghgEccs ?? "0") === 0 &&
      parseFloat(values.ghgEec ?? "0") === 0 &&
      parseFloat(values.ghgEee ?? "0") === 0 &&
      parseFloat(values.ghgEl ?? "0") === 0 &&
      parseFloat(values.ghgEp ?? "0") === 0 &&
      parseFloat(values.ghgEsca ?? "0") === 0 &&
      parseFloat(values.ghgEtd ?? "0") === 0 &&
      parseFloat(values.ghgEu ?? "0") === 0 &&
      (values.ghgTotal !== TDV ||
        values.ghgTotal !== DDV ||
        values.ghgEtd !== DDV)
    ) {
      setError("ghgEec", {
        type: "value",
        message: "At least one GHG emission must be greater than 0",
      });
      isOthersValid = false;
    }
    if (values.splitDetails?.length > 0) {
      values.splitDetails?.forEach((item, index) => {
        if (
          item.defaultValueAppliedFlag !== true &&
          parseFloat(item.ghgEccr ?? "0") === 0 &&
          parseFloat(item.ghgEccs ?? "0") === 0 &&
          parseFloat(item.ghgEec ?? "0") === 0 &&
          parseFloat(item.ghgEee ?? "0") === 0 &&
          parseFloat(item.ghgEl ?? "0") === 0 &&
          parseFloat(item.ghgEp ?? "0") === 0 &&
          parseFloat(item.ghgEsca ?? "0") === 0 &&
          parseFloat(item.ghgEtd ?? "0") === 0 &&
          parseFloat(item.ghgEu ?? "0") === 0 &&
          (item.ghgTotal !== TDV ||
            item.ghgTotal !== DDV ||
            item.ghgEtd !== DDV)
        ) {
          setError(`splitDetails.${index}.ghgEec`, {
            type: "value",
            message: "At least one GHG emission must be greater than 0",
          });
          isOthersValid = false;
        }
      });
    }
    if (values.euRedCompliantFlag == null) {
      setError("euRedCompliantFlagYes", {
        type: "value",
        message: "Please select EU RED Compliant Flag",
      });
      isOthersValid = false;
    }

    if (values.isccCompliantFlag == null) {
      setError("isccCompliantFlagYes", {
        type: "value",
        message: "Please select ISCC Compliant Flag",
      });
      isOthersValid = false;
    }
    if (!values.feedstockQty > 0) {
      setError("feedstockQty", {
        type: "value",
        message: "Please enter a valid quantity",
      });
      isOthersValid = false;
    }
    if (values.feedstockQtyUom === null || values.feedstockQtyUom === "") {
      setError("feedstockQtyUomMT", {
        type: "value",
        message: "Please select unit of measure",
      });
      isOthersValid = false;
    }

    const flags = {
      supplier: false,
      general: false,
      emission: false,
      certification: false,
      additionalInfo: false,
    };
    Object.keys(tabFields).forEach((tab) => {
      const tabErrors = tabFields[tab].filter((field) => errors[field] != null);
      if (tabErrors.length > 0) {
        flags[tab] = true;
      }
    });
    setInvalidTabs(flags);

    return valid && isOthersValid;
  }, [getValues, setError, trigger, errors]);

  const focusOnFirstError = () => {
    const fields = Object.keys(errors);
    if (fields.length > 0) {
      setFocus(fields[0]);
    }
  };
  const validateAndSave = async () => {
    const isValid = await validate();
    console.log("isValid", isValid);
    if (isValid) {
      const {emissionForTransport, splitDetails, ...data} = getValues();
      setPayload(data);
      await updateDocument({
        variables: {
          event: {
            ...data,
            ghgEec: parseToFloatNumber(data.ghgEec),
            ghgEl: parseToFloatNumber(data.ghgEl ?? 0),
            ghgEp: parseToFloatNumber(data.ghgEp ?? 0),
            ghgEtd:
              data.ghgEtd === DDV
                ? data.ghgEtd
                : parseToFloatNumber(data.ghgEtd ?? 0),
            ghgEu: parseToFloatNumber(data.ghgEu ?? 0),
            ghgEsca: parseToFloatNumber(data.ghgEsca ?? 0),
            ghgEccs: parseToFloatNumber(data.ghgEccs ?? 0),
            ghgEccr: parseToFloatNumber(data.ghgEccr ?? 0),
            ghgEee: parseToFloatNumber(data.ghgEee ?? 0),
            ghgTotal:
              data.ghgTotal === TDV || data.ghgTotal === DDV
                ? data.ghgTotal
                : parseToFloatNumber(data.ghgTotal ?? "0"),
            userAction: "ACCEPT",
            changedBy: account.username,
            documentName: fileName,
            documentType: "PDF",
            splitDetails: splitDetails.map((split) => ({
              ...split,
              transportationEmissionId: emissionForTransport
                ? split.transportationEmissionId
                : null,
              ghgEec: parseToFloatNumber(split.ghgEec ?? "0"),
              ghgEl: parseToFloatNumber(split.ghgEl ?? "0"),
              ghgEp: parseToFloatNumber(split.ghgEp ?? "0"),
              ghgEtd:
                split.ghgEtd === DDV
                  ? split.ghgEtd
                  : parseToFloatNumber(split.ghgEtd ?? "0"),
              ghgEu: parseToFloatNumber(split.ghgEu ?? "0"),
              ghgEsca: parseToFloatNumber(split.ghgEsca ?? "0"),
              ghgEccs: parseToFloatNumber(split.ghgEccs ?? "0"),
              ghgEccr: parseToFloatNumber(split.ghgEccr ?? "0"),
              ghgEee: parseToFloatNumber(split.ghgEee ?? "0"),
              ghgTotal:
                split.ghgTotal === TDV || split.ghgTotal === DDV
                  ? split.ghgTotal
                  : parseToFloatNumber(split.ghgTotal ?? "0"),
            })),
          },
        },
      });
    } else {
      focusOnFirstError();
    }
  };
  const onAdd = async () => validateAndSave();

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  if (loading) {
    return (
      <div className="flex flex-col min-h-full items-center py-12 bp-core bg-light-gray-100">
        <Spinner />
      </div>
    );
  }
  const status = "new";
  return (
    <div className="flex flex-col form-manual-entry view-doc__max-scroll bp-core bg-light-gray-100">
      <div className="flex flex-1 flex-row justify-between items-stretch min-h-full w-full">
        <div className="flex flex-col flex-1 max-h-screen overflow-y-auto">
          <Layout
            title="Add declaration manually"
            breadcrumbs={breadcrumbItems}
            subtitle={<Subtitle />}
          >
            <div className="flex flex-col p-2 min-h-[800px] px-7 pt-6">
              <FormProvider {...methods}>
                <Form className="flex flex-col gap-5">
                  <FormGroup>
                    <Label for="sdNumber" className="fw-light">
                      Unique number of sustainability declaration
                    </Label>
                    <Input
                      type="text"
                      id="sdNumber"
                      {...computeProps("sdNumber", {
                        required: "Please enter a sd number",
                      })}
                      invalid={!!errors.sdNumber}
                      maxLength={100}
                    />
                    {errors.sdNumber && (
                      <FormFeedback>{errors.sdNumber.message}</FormFeedback>
                    )}
                  </FormGroup>
                  <Accordion open={open} {...{toggle}} flush>
                    <AccordionItem>
                      <AccordionHeader targetId="supplier">
                        <div className="accordion-title fw-light text-black text-xl opacity-100 flex flex-row items-center h-8">
                          {invalidTabs.supplier && (
                            <Alert32 className="icon-requires--attention accordion-btn-icon-prefix" />
                          )}
                          <div className="accordion-title">
                            Supplier and recipient information
                          </div>
                        </div>
                      </AccordionHeader>
                      <AccordionBody
                        accordionId="supplier"
                        className="mb-2 mt-6"
                      >
                        <SupplierAndRecipientInfo status={status} />
                        <div className="w-full"></div>
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="general">
                        <div className="accordion-title fw-light text-black text-xl opacity-100 flex flex-row items-center h-8">
                          {invalidTabs.general && (
                            <Alert32 className="icon-requires--attention accordion-btn-icon-prefix" />
                          )}
                          General information
                        </div>
                      </AccordionHeader>
                      <AccordionBody
                        accordionId="general"
                        className="mb-2 mt-6"
                      >
                        <GeneralInformation
                          status={status}
                          countries={data?.bioLcCoproEuGetCountries ?? []}
                          rawMaterialTypes={
                            data?.bioLcCoproRawMaterialAPI?.Records ?? []
                          }
                        />
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="certification">
                        <div className="accordion-title fw-light text-black text-xl opacity-100 flex flex-row items-center justify-items-center h-8">
                          {invalidTabs.certification && (
                            <Alert32 className="icon-requires--attention accordion-btn-icon-prefix" />
                          )}
                          Scope of certification of raw material
                        </div>
                      </AccordionHeader>
                      <AccordionBody
                        accordionId="certification"
                        className="mb-2 mt-6"
                      >
                        <ScopeOfCertification status={status} />
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="emission">
                        <div className="accordion-title fw-light text-black text-xl opacity-100 flex flex-row items-center h-8">
                          {invalidTabs.emission && (
                            <Alert32 className="icon-requires--attention accordion-btn-icon-prefix" />
                          )}
                          Greenhouse gas (GHG) emission information
                        </div>
                      </AccordionHeader>
                      <AccordionBody
                        accordionId="emission"
                        className="mb-2 mt-6"
                      >
                        <GHGEmissionInfo
                          status={status}
                          countries={data?.bioLcCoproEuGetCountries ?? []}
                        />
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="additionalInfo">
                        <div className="accordion-title fw-light text-black text-xl opacity-100 flex flex-row items-center h-8">
                          {invalidTabs.additionalInfo && (
                            <Alert32 className="icon-requires--attention accordion-btn-icon-prefix" />
                          )}
                          Additional information
                        </div>
                      </AccordionHeader>
                      <AccordionBody
                        accordionId="additionalInfo"
                        className="mb-2 mt-6"
                      >
                        <AdditionalInfo
                          status={status}
                          mbLocations={
                            data?.bioLcCoproConfigCenterLocationDetails
                              ?.Records ?? []
                          }
                          transportEmissions={
                            data?.bioLcCoproConfigCenterTransportationEmission
                              ?.Records ?? []
                          }
                        />
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                  <div className="flex flex-row justify-end items-center gap-4 mb-24">
                    <Button
                      color="tertiary"
                      type="button"
                      outline
                      className="show rounded-0"
                      onClick={() =>
                        navigate(
                          "/copro-spain/document-manager/incoming-documents",
                        )
                      }
                      disabled={isSaving}
                    >
                      <Remove24 className="btn-icon-prefix" />
                      Cancel
                    </Button>
                    <Button
                      type="button"
                      color="standard-primary"
                      className="primary-btn rounded-0"
                      onClick={onAdd}
                      disabled={isSaving}
                    >
                      {isSaving ? (
                        <Spinner size="sm" className="btn-icon-prefix" />
                      ) : (
                        <Check24 className="btn-icon-prefix" />
                      )}
                      Add
                    </Button>
                  </div>
                </Form>
              </FormProvider>
            </div>
          </Layout>
        </div>
        <div className="flex flex-col flex-1 items-start justify-stretch bg-light-gray-100">
          {data?.bioLcCoproEuDownloadFileByFileName?.downloadUrl != null &&
          data?.bioLcCoproEuDownloadFileByFileName?.downloadUrl.includes(
            "file-not-found",
          ) ? (
            <div className="flex flex-col p-5 w-full text-center">
              <span className="fs-4 text-danger">Unable to load file</span>
              <code className="fs-6 text-danger">File not found</code>
            </div>
          ) : (
            <iframe
              className="w-full min-h-screen"
              title="View document"
              border="0"
              src={data?.bioLcCoproEuDownloadFileByFileName.downloadUrl}
            ></iframe>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManualEntryPage;
