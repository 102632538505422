import tw from "twin.macro";
import {useState, useMemo, useCallback} from "react";
import PropTypes from "prop-types";
import {Button, Label, Input} from "reactstrap";
import {toast} from "react-toastify";
import {NavLink, useNavigate} from "react-router-dom";
import {Check24} from "@bphxd/ds-core-react/lib/icons";
import {Datepicker} from "@bphxd/ds-core-react";
import {useQuery, useLazyQuery} from "@apollo/client";
import {COPRO_US_BATCHES_API_DATA} from "graphql/coprocessing/batches";
import {
  COPRO_URL,
  BUTTONS,
  ERRORS,
  SUCCESS_SAVE,
} from "../constants/coProcessing";
import {
  BATCH_INPUTS,
  batchesFormMap,
  OPERATIONS,
  INFO,
} from "../constants/batches";
import LoadingSpinner from "./LoadingSpinner";
import FormKeyPoints from "./FormKeyPoints";
import {
  setInitialData,
  setInitialErrors,
  getErrorState,
  getUpdates,
  getPDFErrors,
  convertToString,
  getBatchIDs,
  isBatchIdUnavailable,
} from "../helpers/formBuilder";
import AddBatchModal from "./AddBatch/AddBatchModal";

const FormButtons = tw.div`py-5 flex justify-end`;
const MissingBatchesForm = ({id}) => {
  const [formData, setFormData] = useState([]);
  const [pdfErrors, setPdfErrors] = useState([]);
  const [errors, setErrors] = useState([]);
  const [batchIDs, setBatchIds] = useState([]);
  const [errorState, setErrorState] = useState(true);

  const navigate = useNavigate();

  const {loading, data} = useQuery(COPRO_US_BATCHES_API_DATA, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      op: OPERATIONS.GET,
      c14_id: id,
    },
    onCompleted: () => {
      const response = data?.bioLcCoproUsBatchesApi?.body;
      if (response) {
        setFormData(setInitialData(response.details, BATCH_INPUTS));
        setErrors(setInitialErrors(response.details, BATCH_INPUTS));
        setPdfErrors(getPDFErrors(response.details, BATCH_INPUTS));
        setBatchIds(getBatchIDs(response.batch_ids));
      } else {
        toast.error(ERRORS.FAILED_LOAD);
        navigate(-1);
      }
    },
  });

  const handleChange = useCallback(
    (event) => {
      const {name, value} = event.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      setErrors((prevData) => ({
        ...prevData,
        [name]: !value,
      }));
      setErrorState(getErrorState(errors, name, value));
    },
    [errors],
  );

  const receivedKeyOptions = useMemo(
    () => ({
      defaultDate: formData.date_received,
      onChange: (selectedDate) =>
        handleChange({
          target: {
            value: convertToString(selectedDate[0]),
            name: "date_received",
          },
        }),
    }),
    [formData.date_received, handleChange],
  );

  const reportedKeyOptions = useMemo(
    () => ({
      defaultDate: formData.date_submitted,
      onChange: (selectedDate) =>
        handleChange({
          target: {
            value: convertToString(selectedDate[0]),
            name: "date_submitted",
          },
        }),
    }),
    [formData.date_submitted, handleChange],
  );

  const [submitQuery, {data: dataRes, errors: errorsRes}] = useLazyQuery(
    COPRO_US_BATCHES_API_DATA,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: () => {
        if (errorsRes !== undefined || errorsRes?.length < 0) {
          toast.error(ERRORS.FAILED_UPDATED);
        } else if (dataRes != null) {
          toast.success(SUCCESS_SAVE);
          navigate(-1);
        }
      },
    },
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    const updatedValues = getUpdates(pdfErrors, formData);
    submitQuery({
      variables: {
        op: OPERATIONS.UPDATE,
        c14_id: id,
        updates: updatedValues,
      },
    });
  };

  const getDefaultInput = (input) => {
    return (
      <Input
        data-test="copro-form-input"
        name={input.key}
        id={input.key}
        type={input.data_type}
        value={formData[input.key]}
        placeholder={input.placeholder}
        onChange={handleChange}
        invalid={errors[input.key]}
        required
      />
    );
  };

  const renderInput = (input) => {
    if (input.key === "batch_id" && formData[input.key]) {
      return getDefaultInput(input);
    }

    switch (input.key) {
      case "date_received":
      case "date_submitted":
        return (
          <Datepicker
            data-test="copro-form-datepicker"
            name={input.key}
            options={
              input.key === "date_received"
                ? receivedKeyOptions
                : reportedKeyOptions
            }
            invalid={errors[input.key]}
            required
          />
        );
      case "batch_id":
        return (
          <>
            <Input
              type="select"
              name={input.key}
              id={input.key}
              onChange={handleChange}
            >
              {batchIDs.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Input>
            {(isBatchIdUnavailable(formData) || batchIDs.length === 1) && (
              <div className="mb-4">
                <AddBatchText>{INFO.MISSING_BATCH}</AddBatchText>
                <AddBatchModal />
              </div>
            )}
          </>
        );
      default:
        return getDefaultInput(input);
    }
  };

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <FormKeyPoints
            errors={pdfErrors}
            state={errorState}
            map={batchesFormMap}
          />
          <div data-test="copro-missing-batches-form" className="pt-8">
            {BATCH_INPUTS.map((input) => {
              return (
                <div
                  data-test="copro-form-label"
                  key={`${input.key}-field`}
                  className="mb-4"
                >
                  <Label for={input.key}>{input.label}</Label>
                  {renderInput(input)}
                </div>
              );
            })}
          </div>
          <FormButtons>
            <Button
              data-test="copro-cancel-btn"
              color="standard-quartenary rounded-3"
            >
              <NavLink data-test="copro-base-link" to={`${COPRO_URL}/batches`}>
                {BUTTONS.CANCEL}
              </NavLink>
            </Button>
            <Button
              className="pb-2 rounded-3 bg-dark disabled:text-white"
              data-test="copro-save-btn"
              color=""
              onClick={handleSubmit}
              disabled={errorState}
            >
              <Check24 color="neutral" className="btn-icon-prefix" />
              {BUTTONS.SAVE}
            </Button>
          </FormButtons>
        </div>
      )}
    </div>
  );
};

MissingBatchesForm.propTypes = {
  id: PropTypes.string,
};
export default MissingBatchesForm;

const AddBatchText = tw.span`block text-sm pb-5`;
