import {gql} from "@apollo/client";

const GET_CYCLE_STATUS = gql`
  query bioLcCoproEuMassBalanceGetCycleStatus(
    $location_code: String
    $material_code: String
  ) {
    bioLcCoproEuMassBalanceGetCycleStatus(
      event: {location_code: $location_code, material_code: $material_code}
    ) {
      error
      statusCode
      data {
        mb_quarter
        mb_cycle_status
        mb_year
      }
    }
  }
`;

export default GET_CYCLE_STATUS;
