import PropTypes from "prop-types";

import {useFormContext} from "react-hook-form";
import {FormGroup, Label, Input, FormFeedback} from "reactstrap";
import CheckboxFlag from "modules/common/BinaryFlagNullable";
import {formatNumber, useUserSettings} from "providers/userSettings";
import parseAndRoundToTwoDecimal from "utils/numberUtil";
import {useEffect} from "react";

const VoluntaryAddons = ({data}) => {
  const {
    register,
    formState: {errors},
    control,
    setValue,
  } = useFormContext();

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();

  useEffect(() => {
    setValue(
      "ghgEec",
      parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgEec),
    );
    setValue(
      "ghgEl",
      parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgEl),
    );
    setValue(
      "ghgEp",
      parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgEp),
    );
    setValue(
      "ghgEtd",
      data?.rowData?.outboundRecords?.ghgEtd === "DDV"
        ? data?.rowData?.outboundRecords?.ghgEtd
        : parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgEtd),
    );
    setValue(
      "ghgEu",
      parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgEu),
    );
    setValue(
      "ghgEsca",
      parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgEsca),
    );
    setValue(
      "ghgEccs",
      parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgEccs),
    );
    setValue(
      "ghgEccr",
      parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgEccr),
    );

    setValue(
      "ghgTotal",
      data?.rowData?.outboundRecords?.ghgTotal === "DDV" ||
        data?.rowData?.outboundRecords?.ghgTotal === "TDV"
        ? data?.rowData?.outboundRecords?.ghgTotal
        : parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgTotal),
    );
  }, [setValue, data, decimalFormat]);

  return (
    <div className="flex flex-col gap-4 pb-6">
      <FormGroup>
        <Label for="isscGHGEmissionsRequirementFlag" className="fw-light">
          ISCC PLUS (205-01) GHG-Emissions requirements
        </Label>
        <CheckboxFlag
          id="isscGHGEmissionsRequirementFlag"
          name="isscGHGEmissionsRequirementFlag"
          control={control}
          error={errors.isscGHGEmissionsRequirementFlag}
        />
      </FormGroup>
      <FormGroup>
        <Label for="ghgTotal" className="fw-light">
          Total GHG emission value
        </Label>

        <Input
          {...computeProps("ghgTotal")}
          type="number"
          id="ghgTotal"
          data-test="ghgTotal"
          invalid={!!errors.ghgTotal}
          disabled
        />

        {errors.ghgTotal && (
          <FormFeedback>{errors.ghgTotal.message}</FormFeedback>
        )}
      </FormGroup>
      <div className="flex flex-row justify-between items-start gap-3">
        <FormGroup>
          <Label for="ghgEec" className="fw-light">
            Eec
          </Label>
          <Input
            type="text"
            id="ghgEec"
            name="ghgEec"
            data-test="ghgEec"
            {...computeProps("ghgEec")}
            invalid={!!errors.ghgEec}
            disabled
          />

          {errors.ghgEec && (
            <FormFeedback>{errors.ghgEec?.message}</FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="ghgEl" className="fw-light">
            El
          </Label>
          <Input
            type="text"
            id="ghgEl"
            name="ghgEl"
            data-test="ghgEl"
            {...computeProps("ghgEl")}
            invalid={!!errors.ghgEl}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="ghgEp" className="fw-light">
            Ep
          </Label>
          <Input
            type="text"
            id="ghgEp"
            name="ghgEp"
            data-test="ghgEp"
            {...computeProps("ghgEp")}
            invalid={!!errors.ghgEp}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="ghgEtd" className="fw-light">
            Etd
          </Label>
          <Input
            type="text"
            id="ghgEtd"
            name="ghgEtd"
            data-test="ghgEtd"
            {...computeProps("ghgEtd")}
            invalid={!!errors.ghgEtd}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="ghgEu" className="fw-light">
            Eu
          </Label>
          <Input
            type="text"
            id="ghgEu"
            name="ghgEu"
            data-test="ghgEu"
            {...computeProps("ghgEu")}
            invalid={!!errors.ghgEu}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="ghgEsca" className="fw-light">
            Esca
          </Label>
          <Input
            type="text"
            id="ghgEsca"
            name="ghgEsca"
            data-test="ghgEsca"
            {...computeProps("ghgEsca")}
            invalid={!!errors.ghgEsca}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="ghgEccs" className="fw-light">
            Eccs
          </Label>
          <Input
            type="text"
            id="ghgEccs"
            name="ghgEccs"
            data-test="ghgEccs"
            {...computeProps("ghgEccs")}
            invalid={!!errors.ghgEccs}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="ghgEccr" className="fw-light">
            Eccr
          </Label>
          <Input
            type="text"
            id="ghgEccr"
            name="ghgEccr"
            data-test="ghgEccr"
            {...computeProps("ghgEccr")}
            invalid={!!errors.ghgEccr}
            disabled
          />
        </FormGroup>
      </div>
      <Label className="fw-light">
        Means of transport. Transport distance in km
      </Label>
      <FormGroup>
        <Label for="shipKm" className="fw-light">
          Ship km
        </Label>

        <Input
          {...computeProps("shipKm")}
          type="number"
          id="shipKm"
          data-test="shipKm"
          invalid={!!errors.shipKm}
          maxLength={50}
        />

        {errors.shipKm && <FormFeedback>{errors.shipKm.message}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        <Label for="trainKm" className="fw-light">
          Train km
        </Label>

        <Input
          {...computeProps("trainKm")}
          type="number"
          id="trainKm"
          data-test="trainKm"
          invalid={!!errors.trainKm}
          maxLength={50}
        />

        {errors.trainKm && (
          <FormFeedback>{errors.trainKm.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="roadKm" className="fw-light">
          Road km
        </Label>

        <Input
          {...computeProps("roadKm")}
          type="number"
          id="roadKm"
          data-test="roadKm"
          invalid={!!errors.roadKm}
          maxLength={50}
        />

        {errors.roadKm && <FormFeedback>{errors.roadKm.message}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        <Label for="isccPlusConsumablesFlag" className="fw-light">
          ISCC PLUS (205-02) consumables
        </Label>
        <CheckboxFlag
          id="isccPlusConsumablesFlag"
          name="isccPlusConsumablesFlag"
          control={control}
          error={errors.isccPlusConsumablesFlag}
        />
      </FormGroup>
      <FormGroup>
        <Label for="isccPlusNonGMOFoodFlag" className="fw-light">
          ISCC PLUS (205-03) Non-GMO food / feed
        </Label>
        <CheckboxFlag
          id="isccPlusNonGMOFoodFlag"
          name="isccPlusNonGMOFoodFlag"
          control={control}
          error={errors.isccPlusNonGMOFoodFlag}
        />
      </FormGroup>
      <FormGroup>
        <Label for="isccPlusNonGMOTechnicalFlag" className="fw-light">
          ISCC PLUS (205-04) Non-GMO technical markets
        </Label>
        <CheckboxFlag
          id="isccPlusNonGMOTechnicalFlag"
          name="isccPlusNonGMOTechnicalFlag"
          control={control}
          error={errors.isccPlusNonGMOTechnicalFlag}
        />
      </FormGroup>
      <FormGroup>
        <Label for="isccPlusElectricityHeatFlag" className="fw-light">
          ISCC PLUS (205-06) Electricity and heat from biogas plants
        </Label>
        <CheckboxFlag
          id="isccPlusElectricityHeatFlag"
          name="isccPlusElectricityHeatFlag"
          control={control}
          error={errors.isccPlusElectricityHeatFlag}
        />
      </FormGroup>
      <FormGroup>
        <Label for="IsccPlusAddonFoodSecurityFlag" className="fw-light">
          ISCC PLUS (202-04) FSS Add-on - Food security Standard
        </Label>
        <CheckboxFlag
          id="IsccPlusAddonFoodSecurityFlag"
          name="IsccPlusAddonFoodSecurityFlag"
          control={control}
          error={errors.IsccPlusAddonFoodSecurityFlag}
        />
      </FormGroup>
    </div>
  );
};
VoluntaryAddons.propTypes = {
  data: PropTypes.object,
};
export default VoluntaryAddons;
