import {gql} from "@apollo/client";

export const GET_CERTIFICATE_DETAILS = gql`
  query bioLcCoproEuMassBalanceGetAutoPopulatedData(
    $templateType: String
    $certificationSystem: String
    $locationCode: String
    $vesselName: String
    $dispatchDate: String
    $inboundEuQtyId: String
    $motRecipient: String
    $sdNumber: String
  ) {
    bioLcCoproEuMassBalanceGetAutoPopulatedData(
      event: {
        templateType: $templateType
        certificationSystem: $certificationSystem
        locationCode: $locationCode
        vesselName: $vesselName
        dispatchDate: $dispatchDate
        inboundEuQtyId: $inboundEuQtyId
        motRecipient: $motRecipient
        sdNumber: $sdNumber
      }
    ) {
      templatePdfUrl
      supplierAddress
      supplier
      shippingAddress
      isSameSupplierShippingAddress
      generatedCertificateId
      certificationNumber
      euRedCompliantFlag
      isccCompliantFlag
    }
  }
`;

export const GET_EU_COUNTRIES = gql`
  query EUGetCountries {
    bioLcCoproEuGetCountries {
      code
      name
    }
  }
`;

export default {GET_CERTIFICATE_DETAILS, GET_EU_COUNTRIES};
