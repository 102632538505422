import {gql} from "@apollo/client";

export const COPRO_EU_MANUAL_ENTRY_QUERY = gql`
  query manualEntryQuery($countryCode: String!, $fileName: String!) {
    bioLcCoproEuGetCountries {
      code
      name
    }
    bioLcCoproConfigCenterLocationDetails {
      Records {
        location_id
        location_code
        location_name
      }
    }

    bioLcCoproRawMaterialAPI {
      Records {
        issc_desc
        material_code
      }
    }
    bioLcCoproConfigCenterMaterialMaster {
      Records {
        material_id
        material_code
        material_short_name
        material_long_name
        material_type
      }
    }
    bioLcCoproConfigCenterTransportationEmission {
      Records {
        transportation_emission_id
        sending_location_name
        receiving_location_name
        receiving_location_code
        mot_name
      }
    }

    bioLcCoproEuDownloadFileByFileName(
      event: {countryCode: $countryCode, fileName: $fileName}
    ) {
      downloadUrl
    }
  }
`;

export const ADD_DOCUMENT = gql`
  mutation AddDocumentMutation($event: CoproEuInsertInboundRecordRequest!) {
    bioLcCoproEuInsertInboundRecord(event: $event) {
      statusCode
      message
      docId
    }
  }
`;

export default {COPRO_EU_MANUAL_ENTRY_QUERY};
