import React, {useState} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Button,
} from "reactstrap";
import "./styles.css";

const TaskComponent = ({item}) => {
  const [show, setShow] = useState(false);
  const [showRemaining, setShowRemaining] = useState(false);
  const toggle = () => setShow((prevState) => !prevState);
  const showRemainingDocs = () => setShowRemaining(true);
  const headers = {
    ready_to_accept: "Documents ready to accept",
    requires_attention: "Documents require your attention",
    ready_to_assign: "Documents ready to assign",
    failed_to_process: "Documents failed to process",
  };
  let initialDisplayItems = item.taskItems;
  let remainingItems = [];
  if (item.taskItems === null) return null;
  if (item.total === 0) return null;
  if (item.total > 5) {
    remainingItems = item.taskItems?.slice(6);
    initialDisplayItems = item.taskItems?.slice(0, 6);
  }
  return (
    <div className="flex mr-0 justify-items-between" key={item.id}>
      <Dropdown
        onMouseEnter={toggle}
        onMouseLeave={toggle}
        onClick={toggle}
        isOpen={show}
        toggle={toggle}
        className="flex flex-start border-0 border-transparent bg-transparent p-3"
      >
        <div className="pt-3">{item.icon}</div>
        <DropdownToggle
          className="flex-start border-0 border-transparent p-2 bg-transparent"
          key={item.key}
        >
          <div className="flex flex-col items-start">
            <div className="drop-down-name">{item.text}</div>
            <div className="drop-down-latest-document">
              {item.latestDocument}
            </div>
          </div>
        </DropdownToggle>

        <DropdownMenu className="w-385 drop-down h-80 overflow-y-auto overflow-x-hidden">
          <DropdownItem header className="drop-down-header DropdnMenuItem pl-3">
            {headers[item.header]}
          </DropdownItem>

          {initialDisplayItems?.map((document) => {
            return (
              <>
                <DropdownItem divider />
                <DropdownItem className="DropdnMenuItem pl-5">
                  <div className="flex-shrink-0">{item.icon}</div>
                  <div className="flex ms-4 x5-me-1 flex-row ml-10">
                    <div className="-menu-item-text flex-start">
                      {document.doc_name}
                    </div>
                    <div className="label">{document.modified_last}</div>
                  </div>
                </DropdownItem>
              </>
            );
          })}
          {!showRemaining && item.taskItems?.length > 5 && (
            <DropdownItem className="DropdnMenuItem pl-5">
              <Button
                color="link"
                className="view_all_link -menu-item-text justify-center"
                onClick={showRemainingDocs}
              >
                View all {headers[item.header]}
              </Button>
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

TaskComponent.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    header: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    taskItems: PropTypes.array,
    latestDocument: PropTypes.string,
    total: PropTypes.number,
    key: PropTypes.number,
  }).isRequired,
};

export default TaskComponent;
