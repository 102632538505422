import PropTypes from "prop-types";
import {useQuery} from "@apollo/client";
import {useCallback, useMemo} from "react";
import {Datepicker} from "@bphxd/ds-core-react";
import {GET_TANKS, BATCH_IDS_QUERY} from "graphql/coprocessing/batches";
import {Input} from "reactstrap";
import {BATCH_SUMMARY_COLUMNS} from "modules/co-processing/constants/batches";
import {convertToString} from "modules/co-processing/helpers/formBuilder";
import {displayDate} from "modules/co-processing/helpers/dateHelper";

const BatchSummaryDisplay = ({
  batchDetails,
  editState,
  formData,
  setFormData,
}) => {
  const handleChange = useCallback(
    (event) => {
      const {name, value} = event.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    },
    [setFormData],
  );

  const {data: tankData} = useQuery(GET_TANKS, {
    fetchPolicy: "network-only",
  });

  const {data: batchIdsData} = useQuery(BATCH_IDS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      startTime: formData.batch_start_time,
      endTime: formData.batch_end_time,
      tankNumber: formData.tank_number,
    },
  });

  const batchStartOptions = useMemo(
    () => ({
      defaultDate: batchDetails.batch_start_time,
      onChange: (selectedDate) =>
        handleChange({
          target: {
            value: convertToString(selectedDate[0]),
            name: "batch_start_time",
          },
        }),
    }),
    [batchDetails.batch_start_time, handleChange],
  );

  const batchEndOptions = useMemo(
    () => ({
      defaultDate: batchDetails.batch_end_time,
      onChange: (selectedDate) =>
        handleChange({
          target: {
            value: convertToString(selectedDate[0]),
            name: "batch_end_time",
          },
        }),
    }),
    [batchDetails.batch_end_time, handleChange],
  );

  const getBatchOptions = ({key, options}) => {
    switch (key) {
      case "tank_number":
        return (
          tankData?.bioLcCoproUsBatchesApi?.body?.tanks?.map(
            (t) => t?.tank_number,
          ) ?? []
        );
      case "batch_id":
        return (
          batchIdsData?.bioLcCoproUsBatchesApi?.body?.batch_ids?.map(
            (t) => t?.batch_id,
          ) ?? [formData?.batch_id]
        );
      default:
        return options;
    }
  };

  const renderFormField = (item, editable) => {
    const value = batchDetails?.[item.key];

    switch (editable) {
      case true:
        switch (item.type) {
          case "date":
            return (
              <Datepicker
                data-test="copro-batch-edit-datepicker"
                name={item.key}
                options={
                  item.key === "batch_start_time"
                    ? batchStartOptions
                    : batchEndOptions
                }
                onChange={handleChange}
                required
              />
            );
          case "textarea":
            return (
              <Input
                data-test="copro-batch-edit-textarea"
                id={item.key}
                name={item.key}
                type={item.type}
                value={batchDetails?.[item.key] ?? ""}
                onChange={handleChange}
                rows="3"
                required
              />
            );
          default:
            return (
              <Input
                data-test="copro-batch-edit-select"
                id={item.key}
                type={item.type}
                name={item.key}
                onChange={handleChange}
                value={formData?.[item.key]}
              >
                <option key="none" disabled hidden value="">
                  -- Select an option --
                </option>
                {item.isLoading ? (
                  <option key="loading" value="" disabled>
                    Loading...
                  </option>
                ) : (
                  getBatchOptions(item).map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))
                )}
              </Input>
            );
        }
      default:
        return item.type === "date" ? (
          <span data-test="copro-batch-date-text">{displayDate(value)}</span>
        ) : (
          <span data-test="copro-batch-text">{value}</span>
        );
    }
  };

  return (
    <div className="grid grid-cols-2 gap-4 pt-7 pb-8">
      {BATCH_SUMMARY_COLUMNS.map((item, index) => (
        <div key={index} className="pb-10">
          <div className="text-xs pb-4">{item.label}</div>
          <div className="text-xl">{renderFormField(item, editState)}</div>
        </div>
      ))}
    </div>
  );
};

BatchSummaryDisplay.propTypes = {
  batchDetails: PropTypes.object,
  editState: PropTypes.bool,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
};

export default BatchSummaryDisplay;
