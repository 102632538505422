import {gql} from "@apollo/client";

export const SAF_GET_NOTIFICATION = gql`
  query MyQuery {
    bioLcSafGetTasks(event: {division_id: "", site_reference_id: ""}) {
      data {
        documents {
          doc_name
          id
          modified_last
        }
        latestDocument
        state
        total
      }
      error
      statusCode
    }
  }
`;

export default SAF_GET_NOTIFICATION;
