import PropTypes from "prop-types";
import React, {useEffect, useMemo} from "react";
import {uniqWith, isEqual, sortBy} from "lodash";
import moment from "moment";
import {FormGroup, Label, Input, Button} from "reactstrap";
import {useFormContext} from "react-hook-form";
import "./index.css";

const seperationText = "#-#";

const FeedstockFilterContent = ({
  defaultValues,
  filterDynamicData,
  handleFilterSubmit,
  handleFilterClear,
}) => {
  const {register, watch, setValue} = useFormContext();
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const watchLocation = watch("location");
  const watchFeedstock = watch("feedstock");
  const watchPeriodMonth = watch("periodMonth");
  const watchPeriodYear = watch("periodYear");

  // watchLocation, watchFeedstock, watchPeriodMonth, watchPeriodYear

  const filterDynamicDataResult = useMemo(() => {
    // const feedstockDetails = watchLocation?.split(seperationText);
    // const locationDetails = watchFeedstock?.split(seperationText);
    // console.log(
    //   "dynamic",
    //   feedstockDetails,
    //   locationDetails,
    //   filterDynamicData,
    // );
    return filterDynamicData;
    // ?.filter(
    //   (o) =>
    //     o.locationId ===
    //       (locationDetails[0] === "" ||
    //       locationDetails[0] === undefined ||
    //       locationDetails[0] === null
    //         ? o.locationId
    //         : locationDetails[0]) &&
    //     o.materialId ===
    //       (feedstockDetails[0] === "" ||
    //       feedstockDetails[0] === undefined ||
    //       feedstockDetails[0] === null
    //         ? o.materialId
    //         : feedstockDetails[0]),
    // );
    // &&
    //     o.fifoYear ===
    //       (watchPeriodYear === "" ||
    //       watchPeriodYear === undefined ||
    //       watchPeriodYear === null
    //         ? o.fifoYear
    //         : watchPeriodYear) &&
    //     o.fifoMonth ===
    //       (watchPeriodMonth === "" ||
    //       watchPeriodMonth === undefined ||
    //       watchPeriodMonth === null
    //         ? o.fifoMonth
    //         : watchPeriodMonth),
    // ),
  }, [
    filterDynamicData,
    // watchLocation,
    // watchFeedstock,
    // watchPeriodMonth,
    // watchPeriodYear,
  ]);

  const locationList = useMemo(() => {
    const locList = filterDynamicDataResult.map((item) => ({
      locationId: item.locationId,
      locationName: item.locationName,
    }));

    return uniqWith(locList, isEqual);
  }, [filterDynamicDataResult]);
  // watchLocation, watchFeedstock, watchPeriodMonth, watchPeriodYear

  const feedstockList = useMemo(() => {
    const locationDetails = watchLocation?.split(seperationText);
    const feedstockListItems = filterDynamicDataResult
      ?.filter(
        (o) =>
          o.locationId ===
          (locationDetails[0] === "" ||
          locationDetails[0] === undefined ||
          locationDetails[0] === null
            ? o.locationId
            : locationDetails[0]),
        // &&
        // o.fifoYear ===
        //   (watchPeriodYear === "" ||
        //   watchPeriodYear === undefined ||
        //   watchPeriodYear === null
        //     ? o.fifoYear
        //     : watchPeriodYear) &&
        // o.fifoMonth ===
        //   (watchPeriodMonth === "" ||
        //   watchPeriodMonth === undefined ||
        //   watchPeriodMonth === null
        //     ? o.fifoMonth
        //     : watchPeriodMonth),
      )
      ?.map((item) => ({
        materialId: item.materialId,
        materialShortName: item.materialShortName,
        sapMaterialCode: item.sapMaterialCode,
        sapNCCode: item.sapNCCode,
      }));

    return uniqWith(feedstockListItems, isEqual);
  }, [filterDynamicDataResult, watchLocation]);

  const monthListItems = useMemo(() => {
    const locationDetails = watchLocation?.split(seperationText);
    const feedstockDetails = watchFeedstock?.split(seperationText);
    const monthItems = filterDynamicDataResult
      ?.filter(
        (o) =>
          o.locationId ===
          (locationDetails[0] === "" ||
          locationDetails[0] === undefined ||
          locationDetails[0] === null
            ? o.locationId
            : locationDetails[0]),
        //      &&
        // o.materialId ===
        //   (feedstockDetails[0] === "" ||
        //   feedstockDetails[0] === undefined ||
        //   feedstockDetails[0] === null
        //     ? o.materialId
        //     : feedstockDetails[0]) &&
        // o.fifoYear ===
        //   (watchPeriodYear === "" ||
        //   watchPeriodYear === undefined ||
        //   watchPeriodYear === null
        //     ? o.fifoYear
        //     : watchPeriodYear) &&
        // o.fifoMonth ===
        //   (watchPeriodMonth === "" ||
        //   watchPeriodMonth === undefined ||
        //   watchPeriodMonth === null
        //     ? o.fifoMonth
        //     : watchPeriodMonth),
      )
      ?.map((item) => ({
        fifoMonth: item.fifoMonth,
      }));

    const uniqueItems = uniqWith(monthItems, isEqual);
    return sortBy(uniqueItems, "fifoMonth", "asc");
  }, [
    filterDynamicDataResult,
    watchLocation,
    watchFeedstock,
    // watchPeriodMonth,
    // watchPeriodYear,
  ]);

  const yearListItems = useMemo(() => {
    const locationDetails = watchLocation?.split(seperationText);
    const feedstockDetails = watchFeedstock?.split(seperationText);
    const yearItems = filterDynamicDataResult
      ?.filter(
        (o) =>
          o.locationId ===
          (locationDetails[0] === "" ||
          locationDetails[0] === undefined ||
          locationDetails[0] === null
            ? o.locationId
            : locationDetails[0]),
        //     &&
        // o.materialId ===
        //   (feedstockDetails[0] === "" ||
        //   feedstockDetails[0] === undefined ||
        //   feedstockDetails[0] === null
        //     ? o.materialId
        //     : feedstockDetails[0]) &&
        // o.fifoYear ===
        //   (watchPeriodYear === "" ||
        //   watchPeriodYear === undefined ||
        //   watchPeriodYear === null
        //     ? o.fifoYear
        //     : watchPeriodYear) &&
        // o.fifoMonth ===
        //   (watchPeriodMonth === "" ||
        //   watchPeriodMonth === undefined ||
        //   watchPeriodMonth === null
        //     ? o.fifoMonth
        //     : watchPeriodMonth),
      )
      ?.map((item) => ({
        fifoYear: item.fifoYear,
      }));

    const uniqueItems = uniqWith(yearItems, isEqual);
    return sortBy(uniqueItems, "fifoYear", "desc");
  }, [
    filterDynamicDataResult,
    watchLocation,
    watchFeedstock,
    // watchPeriodMonth,
    // watchPeriodYear,
  ]);

  useEffect(() => {
    if (filterDynamicDataResult?.length > 0) {
      setValue("location", defaultValues?.location);
      setValue("periodMonth", defaultValues?.periodMonth);
      setValue("periodYear", defaultValues?.periodYear);
      setValue("feedstock", defaultValues?.feedstock);
    }
  }, [
    setValue,
    defaultValues?.location,
    defaultValues?.periodMonth,
    defaultValues?.periodYear,
    defaultValues?.feedstock,
    filterDynamicDataResult?.length,
  ]);

  return (
    <div className="flex flex-col fifo-filter-content-section">
      <Label className="pb-2 filter-by ">Filter by:</Label>
      <div className="flex flex-row gap-5">
        <FormGroup>
          <Label for="periodMonth" className="fw-normal">
            Month
          </Label>
          <Input
            type="select"
            id="periodMonth"
            data-test="periodMonth"
            {...computeProps("periodMonth", {
              required: "Please select month",
            })}
            maxLength={100}
            placeholder="Please choose from list"
            className="!w-32 bg-white"
          >
            {monthListItems.map((month) => (
              <option key={month.fifoMonth} value={month.fifoMonth}>
                {moment(month.fifoMonth, "MM").format("MMMM")}
              </option>
            ))}
          </Input>
        </FormGroup>

        <FormGroup>
          <Label for="periodYear" className="fw-normal">
            Year
          </Label>
          <Input
            type="select"
            id="periodYear"
            data-test="periodYear"
            {...computeProps("periodYear", {
              required: "Please select year",
            })}
            maxLength={100}
            placeholder="Please choose from list"
            className="!w-32 bg-white"
          >
            {yearListItems.map((year) => (
              <option key={year.fifoYear} value={year.fifoYear}>
                {year.fifoYear}
              </option>
            ))}
          </Input>
        </FormGroup>

        <FormGroup>
          <Label for="location" className="fw-normal">
            Location
          </Label>
          <Input
            type="select"
            id="location"
            data-test="location"
            {...computeProps("location", {
              required: "Please select Location",
            })}
            maxLength={100}
            placeholder="Please choose from list"
            className="!w-60 bg-white"
          >
            {locationList.map((location) => (
              <option
                key={location.locationId}
                value={`${location.locationId}${seperationText}${location.locationName}`}
              >
                {location.locationName}
              </option>
            ))}
          </Input>
        </FormGroup>

        <FormGroup>
          <Label for="feedstock" className="fw-normal">
            Feedstock
          </Label>
          <Input
            type="select"
            id="feedstock"
            data-test="feedstock"
            {...computeProps("feedstock", {
              required: "Please select feedstock",
            })}
            maxLength={100}
            placeholder="Please choose from list"
            className="!w-60 bg-white"
          >
            <option key="none" value="">
              -- All --
            </option>
            {feedstockList.map((feedstock) => (
              <option
                key={feedstock.materialId}
                value={`${feedstock.materialId}${seperationText}${feedstock.sapMaterialCode}${seperationText}${feedstock.sapNCCode}${seperationText}${feedstock.materialShortName}`}
              >
                {`${feedstock.materialShortName} - ${
                  defaultValues?.materialView === "sap_code"
                    ? feedstock.sapMaterialCode
                    : feedstock.sapNCCode
                }`}
              </option>
            ))}
          </Input>
        </FormGroup>

        <div className="flex py-6 gap-x-4 mt-1">
          <Button
            className="padded h-10 rounded-0  apply-button !w-32"
            color="standard-outline"
            data-test="filter-submit-button"
            type="button"
            form="fifo-feedstock-filter-form"
            onClick={handleFilterSubmit}
          >
            Apply filters
          </Button>
          <Button
            className="btn-clear rounded-0"
            color="standard-outline"
            data-test="filter-clear-button"
            onClick={handleFilterClear}
          >
            <span className="btn-clear--text">Clear</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

FeedstockFilterContent.propTypes = {
  defaultValues: PropTypes.object,
  filterDynamicData: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleFilterSubmit: PropTypes.func,
  handleFilterClear: PropTypes.func,
};
export default FeedstockFilterContent;
