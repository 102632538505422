import ContractSummary from "modules/co-processing/components/ContractSummary";
import FeedstockTracking from "modules/co-processing/components/FeedstockTracking";
import SectionWrapper from "modules/co-processing/components/SectionWrapper";
import {
  TABLE_TITLE,
  HEADER,
  DATE_RANGE_TEXT,
} from "modules/co-processing/constants/receipts";
import StickyDatePicker from "modules/co-processing/components/DatePicker/StickyDatePicker";
import TaskManager from "modules/co-processing/components/TaskManager";
import {ContractDateRangeProvider} from "modules/co-processing/context/DateRangeContext";
import {StatusProvider} from "modules/co-processing/context/StatusContext";
import {PAGE_TITLE} from "modules/co-processing/constants/coProcessing";
import "../../components/index.scss";

const PrimaryPage = () => {
  return (
    <div>
      <div className="mt-4 pb-6 mx-[40px] text-[32px] border-b-[1px]">
        {PAGE_TITLE.RECEIPTS}
      </div>
      <ContractDateRangeProvider>
        <StatusProvider>
          <div className="co-processing-container overflow-x-auto">
            <TaskManager task_type="receipts" />
            <div className="receipts">
              <span className="text-xl">{DATE_RANGE_TEXT}</span>
              <StickyDatePicker />
              <SectionWrapper page={HEADER} tableTitle={TABLE_TITLE.SUMMARY}>
                <ContractSummary />
              </SectionWrapper>
              <SectionWrapper
                page={HEADER}
                tableTitle={TABLE_TITLE.FEEDSTOCK_TRACKING}
              >
                <FeedstockTracking />
              </SectionWrapper>
            </div>
          </div>
        </StatusProvider>
      </ContractDateRangeProvider>
    </div>
  );
};

export default PrimaryPage;
