import {useQuery} from "@apollo/client";
import {Down24, Filter24} from "@bphxd/ds-core-react/lib/icons";

import {useAccount, useMsal} from "@azure/msal-react";
import {MATERIAL_MASTER} from "graphql/config-center/MaterialMaster.js";
import GET_ALL_RECORDS from "graphql/es-co-processing/mass-balance/AllRecords.js";
import GET_CYCLE_STATUS from "graphql/es-co-processing/mass-balance/CycleStatus.js";
import {useUserSettings} from "providers/userSettings/context.js";
import React, {useMemo, useState, useEffect, useCallback} from "react";
import {useParams} from "react-router-dom";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";

import {COPRO_EU_GET_AVAILABLE_QTY} from "graphql/es-co-processing/EuLandingPage.js";
import {formatNumber} from "providers/userSettings/utils.js";
import roundToTwoDecimal from "utils/helpers/roundNumber.js";
import SpinnerLoading from "modules/common/SpinnerLoading";
import CloseBalance from "../components/CloseBalance.js";
import CustomizeTable from "../components/CustomizeTable/CustomizeTable";
import Layout from "../components/Layout/index.js";
import OpenBalance from "../components/OpenBalance.js";
import {
  INCOMING_COLUMNS,
  OUTGOING_COLUMNS,
} from "../components/Table/Column.js";
import SplitTable from "../components/Table/index.js";
import {statusData} from "../content/status.js";
import "./index.css";

const MassBalanceForLocation = () => {
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const defaultPageSize = 10;

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: defaultPageSize,
  });

  const handlePaginationChange = ({pageIndex, pageSize}) => {
    setPagination({pageIndex, pageSize});
  };

  const hasQuartersData = (quartersData) =>
    quartersData?.bioLcCoproEuMassBalanceGetCycleStatus.data &&
    quartersData?.bioLcCoproEuMassBalanceGetCycleStatus.data.length > 0;
  const {locationCode, locationName, materialCode, quarter, year} = useParams();
  const [firstLoad, setFirstLoad] = useState(true);
  const [chosenCycle, setChosenCycle] = useState("");
  const [filterPopoverVisible, setFilterPopoverVisible] = useState(false);
  const [filterStatus, setFilterStatus] = useState("");

  // Default to first material code if not specified
  const [activeMaterial, setActiveMaterial] = useState(materialCode || "1");
  const breadcrumbItems = useMemo(
    () => [
      {text: "BioVerse", link: "/"},
      {text: "Co-processing", link: "/copro-spain"},
      {text: "Mass balance", link: "/copro-spain"},
      {
        text: locationName,
      },
    ],
    [locationName],
  );

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: defaultPageSize,
    });
  }, [activeMaterial, chosenCycle]);

  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();

  const {data: materialMasterData, loading: materialMasterLoading} =
    useQuery(MATERIAL_MASTER);

  const {data: quartersData, loading: quartersLoading} = useQuery(
    GET_CYCLE_STATUS,
    {variables: {location_code: locationCode, material_code: activeMaterial}},
  );

  const mbCycle = chosenCycle
    ? `${chosenCycle.mb_year}${chosenCycle.mb_quarter}`
    : year != null
    ? `${year}${quarter}`
    : null;

  const {data: quantitiesData, loading: quantitiesLoading} = useQuery(
    COPRO_EU_GET_AVAILABLE_QTY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      variables: {
        event: {
          mbCycle,
          mbLocation: locationCode,
        },
      },
      skip: !mbCycle,
    },
  );

  const quantities = useMemo(() => {
    if (
      quantitiesData?.bioLcCoproEuMassBalanceAvailableQuantity != null &&
      quantitiesData?.bioLcCoproEuMassBalanceAvailableQuantity.length > 0
    ) {
      const qty =
        quantitiesData?.bioLcCoproEuMassBalanceAvailableQuantity[0].data.reduce(
          (acc, {materialCode, quantity, materialStatus, closingDate}) => {
            acc[materialCode] = {quantity, materialStatus, closingDate};
            return acc;
          },
          {},
        );
      return qty;
    }
    return {};
  }, [quantitiesData?.bioLcCoproEuMassBalanceAvailableQuantity]);

  const {
    data: recordsData,
    loading: recordsLoading,
    refetch,
  } = useQuery(GET_ALL_RECORDS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      filterStatus: [],
      mbLocationCode: locationCode,
      materialCode: activeMaterial,
      mbCycle,
    },
    skip: !mbCycle,
  });

  // Default to chosen material and current quarter
  if (
    firstLoad &&
    !quartersLoading &&
    quartersData?.bioLcCoproEuMassBalanceGetCycleStatus.data &&
    !quarter
  ) {
    setFirstLoad(false);
    if (hasQuartersData(quartersData)) {
      setChosenCycle(
        quartersData.bioLcCoproEuMassBalanceGetCycleStatus.data[0],
      );
    }
  }
  if (
    firstLoad &&
    quarter &&
    year &&
    !quartersLoading &&
    hasQuartersData(quartersData)
  ) {
    setFirstLoad(false);
    setChosenCycle(
      quartersData.bioLcCoproEuMassBalanceGetCycleStatus.data.find(
        (quart) => quart.mb_quarter === quarter && quart.mb_year === year,
      ),
    );
  }

  const dropdownText = chosenCycle
    ? `${chosenCycle?.mb_quarter} ${chosenCycle?.mb_year}`
    : "Select a quarter";

  const handleFilterSubmit = () => {
    refetch({
      filterStatus: filterStatus === "" ? [] : [filterStatus],
      mbLocationCode: locationCode,
      materialCode: activeMaterial,
      mbCycle: chosenCycle
        ? `${chosenCycle.mb_year}${chosenCycle.mb_quarter}`
        : "fake_cycle",
    });
    setFilterPopoverVisible(false);
  };

  const refereshChosenCycleData = () => {
    setChosenCycle(
      quartersData.bioLcCoproEuMassBalanceGetCycleStatus.data.find(
        (quart) => quart.mb_quarter === quarter && quart.mb_year === year,
      ),
    );
  };

  // set columns - customize columns
  const incomingColumns = INCOMING_COLUMNS(dateFormat, decimalFormat);
  const IncomingColumnsList = incomingColumns[0]?.columns.filter(
    (columnItem) =>
      columnItem.header === "Date of physical receipt" ||
      columnItem.header === "Month of receipt",
  );

  const [customizedColumns, setCustomizedColumns] =
    useState(IncomingColumnsList);
  const custmozedColumnsChange = useCallback((customizedColumns) => {
    setCustomizedColumns(customizedColumns);
  }, []);

  const columns = useMemo(() => {
    const incomingColumns = INCOMING_COLUMNS(dateFormat, decimalFormat);
    const customizedVisibleCols = customizedColumns?.filter(
      (columnItem) => columnItem?.visible === false,
    );

    const IncomingCols = incomingColumns[0]?.columns.filter((columnItem) => {
      return !columnItem?.header
        ?.toString()
        .includes(customizedVisibleCols?.map((item) => item.header.toString()));
    });

    const IncomingColsList =
      customizedVisibleCols?.length > 0
        ? [{header: incomingColumns[0].header, columns: IncomingCols}]
        : [
            {
              header: incomingColumns[0].header,
              columns: incomingColumns[0]?.columns?.filter(
                (columnItem) => columnItem?.visible === true,
              ),
            },
          ];
    return IncomingColsList.concat(OUTGOING_COLUMNS(decimalFormat));
  }, [dateFormat, decimalFormat, customizedColumns]);

  return (
    <Layout title={locationName} breadcrumbs={breadcrumbItems}>
      <div className="min-h-[40vh]">
        <Nav className="bp-core nav-standard">
          {!materialMasterLoading ? (
            materialMasterData.bioLcCoproConfigCenterMaterialMaster.Records.filter(
              (material) => material.material_type === "Raw Material",
            ).map((material) => {
              return (
                <NavItem
                  key={material.material_code}
                  data-test={`data-test-tab-nav-item-${material.material_code}`}
                  className="min-w-[10.44rem]"
                >
                  <NavLink
                    data-test={`data-test-tab-link-${material.material_code}`}
                    className={
                      activeMaterial === material.material_code ? "active" : ""
                    }
                    onClick={() => {
                      setFilterPopoverVisible(false);
                      setActiveMaterial(material.material_code);
                      setChosenCycle("");
                    }}
                  >
                    <span className="btn-text-text-default-light">
                      {material.material_short_name}
                    </span>
                    <div className="text-xs">
                      {quantitiesLoading ? (
                        <Spinner size="sm" />
                      ) : (
                        <>
                          {formatNumber(
                            parseFloat(
                              roundToTwoDecimal(
                                quantities[material.material_code]?.quantity ??
                                  "0.00",
                              ),
                            ),
                            decimalFormat,
                            0,
                          )}
                          t
                        </>
                      )}
                    </div>
                  </NavLink>
                </NavItem>
              );
            })
          ) : (
            <SpinnerLoading />
          )}
        </Nav>
        {quartersLoading ? (
          <SpinnerLoading />
        ) : quartersData?.bioLcCoproEuMassBalanceGetCycleStatus.data &&
          quartersData?.bioLcCoproEuMassBalanceGetCycleStatus.data.length >
            0 ? (
          <div className="flex my-6 justify-between">
            <div className="flex flex-row gap-4 items-center ">
              <UncontrolledDropdown>
                <DropdownToggle
                  color="tertiary"
                  className="rounded-0 w-72 border bg-white justify-content-between"
                  caret
                >
                  {dropdownText}
                  <Down24 className="float-right" />
                </DropdownToggle>
                <DropdownMenu className="w-72">
                  {quartersData?.bioLcCoproEuMassBalanceGetCycleStatus.data.map(
                    (cycle) => (
                      <DropdownItem
                        key={cycle.mb_quarter}
                        onClick={() => setChosenCycle(cycle)}
                      >
                        {`${cycle.mb_quarter} ${cycle.mb_year}`}
                      </DropdownItem>
                    ),
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
              {chosenCycle &&
                chosenCycle !== "" &&
                (chosenCycle?.mb_cycle_status === "OPEN" ? (
                  <CloseBalance
                    chosenCycle={chosenCycle}
                    locationCode={locationCode}
                    activeMaterial={activeMaterial}
                    account={account}
                    refereshChosenCycleData={refereshChosenCycleData}
                  />
                ) : (
                  <OpenBalance
                    chosenCycle={chosenCycle}
                    locationCode={locationCode}
                    activeMaterial={activeMaterial}
                    account={account}
                    refereshChosenCycleData={refereshChosenCycleData}
                  />
                ))}
            </div>
            <div>
              <Button
                data-test="copro-filter-btn"
                id="filterButton"
                name="filterButton"
                color="standard-tertiary rounded-0 bg-white"
                onClick={() => setFilterPopoverVisible(!filterPopoverVisible)}
                active={filterPopoverVisible}
              >
                Filter
                <Filter24 className="btn-icon-suffix" />
              </Button>

              <Popover
                popperClassName="request-revision-popover"
                innerClassName="request-revision-popover-inner"
                placement="bottom"
                hideArrow
                target="filterButton"
                isOpen={filterPopoverVisible}
              >
                <PopoverBody>
                  <div className="grid grid-cols-1 px-5 ">
                    <FormGroup className="form-check-reverse flex-grow-1">
                      <Label check>Please select status</Label>
                      <Input
                        type="select"
                        id="status"
                        name="status"
                        data-test="status"
                        className="w-64"
                        onChange={(e) => setFilterStatus(e.target.value)}
                        value={filterStatus}
                      >
                        <option key="none" value="">
                          -- ALL --
                        </option>
                        {statusData?.map((status) => (
                          <option key={status.id} value={status.id}>
                            {status.label}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </div>
                  <div className="flex justify-center p-3">
                    <Button
                      size="sm"
                      color="standard-primary"
                      className="rounded-0 ms-3 primary-btn"
                      onClick={() => handleFilterSubmit()}
                    >
                      Apply
                    </Button>
                  </div>
                </PopoverBody>
              </Popover>
            </div>
          </div>
        ) : (
          <div className="flex my-6">
            <p className={activeMaterial === "" ? "hidden" : ""}>
              No cycles found for this location/feedstock combo
            </p>
          </div>
        )}

        {chosenCycle !== "" && activeMaterial !== "" && (
          <>
            <div className=" flex flex-row justify-between w-full bg-white p-4">
              <div className="bg-white">
                <CustomizeTable
                  columns={customizedColumns}
                  onColumnChange={(customizedColumns) =>
                    custmozedColumnsChange(customizedColumns)
                  }
                />
              </div>
            </div>
            <SplitTable
              id="mass-balance-table-view"
              columns={columns}
              data={recordsData?.bioLcCoproEuMassBalanceAllRecords?.data ?? []}
              loading={recordsLoading}
              onPaginationChange={handlePaginationChange}
              pagination={pagination}
            />
          </>
        )}
      </div>
    </Layout>
  );
};
export default MassBalanceForLocation;
