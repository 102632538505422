import {Input, Label} from "reactstrap";
import {useEffect, useState} from "react";
import {useQuery} from "@apollo/client";
import {useFormContext, Controller} from "react-hook-form";
import {roundDecimal} from "utils/helpers/uihelper";
import PropTypes from "prop-types";
import FormField from "modules/common/FormFieldNew.js";
import {MATERIAL_MASTER} from "graphql/config-center/MaterialMaster.js";
import {PRODUCTION_BASELINE_EMISSIONS} from "graphql/config-center/ProductionBaselineEmissions.js";

import {useUserSettings, formatNumber} from "providers/userSettings";
import {GET_AVAILABLE_QTY} from "graphql/es-co-processing/mass-balance/massbalance";

const ProductDetails = ({data, rowData}) => {
  const {
    formState: {errors},
    trigger,
    watch,
    setValue,
  } = useFormContext();

  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  const watchTemplateType = watch("templateType");

  const {data: productDetails, loading} = useQuery(MATERIAL_MASTER);
  const {data: productBaselineEmissions, loading: loadingbaselineEmissions} =
    useQuery(PRODUCTION_BASELINE_EMISSIONS);
  const productList =
    productDetails?.bioLcCoproConfigCenterMaterialMaster?.Records?.filter(
      (obj) => obj.material_type === "Product",
    );

  const watchUOM = watch("uom");
  const [availableQty, setAvailableQty] = useState("");
  const {
    data: availableQtyResponse,
    loading: loadingAvailableQty,
    refetch,
  } = useQuery(GET_AVAILABLE_QTY, {
    variables: {
      currentUom: "MT",
      quantity: rowData?.outboundRecords?.qtyAllocated,
      targetUom: watchUOM ?? "MT",
    },
  });

  useEffect(() => {
    setValue("productType", "Raw material");
  }, [watchTemplateType, setValue]);

  useEffect(() => {
    refetch({
      currentUom: "MT",
      quantity: rowData?.outboundRecords?.qtyAllocated,
      targetUom: watchUOM ?? "MT",
    });
    setAvailableQty(
      availableQtyResponse?.bioLcCoproEuMassBalanceGetQuantityUom
        ?.convertedQuantity,
    );
  }, [
    watchUOM,
    refetch,
    rowData?.outboundRecords?.qtyAllocated,
    availableQtyResponse,
  ]);

  return (
    <div className="grid sm:grid-cols-1 text-left md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-x-4 gap-y-4 ">
      <FormField
        label="Type of Product"
        errorMessage={errors.productType?.message}
      >
        <Controller
          name="productType"
          defaultValue={data?.productType || undefined}
          render={({field}) => (
            <Input
              {...field}
              type="select"
              id="productType"
              data-test="productType"
              invalid={!!errors.productType}
            >
              {watchTemplateType === "ISCC SD 2.6" ? (
                <option key="Raw material" value="Raw material">
                  Raw material
                </option>
              ) : (
                <>
                  <option key="none" value="">
                    --Select an option--
                  </option>
                  {productList?.map((product) => (
                    <option
                      key={product.material_long_name}
                      value={product.material_long_name}
                    >
                      {product.material_long_name}
                    </option>
                  ))}
                </>
              )}
            </Input>
          )}
        />
      </FormField>

      <FormField label="Unit of measure" errorMessage={errors.uom?.message}>
        <Controller
          name="uom"
          defaultValue={data?.uom || undefined}
          render={({field}) => (
            <Input
              {...field}
              type="select"
              id="uom"
              data-test="uom"
              invalid={!!errors.uom}
            >
              <option key="none" value="">
                --Select an option--
              </option>
              <option value="MT">t (metric tons)</option>
              <option value="M3">meter cube</option>
            </Input>
          )}
        />
      </FormField>
      <FormField label="Quantity" errorMessage={errors.quantity?.message}>
        <Controller
          name="quantity"
          defaultValue={data?.quantity || undefined}
          render={({field}) => (
            <Input
              {...field}
              type="number"
              id="quantity"
              data-test="quantity"
              invalid={!!errors.quantity}
            />
          )}
        />

        {availableQty !== "" && watchUOM && (
          <Label className="text-sm font-light text-gray-600">{`You have ${formatNumber(
            parseFloat(availableQty),
            decimalFormat,
            2,
          )} ${
            watchUOM === "M3" ? "meter cube" : "t (metric tons)"
          } available`}</Label>
        )}
      </FormField>
      <FormField
        label="Fossil fuel comparator"
        errorMessage={errors.fossilFuelComparator?.message}
      >
        <Controller
          name="fossilFuelComparator"
          defaultValue={data?.fossilFuelComparator || undefined}
          render={({field}) => (
            <Input
              {...field}
              type="select"
              id="fossilFuelComparator"
              data-test="fossilFuelComparator"
              invalid={!!errors.fossilFuelComparator}
            >
              <option key="none" value="">
                --Select an option--
              </option>
              {productBaselineEmissions?.bioLcCoproConfigCenterProductBaselineEmissionDetails?.Records?.map(
                (product) => (
                  <option
                    key={product.material_code}
                    value={product.material_code}
                  >
                    {product.material_short_name}
                  </option>
                ),
              )}
            </Input>
          )}
        />
      </FormField>
    </div>
  );
};

ProductDetails.propTypes = {
  data: PropTypes.object,
  rowData: PropTypes.object,
};

export default ProductDetails;
