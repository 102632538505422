import PropTypes from "prop-types";
import {Add16} from "@bphxd/ds-core-react/lib/icons";
import {useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {FormGroup, Label, Input, FormFeedback, Button} from "reactstrap";
import {useEffect, useState} from "react";
import CheckboxFlag from "modules/common/BinaryFlagNullable";
import Datepicker from "../Datepicker";
import GHGSplit from "./GHGSplit";
import {DDV, TDV, parseGhgValues} from "./utils";

const GHGEmissionInfo = ({countries, status}) => {
  const [isCheckedYes, setIsCheckedYes] = useState(null);

  const {
    register,
    formState: {errors},
    setError,
    control,
    watch,
    clearErrors,
    setValue,
    getValues,
  } = useFormContext();
  const {fields, append, remove} = useFieldArray({
    control,
    name: "splitDetails",
    mode: "onBlur",
    rules: {
      validate: (data) => {
        data.forEach((item, index) => {
          if (
            parseFloat(item.ghgEccr ?? "0") === 0 &&
            parseFloat(item.ghgEccs ?? "0") === 0 &&
            parseFloat(item.ghgEec ?? "0") === 0 &&
            parseFloat(item.ghgEee ?? "0") === 0 &&
            parseFloat(item.ghgEl ?? "0") === 0 &&
            parseFloat(item.ghgEp ?? "0") === 0 &&
            parseFloat(item.ghgEsca ?? "0") === 0 &&
            parseFloat(item.ghgEtd ?? "0") === 0 &&
            parseFloat(item.ghgEu ?? "0") === 0 &&
            (item.ghgTotal !== TDV ||
              item.ghgTotal !== DDV ||
              item.ghgEtd !== DDV)
          ) {
            setError(`splitDetails.${index}.ghgEec`, {
              type: "value",
              message: "At least one GHG emission must be greater than 0",
            });
          }
        });
      },
    },
  });
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };
  const createAdditionalSplit = () => {
    clearErrors(["physicalReceiptDate", "rawMaterialOriginCountryName"]);
    setIsCheckedYes(null);
    if (fields.length === 0) {
      const values = getValues();
      append({
        ghgEec: values.ghgEec ?? 0.0,
        ghgEl: values.ghgEl ?? 0.0,
        ghgEp: values.ghgEp ?? 0.0,
        ghgEtd: values.ghgEtd ?? 0.0,
        ghgEu: values.ghgEu ?? 0.0,
        ghgEsca: values.ghgEsca ?? 0.0,
        ghgEccs: values.ghgEccs ?? 0.0,
        ghgEccr: values.ghgEccr ?? 0.0,
        ghgTotal: values.ghgTotal ?? 0.0,
        physicalReceiptDate: values.physicalReceiptDate ?? "",
        originCountryName: values.rawMaterialOriginCountryName ?? "",
        defaultValueAppliedFlag: values.defaultValueAppliedFlag ?? null,
      });
    }
    append({
      ghgEec: 0.0,
      ghgEl: 0.0,
      ghgEp: 0.0,
      ghgEtd: 0.0,
      ghgEu: 0.0,
      ghgEsca: 0.0,
      ghgEccs: 0.0,
      ghgEccr: 0.0,
      ghgTotal: 0.0,
      physicalReceiptDate: "",
      originCountryName: "",
      defaultValueAppliedFlag: null,
    });
  };
  const splitDetailsField = watch("splitDetails");
  const changes = useWatch({
    name: [
      "ghgEec",
      "ghgEl",
      "ghgEp",
      "ghgEtd",
      "ghgEu",
      "ghgEsca",
      "ghgEccs",
      "ghgEccr",
      "defaultValueAppliedFlag",
    ],
  });

  const numberInputPattern = {
    pattern: {
      value: /^(\d{1,10}(\.\d{1,5})?)$/,
      message:
        "Value must be number with (max 10 digits before and 5 digits after decimal point)",
    },
  };

  useEffect(() => {
    const ghgTotal = getValues("ghgTotal");
    const flagValue = getValues("defaultValueAppliedFlag");
    const parsedChanges = parseGhgValues(changes);
    const total =
      parsedChanges[0] +
      parsedChanges[1] +
      parsedChanges[2] +
      parsedChanges[3] +
      parsedChanges[4] -
      (parsedChanges[5] + parsedChanges[6] + parsedChanges[7]);
    if (ghgTotal !== TDV && ghgTotal !== DDV) {
      setValue("ghgTotal", total.toFixed(2));
      setIsCheckedYes(false);
    }
    if (flagValue) {
      setValue("ghgTotal", TDV);
      setIsCheckedYes(true);
    }
    if (!flagValue) {
      setValue("ghgTotal", total.toFixed(2));
      setIsCheckedYes(false);
    }
  }, [changes, setValue, getValues, isCheckedYes]);
  const isDisabled = status === "completed" || status === "additional_info";

  return (
    <div className="flex flex-col gap-4 pb-6">
      {fields?.length === 0 && (
        <>
          <FormGroup>
            <Label for="defaultValueAppliedFlag" className="fw-normal">
              Total default value according to RED II applied
            </Label>
            <CheckboxFlag
              id="defaultValueAppliedFlag"
              name="defaultValueAppliedFlag"
              control={control}
              error={errors.defaultValueAppliedFlag}
              disabled={isDisabled}
            />
          </FormGroup>
          {!isCheckedYes && (
            <div className="flex flex-row justify-between items-start gap-3 pb-8">
              <FormGroup>
                <Label for="ghgEec" className="fw-normal">
                  Eec
                </Label>
                <Input
                  type="number"
                  id="ghgEec"
                  data-test="ghgEec"
                  {...computeProps("ghgEec", numberInputPattern)}
                  invalid={!!errors.ghgEec}
                  disabled={isDisabled}
                />
                {errors.ghgEec && (
                  <FormFeedback>{errors.ghgEec?.message}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="ghgEl" className="fw-normal">
                  El
                </Label>
                <Input
                  type="number"
                  id="ghgEl"
                  data-test="ghgEl"
                  {...computeProps("ghgEl", numberInputPattern)}
                  invalid={!!errors.ghgEl}
                  disabled={isDisabled}
                />
                {errors.ghgEl && (
                  <FormFeedback>{errors.ghgEl?.message}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="ghgEp" className="fw-normal">
                  Ep
                </Label>
                <Input
                  type="number"
                  id="ghgEp"
                  data-test="ghgEp"
                  {...computeProps("ghgEp", numberInputPattern)}
                  invalid={!!errors.ghgEp}
                  disabled={isDisabled}
                />
                {errors.ghgEp && (
                  <FormFeedback>{errors.ghgEp?.message}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="ghgEtd" className="fw-normal">
                  Etd
                </Label>
                <Input
                  type="text"
                  id="ghgEtd"
                  data-test="ghgEtd"
                  {...computeProps("ghgEtd", {
                    pattern: {
                      value: /^(DDV$)|^(\d{1,10}(\.\d{1,5})?)$/,
                      message:
                        "Value must be number with (max 10 digits before and 5 digits after decimal point) or DDV",
                    },
                  })}
                  invalid={!!errors.ghgEtd}
                  disabled={isDisabled}
                />
                {errors.ghgEtd && (
                  <FormFeedback>{errors.ghgEtd?.message}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="ghgEu" className="fw-normal">
                  Eu
                </Label>
                <Input
                  type="number"
                  id="ghgEu"
                  data-test="ghgEu"
                  {...computeProps("ghgEu", numberInputPattern)}
                  invalid={!!errors.ghgEu}
                  disabled={isDisabled}
                />
                {errors.ghgEu && (
                  <FormFeedback>{errors.ghgEu?.message}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="ghgEsca" className="fw-normal">
                  Esca
                </Label>
                <Input
                  type="number"
                  id="ghgEsca"
                  data-test="ghgEsca"
                  {...computeProps("ghgEsca", numberInputPattern)}
                  invalid={!!errors.ghgEsca}
                  disabled={isDisabled}
                />
                {errors.ghgEsca && (
                  <FormFeedback>{errors.ghgEsca?.message}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="ghgEccs" className="fw-normal">
                  Eccs
                </Label>
                <Input
                  type="number"
                  id="ghgEccs"
                  data-test="ghgEccs"
                  {...computeProps("ghgEccs", numberInputPattern)}
                  invalid={!!errors.ghgEccs}
                  disabled={isDisabled}
                />
                {errors.ghgEccs && (
                  <FormFeedback>{errors.ghgEccs?.message}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="ghgEccr" className="fw-normal">
                  Eccr
                </Label>
                <Input
                  type="number"
                  id="ghgEccr"
                  data-test="ghgEccr"
                  {...computeProps("ghgEccr", numberInputPattern)}
                  invalid={!!errors.ghgEccr}
                  disabled={isDisabled}
                />
                {errors.ghgEccr && (
                  <FormFeedback>{errors.ghgEccr?.message}</FormFeedback>
                )}
              </FormGroup>
            </div>
          )}
          <FormGroup>
            <Label for="ghgTotal" className="fw-normal">
              GHG (total) kgCO2/dry-t
            </Label>
            <Input
              type="text"
              id="ghgTotal"
              data-test="ghgTotal"
              {...computeProps("ghgTotal", {
                pattern: {
                  value: /^(TDV$)|^(DDV$)|^(\d{1,10}(\.\d{1,5})?)$/,
                  message:
                    "Value must be number with (max 10 digits before and 5 digits after decimal point) or TDV or DDV",
                },
              })}
              invalid={!!errors.ghgTotal}
              disabled={isDisabled || isCheckedYes}
            />
            {errors.ghgTotal && (
              <FormFeedback>{errors.ghgTotal?.message}</FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="physicalReceiptDate" className="fw-normal">
              Date of physical receipt
            </Label>
            <Datepicker
              id="physicalReceiptDate"
              name="physicalReceiptDate"
              control={control}
              error={errors.physicalReceiptDate}
              placeholder="Please add date if available"
              disabled={isDisabled || (splitDetailsField?.length ?? 0) > 0}
            />
          </FormGroup>
        </>
      )}
      {fields.map((field, index) => (
        <div key={field.id}>
          <GHGSplit
            field={field}
            index={index}
            countries={countries}
            onRemoveSplit={remove}
            status={status}
            isCheckedYes={isCheckedYes}
          />
          <hr className="border-1 mt-4"></hr>
        </div>
      ))}
      {!isDisabled && fields?.length === 0 && <hr className="border-1"></hr>}
      {!isDisabled && (
        <div>
          <Button
            size="xs"
            onClick={createAdditionalSplit}
            color="standard-primary"
            className="primary-btn rounded-0"
          >
            Create split
            <Add16 className="btn-icon-suffix" />
          </Button>
        </div>
      )}
      <FormGroup className="flex flex-col">
        <Label for="diseggregatedDefaultValueOilFlag" className="fw-normal">
          Disaggregated default value for oil extraction only is applied
        </Label>
        <FormGroup check inline className="form-check-alt form-check-lg !mb-0">
          <Input
            type="checkbox"
            id="diseggregatedDefaultValueOilFlag"
            data-test="diseggregatedDefaultValueOilFlag"
            {...computeProps("diseggregatedDefaultValueOilFlag")}
            invalid={!!errors.diseggregatedDefaultValueOilFlag}
            disabled={isDisabled}
          />
          <Label for="diseggregatedDefaultValueOilFlag">Yes</Label>
        </FormGroup>
      </FormGroup>
      <FormGroup className="flex flex-col">
        <Label for="diseggregatedDefaultValueSoiln2oFlag" className="fw-normal">
          Disaggregated default value for soil N2O emissions is applied
        </Label>
        <FormGroup check inline className="form-check-alt form-check-lg !mb-0">
          <Input
            type="checkbox"
            id="diseggregatedDefaultValueSoiln2oFlag"
            data-test="diseggregatedDefaultValueSoiln2oFlag"
            {...computeProps("diseggregatedDefaultValueSoiln2oFlag")}
            invalid={!!errors.diseggregatedDefaultValueSoiln2oFlag}
            disabled={isDisabled}
          />
          <Label for="diseggregatedDefaultValueSoiln2oFlag">Yes</Label>
        </FormGroup>
      </FormGroup>
      <FormGroup>
        <Label for="bonusCo245gManureUsedFlag" className="fw-normal">
          Esca: Bonus of 45g CO2eq/MJ manure in the case animal manure is used
          as substrate for the production of biogas and biomethane
        </Label>
        <CheckboxFlag
          id="bonusCo245gManureUsedFlag"
          name="bonusCo245gManureUsedFlag"
          control={control}
          error={errors.bonusCo245gManureUsedFlag}
          disabled={isDisabled}
        />
      </FormGroup>
      <FormGroup>
        <Label for="bonusCo229gManureUsedFlag" className="fw-normal">
          El: Bonus of 29 g CO2eq/MJ biofuel/bioliquid/biomass fuel if biomass
          is obtained from restored degraded land
        </Label>
        <CheckboxFlag
          id="bonusCo229gManureUsedFlag"
          name="bonusCo229gManureUsedFlag"
          control={control}
          error={errors.bonusCo229gManureUsedFlag}
          disabled={isDisabled}
        />
      </FormGroup>
      <FormGroup>
        <Label for="supplychainIncentiveReceivedFlag" className="fw-normal">
          For biogas supply chains: Were incentives/subsidies received for the
          production of the biogas?
        </Label>
        <CheckboxFlag
          id="supplychainIncentiveReceivedFlag"
          name="supplychainIncentiveReceivedFlag"
          control={control}
          error={errors.supplychainIncentiveReceivedFlag}
          disabled={isDisabled}
        />
      </FormGroup>
      <FormGroup>
        <Label for="supplychainIncentiveReceivedName" className="fw-normal">
          If yes please specify
        </Label>
        <Input
          type="text"
          id="supplychainIncentiveReceivedName"
          data-test="supplychainIncentiveReceivedName"
          {...computeProps("supplychainIncentiveReceivedName")}
          invalid={!!errors.supplychainIncentiveReceivedName}
          disabled={isDisabled}
          maxLength={100}
        />
        {errors.supplychainIncentiveReceivedName && (
          <FormFeedback>
            {errors.supplychainIncentiveReceivedName.message}
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="nuts2Region" className="fw-normal">
          If applicable, please specify the NUTS2 Region
        </Label>
        <Input
          type="text"
          id="nuts2Region"
          data-test="nuts2Region"
          {...computeProps("nuts2Region")}
          invalid={!!errors.nuts2Region}
          disabled={isDisabled}
          maxLength={100}
        />
        {errors.nuts2Region && (
          <FormFeedback>{errors.nuts2Region.message}</FormFeedback>
        )}
      </FormGroup>
    </div>
  );
};
GHGEmissionInfo.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  status: PropTypes.string.isRequired,
};
export default GHGEmissionInfo;
