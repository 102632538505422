import moment from "moment";
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import {useLazyQuery} from "@apollo/client";
import {COPRO_US_SHIPMENTS_API_CHECK_SHIPMENT_ID_EXISTS} from "graphql/coprocessing/shipments";
import {PI_Vision_URL} from "modules/co-processing/constants/coProcessing";
import {
  dtnShipmentKeys,
  SHIPMENT_TYPE,
} from "modules/co-processing/constants/shipments";
import Form from "../Form";

const truckRackFieldOrder = [
  "SHIPMENT_ID",
  "SHIPMENT_DATE",
  "PI_VISION", // field is for display only
  "SHIPMENT_START_DATE",
  "SHIPMENT_END_DATE",
  "ESTIMATED_VOLUME",
  "SOURCE_TANK",
];

const TruckRackDetails = () => {
  const {watch, setError, clearErrors} = useFormContext();
  const startTime = watch(dtnShipmentKeys.SHIPMENT_START_DATE);
  const endTime = watch(dtnShipmentKeys.SHIPMENT_END_DATE);
  const shipmentId = watch(dtnShipmentKeys.SHIPMENT_ID);

  const [checkShipmentIdExists] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_CHECK_SHIPMENT_ID_EXISTS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const error = data?.bioLcCoproUsShipmentsApi?.error;
        if (error) {
          setError(dtnShipmentKeys.SHIPMENT_ID, {
            type: "custom",
            message: error,
          });
        } else {
          clearErrors(dtnShipmentKeys.SHIPMENT_ID);
        }
      },
    },
  );

  useEffect(() => {
    // Validate only if both startTime and endTime have been provided by the user
    if (startTime && endTime) {
      const startMoment = moment(startTime, "HH:mm");
      const endMoment = moment(endTime, "HH:mm");

      if (startMoment.isSameOrAfter(endMoment)) {
        setError(dtnShipmentKeys.SHIPMENT_START_DATE, {
          type: "custom",
          message: "Start time must be earlier than end time.",
        });
        setError(dtnShipmentKeys.SHIPMENT_END_DATE, {
          type: "custom",
          message: "End time must be after start time.",
        });
      } else {
        clearErrors([
          dtnShipmentKeys.SHIPMENT_START_DATE,
          dtnShipmentKeys.SHIPMENT_END_DATE,
        ]);
      }
    }
  }, [startTime, endTime, setError, clearErrors]);

  return (
    <>
      <h5 className="pb-4">
        Give this truck rack shipment a unique ID and enter the shipping
        details.
      </h5>

      <div className="divide-y-2">
        <div className="row g-5 mb-8">
          {truckRackFieldOrder.map((identifier) => {
            if (identifier === "ESTIMATED_VOLUME") {
              return (
                <Form.EstVolumeDisplayField
                  key={identifier}
                  startTime={startTime}
                  endTime={endTime}
                />
              );
            }
            if (identifier === "PI_VISION") {
              return (
                <div
                  key={identifier}
                  className="h-[521px]"
                  data-test="pi-vision"
                >
                  <iframe
                    className="w-full"
                    src={PI_Vision_URL}
                    title="PIVisionSRC"
                    width="100%"
                    height="100%"
                  />
                </div>
              );
            }

            if (identifier === "SHIPMENT_ID") {
              return (
                <Form.Field
                  key={identifier}
                  fieldName={identifier}
                  validate={() =>
                    checkShipmentIdExists({variables: {shipmentId}})
                  }
                />
              );
            }

            return <Form.Field key={identifier} fieldName={identifier} />;
          })}
        </div>

        {/* --- Optional form fields --- */}
        <div className="row g-4">
          <Form.Field fieldName="NOTES" />
        </div>
      </div>
    </>
  );
};

const BackToUnitDetails = () => {
  return (
    <div className="col-12">
      <h5 className="display-6">Enter the back to unit details.</h5>
    </div>
  );
};

const EnterDetails = ({shipmentType}) => {
  return (
    <div className="row pt-4" data-test="enter-shipment-deets">
      {shipmentType === SHIPMENT_TYPE.TRUCK_RACK ? (
        <TruckRackDetails />
      ) : (
        <BackToUnitDetails />
      )}
    </div>
  );
};

EnterDetails.propTypes = {
  shipmentType: PropTypes.string,
};

export default EnterDetails;
