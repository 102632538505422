import {CardValueGroup, CardValue} from "modules/common/Card";
import DashboardLayout from "modules/common/DashboardLayout";
import {ReactComponent as WatchIcon} from "assets/images/svg/watch.svg";
import {ReactComponent as FileOffice} from "assets/images/svg/file-office.svg";

import {CEEMAS_MOT_METRICS} from "graphql/product-metrics";
import {useQuery} from "@apollo/client";
import tabsData from "./tabsData";

const CeemasMOTValidation = () => {
  const {data: response} = useQuery(CEEMAS_MOT_METRICS);

  const data = response?.getCeemasProductMetrics.data;

  return (
    <DashboardLayout pageTitle="Reporting" tabsData={tabsData}>
      <CardValueGroup>
        <CardValue
          icon={WatchIcon}
          value={data?.daysSaved}
          subHeading="Total days saved"
          data-test="ceemas-saved-days"
        />
        <CardValue
          icon={FileOffice}
          value={data?.automatedBalanceGroup}
          subHeading="Number of automated Balance Group Comparisons"
          data-test="ceemas-automated-balance-group"
        />
        <CardValue
          icon={FileOffice}
          value={data?.unreconciledBalanceGroup}
          subHeading="Number of identified unreconciled balance groups"
          data-test="ceemas-unreconciled-balance-group"
        />
      </CardValueGroup>
    </DashboardLayout>
  );
};

export default CeemasMOTValidation;
