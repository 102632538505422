import tw from "twin.macro";
import {useState, useMemo, useCallback} from "react";
import PropTypes from "prop-types";
import {Button, Label, Input} from "reactstrap";
import {NavLink, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {Check24} from "@bphxd/ds-core-react/lib/icons";
import {Datepicker} from "@bphxd/ds-core-react";
import {useQuery, useLazyQuery} from "@apollo/client";
import {COPRO_US_COFA_API} from "graphql/coprocessing/receipts";
import {
  RECEIPT_INPUTS,
  receiptFormMap,
  OPERATIONS,
} from "../constants/receipts";
import {
  COPRO_URL,
  BUTTONS,
  ERRORS,
  SUCCESS_SAVE,
} from "../constants/coProcessing";
import {formatDatePickerValue} from "../helpers/dateHelper";
import LoadingSpinner from "./LoadingSpinner";
import FormKeyPoints from "./FormKeyPoints";

import {
  setInitialData,
  setInitialErrors,
  getErrorState,
  getUpdates,
  getPDFErrors,
} from "../helpers/formBuilder";

const FormButtons = tw.div`py-5 flex justify-end`;
const MissingReceiptsForm = ({id}) => {
  const [formData, setFormData] = useState([]);
  const [pdfErrors, setPdfErrors] = useState([]);
  const [errors, setErrors] = useState([]);
  const [errorState, setErrorState] = useState(true);

  const navigate = useNavigate();

  const {loading, data} = useQuery(COPRO_US_COFA_API, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      op: OPERATIONS.GET,
      cofa_id: id,
    },
    onCompleted: () => {
      const response = data?.bioLcCofaUsDetailsApi?.body?.data;
      if (response) {
        setFormData(setInitialData(response, RECEIPT_INPUTS));
        setErrors(setInitialErrors(response, RECEIPT_INPUTS));
        setPdfErrors(getPDFErrors(response, RECEIPT_INPUTS));
      } else {
        toast.error(ERRORS.FAILED_LOAD);
        navigate(-1);
      }
    },
  });

  const handleChange = useCallback(
    (event) => {
      const {name, value} = event.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      setErrors((prevData) => ({
        ...prevData,
        [name]: !value,
      }));
      setErrorState(getErrorState(errors, name, value));
    },
    [errors],
  );

  const options = useMemo(
    () => ({
      defaultDate: formData.tested_datetime,
      onChange: (selectedDate) =>
        handleChange({
          target: {
            value: formatDatePickerValue(selectedDate[0]),
            name: "tested_datetime",
          },
        }),
    }),
    [formData.tested_datetime, handleChange],
  );

  const [submitQuery, {data: dataRes, errors: errorsRes}] = useLazyQuery(
    COPRO_US_COFA_API,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: () => {
        if (errorsRes !== undefined || errorsRes?.length < 0) {
          toast.error(ERRORS.FAILED_UPDATED);
        } else if (dataRes != null) {
          toast.success(SUCCESS_SAVE);
          navigate(-1);
        }
      },
    },
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    const updatedValues = getUpdates(pdfErrors, formData);
    submitQuery({
      variables: {
        op: OPERATIONS.UPDATE,
        cofa_id: id,
        updates: updatedValues,
      },
    });
  };

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <FormKeyPoints
            errors={pdfErrors}
            state={errorState}
            map={receiptFormMap}
          />
          <div data-test="copro-missing-receipts-form" className="pt-8">
            {RECEIPT_INPUTS.map((input) => {
              return input.key === "tested_datetime" ? (
                <div
                  data-test="copro-form-label"
                  key={`${input.key}-field`}
                  className="mb-4"
                >
                  <Label for={input.key}>{input.label}</Label>
                  <Datepicker
                    data-test="copro-form-datepicker"
                    name={input.key}
                    options={options}
                    invalid={errors[input.key]}
                    required
                  />
                </div>
              ) : (
                <div key={`${input.key}-field`} className="mb-4">
                  <Label data-test="copro-form-label" for={input.key}>
                    {input.label}
                  </Label>
                  <Input
                    data-test="copro-form-input"
                    name={input.key}
                    id={input.key}
                    type={input.data_type}
                    value={formData[input.key]}
                    placeholder={input.placeholder}
                    onChange={handleChange}
                    invalid={errors[input.key]}
                    required
                  />
                </div>
              );
            })}
          </div>
          <FormButtons>
            <Button
              data-test="copro-cancel-btn"
              color="standard-quartenary rounded-3"
            >
              <NavLink data-test="copro-base-link" to={COPRO_URL}>
                {BUTTONS.CANCEL}
              </NavLink>
            </Button>
            <Button
              className="pb-2 rounded-3 bg-dark disabled:text-white"
              data-test="copro-save-btn"
              color=""
              onClick={handleSubmit}
              disabled={errorState}
            >
              <Check24 color="neutral" className="btn-icon-prefix" />
              {BUTTONS.SAVE}
            </Button>
          </FormButtons>
        </div>
      )}
    </div>
  );
};

MissingReceiptsForm.propTypes = {
  id: PropTypes.string,
};
export default MissingReceiptsForm;
