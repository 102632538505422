import {Kebab24} from "@bphxd/ds-core-react/lib/icons";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {Link} from "react-router-dom";
import moment from "moment";
import {formatNumber} from "providers/userSettings";

const showStatus = (status, saleStatus) => {
  if (saleStatus === "VOIDED") {
    return "Assignment removed";
  }
  switch (status) {
    case "ASSIGNED AND SOLD":
      return "Assigned to sale";
    case "ASSIGNED AND SENT":
      return "Assigned & sent";
    default:
      return "Unknown state";
  }
};

const getColumns = (dateFormat, decimalFormat, handleSendCertificate) => [
  {
    header: "Status",
    key: "productStatus",
    accessorKey: "productStatus",
    visible: true,
    cell: ({
      row: {
        original: {productStatus, saleStatus},
      },
    }) => showStatus(productStatus, saleStatus),
  },
  {
    header: "Date of issuance",
    key: "issueDate",
    accessorKey: "issueDate",
    cell: (cell) => {
      return moment(cell.getValue()).format(dateFormat);
    },
    visible: true,
  },
  {
    header: "Certificate type",
    key: "certificateType",
    accessorKey: "certificateType",
    visible: true,
  },
  {
    header: "Certificate ID",
    accessorKey: "certificateNumber",
    key: "certificateNumber",
    visible: true,
    cell: (cell) => {
      return (
        <Link
          to="/copro-spain/document-manager/outgoing-documents/view"
          state={{
            certificateNumber: cell.getValue(),
            certificatePdfUrl: cell.row?.original?.certificatePdfUrl,
          }}
          className="link-dark"
        >
          <u>{cell.getValue()}</u>
        </Link>
      );
    },
  },
  {
    header: "Quantity (t)",
    accessorKey: "totalDeliveryQty",
    key: "totalDeliveryQty",
    sortingFn: "alphanumeric",
    cell: (cell) => {
      return formatNumber(cell.getValue(), decimalFormat, 2);
    },
    visible: true,
  },
  {
    header: "Recipient",
    accessorKey: "recipientName",
    key: "recipientName",
    size: 200,
    visible: true,
  },
  {
    header: "Product",
    accessorKey: "productType",
    key: "productType",
    visible: true,
  },
  {
    header: "Mass Balance",
    accessorKey: "mbLocation",
    cell: ({
      row: {
        original: {
          fromLocationCode,
          locationName,
          materialCode,
          materialName,
          saleYear,
          saleQtr,
        },
      },
    }) => (
      <Link
        to={`/copro-spain/mass-balance/${fromLocationCode}/${locationName}/${materialCode}/${saleQtr}/${saleYear}`}
        className="link-dark"
      >
        <u>{`${materialName} ${locationName} ${saleQtr} ${saleYear}`}</u>
      </Link>
    ),
    key: "mbLocation",
    size: 110,
    visible: true,
  },
  {
    header: "",
    accessorKey: "actions",
    key: "actions",
    size: 25,
    visible: true,
    enableSorting: false,
    cell: ({
      row: {
        original: {
          certificateNumber,
          certificatePdfUrl,
          outboundEuQtyId,
          saleStatus,
        },
      },
    }) => {
      return (
        <UncontrolledDropdown direction="up">
          <DropdownToggle color="standard-quartenary" className="!px-0">
            <Kebab24></Kebab24>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem>
              <Link
                to="/copro-spain/document-manager/outgoing-documents/view"
                state={{
                  certificateNumber,
                  certificatePdfUrl,
                }}
                className="link-dark"
              >
                <u>View certificate</u>
              </Link>
            </DropdownItem>
            <DropdownItem
              onClick={() => handleSendCertificate(outboundEuQtyId)}
              disabled={saleStatus === "VOIDED"}
            >
              Send certificate
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    },
  },
];

export default getColumns;
