import {gql} from "@apollo/client";

export const INBOUND_RECORDS = gql`
  query InboundRecords {
    bioLcCoproEuGetInboundRecords {
      certificateNumber
      feedstockQty
      ghgTotal
      inboundDocId
      inboundDocStatusEuId
      rawMaterialType
      recipientName
      recipientReceiptAddress
      sdNumber
      supplierName
      inboundDocStatusEuId
      mbLocationCode
      mbLocation
      mbCycle
      materialCode
      invoiceProviderName
      physicalReceiptDate
      vesselAtHuelva
      stateMgmt
      userAck
      splitDetails {
        feedstockQty
        physicalReceiptDate
        originCountryName
        ghgTotal
        mbCycle
      }
    }
    bioLcCoproConfigCenterLocationDetails {
      Records {
        location_id
        location_code
        location_name
      }
    }
  }
`;

export default {
  INBOUND_RECORDS,
};
