import React, {useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
  Spinner,
  FormGroup,
  Label,
  Input,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Button,
} from "reactstrap";
import {toast} from "react-toastify";
import {Check24} from "@bphxd/ds-core-react/lib/icons";
import SingleDocumentViewer from "modules/common/SingleDocumentViewer";
import {FormProvider, useForm, Controller} from "react-hook-form";
import Form from "modules/common/FormNew";
import CheckboxFlag from "modules/common/BinaryFlag";
import {SAF_GET_OUTGOING_DOCUMENTS_SINGLE} from "graphql/saf/OutgoingDocuments";
import {SAF_OUTGOING_DRAFT_TO_GENERATED_MUTATION} from "graphql/saf/MassBalance";
import {useMutation, useQuery} from "@apollo/client";
import Layout from "../components/Layout";
import Datepicker from "../components/Datepicker";
import "./styles.css";

const defaultValues = {
  sd_number: "",
  date_of_issuance: "",
  supplier_name: "",
  supplier_address: "",
  certification_system: "",
  certificate_number: "",
  recipient_name: "",
  recipient_address: "",
  contract_number: "",
  supplier_dispatch_address: "",
  recipient_receipt_address: "",
  material_dispatch_datez: "",
  bio_product_producer: false,
  producer_installation_date: "",
  bio_product_user: false,
  user_installation_date: "",
  product_type: "",
  raw_material_type: "",
  additional_info: "",
  raw_material_origin_country_name: "",
  product_qty: "",
  product_qty_uom: "",
  product_energy_content: "",
  eu_red_compliant_flag: false,
  iscc_compliant_flag: false,
  chain_of_custody_option: "",
  material_sustainability_criteria_flag: false,
  agricultural_biomass_intermediate_crop_flag: false,
  agricultural_biomass_low_risk_flag: false,
  raw_material_waste_or_residue_flag: false,
  nuts2_region: "",
  default_value_applied_flag: false,
  ghg_eec: 0,
  ghg_el: 0,
  ghg_ep: 0,
  ghg_etd: 0,
  ghg_eu: 0,
  ghg_esca: 0,
  ghg_eccs: 0,
  ghg_eccr: 0,
  ghg_total: "",
  allocated_heat: "",
  allocated_electricity: "",
  ghg_emission_percentage_in: "",
  recipient_receipt_address_same_flag: false,
  supplier_dispatch_address_same_flag: false,
};

const data = {
  sd_number: "1234",
  date_of_issuance: "26/02/2024",
  supplier_name: "Shubham",
  supplier_address: "CHB",
  certification_system: "ISCC-EU",
  certificate_number: "12345555",
  recipient_name: "Khatri",
  recipient_address: "KH ADD",
  contract_number: "2324234",
  supplier_dispatch_address: "addd suppp",
  recipient_receipt_address: "recp addd",
  material_dispatch_datez: "23/02/2024",
  bio_product_producer: true,
  producer_installation_date: "23/02/2023",
  bio_product_user: false,
  user_installation_date: "",
  product_type: "",
  raw_material_type: "",
  additional_info: "infooo",
  raw_material_origin_country_name: "India",
  product_qty: "12",
  product_qty_uom: "M3",
  product_energy_content: "9",
  eu_red_compliant_flag: true,
  iscc_compliant_flag: false,
  chain_of_custody_option: "",
  material_sustainability_criteria_flag: true,
  agricultural_biomass_intermediate_crop_flag: true,
  agricultural_biomass_low_risk_flag: true,
  raw_material_waste_or_residue_flag: true,
  nuts2_region: "nuts",
  default_value_applied_flag: true,
  ghg_eec: 1,
  ghg_el: 2,
  ghg_ep: 3,
  ghg_etd: 4,
  ghg_eu: 5,
  ghg_esca: 6,
  ghg_eccs: 7,
  ghg_eccr: 8,
  ghg_total: "12",
  allocated_heat: "1",
  allocated_electricity: "12",
  ghg_emission_percentage_in: "90",
  recipient_receipt_address_same_flag: false,
  supplier_dispatch_address_same_flag: false,
};

const MassBalanceDetailViewPage = () => {
  const [open, setOpen] = useState([]);

  const toggle = (id) => {
    if (open.includes(id)) {
      setOpen(open.filter((item) => item !== id));
    } else {
      setOpen([...open, id]);
    }
  };

  const navigate = useNavigate();

  const {docId} = useParams();

  const {data, loading} = useQuery(SAF_GET_OUTGOING_DOCUMENTS_SINGLE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      certificate_outbound_eu_id: docId,
    },
  });

  const document = data?.bioLcSafGetOutgoingDocs?.documents?.[0];

  const values = useMemo(() => {
    if (document) {
      const {
        sd_number = "",
        date_of_issuance = "",
        supplier_name = "",
        supplier_address = "",
        certification_system = "",
        certificate_number = "",
        recipient_name = "",
        recipient_address = "",
        contract_number = "",
        supplier_dispatch_address = "",
        recipient_receipt_address = "",
        material_dispatch_datez = "",
        bio_product_producer = false,
        producer_installation_date = "",
        bio_product_user = false,
        user_installation_date = "",
        product_type = "",
        raw_material_type = "",
        additional_info = "",
        raw_material_origin_country_name = "",
        product_qty = "",
        product_qty_uom = "",
        product_energy_content = "",
        eu_red_compliant_flag = false,
        iscc_compliant_flag = false,
        chain_of_custody_option = "",
        material_sustainability_criteria_flag = false,
        agricultural_biomass_intermediate_crop_flag = false,
        agricultural_biomass_low_risk_flag = false,
        raw_material_waste_or_residue_flag = false,
        nuts2_region = "",
        default_value_applied_flag = "",
        ghg_eec = 0,
        ghg_el = 0,
        ghg_ep = 0,
        ghg_etd = 0,
        ghg_eu = 0,
        ghg_esca = 0,
        ghg_eccs = 0,
        ghg_eccr = 0,
        ghg_total = "",
        allocated_heat = "",
        allocated_electricity = "",
        ghg_emission_percentage_in = "",
        recipient_receipt_address_same_flag = false,
        supplier_dispatch_address_same_flag = false,
      } = document;
      const props = {
        sd_number,
        date_of_issuance,
        supplier_name,
        supplier_address,
        certification_system,
        certificate_number,
        recipient_name,
        recipient_address,
        contract_number,
        supplier_dispatch_address,
        recipient_receipt_address,
        material_dispatch_datez,
        bio_product_producer,
        producer_installation_date,
        bio_product_user,
        user_installation_date,
        product_type,
        raw_material_type,
        additional_info,
        raw_material_origin_country_name,
        product_qty,
        product_qty_uom,
        product_energy_content,
        eu_red_compliant_flag,
        iscc_compliant_flag,
        chain_of_custody_option,
        material_sustainability_criteria_flag,
        agricultural_biomass_intermediate_crop_flag,
        agricultural_biomass_low_risk_flag,
        raw_material_waste_or_residue_flag,
        nuts2_region,
        default_value_applied_flag,
        ghg_eec,
        ghg_el,
        ghg_ep,
        ghg_etd,
        ghg_eu,
        ghg_esca,
        ghg_eccs,
        ghg_eccr,
        ghg_total,
        allocated_heat,
        allocated_electricity,
        ghg_emission_percentage_in,
        recipient_receipt_address_same_flag,
        supplier_dispatch_address_same_flag,
      };
      return props;
    }
    return defaultValues;
  }, [document]);

  const methods = useForm({
    defaultValues,
    values,
  });

  const {
    register,
    control,
    formState: {errors},
  } = methods;

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  // const [updateOutgoingDocumentStatus, {loading: statusUpdateLoading}] =
  //   useMutation(SAF_OUTGOING_DRAFT_TO_GENERATED_MUTATION, {
  //     onCompleted: ({bioLcSafUpdateOutgoingDocsData}) => {
  //       console.log(
  //         "bioLcSafUpdateOutgoingDocsData",
  //         bioLcSafUpdateOutgoingDocsData,
  //       );
  //       if (bioLcSafUpdateOutgoingDocsData?.statusCode === 204) {
  //         navigate({pathname: "/saf-sweden/mass-balance/"});
  //         toast.success("Document generated successfully.");
  //       } else {
  //         toast.error("Failed to generate document.");
  //       }
  //     },
  //   });

  // const handleGenerateCertificate = () => {
  //   updateOutgoingDocumentStatus({
  //     variables: {certificate_outbound_eu_id: docId},
  //   });
  // };

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: "SAF Sweden", link: "/saflandingpage"},
    {text: "Mass balance", link: "/saf-sweden/mass-balance"},
    {text: docId, link: `/saf-sweden/mass-balance/${docId}`},
  ];

  const renderTitle = (status) => {
    switch (status) {
      default:
        return "Proof of Sustainability (PoS)";
    }
  };

  const productTypeItems = data?.bioLcSafGetMaterials?.product_names || [];

  const rawMaterialTypes = data?.bioLcSafGetMaterials?.raw_material_names || [];

  const countries = data?.bioLcCoproEuGetCountries || [];

  const chainOfCustody = ["Mass Balance", "Physical Segregation"];

  if (loading) {
    return (
      <div className="flex flex-col min-h-full items-center py-12 bp-core bg-light-gray-100">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="view-document flex flex-col view-doc__max-scroll bp-core bg-light-gray-100 ">
      <div className="flex flex-1 flex-row justify-between items-stretch min-h-full w-full">
        <div className="flex flex-col flex-1 max-h-screen overflow-y-auto">
          <Layout
            title={renderTitle("status")}
            breadcrumbs={breadcrumbItems}
            subtitle={
              <div className="flex flex-col justify-between items-start gap-2 small pb-16 px-7">
                <h1 className="text-[23px] fw-normal">
                  Please review and complete
                </h1>
                <span className="mb-2">
                  To finalise this sales assignment please review the following
                  details answering any outstanding questions. An example
                  certificate is shown on the right for your reference.
                </span>
                <span className="mb-2">
                  Once you have completed the review, you can generate the
                  certificate. This will take approximately 20 seconds.
                </span>
              </div>
            }
          >
            <div className="flex flex-col p-7 min-h-[800px]">
              <FormProvider {...methods}>
                <Form className="flex flex-col gap-8">
                  <FormGroup>
                    <Label for="sd_number" className="fw-normal mb-4">
                      Unique number of the PoS
                    </Label>
                    <Input
                      className="disabled-field"
                      type="text"
                      id="sd_number"
                      maxLength={100}
                      disabled
                      {...computeProps("sd_number")}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="date_of_issuance" className="fw-normal mb-4">
                      Date of issuance
                    </Label>
                    <Datepicker
                      className="disabled-field"
                      id="date_of_issuance"
                      name="date_of_issuance"
                      control={control}
                      error={errors.date_of_issuance}
                      rules={{required: "Please enter date of issuance"}}
                      disabled
                    />
                  </FormGroup>
                  <Accordion open={open} {...{toggle}} flush>
                    <AccordionItem>
                      <AccordionHeader targetId="supplier">
                        <h5 className="accordion-title fw-light text-black text-xl opacity-100">
                          Supplier and recipient
                        </h5>
                      </AccordionHeader>
                      <AccordionBody accordionId="supplier" className="mb-4">
                        <div className="flex flex-col gap-5 pb-8">
                          <FormGroup className="my-5">
                            <Label
                              for="supplier_name"
                              className="fw-normal mb-4"
                            >
                              Name of supplier
                            </Label>
                            <Input
                              className="disabled-field"
                              type="text"
                              id="supplier_name"
                              {...computeProps("supplier_name")}
                              data-test="supplier_name"
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="supplier_address"
                              className="fw-normal mb-4"
                            >
                              Address of supplier
                            </Label>
                            <Input
                              className="disabled-field"
                              type="text"
                              id="supplier_address"
                              {...computeProps("supplier_address")}
                              data-test="supplier_address"
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="certification_system"
                              className="fw-normal mb-4"
                            >
                              Certification system
                            </Label>
                            <Input
                              className="disabled-field"
                              type="select"
                              id="certification_system"
                              data-test="certification_system"
                              {...computeProps("certification_system")}
                              disabled
                            >
                              <option value="ISCC-EU">ISCC-EU</option>
                            </Input>
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="certificate_number"
                              className="fw-normal mb-4"
                            >
                              Certificate number
                            </Label>
                            <Input
                              className="disabled-field"
                              type="text"
                              id="certificate_number"
                              data-test="certificate_number"
                              {...computeProps("certificate_number")}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="recipient_name"
                              className="fw-normal mb-4"
                            >
                              Name of recipient
                            </Label>
                            <Input
                              className="disabled-field"
                              type="text"
                              id="recipient_name"
                              data-test="recipient_name"
                              {...computeProps("recipient_name")}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="recipient_address"
                              className="fw-normal mb-4"
                            >
                              Address of recipient
                            </Label>
                            <Input
                              className="disabled-field"
                              type="text"
                              id="recipient_address"
                              data-test="recipient_address"
                              {...computeProps("recipient_address")}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="contract_number"
                              className="fw-normal mb-4"
                            >
                              Contract number
                            </Label>
                            <Input
                              className="disabled-field"
                              type="text"
                              id="contract_number"
                              data-test="contract_number"
                              {...computeProps("contract_number")}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="supplier_dispatch_address"
                              className="fw-normal mb-4"
                            >
                              Address of dispatch/shipping point
                            </Label>
                            <Input
                              className="disabled-field mb-[10px]"
                              type="text"
                              id="supplier_dispatch_address"
                              data-test="supplier_dispatch_address"
                              {...computeProps("supplier_dispatch_address")}
                              disabled
                            />
                            <Input
                              className="mr-[15px]"
                              type="checkbox"
                              id="supplier_dispatch_address_same_flag"
                              data-test="supplier_dispatch_address_same_flag"
                              {...computeProps(
                                "supplier_dispatch_address_same_flag",
                              )}
                              disabled
                            />
                            <Label
                              check
                              for="supplier_dispatch_address_same_flag"
                            >
                              Same as address of supplier
                            </Label>
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="recipient_receipt_address"
                              className="fw-normal mb-4"
                            >
                              Address of receipt/receiving point
                            </Label>
                            <Input
                              className="disabled-field mb-[10px]"
                              type="text"
                              id="recipient_receipt_address"
                              data-test="recipient_receipt_address"
                              {...computeProps("recipient_receipt_address")}
                              disabled
                            />
                            <Input
                              className="mr-[15px]"
                              type="checkbox"
                              id="recipient_receipt_address_same_flag"
                              data-test="recipient_receipt_address_same_flag"
                              {...computeProps(
                                "recipient_receipt_address_same_flag",
                              )}
                              disabled
                            />
                            <Label
                              check
                              for="recipient_receipt_address_same_flag"
                            >
                              Same as address of recipeint
                            </Label>
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="material_dispatch_datez"
                              className="fw-normal mb-4"
                            >
                              Date of dispatch
                            </Label>
                            <Datepicker
                              id="material_dispatch_datez"
                              name="material_dispatch_datez"
                              control={control}
                              disabled
                            />
                          </FormGroup>
                          <div className="flex my-5">
                            <FormGroup className="flex flex-col flex-1">
                              <Label
                                for="bio_product_producer"
                                className="fw-normal mb-4"
                              >
                                Producer of biofuel/bioliquid/biomass fuel
                              </Label>
                              <div className="h-full flex items-center">
                                <FormGroup
                                  check
                                  inline
                                  className="form-check-alt form-check-lg !mb-0"
                                >
                                  <Input
                                    type="checkbox"
                                    id="bio_product_producer"
                                    data-test="bio_product_producer"
                                    {...computeProps("bio_product_producer")}
                                    disabled
                                  />
                                  <Label check for="bio_product_producer">
                                    Yes
                                  </Label>
                                </FormGroup>
                              </div>
                            </FormGroup>
                            <FormGroup className="flex flex-col flex-1">
                              <Label
                                for="producer_installation_date"
                                className="fw-normal"
                              >
                                Date of installation
                              </Label>
                              <span className="mt-[-4px]">
                                <Datepicker
                                  id="producer_installation_date"
                                  name="producer_installation_date"
                                  control={control}
                                  disabled
                                />
                              </span>
                            </FormGroup>
                          </div>
                          <div className="flex my-5">
                            <FormGroup className="flex flex-col flex-1">
                              <Label
                                for="bio_product_user"
                                className="fw-normal mb-4"
                              >
                                User of bioliquid/biomass fuel
                              </Label>
                              <div className="h-full flex items-center">
                                <FormGroup
                                  check
                                  inline
                                  className="form-check-alt form-check-lg !mb-0"
                                >
                                  <Input
                                    type="checkbox"
                                    id="bio_product_user"
                                    data-test="bio_product_user"
                                    {...computeProps("bio_product_user")}
                                    disabled
                                  />
                                  <Label check for="bio_product_user">
                                    Yes
                                  </Label>
                                </FormGroup>
                              </div>
                            </FormGroup>
                            <FormGroup className="flex flex-col flex-1">
                              <Label
                                for="user_installation_date"
                                className="fw-normal"
                              >
                                Date of installation
                              </Label>
                              <span className="mt-[-4px]">
                                <Datepicker
                                  id="user_installation_date"
                                  name="user_installation_date"
                                  control={control}
                                  disabled
                                />
                              </span>
                            </FormGroup>
                          </div>
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="general">
                        <h5 className="accordion-title fw-light text-black text-xl opacity-100">
                          General information
                        </h5>
                      </AccordionHeader>
                      <AccordionBody accordionId="general" className="mb-4">
                        <div className="flex flex-col gap-5 pb-8">
                          <FormGroup className="my-5">
                            <Label
                              for="product_type"
                              className="fw-normal mb-4"
                            >
                              Type of product
                            </Label>
                            <Input
                              className="disabled-field"
                              type="select"
                              id="product_type"
                              data-test="product_type"
                              {...computeProps("product_type")}
                              disabled
                              placeholder="Please choose from list"
                            >
                              <option value="">Please choose from list</option>
                              {productTypeItems.map((productTypeItem) => (
                                <option
                                  key={productTypeItem.product_name}
                                  value={productTypeItem.product_name}
                                >
                                  {productTypeItem.product_name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="raw_material_type"
                              className="fw-normal mb-4"
                            >
                              Type of raw material
                            </Label>

                            <Input
                              className="disabled-field"
                              type="select"
                              id="raw_material_type"
                              data-test="raw_material_type"
                              {...computeProps("raw_material_type")}
                              disabled
                              placeholder="Please choose from list"
                            >
                              <option value="">Please choose from list</option>
                              {rawMaterialTypes.length > 0 &&
                                rawMaterialTypes.map((p) => (
                                  <option
                                    key={p.raw_material_name}
                                    value={p.raw_material_name}
                                  >
                                    {p.raw_material_name}
                                  </option>
                                ))}
                            </Input>
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="additional_info"
                              className="fw-normal mb-4"
                            >
                              Additional information (voluntary)
                            </Label>
                            <Input
                              className="disabled-field"
                              type="textarea"
                              rows="3"
                              id="additional_info"
                              data-test="additional_info"
                              {...computeProps("additional_info")}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="raw_material_origin_country_name"
                              className="fw-normal mb-4"
                            >
                              Country of origin of the origin raw material
                            </Label>
                            <Input
                              className="disabled-field"
                              type="select"
                              id="raw_material_origin_country_name"
                              data-test="raw_material_origin_country_name"
                              {...computeProps(
                                "raw_material_origin_country_name",
                              )}
                              disabled
                            >
                              {countries &&
                                countries?.length > 0 &&
                                countries.map((country) => (
                                  <option
                                    key={country.code}
                                    value={country.name}
                                  >
                                    {country.name}
                                  </option>
                                ))}
                            </Input>
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label for="product_qty" className="fw-normal mb-4">
                              Quantity
                            </Label>
                            <Input
                              className="disabled-field"
                              type="number"
                              id="product_qty"
                              data-test="product_qty"
                              {...computeProps("product_qty")}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="product_qty_uom"
                              className="fw-normal mb-4"
                            >
                              Unit of measure
                            </Label>
                            <Controller
                              render={({field: {onChange, value, ref}}) => {
                                const m3 = {checked: value === "M3"};
                                const metricTons = {checked: value === "MT"};
                                return (
                                  <div>
                                    <FormGroup
                                      check
                                      inline
                                      className="form-check-alt form-check-lg !mb-0"
                                    >
                                      <Input
                                        innerRef={ref}
                                        id="m3"
                                        type="checkbox"
                                        data-test="m3"
                                        disabled
                                        onChange={(e) =>
                                          onChange(e.target.checked)
                                        }
                                        {...m3}
                                      />
                                      <Label check for="m3">
                                        m³
                                      </Label>
                                    </FormGroup>
                                    <FormGroup
                                      check
                                      inline
                                      className="form-check-alt form-check-lg !mb-0"
                                    >
                                      <Input
                                        id="metricTons"
                                        data-test="metricTons"
                                        type="checkbox"
                                        disabled
                                        onChange={(e) =>
                                          onChange(!e.target.checked)
                                        }
                                        {...metricTons}
                                      />
                                      <Label check for="metricTons">
                                        Metric tons
                                      </Label>
                                    </FormGroup>
                                  </div>
                                );
                              }}
                              control={control}
                              name="product_qty_uom"
                              defaultValue=""
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="product_energy_content"
                              className="fw-normal mb-4"
                            >
                              Energy content (MJ)
                            </Label>
                            <Input
                              className="disabled-field"
                              type="number"
                              id="product_energy_content"
                              data-test="product_energy_content"
                              {...computeProps("product_energy_content")}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="eu_red_compliant_flag"
                              className="fw-normal mb-4"
                            >
                              EU RED Compliant material
                            </Label>
                            <Controller
                              render={({field: {onChange, value, ref}}) => {
                                const Yes = {checked: value === true};
                                const No = {checked: value === false};
                                return (
                                  <div>
                                    <FormGroup
                                      check
                                      inline
                                      className="form-check-alt form-check-lg !mb-0"
                                    >
                                      <Input
                                        innerRef={ref}
                                        id="EuYes"
                                        type="checkbox"
                                        data-test="EuYes"
                                        disabled
                                        onChange={(e) =>
                                          onChange(e.target.checked)
                                        }
                                        {...Yes}
                                      />
                                      <Label check for="EuYes">
                                        Yes
                                      </Label>
                                    </FormGroup>
                                    <FormGroup
                                      check
                                      inline
                                      className="form-check-alt form-check-lg !mb-0"
                                    >
                                      <Input
                                        id="EuNo"
                                        data-test="EuNo"
                                        type="checkbox"
                                        disabled
                                        onChange={(e) =>
                                          onChange(!e.target.checked)
                                        }
                                        {...No}
                                      />
                                      <Label check for="EuNo">
                                        No
                                      </Label>
                                    </FormGroup>
                                  </div>
                                );
                              }}
                              control={control}
                              name="eu_red_compliant_flag"
                              defaultValue=""
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="iscc_compliant_flag"
                              className="fw-normal mb-4"
                            >
                              ISCC Compliant material (voluntary)
                            </Label>
                            <Controller
                              render={({field: {onChange, value, ref}}) => {
                                const Yes = {checked: value === true};
                                const No = {checked: value === false};
                                return (
                                  <div>
                                    <FormGroup
                                      check
                                      inline
                                      className="form-check-alt form-check-lg !mb-0"
                                    >
                                      <Input
                                        innerRef={ref}
                                        id="ISCCYes"
                                        type="checkbox"
                                        data-test="ISCCYes"
                                        disabled
                                        onChange={(e) =>
                                          onChange(e.target.checked)
                                        }
                                        {...Yes}
                                      />
                                      <Label check for="ISCCYes">
                                        Yes
                                      </Label>
                                    </FormGroup>
                                    <FormGroup
                                      check
                                      inline
                                      className="form-check-alt form-check-lg !mb-0"
                                    >
                                      <Input
                                        id="ISCCNo"
                                        data-test="ISCCNo"
                                        type="checkbox"
                                        disabled
                                        onChange={(e) =>
                                          onChange(!e.target.checked)
                                        }
                                        {...No}
                                      />
                                      <Label check for="ISCCNo">
                                        No
                                      </Label>
                                    </FormGroup>
                                  </div>
                                );
                              }}
                              control={control}
                              name="iscc_compliant_flag"
                              defaultValue=""
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="chain_of_custody_option"
                              className="fw-normal mb-4"
                            >
                              Chain of custody option (voluntary)
                            </Label>
                            <Input
                              className="disabled-field"
                              type="select"
                              id="chain_of_custody_option"
                              data-test="chain_of_custody_option"
                              {...computeProps("chain_of_custody_option")}
                              disabled
                              // placeholder="Please select"
                            >
                              <option value="">Please select</option>
                              {chainOfCustody.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="certification">
                        <h5 className="accordion-title fw-light text-black text-xl opacity-100">
                          Scope of certification of raw material
                        </h5>
                      </AccordionHeader>
                      <AccordionBody
                        accordionId="certification"
                        className="mb-4"
                      >
                        <div className="flex flex-col gap-5 pb-8">
                          <FormGroup className="my-5">
                            <Label
                              for="material_sustainability_criteria_flag"
                              className="fw-normal mb-4"
                            >
                              The raw material complies with the relevant
                              sustainability criteria according to Art. 29 (2) -
                              (7) RED II
                            </Label>
                            <CheckboxFlag
                              control={control}
                              name="material_sustainability_criteria_flag"
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="agricultural_biomass_intermediate_crop_flag"
                              className="fw-normal mb-4"
                            >
                              The agricultural biomass was cultivated as
                              intermediate crop (if applicable)
                            </Label>
                            <CheckboxFlag
                              control={control}
                              name="agricultural_biomass_intermediate_crop_flag"
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="agricultural_biomass_low_risk_flag"
                              className="fw-normal mb-4"
                            >
                              The agricultural biomass additionally fulfills the
                              measures for low ILUC risk feedstocks (if
                              applicable)
                            </Label>
                            <CheckboxFlag
                              control={control}
                              name="agricultural_biomass_low_risk_flag"
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="raw_material_waste_or_residue_flag"
                              className="fw-normal mb-4"
                            >
                              The raw material meets the definition of waste or
                              residue according to the RED II
                            </Label>
                            <CheckboxFlag
                              control={control}
                              name="raw_material_waste_or_residue_flag"
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="nuts2_region"
                              className="fw-normal mb-4"
                            >
                              If applicable, please specify NUTS 2 region
                            </Label>
                            <Input
                              className="disabled-field"
                              type="text"
                              id="nuts2_region"
                              data-test="nuts2_region"
                              {...computeProps("nuts2_region")}
                              disabled
                            />
                          </FormGroup>
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="emission">
                        <h5 className="accordion-title fw-light text-black text-xl opacity-100">
                          Greenhouse gas (GHG) emission information
                        </h5>
                      </AccordionHeader>
                      <AccordionBody accordionId="emission" className="mb-4">
                        <div className="flex flex-col gap-5 pb-8">
                          <FormGroup className="my-5">
                            <Label
                              for="default_value_applied_flag"
                              className="fw-normal mb-4"
                            >
                              Total default value according to RED II applied
                            </Label>
                            <CheckboxFlag
                              control={control}
                              name="default_value_applied_flag"
                              disabled
                            />
                          </FormGroup>
                          <div className="flex flex-row justify-between items-start gap-3 my-5">
                            <FormGroup>
                              <Label for="ghg_eec" className="fw-normal">
                                Eec
                              </Label>
                              <Input
                                className="disabled-field"
                                type="number"
                                id="ghg_eec"
                                data-test="ghg_eec"
                                {...computeProps("ghg_eec")}
                                disabled
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_el" className="fw-normal">
                                + El
                              </Label>
                              <Input
                                className="disabled-field"
                                type="number"
                                id="ghg_el"
                                data-test="ghg_el"
                                {...computeProps("ghg_el")}
                                disabled
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_ep" className="fw-normal">
                                + Ep
                              </Label>
                              <Input
                                className="disabled-field"
                                type="number"
                                id="ghg_ep"
                                data-test="ghg_ep"
                                {...computeProps("ghg_ep")}
                                disabled
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_etd" className="fw-normal">
                                + Etd
                              </Label>
                              <Input
                                className="disabled-field"
                                type="text"
                                id="ghg_etd"
                                data-test="ghg_etd"
                                {...computeProps("ghg_etd")}
                                disabled
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_eu" className="fw-normal">
                                + Eu 7
                              </Label>
                              <Input
                                className="disabled-field"
                                type="number"
                                id="ghg_eu"
                                data-test="ghg_eu"
                                {...computeProps("ghg_eu")}
                                disabled
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_esca" className="fw-normal">
                                - Esca
                              </Label>
                              <Input
                                className="disabled-field"
                                type="number"
                                id="ghg_esca"
                                data-test="ghg_esca"
                                {...computeProps("ghg_esca")}
                                disabled
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_eccs" className="fw-normal">
                                - Eccs
                              </Label>
                              <Input
                                className="disabled-field"
                                type="number"
                                id="ghg_eccs"
                                data-test="ghg_eccs"
                                {...computeProps("ghg_eccs")}
                                disabled
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_eccr" className="fw-normal">
                                - Eccr
                              </Label>
                              <Input
                                className="disabled-field"
                                type="number"
                                id="ghg_eccr"
                                data-test="ghg_eccr"
                                {...computeProps("ghg_eccr")}
                                disabled
                              />
                            </FormGroup>
                          </div>
                          <FormGroup className="my-5">
                            <Label for="ghg_total" className="fw-normal mb-4">
                              Total GHG emissions (gCO2eq/MJ)
                            </Label>
                            <Input
                              className="disabled-field"
                              type="text"
                              id="ghg_total"
                              data-test="ghg_total"
                              {...computeProps("ghg_total")}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="allocated_heat"
                              className="fw-normal mb-4"
                            >
                              Allocated heat (gCO2eq/MJ heat)
                            </Label>
                            <Input
                              className="disabled-field"
                              type="text"
                              id="allocated_heat"
                              data-test="allocated_heat"
                              {...computeProps("allocated_heat")}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="allocated_electricity"
                              className="fw-normal mb-4"
                            >
                              Allocated electricity (gCO2eq/MJ electricity)
                            </Label>
                            <Input
                              className="disabled-field"
                              type="text"
                              id="allocated_electricity"
                              data-test="allocated_electricity"
                              {...computeProps("allocated_electricity")}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="ghg_emission_percentage_in"
                              className="fw-normal mb-4"
                            >
                              GHG emission saving (%)
                            </Label>
                            <Input
                              className="disabled-field"
                              type="text"
                              id="ghg_emission_percentage_in"
                              data-test="ghg_emission_percentage_in"
                              {...computeProps("ghg_emission_percentage_in")}
                              disabled
                            />
                            <span className="disabled-field text-xs">
                              Biofuels for transport
                            </span>
                          </FormGroup>
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                  <div className="flex flex-row justify-end items-center gap-4">
                    <Button
                      color="tertiary"
                      className="show"
                      style={{
                        borderRadius: "0px",
                        borderColor: "#cccccc",
                      }}
                      type="button"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="standard-primary"
                      style={{
                        borderRadius: "0px",
                      }}
                      type="button"
                      // disabled={!document || statusUpdateLoading}
                      // onClick={() => handleGenerateCertificate()}
                    >
                      {/* {statusUpdateLoading ? (
                        <Spinner size="sm" className="btn-icon-prefix" />
                      ) : (
                        <Check24 className="btn-icon-prefix" />
                      )} */}
                      Generate certificate
                    </Button>
                  </div>
                </Form>
              </FormProvider>
            </div>
          </Layout>
        </div>
        <SingleDocumentViewer document={{download_url: null}} />
      </div>
    </div>
  );
};

export default MassBalanceDetailViewPage;
