import Logo from "assets/images/logo-horizontal.svg";
import Facebook from "assets/images/svg/facebook.svg";
import Instagram from "assets/images/svg/instagram.svg";
import Twitter from "assets/images/svg/twitter.svg";
import {UpCircleOutlined} from "@ant-design/icons";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="flex flex-col px-6 py-16 gap-y-8 lg:px-10 bg-gray-50">
      <img src={Logo} className="w-40 h-10 sm:mx-0 " alt="BP Logo" />
      <div className="flex flex-col justify-between border-t border-gray-200 sm:flex-row gap-y-8 sm:gap-y-0">
        <p className="mt-6 text-dark-gray">
          © {currentYear} BP. All rights reserved.
        </p>

        <button
          type="button"
          className="h-15 w-15"
          data-test="button-scroll-to-top"
          onClick={scrollToTop}
        >
          <UpCircleOutlined style={{color: "gray", fontSize: 30}} />
        </button>
        <div className="flex items-center justify-center hidden mt-6 gap-x-6">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com"
            className="hover:opacity-50"
          >
            <img src={Facebook} alt="Facebook" />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.twitter.com"
            className="hover:opacity-50"
          >
            <img src={Twitter} alt="Twitter" />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com"
            className="hover:opacity-50"
          >
            <img src={Instagram} alt="Instagram" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
