import {FormGroup, Label, Input, FormFeedback} from "reactstrap";
import {useFormContext} from "react-hook-form";
import moment from "moment";
import {useEffect} from "react";
import PropTypes from "prop-types";
import Datepicker from "../../Datepicker";

const SupplierAndRecipientInfo = ({data}) => {
  const {
    register,
    formState: {errors},
    control,
    setValue,
  } = useFormContext();

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const recipientNameParam =
    data?.recipientReceiptName === "Same as recipient address"
      ? data?.recipientName
      : data?.recipientReceiptName;

  const recipientRecord = data?.locationDetails?.find(
    (obj) => obj.location_code === data?.recipientName,
  );

  useEffect(() => {
    setValue("supplierName", data?.supplier);
    setValue("supplierAddress", data?.supplierAddress);
    setValue("certificationSystem", data?.certificationSystem);
    setValue("certificateNumber", data?.certificationNumber);
    setValue("recipientName", recipientRecord?.legal_entity);
    setValue("recipientAddress", data?.recipientAddress);
    setValue("contractNumber", data?.contractNumber);
    setValue("recipientReceiptAddress", data?.shippingAddress);
    setValue("receiptReceivingPointAddress", data?.recipientReceiptAddress);
    setValue("dispatchDate", new Date(data?.dispatchDate));
    setValue("IsProducerOfBiofuelBiomassFuel", true);
    setValue(
      "dateOfInstallation",
      new Date(moment("01/06/2016", "DD/MM/YYYY")),
    );

    if (data?.shippingAddress === data?.supplierAddress) {
      setValue("sameAsAddressOfSupplier", true);
    }
    if (data?.recipientReceiptAddress === data?.recipientAddress) {
      setValue("sameAsAddressOfRecipient", true);
    }
  }, [setValue, data, recipientRecord]);
  return (
    <div className="flex flex-col gap-4 pb-6">
      <FormGroup>
        <Label for="supplierName" className="fw-light">
          Name of supplier
        </Label>

        <Input
          {...computeProps("supplierName")}
          type="text"
          id="supplierName"
          name="supplierName"
          data-test="supplierName"
          invalid={!!errors.supplierName}
          maxLength={50}
          disabled
        />

        <FormFeedback>{errors.supplierName?.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for="supplierAddress" className="fw-light">
          Address of supplier
        </Label>

        <Input
          {...computeProps("supplierAddress")}
          type="text"
          id="supplierAddress"
          name="supplierAddress"
          data-test="supplierAddress"
          invalid={!!errors.supplierAddress}
          maxLength={200}
          disabled
        />

        {errors.supplierAddress && (
          <FormFeedback>{errors.supplierAddress?.message}</FormFeedback>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="certificationSystem" className="fw-light">
          Certification system
        </Label>
        <Input
          {...computeProps("certificationSystem")}
          type="text"
          id="certificationSystem"
          name="certificationSystem"
          data-test="certificationSystem"
          invalid={!!errors.certificationSystem}
          maxLength={200}
          disabled
        />

        {errors.certificationSystem && (
          <FormFeedback>{errors.certificationSystem?.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="certificateNumber" className="fw-light">
          Certificate number
        </Label>

        <Input
          {...computeProps("certificateNumber")}
          type="text"
          id="certificateNumber"
          data-test="certificateNumber"
          invalid={!!errors.certificateNumber}
          maxLength={100}
          disabled
        />

        {errors.certificateNumber && (
          <FormFeedback>{errors.certificateNumber.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="recipientName" className="fw-light">
          Name of recipient
        </Label>

        <Input
          {...computeProps("recipientName")}
          type="text"
          id="recipientName"
          data-test="recipientName"
          invalid={!!errors.recipientName}
          maxLength={50}
          disabled
        />

        {errors.recipientName && (
          <FormFeedback>{errors.recipientName.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="recipientAddress" className="fw-light">
          Address of recipient
        </Label>

        <Input
          {...computeProps("recipientAddress")}
          type="text"
          id="recipientAddress"
          data-test="recipientAddress"
          invalid={!!errors.recipientAddress}
          maxLength={200}
          disabled
        />

        {errors.recipientAddress && (
          <FormFeedback>{errors.recipientAddress.message}</FormFeedback>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="contractNumber" className="fw-light">
          Contract number
        </Label>

        <Input
          {...computeProps("contractNumber")}
          type="text"
          id="contractNumber"
          data-test="contractNumber"
          invalid={!!errors.contractNumber}
          maxLength={50}
          disabled
        />
      </FormGroup>
      <FormGroup>
        <Label for="recipientReceiptAddress" className="fw-light">
          Address of dispatch/shipping point
        </Label>

        <Input
          {...computeProps("recipientReceiptAddress")}
          type="text"
          id="recipientReceiptAddress"
          data-test="recipientReceiptAddress"
          invalid={!!errors.recipientReceiptAddress}
          maxLength={200}
          disabled
        />
      </FormGroup>
      <FormGroup check inline className="form-check-alt form-check-lg !mb-0">
        <Input
          {...computeProps("sameAsAddressOfSupplier")}
          type="checkbox"
          id="sameAsAddressOfSupplier"
          data-test="sameAsAddressOfSupplier"
        />

        <Label check for="sameAsAddressOfSupplier">
          Same as address of supplier
        </Label>
      </FormGroup>

      <FormGroup>
        <Label for="receiptReceivingPointAddress" className="fw-light">
          Address of receipt/receiving point
        </Label>

        <Input
          {...computeProps("receiptReceivingPointAddress")}
          type="text"
          id="receiptReceivingPointAddress"
          data-test="receiptReceivingPointAddress"
          invalid={!!errors.recipientReceiptAddress}
          maxLength={200}
          disabled
        />
      </FormGroup>
      <FormGroup check inline className="form-check-alt form-check-lg !mb-0">
        <Input
          {...computeProps("sameAsAddressOfRecipient")}
          type="checkbox"
          id="sameAsAddressOfRecipient"
          data-test="sameAsAddressOfRecipient"
        />

        <Label check for="sameAsAddressOfRecipient">
          Same as address of recipient
        </Label>
      </FormGroup>

      <FormGroup>
        <Label for="dispatchDate" className="fw-light">
          Date of dispatch
        </Label>
        <Datepicker
          id="dispatchDate"
          name="dispatchDate"
          disabled
          control={control}
          error={errors.dispatchDate}
          rules={{required: "Please enter date of dispatch"}}
        />

        <FormFeedback>{errors?.uniqueNumber?.message}</FormFeedback>
      </FormGroup>
      {data?.templateType === "ISCC PoS 2.6" &&
        data?.certificationSystem === "ISCC EU" && (
          <>
            <FormGroup
              check
              inline
              className="form-check-alt form-check-lg !mb-0"
            >
              <Input
                {...computeProps("IsProducerOfBiofuelBiomassFuel")}
                type="checkbox"
                id="IsProducerOfBiofuelBiomassFuel"
                name="IsProducerOfBiofuelBiomassFuel"
                data-test="IsProducerOfBiofuelBiomassFuel"
              />

              <Label check for="IsProducerOfBiofuelBiomassFuel">
                Producer of biofuel/bioliquid/biomass fuel
              </Label>
            </FormGroup>

            <FormGroup>
              <Label for="dateOfInstallation" className="fw-light">
                Date of installation
              </Label>

              <Datepicker
                id="dateOfInstallation"
                name="dateOfInstallation"
                control={control}
                error={errors.dateOfInstallation}
                rules={{required: "Please enter date of installation"}}
              />

              <FormFeedback>{errors?.dateOfInstallation?.message}</FormFeedback>
            </FormGroup>

            <FormGroup
              check
              inline
              className="form-check-alt form-check-lg !mb-0"
            >
              <Input
                {...computeProps("IsUserofBioLiquidFuel")}
                type="checkbox"
                id="IsUserofBioLiquidFuel"
                data-test="IsUserofBioLiquidFuel"
              />

              <Label check for="IsUserofBioLiquidFuel">
                User of bioliquid/biomass fuel
              </Label>
            </FormGroup>
            <FormGroup>
              <Label for="dateOfInstallation2" className="fw-light">
                Date of installation
              </Label>

              <Datepicker
                id="dateOfInstallation2"
                name="dateOfInstallation2"
                control={control}
                error={errors.dateOfInstallation2}
                rules={{required: "Please enter date of installation"}}
              />

              <FormFeedback>
                {errors?.dateOfInstallation2?.message}
              </FormFeedback>
            </FormGroup>
          </>
        )}
    </div>
  );
};

SupplierAndRecipientInfo.propTypes = {
  data: PropTypes.object,
};

export default SupplierAndRecipientInfo;
