import PropTypes from "prop-types";
import React, {useState} from "react";
import {Settings24} from "@bphxd/ds-core-react/lib/icons";
import {Close} from "@bphxd/ds-core-react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from "reactstrap";
import {FormProvider, useForm} from "react-hook-form";
import ReportSettingForm from "./ReportSettingForm";

const ReportSettingsDetail = ({
  onSave,
  onCancel,
  defaultValues,
  setFilterDefaultValues,
}) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const methods = useForm({
    mode: "onBlur",
  });

  const {getValues} = methods;

  const handleSave = () => {
    const {materialView, uom} = getValues();

    setFilterDefaultValues({
      ...defaultValues,
      materialView: materialView ? "sap_code" : "NC code",
      uom: uom ? "t" : "m3",
    });

    setModalVisible(false);
    onSave({
      materialView: materialView ? "sap_code" : "NC code",
      uom: uom ? "t" : "m3",
    });
  };

  return (
    <>
      <div style={{marginRight: "1rem"}}>
        <Button
          data-test="copro-fifo-report-settings-button"
          id="reportSettingsButton"
          name="reportSettingsButton"
          color="standard-outline rounded-0"
          className="h-9"
          onClick={() => setModalVisible(true)}
        >
          <Settings24 className="btn-icon-prefix" />
          Report settings
        </Button>
      </div>

      <Modal
        size="sm"
        isOpen={isModalVisible}
        className="modal-dialog-centered !min-w-[329px] max-w-[329px]"
      >
        <ModalHeader
          className="p-4"
          close={<Close onClick={() => setModalVisible(false)} />}
        >
          <span className="px-2 text-lg">Report settings</span>
        </ModalHeader>
        <hr className="m-0 bg-blue" style={{color: "#ededed"}} />
        <ModalBody className="text-center py-0">
          <FormProvider {...methods}>
            <Form id="report-settings-form">
              <ReportSettingForm defaultValues={defaultValues} />
            </Form>
          </FormProvider>
        </ModalBody>
        <ModalFooter className="p-0 d-block">
          <div className="row g-0  m-0 modal-footer-row">
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
                onClick={() => setModalVisible(false)}
              >
                Cancel
              </Button>
            </div>
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 py-4 bg-transparent text-default"
                form="report-settings-form"
                type="button"
                onClick={handleSave}
              >
                Save
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

ReportSettingsDetail.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  defaultValues: PropTypes.object,
  setFilterDefaultValues: PropTypes.func,
};
export default ReportSettingsDetail;
