import {Outlet} from "react-router-dom";
import {Helmet} from "react-helmet";
import {HEADER_COPRO} from "modules/co-processing/constants/coProcessing";
import "./index.scss";
import CoproBreadcrumbs from "./CoproBreadcrumbs";

export default () => {
  return (
    <div data-test="copro-root-layout" id="copro-container">
      <Helmet>
        <title>{HEADER_COPRO}</title>
      </Helmet>
      <div className="copro-header-section">
        <div className="flex mx-[40px] pt-5 text-xs">
          <CoproBreadcrumbs />
        </div>
      </div>
      <Outlet />
    </div>
  );
};
