import React from "react";
import {useQuery} from "@apollo/client";
import {CHERRY_POINT_METRICS} from "graphql/product-metrics";
import {Spin} from "antd";
import DashboardLayout from "modules/common/DashboardLayout";
import Product from "./Product";
import tabsData from "./tabsData";

const materices = [
  {
    title: "Invoices",
    type: "invoice",
    showBoth: true,
    valueKey: "count",
    valueKeyTwo: "timeSaved",
  },
  {
    title: "BOL",
    type: "bol",
    showBoth: true,
    valueKey: "count",
    valueKeyTwo: "timeSaved",
  },
  {
    title: "Daily Shipment Tracking Records",
    type: "shipmentRecord",
    showBoth: true,
    valueKey: "count",
    valueKeyTwo: "timeSaved",
  },
  {
    title: "Total Days saved",
    type: "total",
    showBoth: false,
    valueKey: "timeSaved",
    valueKeyTwo: "",
  },
];

const ProductMetrices = () => {
  const {data, loading, error} = useQuery(CHERRY_POINT_METRICS);

  if (loading) {
    return (
      <div
        data-test="product-matrix-loading"
        className="flex flex-col items-center justify-center w-full h-64"
      >
        <Spin
          className="flex items-center content-center justify-center "
          spinning="true"
        ></Spin>
      </div>
    );
  }

  if (error) {
    return (
      <div
        data-test="product-matrix-error"
        className="flex flex-col items-center justify-center w-full h-64"
      >
        <h3>Something went wrong. Please try again after some time!</h3>
      </div>
    );
  }

  return (
    <DashboardLayout pageTitle="Reporting" tabsData={tabsData}>
      <div data-test="product-metrics-body">
        <div
          data-test="product-options-list"
          className="grid w-full gap-0 md:grid-cols-2 md:grid-rows-2 sm:grid-rows-4 sm:grid-cols-1 md:gap-2"
        >
          {materices.map((item) => {
            const itemData = data.getUsageMetrics.data[item.type];

            if (!itemData[item.valueKey]) {
              return null;
            }

            return (
              <Product
                key={item.type}
                item={{
                  ...item,
                  value: itemData[item.valueKey],
                  valueTwo: itemData[item.valueKeyTwo],
                }}
              />
            );
          })}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ProductMetrices;
