import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {useForm, FormProvider, Controller} from "react-hook-form";
import {
  Form,
  FormGroup,
  Label,
  FormFeedback,
  Input,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Spinner,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  Alert32,
  Check24,
  Remove24,
  TailLeft24,
} from "@bphxd/ds-core-react/lib/icons";
import RadioFlag from "modules/common/BinaryFlag";
import {
  SAF_GET_INCOMING_DOCUMENTS_SINGLE,
  SAF_GET_INCOMING_DOCUMENTS_SINGLE_INACTIVE,
  UPDATE_SAF_DOCUMENT,
} from "graphql/saf/IncomingDocuments";
import {useAccount, useMsal} from "@azure/msal-react";
import {Close} from "@bphxd/ds-core-react";
import {toast} from "react-toastify";
import SingleDocumentViewer from "modules/common/SingleDocumentViewer";
import Layout from "../components/Layout";
import Datepicker from "../components/Datepicker";
import {DDV, TDV} from "../components/utils";
import Subtitle from "../components/view-document/Subtitle";
import "./document-view.css";
import CustomRadioButton from "../components/CustomRadioButton";
import {DIVISION_ID, SITE_REFERENCE_ID} from "../constants/documents";

const defaultValues = {
  supplier_name: "",
  date_of_issuance: "",
  supplier_address: "",
  certification_system: "",
  certificate_number: "",
  recipient_name: "",
  recipient_address: "",
  contract_number: "",
  supplier_dispatch_address: "",
  recipient_receipt_address: "",
  material_dispatch_datez: "",
  bio_product_producer: "",
  producer_installation_date: "",
  bio_product_user: "",
  user_installation_date: "",
  product_type: "",
  raw_material_type: "",
  additional_info: "",
  raw_material_origin_country_name: "",
  product_qty: "",
  product_qty_uom: "",
  product_energy_content: "",
  eu_red_compliant_flag: "",
  iscc_compliant_flag: "",
  chain_of_custody_option: "",
  default_value_applied_flag: "",
  ghg_eec: "",
  ghg_el: "",
  ghg_ep: "",
  ghg_etd: "",
  ghg_eu: "",
  ghg_esca: "",
  ghg_eccs: "",
  ghg_eccr: "",
  ghg_total: "",
  allocated_heat: "",
  allocated_electricity: "",
  ghg_emission_percentage_in: "",
  material_sustainability_criteria_flag: "",
  agricultural_biomass_intermediate_crop_flag: "",
  agricultural_biomass_low_risk_flag: "",
  raw_material_waste_or_residue_flag: "",
  nuts2_region: "",
  replace_previous_version: false,
  previous_version_sd_number: "",
};

const tabFields = {
  supplier: [
    "supplier_name",
    "supplier_address",
    "certification_system",
    "certificate_number",
    "recipient_name",
    "recipient_address",
    "contract_number",
    "supplier_dispatch_address",
    "recipient_receipt_address",
    "material_dispatch_datez",
    "bio_product_producer",
    "producer_installation_date",
    "bio_product_user",
    "user_installation_date",
  ],
  general: [
    "product_type",
    "raw_material_type",
    "additional_info",
    "raw_material_origin_country_name",
    "product_qty",
    "product_qty_uom",
    "product_energy_content",
    "eu_red_compliant_flag",
    "iscc_compliant_flag",
    "chain_of_custody_option",
  ],
  emission: [
    "default_value_applied_flag",
    "ghg_eec",
    "ghg_el",
    "ghg_ep",
    "ghg_etd",
    "ghg_eu",
    "ghg_esca",
    "ghg_eccs",
    "ghg_eccr",
    "ghg_total",
    "allocated_heat",
    "allocated_electricity",
    "ghg_emission_percentage_in",
  ],
  certification: [
    "material_sustainability_criteria_flag",
    "agricultural_biomass_intermediate_crop_flag",
    "agricultural_biomass_low_risk_flag",
    "raw_material_waste_or_residue_flag",
    "nuts2_region",
  ],
  additionalInfo: ["replace_previous_version", "prevous_ver"],
};

const rejectReasons = [
  {
    key: "info_missing",
    value: "Required information missing or incorrect",
    message:
      "This certificate will be kept and deactivated. A new certificate needs to be reissued",
  },
  {
    key: "out_of_date",
    value: "Out of date certificate",
    message:
      "This certificate will be kept and deactivated. A new certificate has already been reissued",
  },
  {key: "invalid", value: "Invalid file", message: "This file will be deleted"},
];

const SAFDocumentViewPage = () => {
  const [open, setOpen] = useState([]);
  const [rejecting, setRejecting] = useState(false);
  const [showPrevVersion, setShowPrevVersion] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");
  const [sdNumberOld, setSdNumberOld] = useState(null);
  const [apiVariables, setApiVariables] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {docId} = useParams();

  const isInActive = !!(
    searchParams.get("linked_cert") && searchParams.get("linked_cert_status_id")
  );

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const toggle = (id) => {
    if (open.includes(id)) {
      setOpen(open.filter((item) => item !== id));
    } else {
      setOpen([...open, id]);
    }
  };

  const openAccordion = useCallback(
    (id) => {
      if (!open.includes(id)) {
        setOpen([...open, id]);
      }
    },
    [open],
  );

  const {data, loading, refetch} = useQuery(SAF_GET_INCOMING_DOCUMENTS_SINGLE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      certificate_inbound_eu_id: docId,
    },
    skip: isInActive,
  });

  const {data: inActiveData, loading: inActiveDataLoading} = useQuery(
    SAF_GET_INCOMING_DOCUMENTS_SINGLE_INACTIVE,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      variables: {
        certificate_inbound_eu_id: searchParams.get("linked_cert"),
        inbound_doc_status_eu_id: searchParams.get("linked_cert_status_id"),
      },
      skip: !isInActive,
    },
  );

  const document = isInActive
    ? inActiveData?.bioLcSafGetIncomingDocs?.documents?.[0]
    : data?.bioLcSafGetIncomingDocs?.documents?.[0];

  const documentFile = isInActive
    ? inActiveData?.bioLcSafGetDocumentFile
    : data?.bioLcSafGetDocumentFile;

  useEffect(() => {
    setSdNumberOld(document?.sd_number);
  }, [document?.sd_number, setSdNumberOld]);

  useEffect(() => {
    setShowPrevVersion(!!document?.previous_version_sd_number);
  }, [document?.previous_version_sd_number]);

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: "SAF Sweden", link: "/saflandingpage"},
    {text: "Document manager", link: "/saflandingpage"},
    {
      text: "Incoming Documents",
      link: "/SAFDocumentPage",
    },
    {
      text: document?.sd_number ?? docId,
      link: `/saflandingpage/SAFDocumentPage/${docId}`,
    },
  ];

  const renderTitle = (status) => {
    switch (status) {
      default:
        return "Proof of Sustainability (PoS)";
    }
  };

  const [invalidTabs, setInvalidTabs] = useState({
    supplier: false,
    general: false,
    emission: false,
    certification: false,
    additionalInfo: false,
  });

  const values = useMemo(() => {
    if ((data || inActiveData) && document != null) {
      const {
        sd_number = "",
        supplier_name = "",
        date_of_issuance = "",
        supplier_address = "",
        certification_system = "",
        certificate_number = "",
        recipient_name = "",
        recipient_address = "",
        contract_number = "",
        supplier_dispatch_address = "",
        recipient_receipt_address = "",
        material_dispatch_datez = "",
        bio_product_producer = "",
        producer_installation_date = "",
        bio_product_user = "",
        user_installation_date = "",
        product_type = "",
        raw_material_type = "",
        additional_info = "",
        raw_material_origin_country_name = "",
        product_qty = "",
        product_qty_uom = "",
        product_energy_content = "",
        eu_red_compliant_flag = "",
        iscc_compliant_flag = "",
        chain_of_custody_option = "",
        default_value_applied_flag = "",
        ghg_eec = "",
        ghg_el = "",
        ghg_ep = "",
        ghg_etd = "",
        ghg_eu = "",
        ghg_esca = "",
        ghg_eccs = "",
        ghg_eccr = "",
        ghg_total = "",
        allocated_heat = "",
        allocated_electricity = "",
        ghg_emission_percentage_in = "",
        material_sustainability_criteria_flag = "",
        agricultural_biomass_intermediate_crop_flag = "",
        agricultural_biomass_low_risk_flag = "",
        raw_material_waste_or_residue_flag = "",
        nuts2_region = "",
        previous_version_sd_number = "",
        bv_audit_changed_datez = "",
      } = document;
      const props = {
        sd_number,
        supplier_name,
        date_of_issuance,
        supplier_address,
        certification_system,
        certificate_number,
        recipient_name,
        recipient_address,
        contract_number,
        supplier_dispatch_address,
        recipient_receipt_address,
        material_dispatch_datez,
        bio_product_producer,
        producer_installation_date,
        bio_product_user,
        user_installation_date,
        product_type,
        raw_material_type,
        additional_info,
        raw_material_origin_country_name,
        product_qty,
        product_qty_uom,
        product_energy_content,
        eu_red_compliant_flag,
        iscc_compliant_flag,
        chain_of_custody_option,
        default_value_applied_flag,
        ghg_eec: parseFloat(ghg_eec ?? "0"),
        ghg_el: parseFloat(ghg_el ?? "0"),
        ghg_ep: parseFloat(ghg_ep ?? "0"),
        ghg_etd: parseFloat(ghg_etd ?? "0"),
        ghg_eu: parseFloat(ghg_eu ?? "0"),
        ghg_esca: parseFloat(ghg_esca ?? "0"),
        ghg_eccs: parseFloat(ghg_eccs ?? "0"),
        ghg_eccr: parseFloat(ghg_eccr ?? "0"),
        ghg_total:
          ghg_total === TDV || ghg_total === DDV
            ? ghg_total
            : parseFloat(ghg_total ?? "0"),
        allocated_heat,
        allocated_electricity,
        ghg_emission_percentage_in,
        material_sustainability_criteria_flag,
        agricultural_biomass_intermediate_crop_flag,
        agricultural_biomass_low_risk_flag,
        raw_material_waste_or_residue_flag,
        nuts2_region,
        replace_previous_version: !!previous_version_sd_number,
        previous_version_sd_number,
        bv_audit_changed_datez,
      };
      return props;
    }
    return defaultValues;
  }, [data, document, inActiveData]);

  const methods = useForm({
    defaultValues,
    mode: "onChange",
    values,
    shouldFocusError: true,
  });

  const {
    register,
    control,
    formState: {errors},
    getValues,
    watch,
    trigger,
    setFocus,
    setError,
    clearErrors,
  } = methods;

  const hasErrors = Object.keys(errors).length > 0;

  const validate = useCallback(async () => {
    const valid = await trigger();
    const flags = {
      supplier: false,
      general: false,
      emission: false,
      certification: false,
      additionalInfo: false,
    };
    Object.keys(tabFields).forEach((tab) => {
      const tabErrors = tabFields[tab].filter((field) => errors[field] != null);
      if (tabErrors.length > 0) {
        flags[tab] = true;
      }
    });
    setInvalidTabs(flags);
    return valid;
  }, [trigger, errors]);

  useEffect(() => {
    // Make sure we trigger form validation after values change (e.g. after loading) to show errors
    // aka simulating next tick here
    const timeoutId = setTimeout(() => validate(), 10);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [values, validate, hasErrors]);

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const status = document?.state;

  const isDisabled = status === "ready_to_assign" || status === "rejected";

  const productTypeItems = data?.bioLcSafGetMaterials?.product_names || [];

  const rawMaterialTypes = data?.bioLcSafGetMaterials?.raw_material_names || [];

  const countries = data?.bioLcCoproEuGetCountries || [];

  const chainOfCustody = ["Mass Balance", "Physical Segregation"];

  const numberInputPattern = {
    pattern: {
      value: /^(\d{1,10}(\.\d{1,5})?)$/,
      message:
        "Value must be number with (max 10 digits before and 5 digits after decimal point)",
    },
  };

  const handleRejectMessage = (message) => {
    if (message === "") {
      setRejectMessage("");
    } else {
      setRejectMessage(
        rejectReasons.filter((r) => r.key === message)[0].message,
      );
    }
  };

  const handleErrorMessageClick = (errorValue) => {
    let match = null;
    const tabs = Object.keys(tabFields);
    for (let index = 0; index < tabs.length; index += 1) {
      const element = tabs[index];
      if (tabFields[element].includes(errorValue)) {
        match = element;
        break;
      }
    }
    openAccordion(match);
    setTimeout(() => setFocus(errorValue), 150);
  };

  const [updateSafDocumentMutation] = useMutation(UPDATE_SAF_DOCUMENT, {
    onCompleted: ({bioLcSafUpdateIncomingDocData}) => {
      if (
        bioLcSafUpdateIncomingDocData?.errors &&
        bioLcSafUpdateIncomingDocData?.errors?.length > 0
      ) {
        bioLcSafUpdateIncomingDocData.errors.forEach((element) => {
          setError(element.field, {
            type: "value",
            message: element.message,
          });
        });
        handleErrorMessageClick(bioLcSafUpdateIncomingDocData?.errors[0].field);
        toast.error("Failed to update document");
      } else if (bioLcSafUpdateIncomingDocData?.error) {
        toast.error(bioLcSafUpdateIncomingDocData?.error);
      } else {
        refetch({
          certificate_inbound_eu_id: docId,
        });
        toast.success("Document updated successfully");
      }
      setAcceptModal(false);
      setSubmitLoading(false);
      setRejecting(false);
    },
  });

  const onSubmit = async (userAction) => {
    setSubmitLoading(true);
    const {...docData} = getValues();
    const variables =
      userAction === "accept"
        ? {
            event: {
              division_id: DIVISION_ID,
              site_reference_id: SITE_REFERENCE_ID,
              user_action: userAction,
              user_id: account.username,
              sd_number: docData.sd_number,
              replace_previous_version: docData.replace_previous_version,
              previous_version_sd_number: docData.previous_version_sd_number,
              document_data: {
                date_of_issuance: docData.date_of_issuance,
                additional_info: docData.additional_info,
                agricultural_biomass_intermediate_crop_flag:
                  docData.agricultural_biomass_intermediate_crop_flag,
                agricultural_biomass_low_risk_flag:
                  docData.agricultural_biomass_low_risk_flag,
                allocated_electricity: docData.allocated_electricity,
                allocated_heat: docData.allocated_heat,
                bio_product_producer: docData.bio_product_producer,
                bio_product_user: docData.bio_product_user,
                certification_system: docData.certification_system,
                certificate_number: docData.certificate_number,
                chain_of_custody_option: docData.chain_of_custody_option,
                contract_number: docData.contract_number,
                default_value_applied_flag: docData.default_value_applied_flag,
                eu_red_compliant_flag: docData.eu_red_compliant_flag,
                ghg_eec: docData.ghg_eec,
                ghg_eccr: docData.ghg_eccr,
                ghg_eccs: docData.ghg_eccs,
                ghg_el: docData.ghg_el,
                ghg_emission_percentage_in: docData.ghg_emission_percentage_in,
                ghg_ep: docData.ghg_ep,
                ghg_esca: docData.ghg_esca,
                ghg_etd: docData.ghg_etd,
                ghg_eu: docData.ghg_eu,
                ghg_total: docData.ghg_total,
                iscc_compliant_flag: docData.iscc_compliant_flag,
                material_dispatch_datez: docData.material_dispatch_datez,
                material_sustainability_criteria_flag:
                  docData.material_sustainability_criteria_flag,
                nuts2_region: docData.nuts2_region,
                producer_installation_date: docData.producer_installation_date,
                product_energy_content: docData.product_energy_content,
                product_qty: docData.product_qty,
                product_qty_uom: docData.product_qty_uom,
                product_type: docData.product_type,
                raw_material_origin_country_name:
                  docData.raw_material_origin_country_name,
                raw_material_type: docData.raw_material_type,
                raw_material_waste_or_residue_flag:
                  docData.raw_material_waste_or_residue_flag,
                recipient_address: docData.recipient_address,
                recipient_name: docData.recipient_name,
                recipient_receipt_address: docData.recipient_receipt_address,
                sd_number_new: docData.sd_number,
                sd_number_old: sdNumberOld,
                supplier_address: docData.supplier_address,
                supplier_dispatch_address: docData.supplier_dispatch_address,
                supplier_name: docData.supplier_name,
                user_installation_date: docData.user_installation_date,
              },
            },
          }
        : {
            event: {
              division_id: DIVISION_ID,
              site_reference_id: SITE_REFERENCE_ID,
              user_action: userAction,
              user_id: account.username,
              sd_number: docData.sd_number,
              additional_notes: docData.additional_notes,
              rejection_reason: docData.rejection_reason,
              replace_previous_version: docData.replace_previous_version,
              previous_version_sd_number: docData.previous_version_sd_number,
            },
          };
    await updateSafDocumentMutation({
      variables,
    });
    navigate({
      pathname: "/SAFdocumentpage",
      search: `?docId=${docId}`,
    });
  };

  if (loading || inActiveDataLoading) {
    return (
      <div className="flex flex-col min-h-full items-center py-12 bp-core bg-light-gray-100">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="view-document flex flex-col view-doc__max-scroll bp-core bg-light-gray-100 ">
      <Modal size="md" isOpen={acceptModal} className="modal-dialog-centered">
        <ModalHeader
          className="border-b-2 border-gray-200 mb-2 text-xl"
          close={
            <Close
              onClick={() => {
                setAcceptModal(false);
              }}
            />
          }
        >
          {status === "ready_to_assign"
            ? "Are you sure to update this document?"
            : "Are you sure to accept this document?"}
        </ModalHeader>
        <ModalBody className="text-end p-4">
          <Button
            color="tertiary"
            outline
            type="button"
            onClick={() => {
              setAcceptModal(false);
            }}
            className="show rounded-none mr-3"
            disabled={submitLoading}
          >
            No
          </Button>
          <Button
            type="button"
            color="standard-primary"
            className="primary-btn rounded-none"
            onClick={() => onSubmit("accept")}
            disabled={submitLoading}
          >
            {submitLoading ? (
              <Spinner size="sm" className="btn-icon-prefix" />
            ) : (
              <Check24 className="btn-icon-prefix" />
            )}
            Yes
          </Button>
        </ModalBody>
      </Modal>
      <div className="flex flex-1 flex-row justify-between items-stretch min-h-full w-full">
        <div className="flex flex-col flex-1 max-h-screen overflow-y-auto">
          <Layout
            title={renderTitle(status)}
            breadcrumbs={breadcrumbItems}
            subtitle={
              status !== "completed" ? (
                <Subtitle
                  className="px-7"
                  status={status}
                  errors={errors}
                  onClick={(tab) => {
                    handleErrorMessageClick(tab);
                  }}
                  formData={values}
                />
              ) : (
                <span />
              )
            }
          >
            <div className="flex flex-col p-7 min-h-[800px]">
              <FormProvider {...methods}>
                <Form className="flex flex-col gap-8">
                  <FormGroup>
                    <Label for="sd_number" className="fw-normal mb-4">
                      Unique number of the PoS
                    </Label>
                    <Input
                      type="text"
                      id="sd_number"
                      {...computeProps("sd_number", {
                        required: "Please enter a sd number",
                      })}
                      invalid={!!errors.sd_number}
                      maxLength={100}
                      disabled={isDisabled}
                    />
                    {errors.sd_number &&
                      errors.sd_number.type !== "required" && (
                        <FormFeedback className="mt-0">
                          {errors.sd_number.message}
                        </FormFeedback>
                      )}
                  </FormGroup>
                  <FormGroup>
                    <Label for="date_of_issuance" className="fw-normal mb-4">
                      Date of issuance
                    </Label>
                    <Datepicker
                      id="date_of_issuance"
                      name="date_of_issuance"
                      control={control}
                      error={errors.date_of_issuance}
                      rules={{required: "Please enter date of issuance"}}
                      disabled={isDisabled}
                      format="d/m/Y"
                    />
                  </FormGroup>
                  <Accordion open={open} {...{toggle}} flush>
                    <AccordionItem>
                      <AccordionHeader targetId="supplier">
                        {hasErrors && invalidTabs.supplier && (
                          <Alert32 className="text-danger accordion-btn-icon-prefix" />
                        )}
                        <h5 className="accordion-title fw-light text-black text-xl opacity-100">
                          Supplier and recipient
                        </h5>
                      </AccordionHeader>
                      <AccordionBody accordionId="supplier" className="mb-4">
                        <div className="flex flex-col gap-5 pb-8">
                          <FormGroup>
                            <Label
                              for="supplier_name"
                              className="fw-normal mb-4"
                            >
                              Name of supplier
                            </Label>
                            <Input
                              type="text"
                              id="supplier_name"
                              {...computeProps("supplier_name", {
                                required: "Please enter name of supplier",
                              })}
                              data-test="supplier_name"
                              invalid={!!errors.supplier_name}
                              maxLength={50}
                              disabled={isDisabled}
                            />
                            {errors.supplier_name && (
                              <FormFeedback className="mt-2">
                                {errors.supplier_name.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="supplier_address"
                              className="fw-normal mb-4"
                            >
                              Address of supplier
                            </Label>
                            <Input
                              type="text"
                              id="supplier_address"
                              {...computeProps("supplier_address", {
                                required: "Please enter address of supplier",
                              })}
                              data-test="supplier_address"
                              invalid={!!errors.supplier_address}
                              maxLength={100}
                              disabled={isDisabled}
                            />
                            {errors.supplier_address && (
                              <FormFeedback className="mt-2">
                                {errors.supplier_address?.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="certification_system"
                              className="fw-normal mb-4"
                            >
                              Certification system
                            </Label>
                            <Input
                              type="select"
                              id="certification_system"
                              data-test="certification_system"
                              {...computeProps("certification_system", {
                                required:
                                  "Please select a certification system",
                              })}
                              invalid={!!errors.certification_system}
                              disabled={isDisabled}
                            >
                              <option value="ISCC EU">ISCC EU</option>
                            </Input>
                            {errors.certification_system && (
                              <FormFeedback className="mt-2">
                                {errors.certification_system?.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="certificate_number"
                              className="fw-normal mb-4"
                            >
                              Certificate number
                            </Label>
                            <Input
                              type="text"
                              id="certificate_number"
                              data-test="certificate_number"
                              {...computeProps("certificate_number", {
                                required: "Please enter certificate number",
                              })}
                              invalid={!!errors.certificate_number}
                              maxLength={50}
                              disabled={isDisabled}
                            />
                            {errors.certificate_number ? (
                              <FormFeedback className="mt-2">
                                {errors.certificate_number.message}
                              </FormFeedback>
                            ) : (
                              <span className="text-xs text-gray-700">
                                Valid certificate
                              </span>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="recipient_name"
                              className="fw-normal mb-4"
                            >
                              Name of recipient
                            </Label>
                            <Input
                              type="text"
                              id="recipient_name"
                              data-test="recipient_name"
                              {...computeProps("recipient_name", {
                                required: "Please enter name of recipient",
                              })}
                              invalid={!!errors.recipient_name}
                              maxLength={50}
                              disabled={isDisabled}
                            />
                            {errors.recipient_name && (
                              <FormFeedback className="mt-2">
                                {errors.recipient_name.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="recipient_address"
                              className="fw-normal mb-4"
                            >
                              Address of recipient
                            </Label>
                            <Input
                              type="text"
                              id="recipient_address"
                              data-test="recipient_address"
                              {...computeProps("recipient_address", {
                                required: "Please enter address of recipient",
                              })}
                              invalid={!!errors.recipient_address}
                              maxLength={100}
                              disabled={isDisabled}
                            />
                            {errors.recipient_address && (
                              <FormFeedback className="mt-2">
                                {errors.recipient_address.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="contract_number"
                              className="fw-normal mb-4"
                            >
                              Contract number
                            </Label>
                            <Input
                              type="text"
                              id="contract_number"
                              data-test="contract_number"
                              {...computeProps("contract_number", {
                                required: "Please enter contact number",
                              })}
                              invalid={!!errors.contract_number}
                              maxLength={50}
                              disabled={isDisabled}
                            />
                            {errors.contract_number && (
                              <FormFeedback className="mt-2">
                                {errors.contract_number.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="supplier_dispatch_address"
                              className="fw-normal mb-4"
                            >
                              Address of dispatch
                            </Label>
                            <Input
                              type="text"
                              id="supplier_dispatch_address"
                              data-test="supplier_dispatch_address"
                              {...computeProps("supplier_dispatch_address", {
                                required: "Please enter address of dispatch",
                              })}
                              invalid={!!errors.supplier_dispatch_address}
                              maxLength={100}
                              disabled={isDisabled}
                            />
                            {errors.supplier_dispatch_address && (
                              <FormFeedback className="mt-2">
                                {errors.supplier_dispatch_address.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="recipient_receipt_address"
                              className="fw-normal mb-4"
                            >
                              Address of receipt/receiving point of the
                              sustainable material
                            </Label>
                            <Input
                              type="text"
                              id="recipient_receipt_address"
                              data-test="recipient_receipt_address"
                              {...computeProps("recipient_receipt_address", {
                                required:
                                  "Please enter address of receipt/receiving point of the sustainable material",
                              })}
                              invalid={!!errors.recipient_receipt_address}
                              maxLength={100}
                              disabled={isDisabled}
                            />
                            {errors.recipient_receipt_address && (
                              <FormFeedback className="mt-2">
                                {errors.recipient_receipt_address.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="material_dispatch_datez"
                              className="fw-normal mb-4"
                            >
                              Date of dispatch
                            </Label>
                            <Datepicker
                              id="material_dispatch_datez"
                              name="material_dispatch_datez"
                              control={control}
                              error={errors.material_dispatch_datez}
                              rules={{
                                required: "Please enter date of dispatch",
                              }}
                              disabled={isDisabled}
                            />
                          </FormGroup>
                          <div className="flex">
                            <FormGroup className="flex flex-col flex-1">
                              <Label
                                for="bio_product_producer"
                                className="fw-normal mb-4"
                              >
                                Producer of biofuel/bioliquid/biomass fuel
                              </Label>
                              <div className="h-full flex items-center">
                                <FormGroup
                                  check
                                  inline
                                  className="form-check-alt form-check-lg !mb-0"
                                >
                                  <Input
                                    type="checkbox"
                                    id="bio_product_producer"
                                    data-test="bio_product_producer"
                                    {...computeProps("bio_product_producer")}
                                    invalid={!!errors.bio_product_producer}
                                    disabled={isDisabled}
                                  />
                                  <Label check for="bio_product_producer">
                                    Yes
                                  </Label>
                                </FormGroup>
                              </div>
                            </FormGroup>
                            <FormGroup className="flex flex-col flex-1">
                              <Label
                                for="producer_installation_date"
                                className="fw-normal"
                              >
                                Date of installation
                              </Label>
                              <span className="mt-[-4px]">
                                <Datepicker
                                  id="producer_installation_date"
                                  name="producer_installation_date"
                                  control={control}
                                  error={errors.producer_installation_date}
                                  disabled={isDisabled}
                                />
                              </span>
                            </FormGroup>
                          </div>
                          <div className="flex">
                            <FormGroup className="flex flex-col flex-1">
                              <Label
                                for="bio_product_user"
                                className="fw-normal mb-4"
                              >
                                User of bioliquid/biomass fuel
                              </Label>
                              <div className="h-full flex items-center">
                                <FormGroup
                                  check
                                  inline
                                  className="form-check-alt form-check-lg !mb-0"
                                >
                                  <Input
                                    type="checkbox"
                                    id="bio_product_user"
                                    data-test="bio_product_user"
                                    {...computeProps("bio_product_user")}
                                    invalid={!!errors.bio_product_user}
                                    disabled={isDisabled}
                                  />
                                  <Label check for="bio_product_user">
                                    Yes
                                  </Label>
                                </FormGroup>
                              </div>
                            </FormGroup>
                            <FormGroup className="flex flex-col flex-1">
                              <Label
                                for="user_installation_date"
                                className="fw-normal"
                              >
                                Date of installation
                              </Label>
                              <span className="mt-[-4px]">
                                <Datepicker
                                  id="user_installation_date"
                                  name="user_installation_date"
                                  control={control}
                                  error={errors.producer_installation_date}
                                  disabled={isDisabled}
                                />
                              </span>
                            </FormGroup>
                          </div>
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="general">
                        {hasErrors && invalidTabs.general && (
                          <Alert32 className="text-danger accordion-btn-icon-prefix" />
                        )}
                        <h5 className="accordion-title fw-light text-black text-xl opacity-100">
                          General information
                        </h5>
                      </AccordionHeader>
                      <AccordionBody accordionId="general" className="mb-4">
                        <div className="flex flex-col gap-5 pb-8">
                          <FormGroup>
                            <Label
                              for="product_type"
                              className="fw-normal mb-4"
                            >
                              Type of product
                            </Label>
                            <Input
                              type="select"
                              id="product_type"
                              data-test="product_type"
                              {...computeProps("product_type", {
                                required: "Please enter type of product",
                              })}
                              invalid={!!errors.product_type}
                              maxLength={100}
                              disabled={isDisabled}
                              placeholder="Please choose from list"
                            >
                              <option value="">Please choose from list</option>
                              {productTypeItems.map(
                                (productTypeItem, index) => (
                                  <option
                                    key={index}
                                    value={productTypeItem.product_name}
                                  >
                                    {productTypeItem.product_name}
                                  </option>
                                ),
                              )}
                            </Input>
                            {errors.product_type && (
                              <FormFeedback className="mt-2">
                                {errors.product_type.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="raw_material_type"
                              className="fw-normal mb-4"
                            >
                              Type of raw material
                            </Label>

                            <Input
                              type="select"
                              id="raw_material_type"
                              data-test="raw_material_type"
                              {...computeProps("raw_material_type", {
                                required: "Please enter type of raw material",
                              })}
                              invalid={!!errors.raw_material_type}
                              maxLength={100}
                              disabled={isDisabled}
                              placeholder="Please choose from list"
                            >
                              <option value="">Please choose from list</option>

                              {rawMaterialTypes.length > 0 &&
                                rawMaterialTypes.map((p, i) => (
                                  <option key={i} value={p.raw_material_name}>
                                    {p.raw_material_name}
                                  </option>
                                ))}
                            </Input>

                            {errors.raw_material_type && (
                              <FormFeedback className="mt-2">
                                {errors.raw_material_type.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="additional_info"
                              className="fw-normal mb-4"
                            >
                              Additional information (voluntary)
                            </Label>
                            <Input
                              type="textarea"
                              rows="3"
                              id="additional_info"
                              data-test="additional_info"
                              {...computeProps("additional_info")}
                              invalid={!!errors.additional_info}
                              maxLength={250}
                              disabled={isDisabled}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="raw_material_origin_country_name"
                              className="fw-normal mb-4"
                            >
                              Country of origin of the origin raw material
                            </Label>
                            <Input
                              type="select"
                              id="raw_material_origin_country_name"
                              data-test="raw_material_origin_country_name"
                              {...computeProps(
                                "raw_material_origin_country_name",
                                {
                                  required: "Please enter country of origin",
                                },
                              )}
                              invalid={
                                !!errors.raw_material_origin_country_name
                              }
                              disabled={isDisabled}
                            >
                              {countries &&
                                countries?.length > 0 &&
                                countries.map((country) => (
                                  <option
                                    key={country.code}
                                    value={country.name}
                                  >
                                    {country.name}
                                  </option>
                                ))}
                            </Input>
                            {errors.raw_material_origin_country_name && (
                              <FormFeedback className="mt-2">
                                {
                                  errors.raw_material_origin_country_name
                                    .message
                                }
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label for="product_qty" className="fw-normal mb-4">
                              Quantity
                            </Label>
                            <Input
                              type="number"
                              id="product_qty"
                              data-test="product_qty"
                              {...computeProps("product_qty", {
                                required: "Please enter quantity",
                                pattern: {
                                  value: /^(\d{1,10}(\.\d{1,5})?)$/,
                                  message:
                                    "Value must be number with (max 10 digits before and 5 digits after decimal point)",
                                },
                              })}
                              rules={{
                                validate: (value) =>
                                  value > 0
                                    ? true
                                    : "Please enter a valid quantity",
                              }}
                              invalid={!!errors.product_qty}
                              disabled={isDisabled}
                            />

                            {errors.product_qty && (
                              <FormFeedback className="mt-2">
                                {errors.product_qty.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          {/* SEND M3 or MT in mutation */}
                          <FormGroup>
                            <Label
                              for="product_qty_uom"
                              className="fw-normal mb-4"
                            >
                              Unit of measure
                            </Label>
                            <Controller
                              // rules={rules}
                              render={({field: {onChange, value, ref}}) => {
                                const m3 = {checked: value === "M3"};
                                const metricTons = {checked: value === "MT"};
                                return (
                                  <>
                                    <div>
                                      <FormGroup
                                        check
                                        inline
                                        className="form-check-alt form-check-lg !mb-0"
                                      >
                                        <Input
                                          innerRef={ref}
                                          id="m3"
                                          type="radio"
                                          data-test="m3"
                                          invalid={!!errors.product_qty_uom}
                                          disabled={isDisabled}
                                          onChange={(e) =>
                                            onChange(e.target.checked)
                                          }
                                          {...m3}
                                        />
                                        <Label check for="m3">
                                          m³
                                        </Label>
                                      </FormGroup>
                                      <FormGroup
                                        check
                                        inline
                                        className="form-check-alt form-check-lg !mb-0"
                                      >
                                        <Input
                                          id="metricTons"
                                          data-test="metricTons"
                                          type="radio"
                                          invalid={!!errors.product_qty_uom}
                                          disabled={isDisabled}
                                          onChange={(e) =>
                                            onChange(!e.target.checked)
                                          }
                                          {...metricTons}
                                        />
                                        <Label check for="metricTons">
                                          Metric tons
                                        </Label>
                                      </FormGroup>
                                    </div>
                                    {errors.product_qty_uom && (
                                      <FormFeedback
                                        className="mt-2"
                                        style={{display: "block"}}
                                      >
                                        {errors.product_qty_uom.message}
                                      </FormFeedback>
                                    )}
                                  </>
                                );
                              }}
                              control={control}
                              name="product_qty_uom"
                              defaultValue=""
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="product_energy_content"
                              className="fw-normal mb-4"
                            >
                              Energy content (MJ)
                            </Label>
                            <Input
                              type="number"
                              id="product_energy_content"
                              data-test="product_energy_content"
                              {...computeProps("product_energy_content", {
                                required: "Please enter quantity",
                              })}
                              rules={{
                                validate: (value) =>
                                  value > 0
                                    ? true
                                    : "Please enter a valid quantity",
                              }}
                              invalid={!!errors.product_energy_content}
                              disabled={isDisabled}
                            />

                            {errors.product_energy_content && (
                              <FormFeedback className="mt-2">
                                {errors.product_energy_content.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="eu_red_compliant_flag"
                              className="fw-normal mb-4"
                            >
                              EU RED Compliant material
                            </Label>
                            <Controller
                              rules={{
                                validate: (value) => {
                                  return (
                                    value !== null || "Please select an option"
                                  );
                                },
                              }}
                              render={({field: {onChange, value, ref}}) => {
                                const Yes = {checked: value === true};
                                const No = {checked: value === false};
                                return (
                                  <>
                                    <div>
                                      <FormGroup
                                        check
                                        inline
                                        className="form-check-alt form-check-lg !mb-0"
                                      >
                                        <Input
                                          innerRef={ref}
                                          id="EuYes"
                                          type="radio"
                                          data-test="EuYes"
                                          invalid={
                                            !!errors.eu_red_compliant_flag
                                          }
                                          disabled={isDisabled}
                                          onChange={(e) =>
                                            onChange(e.target.checked)
                                          }
                                          {...Yes}
                                        />
                                        <Label check for="EuYes">
                                          Yes
                                        </Label>
                                      </FormGroup>
                                      <FormGroup
                                        check
                                        inline
                                        className="form-check-alt form-check-lg !mb-0"
                                      >
                                        <Input
                                          id="EuNo"
                                          data-test="EuNo"
                                          type="radio"
                                          invalid={
                                            !!errors.eu_red_compliant_flag
                                          }
                                          disabled={isDisabled}
                                          onChange={(e) =>
                                            onChange(!e.target.checked)
                                          }
                                          {...No}
                                        />
                                        <Label check for="EuNo">
                                          No
                                        </Label>
                                      </FormGroup>
                                    </div>
                                    {errors.eu_red_compliant_flag && (
                                      <FormFeedback
                                        className="mt-2"
                                        style={{display: "block"}}
                                      >
                                        {errors.eu_red_compliant_flag.message}
                                      </FormFeedback>
                                    )}
                                  </>
                                );
                              }}
                              control={control}
                              name="eu_red_compliant_flag"
                              defaultValue=""
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="iscc_compliant_flag"
                              className="fw-normal mb-4"
                            >
                              ISCC Compliant material (voluntary)
                            </Label>
                            <Controller
                              // rules={rules}
                              render={({field: {onChange, value, ref}}) => {
                                const Yes = {checked: value === true};
                                const No = {checked: value === false};
                                return (
                                  <>
                                    <div>
                                      <FormGroup
                                        check
                                        inline
                                        className="form-check-alt form-check-lg !mb-0"
                                      >
                                        <Input
                                          innerRef={ref}
                                          id="ISCCYes"
                                          type="radio"
                                          data-test="ISCCYes"
                                          invalid={!!errors.iscc_compliant_flag}
                                          disabled={isDisabled}
                                          onChange={(e) =>
                                            onChange(e.target.checked)
                                          }
                                          {...Yes}
                                        />
                                        <Label check for="ISCCYes">
                                          Yes
                                        </Label>
                                      </FormGroup>
                                      <FormGroup
                                        check
                                        inline
                                        className="form-check-alt form-check-lg !mb-0"
                                      >
                                        <Input
                                          id="ISCCNo"
                                          data-test="ISCCNo"
                                          type="radio"
                                          invalid={!!errors.iscc_compliant_flag}
                                          disabled={isDisabled}
                                          onChange={(e) =>
                                            onChange(!e.target.checked)
                                          }
                                          {...No}
                                        />
                                        <Label check for="ISCCNo">
                                          No
                                        </Label>
                                      </FormGroup>
                                    </div>
                                    {errors.iscc_compliant_flag && (
                                      <FormFeedback
                                        className="mt-2"
                                        style={{display: "block"}}
                                      >
                                        {errors.iscc_compliant_flag.message}
                                      </FormFeedback>
                                    )}
                                  </>
                                );
                              }}
                              control={control}
                              name="iscc_compliant_flag"
                              defaultValue=""
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="chain_of_custody_option"
                              className="fw-normal mb-4"
                            >
                              Chain of custody option (voluntary)
                            </Label>
                            <Input
                              type="select"
                              id="chain_of_custody_option"
                              data-test="chain_of_custody_option"
                              {...computeProps("chain_of_custody_option")}
                              invalid={!!errors.chain_of_custody_option}
                              disabled={isDisabled}
                            >
                              <option value="">Please select</option>
                              {chainOfCustody.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="certification">
                        {hasErrors && invalidTabs.certification && (
                          <Alert32 className="text-danger accordion-btn-icon-prefix" />
                        )}
                        <h5 className="accordion-title fw-light text-black text-xl opacity-100">
                          Scope of certification of raw material
                        </h5>
                      </AccordionHeader>
                      <AccordionBody
                        accordionId="certification"
                        className="mb-4"
                      >
                        <div className="flex flex-col gap-5 pb-8">
                          <FormGroup>
                            <Label
                              for="material_sustainability_criteria_flag"
                              className="fw-normal mb-4"
                            >
                              The raw material complies with the relevant
                              sustainability criteria according to Art. 29 (2) -
                              (7) RED II
                            </Label>
                            <RadioFlag
                              type="radio"
                              control={control}
                              name="material_sustainability_criteria_flag"
                              error={
                                errors.material_sustainability_criteria_flag
                              }
                              disabled={isDisabled}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="agricultural_biomass_intermediate_crop_flag"
                              className="fw-normal mb-4"
                            >
                              The agricultural biomass was cultivated as
                              intermediate crop (if applicable)
                            </Label>
                            <RadioFlag
                              type="radio"
                              control={control}
                              name="agricultural_biomass_intermediate_crop_flag"
                              error={
                                errors.agricultural_biomass_intermediate_crop_flag
                              }
                              disabled={isDisabled}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="agricultural_biomass_low_risk_flag"
                              className="fw-normal mb-4"
                            >
                              The agricultural biomass additionally fulfills the
                              measures for low ILUC risk feedstocks (if
                              applicable)
                            </Label>
                            <RadioFlag
                              type="radio"
                              control={control}
                              name="agricultural_biomass_low_risk_flag"
                              error={errors.agricultural_biomass_low_risk_flag}
                              disabled={isDisabled}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="raw_material_waste_or_residue_flag"
                              className="fw-normal mb-4"
                            >
                              The raw material meets the definition of waste or
                              residue according to the RED II
                            </Label>
                            <RadioFlag
                              type="radio"
                              control={control}
                              name="raw_material_waste_or_residue_flag"
                              error={errors.raw_material_waste_or_residue_flag}
                              disabled={isDisabled}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="nuts2_region"
                              className="fw-normal mb-4"
                            >
                              If applicable, please specify NUTS 2 region
                            </Label>
                            <Input
                              type="text"
                              id="nuts2_region"
                              data-test="nuts2_region"
                              {...computeProps("nuts2_region")}
                              invalid={!!errors.nuts2_region}
                              disabled={isDisabled}
                              maxLength={100}
                            />
                            {errors.nuts2_region && (
                              <FormFeedback className="mt-2">
                                {errors.nuts2_region.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="emission">
                        {hasErrors && invalidTabs.emission && (
                          <Alert32 className="text-danger accordion-btn-icon-prefix" />
                        )}
                        <h5 className="accordion-title fw-light text-black text-xl opacity-100">
                          Greenhouse gas (GHG) emission information
                        </h5>
                      </AccordionHeader>
                      <AccordionBody accordionId="emission" className="mb-4">
                        <div className="flex flex-col gap-5 pb-8">
                          <FormGroup>
                            <Label
                              for="default_value_applied_flag"
                              className="fw-normal mb-4"
                            >
                              Total default value according to RED II applied
                            </Label>
                            <RadioFlag
                              type="radio"
                              control={control}
                              name="default_value_applied_flag"
                              error={errors.default_value_applied_flag}
                              disabled={isDisabled}
                              rules={{
                                validate: (value) => {
                                  return (
                                    value !== null || "Please select an option"
                                  );
                                },
                              }}
                            />
                          </FormGroup>
                          <div className="flex flex-row justify-between items-start gap-3">
                            <FormGroup>
                              <Label for="ghg_eec" className="fw-normal">
                                Eec
                              </Label>
                              <Input
                                type="number"
                                id="ghg_eec"
                                data-test="ghg_eec"
                                {...computeProps("ghg_eec", numberInputPattern)}
                                invalid={!!errors.ghg_eec}
                                disabled={isDisabled}
                              />
                              {errors.ghg_eec && (
                                <FormFeedback className="mt-2">
                                  {errors.ghg_eec?.message}
                                </FormFeedback>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_el" className="fw-normal">
                                + El
                              </Label>
                              <Input
                                type="number"
                                id="ghg_el"
                                data-test="ghg_el"
                                {...computeProps("ghg_el", numberInputPattern)}
                                invalid={!!errors.ghg_el}
                                disabled={isDisabled}
                              />
                              {errors.ghg_el && (
                                <FormFeedback className="mt-2">
                                  {errors.ghg_el?.message}
                                </FormFeedback>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_ep" className="fw-normal">
                                + Ep
                              </Label>
                              <Input
                                type="number"
                                id="ghg_ep"
                                data-test="ghg_ep"
                                {...computeProps("ghg_ep", numberInputPattern)}
                                invalid={!!errors.ghg_ep}
                                disabled={isDisabled}
                              />
                              {errors.ghg_ep && (
                                <FormFeedback className="mt-2">
                                  {errors.ghg_ep?.message}
                                </FormFeedback>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_etd" className="fw-normal">
                                + Etd
                              </Label>
                              <Input
                                type="text"
                                id="ghg_etd"
                                data-test="ghg_etd"
                                {...computeProps("ghg_etd", {
                                  pattern: {
                                    value: /^(DDV$)|^(\d{1,10}(\.\d{1,5})?)$/,
                                    message:
                                      "Value must be number with (max 10 digits before and 5 digits after decimal point) or DDV",
                                  },
                                })}
                                invalid={!!errors.ghg_etd}
                                disabled={isDisabled}
                              />
                              {errors.ghg_etd && (
                                <FormFeedback className="mt-2">
                                  {errors.ghg_etd?.message}
                                </FormFeedback>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_eu" className="fw-normal">
                                + Eu 7
                              </Label>
                              <Input
                                type="number"
                                id="ghg_eu"
                                data-test="ghg_eu"
                                {...computeProps("ghg_eu", numberInputPattern)}
                                invalid={!!errors.ghg_eu}
                                disabled={isDisabled}
                              />
                              {errors.ghg_eu && (
                                <FormFeedback className="mt-2">
                                  {errors.ghg_eu?.message}
                                </FormFeedback>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_esca" className="fw-normal">
                                - Esca
                              </Label>
                              <Input
                                type="number"
                                id="ghg_esca"
                                data-test="ghg_esca"
                                {...computeProps(
                                  "ghg_esca",
                                  numberInputPattern,
                                )}
                                invalid={!!errors.ghg_esca}
                                disabled={isDisabled}
                              />
                              {errors.ghg_esca && (
                                <FormFeedback className="mt-2">
                                  {errors.ghg_esca?.message}
                                </FormFeedback>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_eccs" className="fw-normal">
                                - Eccs
                              </Label>
                              <Input
                                type="number"
                                id="ghg_eccs"
                                data-test="ghg_eccs"
                                {...computeProps(
                                  "ghg_eccs",
                                  numberInputPattern,
                                )}
                                invalid={!!errors.ghg_eccs}
                                disabled={isDisabled}
                              />
                              {errors.ghg_eccs && (
                                <FormFeedback className="mt-2">
                                  {errors.ghg_eccs?.message}
                                </FormFeedback>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_eccr" className="fw-normal">
                                - Eccr
                              </Label>
                              <Input
                                type="number"
                                id="ghg_eccr"
                                data-test="ghg_eccr"
                                {...computeProps(
                                  "ghg_eccr",
                                  numberInputPattern,
                                )}
                                invalid={!!errors.ghg_eccr}
                                disabled={isDisabled}
                              />
                              {errors.ghg_eccr && (
                                <FormFeedback className="mt-2">
                                  {errors.ghg_eccr?.message}
                                </FormFeedback>
                              )}
                            </FormGroup>
                          </div>

                          <FormGroup>
                            <Label for="ghg_total" className="fw-normal mb-4">
                              Total GHG emissions (gCO2eq/MJ)
                            </Label>
                            <Input
                              type="text"
                              id="ghg_total"
                              data-test="ghg_total"
                              {...computeProps("ghg_total", {
                                pattern: {
                                  value:
                                    /^(TDV$)|^(DDV$)|^(\d{1,10}(\.\d{1,5})?)$/,
                                  message:
                                    "Value must be number with (max 10 digits before and 5 digits after decimal point) or TDV or DDV",
                                },
                                required: "Please enter total GHG emissions",
                              })}
                              invalid={!!errors.ghg_total}
                              disabled={isDisabled}
                            />
                            {errors.ghg_total && (
                              <FormFeedback className="mt-2">
                                {errors.ghg_total?.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="allocated_heat"
                              className="fw-normal mb-4"
                            >
                              Allocated heat (gCO2eq/MJ heat)
                            </Label>
                            <Input
                              type="text"
                              id="allocated_heat"
                              data-test="allocated_heat"
                              {...computeProps("allocated_heat", {
                                pattern: {
                                  value:
                                    /^(TDV$)|^(DDV$)|^(\d{1,10}(\.\d{1,5})?)$/,
                                  message:
                                    "Value must be number with (max 10 digits before and 5 digits after decimal point) or TDV or DDV",
                                },
                              })}
                              invalid={!!errors.allocated_heat}
                              disabled={isDisabled}
                            />
                            {errors.allocated_heat && (
                              <FormFeedback className="mt-2">
                                {errors.allocated_heat?.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="allocated_electricity"
                              className="fw-normal mb-4"
                            >
                              Allocated electricity (gCO2eq/MJ electricity)
                            </Label>
                            <Input
                              type="text"
                              id="allocated_electricity"
                              data-test="allocated_electricity"
                              {...computeProps("allocated_electricity", {
                                pattern: {
                                  value:
                                    /^(TDV$)|^(DDV$)|^(\d{1,10}(\.\d{1,5})?)$/,
                                  message:
                                    "Value must be number with (max 10 digits before and 5 digits after decimal point) or TDV or DDV",
                                },
                              })}
                              invalid={!!errors.allocated_electricity}
                              disabled={isDisabled}
                            />
                            {errors.allocated_electricity && (
                              <FormFeedback className="mt-2">
                                {errors.allocated_electricity?.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="ghg_emission_percentage_in"
                              className="fw-normal mb-4"
                            >
                              GHG emission saving (%)
                            </Label>
                            <Input
                              type="text"
                              id="ghg_emission_percentage_in"
                              data-test="ghg_emission_percentage_in"
                              {...computeProps("ghg_emission_percentage_in", {
                                pattern: {
                                  value:
                                    /^(TDV$)|^(DDV$)|^(\d{1,10}(\.\d{1,5})?)$/,
                                  message:
                                    "Value must be number with (max 10 digits before and 5 digits after decimal point) or TDV or DDV",
                                },
                                required: "Please enter GHG emission savings",
                              })}
                              invalid={!!errors.ghg_emission_percentage_in}
                              disabled={isDisabled}
                            />
                            <span className="text-xs text-gray-700">
                              Biofuels for transport
                            </span>
                            {errors.ghg_emission_percentage_in && (
                              <FormFeedback className="mt-2">
                                {errors.ghg_emission_percentage_in?.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="additionalInfo">
                        {hasErrors && invalidTabs.additionalInfo && (
                          <Alert32 className="text-danger accordion-btn-icon-prefix" />
                        )}
                        <h5 className="accordion-title fw-light text-black text-xl opacity-100">
                          About this document
                        </h5>
                      </AccordionHeader>
                      <AccordionBody
                        accordionId="additionalInfo"
                        className="mb-4"
                      >
                        <div className="flex flex-col gap-5 pb-8">
                          <FormGroup>
                            <Label
                              for="replace_previous_version"
                              className="fw-normal mb-4"
                            >
                              Does this document replace any previous version?
                            </Label>
                            <CustomRadioButton
                              type="radio"
                              control={control}
                              name="replace_previous_version"
                              error={errors.replace_previous_version}
                              disabled={status === "rejected"}
                              selectedValue={setShowPrevVersion}
                            />
                          </FormGroup>
                          {showPrevVersion && (
                            <FormGroup>
                              <Label
                                for="previous_version_sd_number"
                                className="fw-normal mb-4"
                              >
                                Select a previous version
                              </Label>
                              <Input
                                type="select"
                                id="previous_version_sd_number"
                                data-test="previous_version_sd_number"
                                {...computeProps("previous_version_sd_number", {
                                  required:
                                    "Please select a certification system",
                                })}
                                invalid={!!errors.previous_version_sd_number}
                                disabled={status === "rejected"}
                              >
                                <option value="">
                                  Please select a previous version
                                </option>
                                {data?.bioLcSafGetVersioningDocs?.documents?.map(
                                  (doc) => (
                                    <option
                                      key={doc.sd_number}
                                      value={doc.sd_number}
                                    >
                                      {doc.sd_number}
                                    </option>
                                  ),
                                )}
                              </Input>
                              {errors.previous_version_sd_number && (
                                <FormFeedback className="mt-2">
                                  {errors.previous_version_sd_number?.message}
                                </FormFeedback>
                              )}
                            </FormGroup>
                          )}
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                  <div className="flex flex-row justify-end items-center gap-4">
                    {status === "rejected" && (
                      <Button
                        color={
                          status === "rejected"
                            ? "tertiary"
                            : "standard-primary"
                        }
                        className={
                          status === "rejected"
                            ? "show rounded-none"
                            : "primary-btn rounded-none"
                        }
                        type="button"
                        onClick={() =>
                          navigate({
                            pathname: "/SAFdocumentpage",
                          })
                        }
                      >
                        <TailLeft24 className="btn-icon-prefix" />
                        Back
                      </Button>
                    )}
                    {status !== "rejected" && (
                      <>
                        <Button
                          color="tertiary"
                          outline
                          type="button"
                          onClick={() => {
                            setRejecting(!rejecting);
                          }}
                          disabled={rejecting}
                          className="show rounded-none"
                        >
                          <Remove24 className="btn-icon-prefix" />
                          Reject
                        </Button>

                        <Button
                          type="button"
                          color="standard-primary"
                          className="primary-btn rounded-none"
                          onClick={() => {
                            setAcceptModal(true);
                          }}
                          disabled={Object.keys(errors).length !== 0}
                        >
                          <Check24 className="btn-icon-prefix" />
                          {status === "ready_to_assign" ? "Update" : "Accept"}
                        </Button>
                      </>
                    )}
                  </div>
                  {rejecting && (
                    <>
                      <FormGroup>
                        <Label
                          for="rejection_reason"
                          className="fw-normal mb-4"
                        >
                          Why are you rejecting?
                        </Label>

                        <Input
                          type="select"
                          id="rejection_reason"
                          data-test="rejection_reason"
                          {...computeProps("rejection_reason", {
                            required: "Please enter reject reason",
                          })}
                          invalid={!!errors.rejection_reason}
                          maxLength={100}
                          placeholder="Please select a reason"
                          onChange={(e) => {
                            handleRejectMessage(e.target.value);
                          }}
                        >
                          <option value="">Please select a reason</option>

                          {rejectReasons.length > 0 &&
                            rejectReasons.map((p) => (
                              <option key={p.key} value={p.key}>
                                {p.value}
                              </option>
                            ))}
                        </Input>

                        {errors.rejection_reason && (
                          <FormFeedback className="mt-2">
                            {errors.rejection_reason.message}
                          </FormFeedback>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label
                          for="additional_notes"
                          className="fw-normal mb-4"
                        >
                          Additional notes (Optional)
                        </Label>
                        <Input
                          type="textarea"
                          rows="3"
                          id="additional_notes"
                          data-test="additional_notes"
                          {...computeProps("additional_notes")}
                          invalid={!!errors.additional_notes}
                          maxLength={250}
                        />
                      </FormGroup>
                      <span className="mt-[-40px]">{rejectMessage}</span>
                      <div className="flex flex-row justify-end items-center gap-4">
                        <Button
                          color="tertiary"
                          outline
                          type="button"
                          onClick={() => {
                            setRejecting(!rejecting);
                            clearErrors("rejection_reason");
                          }}
                          disabled={!rejecting}
                          className="show rounded-none"
                        >
                          <Remove24 className="btn-icon-prefix" />
                          Cancel
                        </Button>

                        <Button
                          type="button"
                          color="standard-primary"
                          className="primary-btn rounded-none"
                          onClick={() => onSubmit("reject")}
                          disabled={
                            watch("rejection_reason") === undefined ||
                            errors.rejection_reason
                          }
                        >
                          {submitLoading ? (
                            <Spinner size="sm" className="btn-icon-prefix" />
                          ) : (
                            <Check24 className="btn-icon-prefix" />
                          )}
                          Reject
                        </Button>
                      </div>
                    </>
                  )}
                </Form>
              </FormProvider>
            </div>
          </Layout>
        </div>
        <SingleDocumentViewer document={documentFile} />
      </div>
    </div>
  );
};

export default SAFDocumentViewPage;
