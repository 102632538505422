import React from "react";
import {Outlet} from "react-router-dom";
import {Helmet} from "react-helmet";
import Title from "modules/common/Title";
import {HEADER_FORECASTING} from "modules/forecasting/constants/header";
import {LABEL_FORECASTING} from "modules/forecasting/constants/inventory";
import ForecastTabs from "modules/forecasting/components/ForecastTabs";
import ForecastingFilterBar from "modules/forecasting/components/ForecastingFilter";

export default () => (
  <div data-test="forecast-root-layout" id="forecasting-container">
    <Helmet>
      <title>{HEADER_FORECASTING}</title>
    </Helmet>
    <Title title={LABEL_FORECASTING} />
    <ForecastingFilterBar />
    <ForecastTabs />
    <Outlet />
  </div>
);
