import {gql} from "@apollo/client";

export const GET_MASS_BALANCE_INCOMING_DOCS = gql`
  query bioLcSafMBGetIncomingDocs {
    bioLcSafMBGetIncomingDocs(
      event: {
        division_id: "7c5fc8889b6e3c8756abbd76353d68e7"
        site_reference_id: "c8f4261f9f46e6465709e17ebea7a92b"
      }
    ) {
      error
      statusCode
      documents {
        sd_number
        certificate_inbound_eu_id
      }
    }
  }
`;

export const GET_MASS_BALANCE_OUTGOING_DOCS = gql`
  query bioLcSafMBGetOutgoingDocs($sd_number: String) {
    bioLcSafMBGetOutgoingDocs(event: {sd_number: $sd_number}) {
      error
      statusCode
      outgoingRecords {
        certificate_number
        status
        certificate_outbound_eu_id
      }
    }
  }
`;

export const GET_MASS_BALANCE_FORM_DATA = gql`
  query bioLcSafMBGetFormData {
    bioLcSafMBGetModesOfTransportation(event: {}) {
      error
      statusCode
      modes_of_transportations {
        mode_of_transportation
      }
    }

    bioLcSafMBGetNamesOfRecipients(
      event: {division_id: "7c5fc8889b6e3c8756abbd76353d68e7"}
    ) {
      error
      statusCode
      names_of_recipients {
        recipient
      }
    }
  }
`;

export const GET_MASS_BALANCE_ADDRESS_OF_RECEIVING_POINT = gql`
  query bioLcSafMBGetAddressOfReceivingPoint($legal_entity: String) {
    bioLcSafMBGetAddressOfReceivingPoint(
      event: {
        division_id: "7c5fc8889b6e3c8756abbd76353d68e7"
        legal_entity: $legal_entity
      }
    ) {
      error
      statusCode
      addresses {
        receiving_point_address
      }
    }
  }
`;

export const SAF_MASS_BALANCE_MUTATION = gql`
  mutation bioLcSafMBAssign(
    $contract_number: String
    $mode_of_transportation: String
    $quantity: Float
    $same_as_address_of_recipient: Boolean
    $recipient_name: String
    $certificate_inbound_eu_id: String
  ) {
    bioLcSafMBAssign(
      event: {
        contract_number: $contract_number
        division_id: "7c5fc8889b6e3c8756abbd76353d68e7"
        mode_of_transportation: $mode_of_transportation
        quantity: $quantity
        same_as_address_of_recipient: $same_as_address_of_recipient
        recipient_name: $recipient_name
        certificate_inbound_eu_id: $certificate_inbound_eu_id
        site_reference_id: "c8f4261f9f46e6465709e17ebea7a92b"
      }
    ) {
      error
      statusCode
    }
  }
`;

export const SAF_OUTGOING_DRAFT_TO_GENERATED_MUTATION = gql`
  query bioLcSafUpdateOutgoingDocsData($certificate_outbound_eu_id: String) {
    bioLcSafUpdateOutgoingDocsData(
      event: {certificate_outbound_eu_id: $certificate_outbound_eu_id}
    ) {
      error
      statusCode
    }
  }
`;

export default GET_MASS_BALANCE_INCOMING_DOCS;
