import React from "react";
import PropTypes from "prop-types";
import {Nav, NavItem, NavLink, Spinner} from "reactstrap";

const SafTabs = ({
  activeTab = 1,
  setActiveTab,
  incomingDocCount = 0,
  outgoingDocCount = 0,
  loading,
}) => {
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div className="">
      <Nav className="nav-standard">
        <NavItem>
          <NavLink
            active={activeTab === "1"}
            className="h-[72px] w-[240px]"
            onClick={() => toggleTab("1")}
          >
            <span className="text-base">Incoming</span>
            <p className="text-xs">
              {loading ? (
                <Spinner style={{width: "12px", height: "12px"}} />
              ) : (
                incomingDocCount
              )}{" "}
              documents
            </p>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === "2"}
            className="h-[72px] w-[240px]"
            onClick={() => toggleTab("2")}
          >
            <span className="text-base">Outgoing</span>
            <p className="text-xs">
              {loading ? (
                <Spinner style={{width: "12px", height: "12px"}} />
              ) : (
                outgoingDocCount
              )}{" "}
              documents
            </p>
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  );
};

SafTabs.propTypes = {
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func.isRequired,
  incomingDocCount: PropTypes.number,
  outgoingDocCount: PropTypes.number,
  loading: PropTypes.bool,
};

export default SafTabs;
