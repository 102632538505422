import tw from "twin.macro";
import {PAGE_TITLE} from "modules/co-processing/constants/coProcessing";
import ShipmentView from "modules/co-processing/components/Shipments/ShipmentView";
import DataView from "modules/co-processing/components/Shipments/DataView";
import "../../components/index.scss";

const PaddedContainer = tw.div`pl-7 pr-7 bg-[#fafafa]`;
const ShipmentsPage = () => {
  return (
    <div className="co-processing-container overflow-x-auto">
      <h1 className="pb-6 mx-[40px] fs-2 border-b-[1.5px]">
        {PAGE_TITLE.SHIPMENTS}
      </h1>
      <DataView />
      <PaddedContainer>
        <ShipmentView />
      </PaddedContainer>
    </div>
  );
};

export default ShipmentsPage;
