import PropTypes from "prop-types";
import BatchesStatusChip from "modules/co-processing/components/BatchesStatusChip";
import BatchDetailDropdown from "modules/co-processing/components/Batches/BatchDetailDropdown";
import DetailItem from "modules/co-processing/components/DetailItem";
import {BATCH_DETAILS_CARD_COLUMNS} from "../../constants/batches";

const BatchDetailsCard = ({batchDetails}) => {
  return (
    <div className="flex flex-col w-full">
      <div className="bg-white">
        <div className="flex justify-between items-center px-5 py-3 border-b">
          <BatchesStatusChip value={batchDetails.batch_status} small />
          <BatchDetailDropdown batchDetails={batchDetails} expanded />
        </div>
        <div className="grid grid-row-2 px-5 py-6">
          <div className="mb-4">
            <div className="text-sm">{batchDetails.tank_number}</div>
            <div className="text-2xl">{batchDetails.batch_id}</div>
          </div>
          <div className="grid grid-cols-3 gap-4 w-full text-sm">
            {BATCH_DETAILS_CARD_COLUMNS.map((item, index) => (
              <DetailItem
                key={index}
                label={item.label}
                value={batchDetails[item.key]}
                format={item.format}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

BatchDetailsCard.propTypes = {
  batchDetails: PropTypes.shape({
    batch_status: PropTypes.string,
    tank_number: PropTypes.string,
    batch_id: PropTypes.string,
    batch_start_time: PropTypes.string,
    batch_end_time: PropTypes.string,
    operating_condition: PropTypes.string,
    tank_certified_datez: PropTypes.string,
    tank_volume: PropTypes.number,
    c14_testing_date: PropTypes.string,
  }),
};

export default BatchDetailsCard;
