import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {
  Alert24,
  CircleClose24,
  Down24,
  Info24,
  Kebab24,
  Up24,
  Flag24,
} from "@bphxd/ds-core-react/lib/icons";
import {Close} from "@bphxd/ds-core-react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {Link, useNavigate} from "react-router-dom";
import {formatNumber, useUserSettings} from "providers/userSettings";
import parseAndRoundToTwoDecimal from "utils/numberUtil";
import HighLightedText from "../OutgoingDocuments/HighLightedText";

const baseUrl = "/saf/document-manager/incoming-documents";

const getFormattedDecimal = (cell) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();
  if (cell.getValue()) {
    return formatNumber(
      parseAndRoundToTwoDecimal(cell.getValue()),
      decimalFormat,
      2,
    );
  }
  return cell.getValue();
};

const renderLink = (id, text, pageNo, cell) => (
  <Link
    to={{
      pathname: `${baseUrl}/${id}`,
      search: `?pageNo=${pageNo}`,
    }}
    className="link-dark"
  >
    <u>
      <HighLightedText
        value={text}
        globalFilter={cell.getContext().table.getState().globalFilter}
      />
    </u>
  </Link>
);

const RenderDropdown = ({
  stateMgmt,
  sd_number,
  previousVersions,
  previousVersionsCount,
  certificateInboundEuId,
}) => {
  const [versionModal, setVersionModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setVersionModal(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <>
      <Modal size="sm" isOpen={versionModal} className="modal-dialog-centered">
        <ModalHeader
          className="p-4"
          style={{
            borderBottom: "solid 1px #80808061",
          }}
          close={
            <Close
              onClick={() => {
                setVersionModal(false);
              }}
            />
          }
        >
          Version history
        </ModalHeader>
        <ModalBody
          style={{
            padding: "0px",
          }}
        >
          {previousVersions?.map((prevItem) => (
            <div
              className="flex p-3.5 border-b border-gray-400 cursor-pointer"
              style={{color: "gray"}}
            >
              <div className="mr-1.5">
                <Flag24 color="grey" />
              </div>
              <div
                className="max-w-[calc(100%-38px)]"
                onClick={() =>
                  navigate({
                    pathname: `${baseUrl}/${encodeURIComponent(
                      prevItem.linked_cert,
                    )}`,
                  })
                }
                // This will be removed in future commits
                onKeyDown={() => {}}
              >
                <p className="text-sm mb-0.5 break-words">
                  {prevItem.sd_number}
                </p>
                <p className="text-xs mb-0">
                  Rejected {prevItem.bv_audit_changed_datez}
                </p>
              </div>
            </div>
          ))}
        </ModalBody>
      </Modal>
      <UncontrolledDropdown direction="up">
        <DropdownToggle color="standard-quartenary" className="!px-0">
          <Kebab24></Kebab24>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() =>
              navigate({
                pathname: `${baseUrl}/${encodeURIComponent(
                  certificateInboundEuId,
                )}`,
              })
            }
          >
            View document
          </DropdownItem>
          <DropdownItem
            disabled={stateMgmt !== "ready_to_accept"}
            onClick={() =>
              navigate({
                pathname: `${baseUrl}/${encodeURIComponent(
                  certificateInboundEuId,
                )}`,
              })
            }
          >
            Accept the document
          </DropdownItem>
          <DropdownItem
            disabled={stateMgmt !== "ready_to_assign"}
            onClick={() =>
              navigate({
                pathname: `${baseUrl}/${encodeURIComponent(
                  certificateInboundEuId,
                )}`,
              })
            }
          >
            Assign to purchase
          </DropdownItem>
          <DropdownItem
            onClick={() => setVersionModal(true)}
            disabled={previousVersionsCount === 0}
          >
            Version history ({previousVersionsCount})
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

RenderDropdown.propTypes = {
  stateMgmt: PropTypes.string,
  sd_number: PropTypes.string,
  previousVersions: PropTypes.array,
  previousVersionsCount: PropTypes.number,
  certificateInboundEuId: PropTypes.string,
};

const docStatus = {
  ready_to_assign: {
    message: "Ready to assign",
    icon: <Info24 color="#218dcc" />,
  },
  requires_attention: {
    message: "Requires your attention",
    icon: <Alert24 color="#e64949" />,
  },
  ready_to_accept: {
    message: "Ready to accept",
    icon: <Info24 color="#ffc000" />,
  },
  rejected: {
    message: "Rejected",
    icon: <CircleClose24 color="#111111" />,
  },
  failed_to_process: {
    message: "Rejected",
    icon: <CircleClose24 color="#111111" />,
  },
};

const renderStatus = (text, cell) => {
  const {icon: defaultIcon, message: defaultMessage} =
    docStatus.failed_to_process;
  const {icon = defaultIcon, message = defaultMessage} = docStatus[text] || {};

  return (
    <div className="flex items-center">
      {icon}
      <span className="ml-2">
        <HighLightedText
          value={message}
          globalFilter={cell.getContext().table.getState().globalFilter}
        />
      </span>
    </div>
  );
};

const getColumns = (dateFormat, decimalFormatNew, onMarkAsComplete) => {
  return [
    {
      header: "",
      key: "expandCollapse",
      accessorKey: "splitDetails",
      visible: true,
      enableSorting: false,
      size: 20,
      cell: ({row}) => {
        return row.getCanExpand() ? (
          <Button
            onClick={row.getToggleExpandedHandler()}
            color="standard-quartenary"
            className="!px-0"
          >
            {row.getIsExpanded() ? <Down24 /> : <Up24 />}
          </Button>
        ) : (
          ""
        );
      },
    },
    {
      header: "Status",
      key: "status",
      disabled: true,
      accessorKey: "status",
      visible: true,
      cell: ({getValue, cell}) => renderStatus(getValue(), cell),
    },
    {
      header: "Certificate ID",
      accessorKey: "certificateNumber",
      key: "certificateNumber",
      disabled: true,
      cell: ({row, getValue, table, cell}) => {
        return renderLink(
          encodeURIComponent(row.original.certificateInboundEuId),
          getValue(),
          table.getState()?.pagination?.pageIndex,
          cell,
        );
      },
      size: 250,
      maxSize: 400,
      visible: true,
    },
    {
      header: "Product",
      accessorKey: "product",
      key: "product",
      visible: true,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Raw Material",
      accessorKey: "rawMaterialType",
      key: "rawMaterialType",
      size: 110,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Quantity m3",
      accessorKey: "quantinInm3",
      key: "Quantity m3",
      size: 100,
      visible: true,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Quantity mt",
      accessorKey: "quantity",
      key: "Quantity mt",
      size: 100,
      visible: true,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Supplier",
      accessorKey: "supplierName",
      key: "supplierName",
      size: 150,
      visible: true,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Receiving point",
      accessorKey: "recipientReceiptAddress",
      key: "recipientReceiptAddress",
      size: 200,
      visible: true,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Date of Issuance",
      accessorKey: "dateOfIssuance",
      key: "dateOfIssuance",
      size: 200,
      visible: true,
      filterFn: "dateRangeFilterFn",
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Contract Number",
      accessorKey: "contractNumber",
      key: "contractNumber",
      size: 200,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "GHG total",
      accessorKey: "ghgTotal",
      key: "ghgTotal",
      size: 110,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Mass Balance",
      accessorKey: "mbLocation",
      key: "mbLocation",
      size: 110,
      visible: false,
      cell: ({getValue, row, cell}) => {
        const value = getValue();
        if (
          value != null &&
          row.original.mbCycle !== null &&
          row.subRows?.length === 0
        ) {
          const qtr = row.original.mbCycle ?? "      ";
          return (
            <Link
              to={`/copro-spain/mass-balance/${
                row.original.mbLocationCode
              }/${encodeURIComponent(value)}/${
                row.original.materialCode
              }/${qtr.substring(4, 6)}/${qtr.substring(0, 4)}`}
              className="link-dark"
            >
              <u>
                <HighLightedText
                  value={(value, qtr.substring(4, 6), qtr.substring(0, 4))}
                  globalFilter={cell.getContext().table.getState().globalFilter}
                />
              </u>
            </Link>
          );
        }
        return "";
      },
    },
    {
      header: "",
      accessorKey: "actions",
      key: "actions",
      size: 25,
      visible: true,
      enableSorting: false,
      cell: ({
        row: {
          original: {
            sdNumber,
            status,
            previousVersions,
            previousVersionsCount,
            certificateInboundEuId,
          },
        },
      }) => (
        <RenderDropdown
          stateMgmt={status}
          sd_number={sdNumber}
          previousVersions={previousVersions}
          previousVersionsCount={previousVersionsCount}
          certificateInboundEuId={certificateInboundEuId}
        />
      ),
    },
  ];
};

export default getColumns;
