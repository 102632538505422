import {Kebab24} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {toast} from "react-toastify";
import {useMsal, useAccount} from "@azure/msal-react";

import {RevertAPI} from "graphql/es-co-processing/mass-balance/revert";
import {RemoveAPI} from "graphql/es-co-processing/mass-balance/remove";
import AssignToSaleModal from "modules/es-co-processing/components/AssignToSale";
import AssignToTransferModal from "modules/es-co-processing/components/AssignToTransfer";
import WriteOffModal from "modules/es-co-processing/components/WriteOff";
import {useState} from "react";
import ConfirmationPopup from "../ConfirmationPopup";
import RemoveFromBalancePopup from "../RemoveFromBalancePopup";

const ActionDropdown = ({row, table}) => {
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);
  const [actionModalVisible, setActionModalVisible] = useState("");

  const ACTIONS = [
    {
      name: "View Details",
      id: "view-details",
      action: () => {
        table.resetExpanded();
        setTimeout(() => {
          row.getToggleExpandedHandler()();
          row.highlight();
        }, 100);
      },
      isDisabled: row.getIsExpanded(),
    },
    {
      name: "Assign to sale",
      id: "assign-to-sale",
      action: () => {
        setActionModalVisible("Assign to sale");
      },
      isDisabled:
        row?.original.outboundRecords?.productStatus !== "AVAILABLE" &&
        row?.original.outboundRecords?.productStatus !== "CARRY FORWARD",
    },
    {
      name: "Transfer",
      id: "transfer",
      action: () => {
        setActionModalVisible("Transfer");
      },
      isDisabled:
        row?.original.outboundRecords?.productStatus !== "AVAILABLE" &&
        row?.original.outboundRecords?.productStatus !== "CARRY FORWARD",
    },
    {
      name: "Write off",
      id: "write-off",
      action: () => {
        setActionModalVisible("Write off");
      },
      isDisabled:
        row?.original.outboundRecords?.productStatus !== "AVAILABLE" &&
        row?.original.outboundRecords?.productStatus !== "CARRY FORWARD",
    },
    {
      name: "Remove Assignment",
      id: "remove-assignment",
      action: () => {
        setActionModalVisible("Revert");
      },
      isDisabled: !(
        (row?.original.outboundRecords?.productStatus === "ASSIGNED AND SOLD" ||
          row?.original.outboundRecords?.productStatus ===
            "ASSIGNED AND SENT" ||
          row?.original.outboundRecords?.productStatus === "WRITTEN OFF") &&
        row.original.inboundRecord?.recordStatus !== "LOCKED"
      ),
    },
    {
      name: "Remove from Balance",
      id: "remove-from-balance",
      action: () => {
        setActionModalVisible("Remove");
      },
      isDisabled: !(
        (row?.original.outboundRecords?.productStatus === "AVAILABLE" ||
          row?.original.outboundRecords?.productStatus === "CARRY FORWARD") &&
        row?.original.rowSpan === 1
      ),
    },
  ];

  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle
          color="standard-quartenary"
          className="!px-0"
          caret
          onClick={() => setActionModalVisible("")}
        >
          <Kebab24 />
        </DropdownToggle>
        <DropdownMenu>
          {ACTIONS.map((action) => (
            <DropdownItem
              key={action.name}
              onClick={action.action}
              disabled={action.isDisabled}
              data-test={`data-test-mb-table-action-${action.id}`}
            >
              {action.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      {actionModalVisible && (
        <>
          <AssignToSaleModal
            isVisibleModal={actionModalVisible === "Assign to sale"}
            rowData={row.original}
          />
          <AssignToTransferModal
            isVisibleModal={actionModalVisible === "Transfer"}
            rowData={row.original}
          />
          <WriteOffModal
            isVisibleModal={actionModalVisible === "Write off"}
            rowData={row.original}
          />
          <ConfirmationPopup
            isOpen={actionModalVisible === "Revert"}
            title="Remove assignment"
            confirmLabel="Remove"
            onCancel={() => setActionModalVisible("")}
            onConfirm={async () => {
              const {data: response} = await RevertAPI({
                inboundEuQtyId: row?.original?.inboundRecord?.inboundEuQtyId,
                outboundEuQtyId:
                  row?.original?.outboundRecords?.outboundEuQtyId,
                sdNumber: row?.original?.inboundRecord?.sdNumber,
                createdBy: account.username,
              });

              if (response?.bioLcCoproEuMassBalanceRevert?.statusCode === 200) {
                toast.success(
                  response?.bioLcCoproEuMassBalanceRevert?.message ??
                    "Write off successful",
                );
              } else {
                toast.error(
                  response?.bioLcCoproEuMassBalanceRevert?.message ??
                    "Something went wrong, failed to write off",
                );
              }
              setActionModalVisible("");
            }}
            message={
              <span>
                <p>Removing this assignment will return allocated stock</p>
                <br />
                <p>
                  The generated certificate will be available in the outgoing
                  document in your records with the status assignment removed.
                </p>
              </span>
            }
          />
          <RemoveFromBalancePopup
            isOpen={actionModalVisible === "Remove"}
            data={row.original}
            title="Remove from balance"
            confirmLabel="Remove"
            onCancel={() => setActionModalVisible("")}
            onConfirm={async (notes) => {
              const {data: response} = await RemoveAPI({
                inboundEuQtyId: row?.original?.inboundRecord?.inboundEuQtyId,
                sdNumber: row?.original?.inboundRecord?.sdNumber,
                remarks: notes,
                createdBy: account.username,
              });

              if (response?.bioLcCoproEuMassBalanceRemove?.statusCode === 200) {
                toast.success(
                  response?.bioLcCoproEuMassBalanceRemove?.message ??
                    "Remove successful.",
                );
              } else {
                toast.error(
                  response?.bioLcCoproEuMassBalanceRemove?.message ??
                    "Something went wrong, failed to remove.",
                );
              }
              setActionModalVisible("");
            }}
          />
        </>
      )}
    </>
  );
};

ActionDropdown.propTypes = {
  row: PropTypes.object,
  table: PropTypes.object,
};

export default ActionDropdown;
