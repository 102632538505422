import HomeIcon from "assets/images/svg/home.svg";

// Used in Side
const BioverseSaf = () => {
  return [
    {
      link: "/",
      id: "bioverse",
      img: HomeIcon,
      title: "BioVerse Dashboard",
      isVisible: true,
      selectorColor: "bg-bp-green-900",
    },
    {
      link: "/SAFDocumentPage",
      id: "SAFdocumentpage",
      img: HomeIcon,
      title: "Documents",
      isVisible: true,
      selectorColor: "bg-bp-green-900",
    },
    {
      link: "/saf-sweden/mass-balance/",
      id: "SAFMassBalancePage",
      img: HomeIcon,
      title: "Mass Balance",
      isVisible: true,
      selectorColor: "bg-bp-green-900",
    },
  ];
};

export default BioverseSaf;
