import tw from "twin.macro";
import {useMemo} from "react";
import {Card, CardTitle, CardBody} from "reactstrap";
import {useLocation} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {
  COPRO_US_BATCH_DAYS_REMAINING,
  COPRO_US_BATCH_PI_TAGS,
  GET_BATCH_BY_ID,
} from "graphql/coprocessing/batches";
import {PAGE_TITLE} from "modules/co-processing/constants/coProcessing";
import {
  BATCH_LABELS,
  BATCH_RECORD_KEYS,
  BATCH_STATUS_CARD_KEYS,
  DHDS_SUMMARY_LABELS,
  DHDS_FEED_COLUMN,
  DHDS_PRODUCTION_COLUMN,
} from "modules/co-processing/constants/batches";
import BatchesStatusChip from "modules/co-processing/components/BatchesStatusChip";
import RecordCard from "modules/co-processing/components/DetailsScreen/RecordCard";
import DataGrid from "modules/co-processing/components/DetailsScreen/DataGrid";
import BatchStatusCard from "modules/co-processing/components/DetailsScreen/BatchStatusCard";
import BatchSummarySection from "modules/co-processing/components/BatchSummary/BatchSummarySection";
import C14Card from "modules/co-processing/components/DetailsScreen/C14Card";
import LoadingSpinner from "modules/co-processing/components/LoadingSpinner";

const SectionHeader = tw.div`text-[26px] pr-3`;
const SectionWrapper = tw.div`pb-8 w-3/4`;
const BatchDetails = () => {
  const {state} = useLocation();
  const batchId = state?.batch_id;
  const isEditable = state?.editable;

  const {loading, data, refetch} = useQuery(GET_BATCH_BY_ID, {
    fetchPolicy: "network-only",
    variables: {
      batch_id: batchId,
    },
  });

  const batchDetails = useMemo(
    () => data?.bioLcCoproUsBatchesApi?.body?.batch_details,
    [data],
  );

  const {data: daysRemainingData} = useQuery(COPRO_US_BATCH_DAYS_REMAINING, {
    fetchPolicy: "network-only",
    variables: {
      c14_id: batchDetails?.c14_detail_id,
    },
    skip: !batchDetails?.c14_detail_id,
  });

  const {data: piData} = useQuery(COPRO_US_BATCH_PI_TAGS, {
    fetchPolicy: "network-only",
    variables: {
      startTime: batchDetails?.batch_start_time,
      endTime: batchDetails?.batch_end_time,
    },
  });

  return (
    <div data-test="batches-details-screen" className="mx-[40px]">
      <h1 className="pb-6 fs-2 border-b-[1.5px]">{PAGE_TITLE.BATCH_DETAILS}</h1>
      <div>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div>
            <div className="flex flex-row mb-12 mt-16">
              <SectionHeader>{BATCH_LABELS.STATUS}</SectionHeader>
              <BatchesStatusChip value={batchDetails?.batch_status} small />
            </div>
            <div
              data-test="batch-status-wrapper"
              className="flex flex-row w-3/4 justify-between pb-16"
            >
              {BATCH_STATUS_CARD_KEYS.map((data, index) => (
                <BatchStatusCard
                  key={index}
                  title={data.title}
                  status={data.status}
                />
              ))}
            </div>
            <BatchSummarySection
              data={batchDetails}
              isEditable={isEditable}
              daysRemaining={
                daysRemainingData?.bioLcCoproUsBatchesApi?.body?.days_remaining
              }
            />
            <SectionWrapper data-test="DHDS-summary-wrapper">
              <Card className="card-border rounded-6">
                <CardBody>
                  <CardTitle className="text-[26px] pb-10">
                    {BATCH_LABELS.DHDS_SUMMARY}
                  </CardTitle>
                  <div className="grid grid-cols-2 gap-16 pb-12">
                    <DataGrid
                      data={piData?.bioLcCoproUsBatchesApi?.body?.pi_tags}
                      title={DHDS_SUMMARY_LABELS.DHDS_FEED}
                      dataColumn={DHDS_FEED_COLUMN}
                    />
                    <DataGrid
                      data={piData?.bioLcCoproUsBatchesApi?.body?.pi_tags}
                      title={DHDS_SUMMARY_LABELS.DHDS_PRODUCTION}
                      dataColumn={DHDS_PRODUCTION_COLUMN}
                    />
                  </div>
                </CardBody>
              </Card>
            </SectionWrapper>
            <SectionWrapper data-test="batch-record-cards-wrapper">
              {BATCH_RECORD_KEYS.map((label, index) => {
                if (label.title === BATCH_LABELS.C14_DETAILS) {
                  return (
                    <C14Card
                      title={label.title}
                      testId={label.testId}
                      data={batchDetails}
                      batchId={batchId}
                      refetch={refetch}
                    />
                  );
                }
                return (
                  <RecordCard
                    key={index}
                    title={label.title}
                    testId={label.testId}
                  />
                );
              })}
            </SectionWrapper>
          </div>
        )}
      </div>
    </div>
  );
};

export default BatchDetails;
