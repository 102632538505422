import PropTypes from "prop-types";
import {displayDate} from "../helpers/dateHelper";

const DetailItem = ({label, value, format}) => (
  <div data-test="batches-card-detail-item">
    <div data-test="batches-card-detail-label">{label}</div>
    <div data-test="batches-card-detail-value" className="text-gray-600">
      {format
        ? format === "date"
          ? displayDate(value)
          : value?.toLocaleString()
        : value}
    </div>
  </div>
);

DetailItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  format: PropTypes.string,
};

export default DetailItem;
