import {
  dtnShipmentKeys,
  INPUT_METRIC,
  SHIPMENTS_LABELS,
  SHIPMENT_TYPE,
} from "modules/co-processing/constants/shipments";
import dateFns from "utils/helpers/dateFunctions";
import {formatTimePicker} from "./formUtils";

// Combined structure for create shipment form fields
export const dtnShipmentFields = {
  SHIPMENT_ID: {
    key: dtnShipmentKeys.SHIPMENT_ID,
    label: "Shipment ID",
    format: null, // No formatting needed....yet
  },
  SHIPMENT_TYPE: {
    key: dtnShipmentKeys.SHIPMENT_TYPE,
    label: SHIPMENTS_LABELS.SHIPMENT_TYPE,
    options: [
      {value: SHIPMENT_TYPE.BACK_TO_UNIT, label: "Back to unit misc"},
      {value: SHIPMENT_TYPE.TRUCK_RACK, label: "Truck rack"},
    ],
    format: (value) => value?.replace(/_/g, " ") || value,
    type: "select",
  },
  SHIPMENT_DATE: {
    key: dtnShipmentKeys.SHIPMENT_DATE,
    label: SHIPMENTS_LABELS.SHIPMENT_DATE,
    format: (value) =>
      value ? dateFns.convertDateFormat_MM_DD_YYYY(value) : "-",
    type: "date",
  },
  SHIPMENT_START_DATE: {
    key: dtnShipmentKeys.SHIPMENT_START_DATE,
    label: "Start time",
    format: formatTimePicker,
    type: "time",
  },
  SHIPMENT_END_DATE: {
    key: dtnShipmentKeys.SHIPMENT_END_DATE,
    label: "End time",
    format: formatTimePicker,
    type: "time",
  },
  ESTIMATED_VOLUME: {
    key: dtnShipmentKeys.ESTIMATED_VOLUME,
    label: "Estimated shipment max volume",
    format: (value) => `${value?.toLocaleString()} ${INPUT_METRIC}`,
  },
  SOURCE_TANK: {
    key: dtnShipmentKeys.SOURCE_TANK,
    label: "Source tank",
    placeholder: "Enter a source tank number",
    type: "input",
  },
  RENEWABLE_SHIPMENT_FLAG: {
    key: dtnShipmentKeys.RENEWABLE_SHIPMENT_FLAG,
    label: "Renewable",
    format: (value) => (value ? "Yes" : "No"),
    info: "Are you sure? Make sure to double check the renewability of this shipment.",
    type: "switch",
  },

  // --- OPTIONAL FIELDS ---
  SHIPMENT_SUB_TYPE: {
    key: dtnShipmentKeys.ADDITIONAL_INFO,
    label: "Shipment sub-type",
    options: ["sub-type A", "sub-type B"],
    isOptional: true,
    type: "select",
  },
  ADJUSTED_SHIPMENT_VOLUME: {
    key: dtnShipmentKeys.ADJUSTED_VOLUME,
    addOn: INPUT_METRIC,
    label: "Actual shipment volume",
    info: "This amount cannot exceed the estimated shipment max volume.",
    placeholder: "Enter actual shipment volume",
    isOptional: true,
    type: "input",
  },
  NOTES: {
    key: dtnShipmentKeys.ADDITIONAL_INFO,
    label: "Notes",
    placeholder: "Add any notes here...",
    isOptional: true,
    width: "col-12",
    type: "textarea",
  },
};

export const INITIAL_FORM_STATE = {
  dtn_shipment_id: "",
  shipment_type: "",
  shipment_datez: "",
  shipment_start_datez: "",
  shipment_end_datez: "",
  estimated_volume: 0, // Float type
  source_tank: "",
  additional_info: "", // Notes field
};

export const FIRST_STEP = 0;
