import tw from "twin.macro";
import {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {useQuery} from "@apollo/client";
import {GET_BATCH_BY_ID} from "graphql/coprocessing/batches";
import {
  ACTIVE_BATCH_FIELDS,
  ACTIVE_BATCH_KEYS,
  ACTIVE_BATCH_LABELS,
  ACTIVE_BATCH_PLACEHOLDERS,
  WARNINGS,
} from "modules/co-processing/constants/shipments";
import MetricInput from "modules/co-processing/components/Shipments/MetricInput";
import BatchesVolumeBar from "./BatchesVolumeBar";

const ActiveBatchLabel = tw.div`min-w-[120px] w-[120px]`;
const ActiveBatchField = tw.div`flex items-center pb-3 text-[13px] font-[400]`;
const ActiveBatchValue = tw.div`text-[#111111] pl-3 text-opacity-[0.64]`;
const ActiveBatchCard = ({
  data,
  handleChange: propHandleChange,
  handleErrors,
  handleWarnings,
}) => {
  const getShipments = (batchData) => {
    const shipments = batchData?.shipments ?? [];

    if (batchData.remaining_volume > 0 && batchData.ending_inventory == null) {
      return [...shipments, {shipment_id: "", shipment_volume: 0}];
    }
    return shipments || [];
  };

  const [shipments, setShipments] = useState(getShipments(data));
  const [hasEndingInventory, setHasEndingInventory] = useState(
    !!data?.ending_inventory,
  );

  // TODO - Add missing_C14 to shipments API for batches
  const {data: c14Data} = useQuery(GET_BATCH_BY_ID, {
    fetchPolicy: "network-only",
    variables: {
      batch_id: data.batch_id,
    },
  });

  useEffect(() => {
    if (c14Data?.bioLcCoproUsBatchesApi?.body?.batch_details?.missing_c14) {
      handleWarnings(
        ACTIVE_BATCH_KEYS.MISSING_C14,
        WARNINGS.MISSING_C14,
        data.batch_id,
      );
    }
  }, [c14Data, handleWarnings, data]);

  useEffect(() => {
    const isLastShipmentEmpty =
      shipments.length > 0 &&
      !shipments[shipments.length - 1].shipment_id &&
      !shipments[shipments.length - 1].shipment_volume;

    if (
      data.remaining_volume > 0 &&
      !hasEndingInventory &&
      !isLastShipmentEmpty
    ) {
      setShipments([...shipments, {shipment_id: "", shipment_volume: 0}]);
    }

    if (
      data.remaining_volume > 0 &&
      hasEndingInventory &&
      isLastShipmentEmpty
    ) {
      // clears any errors during removal of shipment id input field
      handleErrors(`shipment_${shipments.length}`, "");
      setShipments(shipments.slice(0, -1));
    }
  }, [data, hasEndingInventory, handleErrors, shipments]);

  const handleInputChange = (event) => {
    const {name, value} = event.target;

    propHandleChange(event);

    if (name === ACTIVE_BATCH_KEYS.ACTUAL_ENDING_INV) {
      setHasEndingInventory(!!value.trim());
    }
  };

  const handleShipmentChange = (index, shipmentId) => {
    propHandleChange({target: {name: index, value: shipmentId}});
  };

  return (
    <div data-test="copro-active-batch-card">
      <BatchesVolumeBar
        totalAmount={data?.total_volume}
        remainingAmount={data?.remaining_volume}
        endingAmount={data?.ending_inventory ?? null}
      />
      <div className="flex flex-col md:flex-row">
        <div className="pb-10 w-full md:w-1/2 px-2">
          {ACTIVE_BATCH_FIELDS.map((batch, index) => (
            <ActiveBatchField data-test="copro-active-batch-fields" key={index}>
              <ActiveBatchLabel>{batch.label}</ActiveBatchLabel>
              {batch.type === "text" ? (
                <ActiveBatchValue>{`${data[batch.key]?.toLocaleString()} ${
                  batch.metric ?? ""
                }`}</ActiveBatchValue>
              ) : (
                <ActiveBatchValue>
                  <MetricInput
                    value={data[batch.key]}
                    dataKey={batch.key}
                    errorKey={batch.key}
                    placeholder={batch.placeholder}
                    showMetric={!!batch.metric}
                    maxValue={data[batch.max]}
                    onChange={handleInputChange}
                    reportErrors={handleErrors}
                  />
                </ActiveBatchValue>
              )}
            </ActiveBatchField>
          ))}
        </div>
        <div className="w-full md:w-1/2 px-2">
          {shipments?.map((shipment, index) => (
            <div data-test="copro-active-batch-shipment-list" key={index}>
              <div
                data-test="copro-active-batch-shipment-title"
                className="text-[13px] fw-medium pb-2"
              >{`${ACTIVE_BATCH_LABELS.SHIPMENT} ${index + 1}`}</div>
              {!!shipment?.shipment_id && (
                <ActiveBatchField>
                  <ActiveBatchLabel data-test="copro-active-batch-shipment-fields">
                    {ACTIVE_BATCH_LABELS.SHIPMENT_VOLUME}
                  </ActiveBatchLabel>
                  <div>{`${shipment?.shipment_volume?.toLocaleString()} bbl`}</div>
                </ActiveBatchField>
              )}
              <ActiveBatchField data-test="copro-active-batch-shipment-inputs">
                <ActiveBatchLabel>
                  {ACTIVE_BATCH_LABELS.SHIPMENT_ID}
                </ActiveBatchLabel>
                <MetricInput
                  value={shipment?.shipment_id}
                  dataKey={`shipment_${index + 1}`}
                  errorKey={`shipment_${index + 1}`}
                  placeholder={ACTIVE_BATCH_PLACEHOLDERS.ID}
                  onChange={(event) =>
                    handleShipmentChange(index, event.target.value)
                  }
                  reportErrors={handleErrors}
                />
              </ActiveBatchField>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

ActiveBatchCard.propTypes = {
  data: PropTypes.any,
  handleChange: PropTypes.func,
  handleErrors: PropTypes.func,
  handleWarnings: PropTypes.func,
};

export default ActiveBatchCard;
