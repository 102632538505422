import PropTypes from "prop-types";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import "./locale";

const Form = ({children, schema, onSubmit, defaultValues, ...props}) => {
  const {handleSubmit, ...formMethods} = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    resolver: schema ? yupResolver(schema) : undefined,
    defaultValues,
  });

  return (
    <FormProvider {...formMethods}>
      <form noValidate onSubmit={handleSubmit(onSubmit)} {...props}>
        {children}
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  schema: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
};

export default Form;
