import CsvIcon from "assets/images/svg/csv.svg";
import FilesIcon from "assets/images/svg/files.svg";
import HomeIcon from "assets/images/svg/home.svg";
import PdfIcon from "assets/images/svg/pdf.svg";
import ShipmentTrackingIcon from "assets/images/svg/shipment-tracking-report.svg";
import TicketIcon from "assets/images/svg/ticket.svg";
import Icon_Plane_64 from "assets/images/svg/Icon_Plane_64.svg";
import CoprocessingIcon from "assets/images/svg/coprocessing-icon.svg";
import ForcastingIcon from "assets/images/svg/forcasting-icon.svg";
import ConfigurationCenterIcon from "assets/images/svg/configuration-icon.svg";
import CmosMOTIcon from "assets/images/svg/cmos-icon.svg";
import PoSIcon from "assets/images/svg/pos-icon.svg";
import ComplianceMassBalanceIcon from "assets/images/svg/compliance-mass-balance-icon.svg";
import ReportingIcon from "assets/images/svg/reporting-icon.svg";
import * as LABELS from "constants/commonLabels";
import {useQuery} from "@apollo/client";
import {LOCATION_DETAILS} from "graphql/config-center/LocationDetails";

export const documentsType = [
  {
    link: "/co-processing/upload-documents/invoices",
    img: PdfIcon,
    title: "Invoices",
    description: "Upload invoices for biofeedstocks to Cherry Point here",
  },
  {
    link: "/co-processing/upload-documents/shipment-tracking-reports",
    img: CsvIcon,
    title: "Daily Shipment Tracking Reports",
    description: "Submit Daily Shipment Tracker here",
  },
  {
    link: "/co-processing/upload-documents/bol",
    img: PdfIcon,
    title: "BOL",
    description: "Upload BOLs for biofeedstocks to Cherry Point here",
  },
];

export const posDocTypes = [
  // {
  //   link: "/pos-management/upload-documents/volume-tracker",
  //   img: ExcelIcon,
  //   title: "Upload Volume Tracker",
  //   description: "Upload Volume Tracker here",
  // },
  {
    link: "/pos-management/upload-documents/pos-certificate",
    img: PdfIcon,
    title: "Upload PoS Certificate",
    description: "Upload PoS Certificate here",
  },
];

// Used in Side
export const Bioverse = () => {
  const {data: locationData, loading: locationLoading} =
    useQuery(LOCATION_DETAILS);
  return [
    {
      link: "/co-processing",
      id: "bioverse-co-processing-v2",
      img: CoprocessingIcon,
      title: "Co Processing - United States",
      isVisible: true,
      selectorColor: "bg-bp-green-900",
      subItems: [
        {
          link: "/co-processing",
          id: "bioverse-co-processing-v2-receipts",
          title: "Receipts",
          isVisible: true,
          selectorColor: "bg-yellow-400",
        },
        {
          link: "/co-processing/batches",
          id: "bioverse-co-processing-v2-batches",
          title: "Batches",
          isVisible: true,
          selectorColor: "bg-yellow-400",
        },
        {
          link: "/co-processing/shipments",
          id: "bioverse-co-processing-v2-shipments",
          title: "Shipments",
          isVisible: true,
          selectorColor: "bg-yellow-400",
        },
      ],
    },
    {
      link: "/co-processing-spain",
      id: "bioverse-co-processing-spain",
      img: CoprocessingIcon,
      title: "Co Processing - Spain",
      isVisible: true,
      selectorColor: "bg-bp-green-900",
      subItems: [
        {
          link: "/copro-spain",
          id: "co-processing-spain-Dashboard",
          title: "Dashboard",
          isVisible: true,
          selectorColor: "bg-yellow-400",
        },
        {
          link: "/co-processing-spain-Document-manager",
          id: "co-processing-spain-Document-manager",
          title: "Document Manager",
          isVisible: true,
          selectorColor: "bg-yellow-400",
          subItems: [
            {
              link: "/copro-spain/document-manager/incoming-documents",
              id: "co-processing-spain-Document-manager-incoming",
              title: "Incoming Documents",
              isVisible: true,
              selectorColor: "bg-yellow-400",
            },
            {
              link: "/copro-spain/document-manager/outgoing-documents",
              id: "co-processing-spain-Document-manager-outgoing",
              title: "Outgoing Documents",
              isVisible: true,
              selectorColor: "bg-yellow-400",
            },
          ],
        },
        {
          link: "/copro-spain/fifo-feedstock",
          id: "co-processing-spain-Dashboard",
          title: "Feedstock FIFO",
          isVisible: true,
          selectorColor: "bg-yellow-400",
        },
        {
          link: "/co-processing-spain-mass-balance",
          id: "co-processing-spain-Mass-balance",
          title: "Mass Balance",
          isVisible: true,
          selectorColor: "bg-yellow-400",
          subItems: locationLoading
            ? []
            : locationData.bioLcCoproConfigCenterLocationDetails.Records.map(
                ({location_name, location_code}) => {
                  return {
                    link:
                      "/copro-spain/mass-balance/" +
                      encodeURIComponent(location_code) +
                      "/" +
                      encodeURIComponent(location_name),
                    id: "copro-spain-mass-balance-" + location_name,
                    title: location_name,
                    isVisible: true,
                    selectorColor: "bg-yellow-400",
                  };
                },
              ),
        },
      ],
    },
    {
      link: "/forecasting",
      id: "bioverse-forecasting",
      img: ForcastingIcon,
      title: "Forecasting",
      isVisible: true,
      selectorColor: "bg-bp-green-900",
    },
    {
      link: "https://app.powerbi.com/groups/4c0e4500-a7be-4850-8e43-82c614cd4937/reports/3e37a0c4-8a78-489f-8644-ad5b567f36ad/ReportSection1d7501e01e01aec56212",
      id: "bioverse-ceemas-mot",
      img: TicketIcon,
      title: "GY-Enablement",
      isVisible: false,
    },

    {
      link: "/gy-enablement/ceemas-mot-validation/:filterDetails",
      id: "submenu-gy-enablement",
      img: CmosMOTIcon,
      title: "CEEMAS MOT Validation",
      isVisible: true,
      selectorColor: "bg-red-500",
      subItems: [
        {
          link: "/gy-enablement/ceemas-mot-validation/:filterDetails",
          id: "submenu-gy-enablement-report",
          img: CmosMOTIcon,
          title: "CEEMAS MOT Validation",
          isVisible: true,
          selectorColor: "bg-red-500",
        },
        {
          link: "/gy-enablement/mot-manual-adjustment",
          id: "submenu-gy-enablement-manual-adjustment",
          img: CmosMOTIcon,
          title: "MOT Manual Adjustment",
          isVisible: true,
          selectorColor: "bg-red-500",
        },
      ],
    },

    {
      link: "/pos-management/landing",
      id: "pos-management",
      img: PoSIcon,
      title: "Proof of Sustainability (PoS)",
      isVisible: true,
      selectorColor: "bg-bp-green-100",
    },
    {
      link: "/compliance/monitor",
      id: "compliance-monitor",
      img: ComplianceMassBalanceIcon,
      title: "Compliance Monitor",
      isVisible: true,
      selectorColor: "bg-blue-500",
    },
    {
      link: "/product-metrics",
      id: "bioverse-product-metrics",
      img: ReportingIcon,
      title: "Reporting",
      isVisible: true,
      selectorColor: "bg-selector7",
      subItems: [
        {
          link: "/product-metrics",
          id: "product-metrics-feedstock-tracking",
          img: CmosMOTIcon,
          title: "Cherrypoint Feedstock Tracking",
          isVisible: true,
          selectorColor: "bg-red-500",
        },
        {
          link: "/product-metrics/ceemas-mot-validation",
          id: "product-metrics-mot-validation",
          img: CmosMOTIcon,
          title: "Ceemas MOT Validation",
          isVisible: true,
          selectorColor: "bg-red-500",
        },
        {
          link: "/reporting/bvc-report",
          id: "product-metrics-bvc-report",
          img: CmosMOTIcon,
          title: "BVC Report",
          isVisible: true,
          selectorColor: "bg-red-500",
        },
      ],
    },
    {
      link: "/gy-enablement/master/tax-plant-registry",
      id: "config-center",
      img: ConfigurationCenterIcon,
      title: "Configuration Center",
      isVisible: true,
      selectorColor: "bg-yellow-400",
      subItems: [
        {
          link: "/gy-enablement/master/tax-plant-registry",
          id: "config-center-gnets-tax-plant-registry",
          title: "Tax Plant Registry",
          isVisible: true,
          selectorColor: "bg-yellow-400",
        },
        {
          link: "/gy-enablement/master/NETS-CO2-Costs",
          id: "config-center-gnets-co2-costs",
          title: "NETS CO2 Costs",
          isVisible: true,
          selectorColor: "bg-yellow-400",
        },
        {
          link: "/config-center/coprocessing-spain",
          id: "co-processing-spain-master-settings",
          title: "Co-processing - Spain",
          isVisible: true,
          selectorColor: "bg-yellow-400",
        },
      ],
    },
  ];
};

// Used in the old landing content (To be deprecated soon)
export const sectionsType = [
  {
    link: "/co-processing",
    id: "reports",
    img: ShipmentTrackingIcon,
    title: "Co Processing - United States",
    description: LABELS.LANDINGPAGE_ITEM_DESCRIPTION,
    isVisible: true,
    redirectToExternalLink: false,
    redirectToExternalLinkURL: "",
  },
  {
    link: "/forecasting",
    id: "bioverse-forecasting",
    img: HomeIcon,
    title: "Forecasting",
    description: LABELS.LANDINGPAGE_ITEM_DESCRIPTION,
    isVisible: true,
    redirectToExternalLink: false,
    redirectToExternalLinkURL: "",
  },
  {
    link: "/cp-batches",
    id: "cp-batches",
    img: HomeIcon,
    title: "CP Batches",
    description: LABELS.LANDINGPAGE_ITEM_DESCRIPTION,
    isVisible: false,
    redirectToExternalLink: false,
    redirectToExternalLinkURL: "",
  },
  {
    link: "/cp-inventory-dashboards",
    id: "cp-inventory-dashboards",
    img: HomeIcon,
    title: "CP Inventory Dashboards",
    description: LABELS.LANDINGPAGE_ITEM_DESCRIPTION,
    isVisible: false,
    redirectToExternalLink: false,
    redirectToExternalLinkURL: "",
  },
  {
    link: "/cp-rins-generation",
    id: "cp-rins-generation",
    img: HomeIcon,
    title: "CP RINS generation",
    description: LABELS.LANDINGPAGE_ITEM_DESCRIPTION,
    isVisible: false,
    redirectToExternalLink: false,
    redirectToExternalLinkURL: "",
  },
  {
    link: "/cp-carbon-credits",
    id: "cp-carbon-credits",
    img: HomeIcon,
    title: "CP Carbon Credits",
    description: LABELS.LANDINGPAGE_ITEM_DESCRIPTION,
    isVisible: false,
    redirectToExternalLink: false,
    redirectToExternalLinkURL: "",
  },
  {
    link: "/gy-enablement/master/tax-plant-registry",
    id: "gy-gnets-module",
    img: HomeIcon,
    title: "Configuration Center",
    description: LABELS.LANDINGPAGE_ITEM_DESCRIPTION,
    isVisible: true,
    redirectToExternalLink: false,
    redirectToExternalLinkURL: "",
  },
  {
    link: "/gy-pos-administration",
    id: "gy-pos-administration",
    img: HomeIcon,
    title: "GY POS Administration",
    description: LABELS.LANDINGPAGE_ITEM_DESCRIPTION,
    isVisible: false,
    redirectToExternalLink: false,
    redirectToExternalLinkURL: "",
  },
  {
    link: "/gy-enablement/ceemas-mot-validation/:filterDetails",
    id: "gy-enablement",
    img: HomeIcon,
    title: "CEEMAS MOT Validation",
    description: LABELS.LANDINGPAGE_ITEM_DESCRIPTION,
    isVisible: true,
    redirectToExternalLink: false,
    redirectToExternalLinkURL: "",
  },
  {
    link: "/pos-management/landing",
    id: "pos-management",
    img: HomeIcon,
    title: "Proof of Sustainability (PoS)",
    description: LABELS.LANDINGPAGE_ITEM_DESCRIPTION,
    isVisible: true,
    redirectToExternalLink: false,
    redirectToExternalLinkURL: "",
  },
  {
    link: "/compliance/monitor",
    id: "compliance-monit",
    img: HomeIcon,
    title: "Compliance Monitor",
    description: LABELS.LANDINGPAGE_ITEM_DESCRIPTION,
    isVisible: true,
    redirectToExternalLink: false,
    redirectToExternalLinkURL: "",
  },
  {
    link: "/product-metrics",
    id: "product-metrics",
    img: FilesIcon,
    title: "Reporting",
    description: LABELS.LANDINGPAGE_ITEM_DESCRIPTION,
    isVisible: true,
    redirectToExternalLink: false,
    redirectToExternalLinkURL: "",
  },
  {
    link: "/copro-spain",
    id: "es-co-processing-metrics",
    img: ShipmentTrackingIcon,
    title: "Co Processing - Spain",
    description: LABELS.LANDINGPAGE_ITEM_DESCRIPTION,
    isVisible: true,
    redirectToExternalLink: false,
    redirectToExternalLinkURL: "",
  },
  {
    link: "/saflandingpage",
    id: "saflandingpage",
    img: Icon_Plane_64,
    title: "SAF",
    description: LABELS.LANDINGPAGE_ITEM_DESCRIPTION,
    isVisible: true,
    redirectToExternalLink: false,
    redirectToExternalLinkURL: "",
  },
];

export default {documentsType, sectionsType};
