import PropTypes from "prop-types";
import {isEmpty, sortBy} from "lodash";
import truckImg from "assets/images/svg/truck.svg";
import vesselImg from "assets/images/svg/vessel.svg";

import {useMemo, Fragment, useState, useCallback} from "react";
import {Button} from "reactstrap";
import {Edit24} from "@bphxd/ds-core-react/lib/icons";

import UpdateMOTForm from "./UpdateMOTFormDetails";
import "./index.css";

const RouteDetail = ({data}) => {
  const [modalAddMOTDetailsVisible, setModalAddMOTDetailsVisible] =
    useState(false);

  const [motDetails, setMOTDetails] = useState(null);

  const routeDetails = useMemo(() => {
    return data?.moreDetailsData[0];
  }, [data]);

  const pad = (str, length, char = " ") =>
    str.padStart((str.length + length) / 2, char).padEnd(length, char);

  const routeDetailsSorted = sortBy(
    routeDetails?.route ?? [],
    "seqNumber",
    "asc",
  );

  return (
    <div className="flex flex-col justify-start items-stretch w-full gap-4">
      <div className="flex flex-col gap-4 w-full route-details">
        <div className="flex flex-col w-full pl-[30px]">
          <span className="border-b text-start max-w-[740px]">Route</span>
          <div className="flex items-center mx-5 my-7">
            {routeDetailsSorted?.map((item, index) => {
              const routeLength = routeDetailsSorted?.length;

              return (
                <Fragment key={index}>
                  <div className="flex flex-col">
                    <div className="flex justify-start">
                      {!isEmpty(item?.plantName) && (
                        <span className="text-black ml-[-125px] w-60">
                          {item?.plantName}
                        </span>
                      )}
                      {isEmpty(item?.plantName) && (
                        <span className="">{"\u00A0"}</span>
                      )}
                    </div>
                    <div className="flex items-center">
                      <div className="route-location-indicator"></div>
                      <div
                        className={`route-location-connector ${
                          index === routeLength - 1 ? "invisible" : "visible"
                        }`}
                      ></div>
                      <div
                        className={`${
                          item.mot === null ? "route-mot-null" : "route-mot"
                        } ${
                          index === routeLength - 1 ? "invisible" : "visible"
                        } flex`}
                      >
                        {item?.mot?.toLowerCase() === "truck" && (
                          <img src={truckImg} alt="truck" />
                        )}
                        {item.mot?.toLowerCase() === "vessel" && (
                          <img src={vesselImg} alt="vessel" />
                        )}
                        {item.mot === null && (
                          <h1 className="text-white m-0">?</h1>
                        )}
                      </div>
                      <div
                        className={`route-location-connector ${
                          index === routeLength - 1 ? "invisible" : "visible"
                        }`}
                      ></div>
                    </div>
                    <div
                      className={`flex justify-center mt-3 ${
                        index === routeLength - 1 ? "invisible" : "visible"
                      }`}
                    >
                      {!isEmpty(item?.motName) && (
                        <span className="text-black">{item?.motName}</span>
                      )}
                      {isEmpty(item?.motName) && (
                        <span className="">
                          {isEmpty(item?.mot)
                            ? "[Missing mode of transport]"
                            : "[Missing Name]"}
                        </span>
                      )}
                    </div>

                    <div
                      className={`flex justify-center mt-2 ${
                        item?.bioverseFlag && index !== routeLength - 1
                          ? "visible"
                          : "invisible"
                      }`}
                    >
                      <Button
                        size="md"
                        color="standard-tertiary bg-white"
                        className="underline btn-standard-secondary border-0 bg-white"
                        onClick={() => {
                          setModalAddMOTDetailsVisible(true);
                          setMOTDetails({
                            motName: item?.motName,
                            mot: item?.mot,
                          });
                        }}
                      >
                        {item?.bioverseFlag && item.mot === null
                          ? "Add"
                          : "Edit"}
                        <Edit24 className="btn-icon-suffix" />
                      </Button>
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>

      {modalAddMOTDetailsVisible && (
        <UpdateMOTForm
          isVisibleModal={modalAddMOTDetailsVisible}
          setModalAddMOTDetailsVisible={setModalAddMOTDetailsVisible}
          rowData={data}
          motDetails={motDetails}
        />
      )}
    </div>
  );
};

RouteDetail.propTypes = {
  data: PropTypes.object,
};

export default RouteDetail;
