import PropTypes from "prop-types";

import {useFormContext} from "react-hook-form";
import {FormGroup, Label, Input} from "reactstrap";

const InformationforDeliveries = ({data}) => {
  const {register, setValue} = useFormContext();

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  return (
    <div className="flex flex-col gap-4 pb-6">
      <Label className="fw-light">
        For deliveries of corn or corn based ethanol from the U.S.:
      </Label>

      <FormGroup check inline className="form-check-alt form-check-lg !mb-0">
        <Input
          {...computeProps("isJapaneseDefaultValuesforBrazilianEthanolUS")}
          type="checkbox"
          id="isJapaneseDefaultValuesforBrazilianEthanolUS"
          data-test="isJapaneseDefaultValuesforBrazilianEthanolUS"
        />

        <Label check for="isJapaneseDefaultValuesforBrazilianEthanolUS">
          Use of Japanese default values for U.S. ethanol (corn)
        </Label>
      </FormGroup>
      <FormGroup check inline className="form-check-alt form-check-lg !mb-0">
        <Input
          {...computeProps("isEmissionsfromLandUseZeroUS")}
          type="checkbox"
          id="isEmissionsfromLandUseZeroUS"
          data-test="isEmissionsfromLandUseZeroUS"
        />

        <Label check for="isEmissionsfromLandUseZeroUS">
          Emissions from land use change are zero (el = 0)
        </Label>
      </FormGroup>

      <Label className="fw-light">
        For deliveries of sugar cane or sugar cane based ethanol from Brazil:
      </Label>

      <FormGroup check inline className="form-check-alt form-check-lg !mb-0">
        <Input
          {...computeProps("isJapaneseDefaultValuesforBrazilianEthanolBrazil")}
          type="checkbox"
          id="isJapaneseDefaultValuesforBrazilianEthanolBrazil"
          data-test="isJapaneseDefaultValuesforBrazilianEthanolBrazil"
        />

        <Label check for="isJapaneseDefaultValuesforBrazilianEthanolBrazil">
          Use of Japanese default values for Brazilian ethanol (sugar cane)
        </Label>
      </FormGroup>
      <FormGroup check inline className="form-check-alt form-check-lg !mb-0">
        <Input
          {...computeProps("isEmissionsfromLandUseZeroBrazil")}
          type="checkbox"
          id="isEmissionsfromLandUseZeroBrazil"
          data-test="isEmissionsfromLandUseZeroBrazil"
        />

        <Label check for="isEmissionsfromLandUseZeroBrazil">
          Emissions from land use change are zero (el = 0)
        </Label>
      </FormGroup>
    </div>
  );
};
InformationforDeliveries.propTypes = {
  data: PropTypes.object,
};
export default InformationforDeliveries;
