import PropTypes from "prop-types";
import {useState} from "react";
import {
  Kebab24,
  Add24,
  Remove24,
  Edit24,
  Trash24,
  Hamburger24,
} from "@bphxd/ds-core-react/lib/icons";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {useNavigate} from "react-router-dom";
import {useLazyQuery} from "@apollo/client";
import {COPRO_US_BATCHES_WATCHLIST_API} from "graphql/coprocessing/batches";
import {useBatchType} from "modules/co-processing/context/BatchTypeContext";
import {COPRO_URL} from "modules/co-processing/constants/coProcessing";
import BatchesDeletePopover from "./BatchesDeletePopover";
import {useRefetch} from "../../context/RefetchContext";
import {DROPDOWN_OPTIONS, ACTIVE_STATUSES} from "../../constants/batches";

const DropdownOption = ({icon: Icon, label, onClick}) => (
  <DropdownItem onClick={onClick}>
    <Icon />
    <span className="pl-3.5">{label}</span>
  </DropdownItem>
);

DropdownOption.propTypes = {
  icon: PropTypes.any,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

const BatchDetailDropdown = ({batchDetails}) => {
  const navigate = useNavigate();
  const batchID = batchDetails?.batch_id;
  const isItemOnWatchlist = batchDetails?.watchlist;
  const isActive = ACTIVE_STATUSES.includes(
    batchDetails?.batch_status?.toUpperCase(),
  );
  const {batchType} = useBatchType();
  const {refetchBatches} = useRefetch();
  const [showPopover, setShowPopover] = useState(false);

  const [updateWatchList] = useLazyQuery(COPRO_US_BATCHES_WATCHLIST_API, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      setTimeout(() => {
        refetchBatches();
      }, 500);
    },
  });

  const onUpdateWatchList = () => {
    updateWatchList({
      variables: {
        watchlist: {
          batch_id: batchID,
          watchlist_op: batchType === "watchlist" ? "remove" : "add",
        },
      },
    });
  };

  const navigateToDetailsScreen = (isEditable = false) => {
    navigate(`${COPRO_URL}/batches/details`, {
      state: {
        batch_id: batchDetails.batch_id,
        details: batchDetails,
        editable: isEditable,
      },
    });
  };

  const onDelete = () => {
    setShowPopover(true);
  };

  const dropdownOptionList = [
    {
      icon: batchType === "watchlist" || isItemOnWatchlist ? Remove24 : Add24,
      label:
        batchType === "watchlist" || isItemOnWatchlist
          ? DROPDOWN_OPTIONS.REMOVE_FROM_WATCHLIST
          : DROPDOWN_OPTIONS.ADD_TO_WATCHLIST,
      onClick: () => onUpdateWatchList(),
    },
    {
      icon: Hamburger24,
      label: DROPDOWN_OPTIONS.VIEW_DETAILS,
      onClick: () => navigateToDetailsScreen(),
    },
    {
      icon: Edit24,
      label: DROPDOWN_OPTIONS.EDIT,
      onClick: () => navigateToDetailsScreen(true),
    },
    {
      icon: Trash24,
      label: DROPDOWN_OPTIONS.DELETE,
      onClick: () => onDelete(),
    },
  ];

  const getDropdownOptions = () => {
    return isActive ? dropdownOptionList : dropdownOptionList.slice(0, -1);
  };

  return (
    <>
      <UncontrolledDropdown data-test="kebab-menu" direction="up">
        <DropdownToggle
          data-test="batches-kebab-menu"
          color="standard-quartenary"
          className="!px-0"
        >
          <Kebab24
            id="batches-kebab-menu"
            data-test="batches-kebab-menu-icon"
          />
        </DropdownToggle>
        <DropdownMenu container="body">
          {getDropdownOptions()?.map((option, index) => (
            <DropdownOption
              data-test="batch-dropdown-option"
              key={index}
              icon={option.icon}
              label={option.label}
              onClick={option.onClick}
              id="popover-delete"
            />
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      <BatchesDeletePopover
        showPopover={showPopover}
        setShowPopover={setShowPopover}
        batchID={batchID}
      />
    </>
  );
};

BatchDetailDropdown.propTypes = {
  batchDetails: PropTypes.any,
};

export default BatchDetailDropdown;
