import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import {FormGroup, Label} from "reactstrap";
import CheckboxFlag from "modules/common/BinaryFlagNullable";

const ScopeOfCertification = ({status}) => {
  const {
    formState: {errors},
    control,
  } = useFormContext();
  const isDisabled = status === "completed" || status === "additional_info";
  return (
    <div className="flex flex-col gap-5 pb-6">
      <FormGroup>
        <Label for="materialSustainabilityCriteriaFlag" className="fw-normal">
          The raw material complies with the relevant sustainability criteria
          according to Art. 29 (2)-(7) RED II
        </Label>
        <CheckboxFlag
          id="materialSustainabilityCriteriaFlag"
          name="materialSustainabilityCriteriaFlag"
          control={control}
          error={errors.materialSustainabilityCriteriaFlag}
          disabled={isDisabled}
        />
      </FormGroup>
      <FormGroup>
        <Label
          for="agriculturalBiomassIntermediateCropFlag"
          className="fw-normal"
        >
          The agricultural biomass was cultivated as intermediate crop (if
          applicable)
        </Label>
        <CheckboxFlag
          id="agriculturalBiomassIntermediateCropFlag"
          name="agriculturalBiomassIntermediateCropFlag"
          control={control}
          error={errors.agriculturalBiomassIntermediateCropFlag}
          disabled={isDisabled}
        />
      </FormGroup>
      <FormGroup>
        <Label for="agriculturalBiomassLowRiskFlag" className="fw-normal">
          The agricultural biomass additionally fulfills the measures for low
          ILUC risk feedstocks (if applicable)
        </Label>
        <CheckboxFlag
          id="agriculturalBiomassLowRiskFlag"
          name="agriculturalBiomassLowRiskFlag"
          control={control}
          error={errors.agriculturalBiomassLowRiskFlag}
          disabled={isDisabled}
        />
      </FormGroup>
      <FormGroup>
        <Label for="rawMaterialWasteOrResidueFlag" className="fw-normal">
          The raw material meets the definition of waste or residue according to
          the RED II
        </Label>
        <CheckboxFlag
          id="rawMaterialWasteOrResidueFlag"
          name="rawMaterialWasteOrResidueFlag"
          control={control}
          error={errors.rawMaterialWasteOrResidueFlag}
          disabled={isDisabled}
        />
      </FormGroup>
    </div>
  );
};

ScopeOfCertification.propTypes = {
  status: PropTypes.string.isRequired,
};
export default ScopeOfCertification;
