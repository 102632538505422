import moment from "moment";

const formatTimeStamp = (string, isShortHand = false) => {
  const timestampArray = string?.split(" ");
  const parsedDigit = timestampArray[0] === "a" ? 1 : timestampArray[0];
  const dateShorthand = isShortHand
    ? timestampArray[1]?.split("")[0]
    : ` ${timestampArray[1]}`;
  return `${parsedDigit}${dateShorthand} ${timestampArray[2]}`;
};

const calculateDaysAgo = (date, isShortHand) =>
  formatTimeStamp(moment(date).fromNow(), isShortHand);

export const formatDatePickerValue = (date) => moment(date).format();

export const displayDate = (value, shortHand = false) => {
  const format = shortHand ? "MM/DD/YYYY" : "MM/DD/YYYY hh:mm A";
  return moment(value)?.format(format);
};

export const displayTime = (value) => moment(value)?.format("hh:mm A");

export default calculateDaysAgo;
