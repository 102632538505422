import React, {useState} from "react";
import {TabContent, TabPane, Nav, NavItem, NavLink} from "reactstrap";
import Layout from "../components/Layout/index.js";
import LocationMaster from "./LocationMaster.js";
import LocationDetails from "./LocationDetails.js";
import ProductDetails from "./ProductDetails.js";
import ProductionBaselineEmissions from "./ProductionBaselineEmissions.js";
import TransportationEmissions from "./TransportationEmissions.js";
import LocationYield from "./LocationYield.js";
import MaterialMaster from "./MaterialMaster.js";
import ProductionEmissions from "./ProductionEmissions.js";
import UomConversion from "./UomConversion.js";
import TransportationLoss from "./TransportationLoss.js";
import "./index.scss";
import ProductDDVDetails from "./ProductDDVDetails.js";

const CoprocessingSettingLandingPage = () => {
  const components = [
    {
      name: "Location master",
      component: <LocationMaster />,
    },
    {
      name: "Location details",
      component: <LocationDetails />,
    },
    {
      name: "Product details",
      component: <ProductDetails />,
    },
    {
      name: "Product baseline emissions",
      component: <ProductionBaselineEmissions />,
    },
    {
      name: "Transportation emissions",
      component: <TransportationEmissions />,
    },
    {
      name: "Location yield details",
      component: <LocationYield />,
    },
    {
      name: "Material master",
      component: <MaterialMaster />,
    },
    {
      name: "Production emissions",
      component: <ProductionEmissions />,
    },
    {
      name: "UoM conversion",
      component: <UomConversion />,
    },
    {
      name: "Transportation loss",
      component: <TransportationLoss />,
    },
    {
      name: "Product Default Value Details",
      component: <ProductDDVDetails />,
    },
  ];
  const [activeTab, setActiveTab] = useState("Location master");
  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: "Config center", link: "/config-center"},
  ];

  return (
    <Layout title="Config center" breadcrumbs={breadcrumbItems}>
      <Nav tabs className="border-b bg-inherit">
        {components.map((component) => {
          return (
            <NavItem>
              <NavLink
                className={
                  activeTab === component.name
                    ? "active nav-underline border-0"
                    : "border-0"
                }
                onClick={() => setActiveTab(component.name)}
              >
                {component.name}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
      <TabContent activeTab={activeTab} className="border-0">
        {components.map((component) => {
          return (
            <TabPane tabId={component.name}>{component.component}</TabPane>
          );
        })}
      </TabContent>
    </Layout>
  );
};

export default CoprocessingSettingLandingPage;
