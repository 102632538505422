/* eslint-disable prettier/prettier */
import {gql} from "@apollo/client";

export const COPRO_US_BATCHES_API_DATA = gql`
  query GetCoproUsBatchesApiDataQuery(
    $op: String
    $c14_id: String
    $updates: [BatchesUsDetailsUpdate]
  ) {
    bioLcCoproUsBatchesApi(
      event: {op: $op, c14_id: $c14_id, updates: $updates}
    ) {
      statusCode
      body {
        details {
          c14_detail_id
          c14_certificate_number
          submitter
          submitter_label
          company_name
          lab_number
          percentage_modern_carbon
          percentage_modern_carbon_var
          testing_method
          date_received
          date_submitted
          batch_id
        }
        batch_ids {
          batch_id
        }
      }
    }
  }
`;

export default COPRO_US_BATCHES_API_DATA;

export const GET_TANKS = gql`
  query GetTanks {
    bioLcCoproUsBatchesApi(event: {op: "get_tanks"}) {
      statusCode
      body {
        tanks {
          tank_number
          tank_detail_id
          latest_date
          latest_end_date
        }
      }
    }
  }
`;

export const BATCH_IDS_QUERY = gql`
  query getBatchIds($startTime: String, $endTime: String, $tankNumber: String) {
    bioLcCoproUsBatchesApi(
      event: {
        op: "get_batch_ids"
        start_time: $startTime
        end_time: $endTime
        tank_number: $tankNumber
      }
    ) {
      statusCode
      body {
        batch_ids {
          batch_id
          batch_code
          tank_certified_date
          source_sample_code
        }
      }
    }
  }
`;

export const COPRO_US_BATCHES_DETAILS_API = gql`
  query GetCoproUsBatchesApiDataQuery($op: String, $batch_tab: String) {
    bioLcCoproUsBatchesApi(event: {op: $op, batch_tab: $batch_tab}) {
      statusCode
      body {
        batches {
          batch_status
          tank_number
          batch_id
          batch_start_time
          batch_end_time
          operating_condition
          tank_volume
          tank_certified_datez
          batch_notes
          c14_detail_id
          watchlist
        }
      }
    }
  }
`;

export const COPRO_US_BATCHES_WATCHLIST_API = gql`
  query UpdateWatchlistItem($watchlist: BatchesUsWatchlistUpdate) {
    bioLcCoproUsBatchesApi(
      event: {op: "update_watchlist", watchlist: $watchlist}
    ) {
      statusCode
    }
  }
`;

export const COPRO_US_BATCH_PI_TAGS = gql`
  query GetBatchPiTags($startTime: String, $endTime: String) {
    bioLcCoproUsBatchesApi(
      event: {op: "get_pi_tags", start_time: $startTime, end_time: $endTime}
    ) {
      statusCode
      body {
        pi_tags {
          total_feed_bbl
          renewable_feed_bbl
          cold_recirc_in_renewable_feed_bbl
          renewable_feed_tank_col_su_sd_bbl
          cold_recirc_bbl
          hot_diesel_recirc_bbl
          unit_rundown_bbl
          total_feed_lb
          renewable_feed_lb
          cold_recirc_in_renewable_feed_lb
          renewable_feed_tank_col_su_sd_lb
          cold_recirc_lb
          hot_diesel_recirc_lb
          unit_rundown_lb
          rundown_density
        }
      }
    }
  }
`;

export const COPRO_US_BATCH_DAYS_REMAINING = gql`
  query GetBatchDaysRemaining($c14_id: String) {
    bioLcCoproUsBatchesApi(event: {op: "get_days_remaining", c14_id: $c14_id}) {
      statusCode
      body {
        days_remaining
      }
    }
  }
`;

export const GET_BATCH_IDS = gql`
  query getBatchIds($startTime: String, $endTime: String, $tankNumber: String) {
    bioLcCoproUsBatchesApi(
      event: {
        op: "get_batch_ids"
        start_time: $startTime
        end_time: $endTime
        tank_number: $tankNumber
      }
    ) {
      statusCode
      body {
        batch_ids {
          batch_id
          batch_code
          tank_certified_date
          source_sample_code
        }
      }
    }
  }
`;

export const GET_VALVE_STATUS = gql`
  query valveStatusOpen($start_time: String, $end_time: String) {
    bioLcCoproUsBatchesApi(
      event: {
        op: "get_valve_status"
        start_time: $start_time
        end_time: $end_time
      }
    ) {
      statusCode
      body {
        valve_open
      }
    }
  }
`;

export const DELETE_BATCH_BY_ID = gql`
  query DeleteBatchByID($op: String, $batch_id: String) {
    bioLcCoproUsBatchesApi(event: {op: $op, batch_id: $batch_id}) {
      statusCode
      error
    }
  }
`;

export const SAVE_BATCH = gql`
  query SaveBatch($batch_details: BatchesUsSaveBatchApi) {
    bioLcCoproUsBatchesApi(
      event: {op: "save_batch", batch_details: $batch_details}
    ) {
      statusCode
      error
    }
  }
`;

export const GET_BATCH_BY_ID = gql`
  query GetBatchByID($batch_id: String) {
    bioLcCoproUsBatchesApi(event: {op: "get_batch", batch_id: $batch_id}) {
      statusCode
      error
      body {
        batch_details {
          batch_id
          batch_status
          batch_start_time
          batch_end_time
          tank_certified_datez
          tank_number
          tank_volume
          operating_condition
          batch_notes
          missing_c14
          c14_pmc
          c14_sample_date
          c14_sample_name
        }
      }
    }
  }
`;

export const UPDATE_BATCH = gql`
  query UpdateBatch($batch_id: String, $updates: [BatchesUsDetailsUpdate]) {
    bioLcCoproUsBatchesApi(
      event: {op: "update_batch", batch_id: $batch_id, updates: $updates}
    ) {
      statusCode
      error
    }
  }
`;
