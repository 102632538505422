import PropTypes from "prop-types";
import {useQuery} from "@apollo/client";
import {MATERIAL_MASTER} from "graphql/config-center/MaterialMaster.js";
import {useFormContext, Controller} from "react-hook-form";
import {useEffect} from "react";
import parseAndRoundToTwoDecimal from "utils/numberUtil";
import {FormGroup, Label, Input, FormFeedback} from "reactstrap";

const GeneralInformationV351 = ({countries, data}) => {
  const {
    register,
    formState: {errors},
    setValue,
  } = useFormContext();

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const bioFeedstockList = [
    {id: "feedstockBioCircular", label: "Bio-circular"},
    {id: "feedstockCircular", label: "Circular"},
    {id: "feedstockBio", label: "Bio"},
    {id: "feedstockRenewableEnergy", label: "Renewable-energy-derived"},
  ];
  const recyclingOperationTypes = [
    {id: "operationTypeChemical", label: "Chemical"},
    {id: "operationTypeMechanical", label: "Mechanical"},
    {id: "operationTypenotApplicable", label: "Not applicable"},
  ];

  const wasteStatuses = [
    {id: "postConsumerMaterial", label: "Post-consumer material"},
    {id: "preConsumerMaterial", label: "Pre-consumer material"},
    {id: "mixUnspecifiedMaterial", label: "Mix/unspecified"},
  ];

  useEffect(() => {
    setValue(
      "ghgEec",
      parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgEec),
    );
    setValue(
      "ghgEl",
      parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgEl),
    );
    setValue(
      "ghgEp",
      parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgEp),
    );
    setValue(
      "ghgEtd",
      data?.rowData?.outboundRecords?.ghgEtd === "DDV"
        ? data?.rowData?.outboundRecords?.ghgEtd
        : parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgEtd),
    );
    setValue(
      "ghgEu",
      parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgEu),
    );
    setValue(
      "ghgEsca",
      parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgEsca),
    );
    setValue(
      "ghgEccs",
      parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgEccs),
    );
    setValue(
      "ghgEccr",
      parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgEccr),
    );
    setValue("totalDefaultValueRedApplied", false);
    setValue(
      "ghgTotal",
      data?.rowData?.outboundRecords?.ghgTotal === "DDV" ||
        data?.rowData?.outboundRecords?.ghgTotal === "TDV"
        ? data?.rowData?.outboundRecords?.ghgTotal
        : parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgTotal),
    );
    const checkboxes = document.getElementsByName("checkboxfeedstock");
    checkboxes.forEach((item) => {
      if (item.id === "feedstockBioCircularcheckboxFlag") {
        /* eslint-disable no-param-reassign */
        item.checked = true;
      }
    });
    setValue("feedstockType", "Bio-circular");
    document.getElementById("feedstockBioCircularInputQty").value =
      data?.quantity;
  }, [setValue, data]);

  const {data: materialMasterData, loading: materialMasterLoading} =
    useQuery(MATERIAL_MASTER);

  useEffect(() => {
    setValue("productType", data?.productType);
    setValue("rawMaterialType", data?.rowData?.inboundRecord?.materialCode);

    setValue(
      "rawMaterialOriginCountryName",
      data?.rowData?.inboundRecord?.originCountryName,
    );
    setValue("certifiedMaterialQty", data?.quantity);
    setValue("feedstockQtyInput", data?.quantity);
    setValue("countryOfProduction", "SPAIN");

    if (data?.uom === "MT") {
      setValue("uomMetricTons", true);
    }
    if (data?.uom === "M3") {
      setValue("uomm3", true);
    }
  }, [setValue, data]);

  const handleCheckBoxListItemClick = (
    e,
    label,
    checkBoxListName,
    controlName,
  ) => {
    const checkboxes = document.getElementsByName(checkBoxListName);

    if (e.target.checked) {
      checkboxes.forEach((item) => {
        if (item !== e.target) {
          /* eslint-disable no-param-reassign */
          item.checked = false;
        }
      });

      setValue(controlName, label);
    }
  };

  const handleFeedstockCheckBoxListItemClick = (
    e,
    label,
    checkBoxListName,
    controlName,
    feedstockId,
    feedstockInputName,
    currentInputItem,
  ) => {
    const checkboxes = document.getElementsByName(checkBoxListName);
    const checkboxeInputs = document.getElementsByName(feedstockInputName);

    checkboxeInputs.forEach((itemInput) => {
      itemInput.setAttribute("disabled", "");
      itemInput.value = "";
      itemInput.setAttribute("value", "");
    });
    setValue("feedstockQtyInput", "");
    if (e.target.checked) {
      document.getElementById(currentInputItem).value = data?.quantity;
      setValue("feedstockQtyInput", data?.quantity);
      checkboxes.forEach((item) => {
        if (item !== e.target) {
          /* eslint-disable no-param-reassign */
          item.checked = false;
        }
      });

      setValue(controlName, label);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <FormGroup>
        <Label for="productType" className="fw-bold">
          Type of product (*)
        </Label>

        <Input
          {...computeProps("productType")}
          type="text"
          id="productType"
          data-test="productType"
          invalid={!!errors.productType}
          maxLength={100}
          disabled
        />

        {errors.productType && (
          <FormFeedback>{errors.productType.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="feedstockQtyUom" className="fw-bold">
          Unit of Measure
        </Label>
        <div>
          <FormGroup
            check
            inline
            className="form-check-alt form-check-lg !mb-0"
          >
            <Input
              {...computeProps("uomMetricTons")}
              type="checkbox"
              id="uomMetricTons"
              data-test="uomMetricTons"
              disabled
            />

            <Label check for="uomMetricTons">
              Metric tons
            </Label>
          </FormGroup>

          <FormGroup
            check
            inline
            className="form-check-alt form-check-lg !mb-0"
          >
            <Input
              {...computeProps("uomm3")}
              type="checkbox"
              id="uomm3"
              data-test="uomm3"
              disabled
            />

            <Label check for="uomm3">
              m<sup>3</sup>
            </Label>
          </FormGroup>
        </div>
      </FormGroup>
      <Label className="fw-bold">Raw material category</Label>
      <div className="grid grid-cols-2 gap-x-3">
        {bioFeedstockList.map((feedstock) => (
          <>
            <FormGroup
              check
              inline
              className="form-check-alt form-check-lg !mb-0"
            >
              <Controller
                name="feedstockType"
                render={({field}) => (
                  <Input
                    {...computeProps(`${feedstock.id}checkboxFlag`)}
                    type="checkbox"
                    id={`${feedstock.id}checkboxFlag`}
                    data-test={`${feedstock.id}checkboxFlag`}
                    name="checkboxfeedstock"
                    onClick={(e) =>
                      handleFeedstockCheckBoxListItemClick(
                        e,
                        feedstock.label,
                        "checkboxfeedstock",
                        "feedstockType",
                        feedstock.id,
                        "feedstockInputQty",
                        `${feedstock.id}InputQty`,
                      )
                    }
                  />
                )}
              />

              <Label check for={`${feedstock.id}checkboxFlag`}>
                {feedstock.label}
              </Label>
            </FormGroup>
            <FormGroup>
              <Controller
                name="feedstockQtyInput"
                render={({field}) => (
                  <Input
                    {...computeProps(`${feedstock.id}InputQty`)}
                    type="number"
                    className="w-36"
                    disabled
                    id={`${feedstock.id}InputQty`}
                    name="feedstockInputQty"
                    data-test={`${feedstock.id}InputQty`}
                    onChange={(e) =>
                      setValue("feedstockQtyInput", e.target.value)
                    }
                  />
                )}
              />
            </FormGroup>
          </>
        ))}
      </div>
      <FormGroup>
        <Label for="certifiedMaterialQty" className="fw-bold">
          Total quantity of certified material mt
        </Label>

        <Input
          {...computeProps("certifiedMaterialQty")}
          type="number"
          id="certifiedMaterialQty"
          data-test="certifiedMaterialQty"
          invalid={!!errors.certifiedMaterialQty}
          disabled
        />

        {errors.certifiedMaterialQty && (
          <FormFeedback>{errors.certifiedMaterialQty.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="deliveryQty" className="fw-bold">
          Total quantity of delivery (optional) mt
        </Label>

        <Input
          {...computeProps("deliveryQty")}
          type="number"
          id="deliveryQty"
          data-test="deliveryQty"
          invalid={!!errors.deliveryQty}
        />

        {errors.deliveryQty && (
          <FormFeedback>{errors.deliveryQty.message}</FormFeedback>
        )}
      </FormGroup>
      <Label className="fw-bold">
        Type of recycling operations (circular):
      </Label>

      <div className="flex flex-row justify-between items-start gap-3">
        {recyclingOperationTypes.map((operationType) => (
          <FormGroup
            check
            inline
            className="form-check-alt form-check-lg !mb-0"
          >
            <Controller
              name="operationType"
              render={({field}) => (
                <Input
                  {...computeProps(`${operationType.id}checkboxFlag`)}
                  type="checkbox"
                  id={`${operationType.id}checkboxFlag`}
                  data-test={`${operationType.id}checkboxFlag`}
                  name="checkboxOperationType"
                  onClick={(e) =>
                    handleCheckBoxListItemClick(
                      e,
                      operationType.label,
                      "checkboxOperationType",
                      "operationType",
                    )
                  }
                />
              )}
            />
            <Label check for={`${operationType.id}checkboxFlag`}>
              {operationType.label}
            </Label>
          </FormGroup>
        ))}
      </div>

      <Label className="fw-bold">Waste status (circular):</Label>

      <div className="flex flex-row justify-between items-start gap-3">
        {wasteStatuses.map((status) => (
          <FormGroup
            check
            inline
            className="form-check-alt form-check-lg !mb-0"
          >
            <Controller
              name="wasteStatus"
              render={({field}) => (
                <Input
                  type="checkbox"
                  id={`${status.id}checkboxFlag`}
                  data-test={`${status.id}checkboxFlag`}
                  name="checkboxWasteStatus"
                  onClick={(e) =>
                    handleCheckBoxListItemClick(
                      e,
                      status.label,
                      "checkboxWasteStatus",
                      "wasteStatus",
                    )
                  }
                />
              )}
            />
            <Label check for={`${status.id}checkboxFlag`}>
              {status.label}
            </Label>
          </FormGroup>
        ))}
      </div>

      <FormGroup>
        <Label for="rawMaterialType" className="fw-bold">
          Type of raw material
        </Label>

        <Input
          {...computeProps("rawMaterialType")}
          type="select"
          id="rawMaterialType"
          data-test="rawMaterialType"
          invalid={!!errors.rawMaterialType}
        >
          {materialMasterData?.bioLcCoproConfigCenterMaterialMaster?.Records?.map(
            (material) => (
              <option
                key={material.material_code}
                value={material.material_code}
              >
                {material.material_short_name}
              </option>
            ),
          )}
        </Input>

        {errors.rawMaterialType && (
          <FormFeedback>{errors.rawMaterialType.message}</FormFeedback>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="rawMaterialOriginCountryName" className="fw-bold">
          Country of origin of the raw material
        </Label>

        <Input
          {...computeProps("rawMaterialOriginCountryName")}
          type="text"
          id="rawMaterialOriginCountryName"
          data-test="rawMaterialOriginCountryName"
          invalid={!!errors.rawMaterialOriginCountryName}
          disabled
        />

        {errors.rawMaterialOriginCountryName && (
          <FormFeedback>
            {errors.rawMaterialOriginCountryName.message}
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="additionalInfo" className="fw-bold">
          Additional information
        </Label>
        <Input
          {...computeProps("additionalInfo")}
          type="textarea"
          rows="3"
          id="additionalInfo"
          data-test="additionalInfo"
          invalid={!!errors.additionalInfo}
          maxLength={250}
        />
      </FormGroup>
    </div>
  );
};
GeneralInformationV351.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.object,
};
export default GeneralInformationV351;
