import PropTypes from "prop-types";
import React from "react";
import {Button, Label} from "reactstrap";
import {Controller, useForm} from "react-hook-form";
import DatePicker from "modules/common/DatePickerNew";
import FormDropdown from "./FormDropdown/FormDropdown";

const SafFilter = ({
  onSubmit,
  supplierList,
  supplierName,
  dateFrom,
  dateTo,
  setColumnFilter,
}) => {
  const {handleSubmit, control, reset, getValues, setValue} = useForm({
    defaultValues: {
      supplier: "",
    },
    values: {
      certificateType: "Proof of Sustainability",
      supplier: supplierName,
      dateRange: dateFrom && dateTo && [dateFrom, dateTo],
    },
  });

  const handleFormSubmit = ({field, data}) => {
    setValue(field, data);
    onSubmit({
      supplierName: getValues("supplier"),
      dateOfIssuance: getValues("dateRange"),
    });
  };

  const handleClearForm = () => {
    reset();
    onSubmit(undefined);
    setColumnFilter([]);
  };

  return (
    <div className="items-start sm:items-center sm:flex-row gap-y-2 sm:gap-x-3">
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="flex gap-4">
          <div className="w-64 mr-3">
            <Controller
              control={control}
              name="certificateType"
              render={({field}) => {
                return (
                  <FormDropdown
                    label="Certificate Type"
                    options={["Proof of Sustainability"]}
                    placeholder="Select"
                    values={["Proof of Sustainability"]}
                    hookToBody
                    menuClassName="compliance-dropdown-filter w-64"
                    menuItemClassName={{width: "256px"}}
                    onSelect={(d) =>
                      handleFormSubmit({field: "certificateType", data: d})
                    }
                    {...field}
                  />
                );
              }}
            />
          </div>
          <div className="w-64 mr-3">
            <Controller
              control={control}
              name="supplier"
              render={({field}) => {
                return (
                  <FormDropdown
                    label="Supplier"
                    options={supplierList.map(
                      (supplier) => supplier.supplier_name,
                    )}
                    placeholder="Select"
                    values={supplierList.map(
                      (supplier) => supplier.supplier_name,
                    )}
                    hookToBody
                    menuClassName="compliance-dropdown-filter w-64"
                    menuItemClassName={{width: "256px"}}
                    onSelect={(d) =>
                      handleFormSubmit({field: "supplier", data: d})
                    }
                    {...field}
                  />
                );
              }}
            />
          </div>
          <div className="w-64 mr-3">
            <Controller
              control={control}
              name="dateRange"
              render={({field: {value}}) => (
                <div>
                  <Label for="dateRange" className="mb-3">
                    Date Range
                  </Label>
                  <DatePicker
                    className="bg-white"
                    placeholder="Date Range"
                    id="dateRange"
                    name="dateRange"
                    allowEmpty={[true]}
                    resetOptions="true"
                    defaultValue=""
                    options={{
                      allowInput: false,
                      dateFormat: "d/m/Y",
                      defaultDate: value || [dateFrom, dateTo],
                      showMonths: 2,
                      enableTime: false,
                      mode: "range",
                      onClose: (selectedDates, date, instance) => {
                        handleFormSubmit({
                          field: "dateRange",
                          data: date.split(" thru "),
                        });
                      },
                    }}
                  />
                </div>
              )}
            />
          </div>
          <div className="flex flex-col-reverse">
            <Button
              className="padded btn btn-light rounded-0 border"
              onClick={handleClearForm}
            >
              Clear
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

SafFilter.propTypes = {
  onSubmit: PropTypes.func,
  supplierList: PropTypes.array,
  supplierName: PropTypes.string,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  setColumnFilter: PropTypes.func,
};

export default SafFilter;
