import PropTypes from "prop-types";
import {useLazyQuery} from "@apollo/client";
import {toast} from "react-toastify";
import {COPRO_US_SHIPMENTS_API_DELETE_SHIPMENT} from "graphql/coprocessing/shipments";
import {
  DELETE_POPOVER,
  ERRORS,
  DELETE_SUCCESS,
} from "../../constants/shipments";
import DeletePopover from "../DeletePopover";

const ShipmentsDeletePopover = ({
  showPopover,
  setShowPopover,
  shipmentID,
  refetchShipments,
}) => {
  const [deleteShipment] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_DELETE_SHIPMENT,
    {
      fetchPolicy: "network-only",
      onCompleted: (result) => {
        if (result?.bioLcCoproUsShipmentsApi?.statusCode === 200) {
          toast.success(DELETE_SUCCESS);
          refetchShipments();
        } else {
          toast.error(ERRORS.FAILED_DELETE);
        }
      },
    },
  );

  const handleDelete = () => {
    deleteShipment({variables: {shipmentId: shipmentID}});
    setShowPopover(false);
  };

  return (
    <DeletePopover
      handleDelete={handleDelete}
      setShowPopover={setShowPopover}
      showPopover={showPopover}
      popoverTarget="shipments-actions-button"
      header={DELETE_POPOVER.HEADER}
      message={DELETE_POPOVER.MESSAGE}
      idToDelete={shipmentID}
    />
  );
};

ShipmentsDeletePopover.propTypes = {
  showPopover: PropTypes.bool,
  setShowPopover: PropTypes.func,
  shipmentID: PropTypes.string,
  refetchShipments: PropTypes.func,
};

export default ShipmentsDeletePopover;
