import PropTypes from "prop-types";
import {toast} from "react-toastify";
import React, {useState} from "react";
import {Button} from "reactstrap";
import {CloseBalanceAPI} from "graphql/es-co-processing/mass-balance/closeBalance.js";
import ConfirmationPopup from "./ConfirmationPopup";

const CloseBalance = ({
  locationCode,
  activeMaterial,
  account,
  chosenCycle,
  refereshChosenCycleData = () => {},
}) => {
  const [isCloseConfirmModalVisible, setCloseConfirmModalVisible] =
    useState(false);
  return (
    <>
      <Button
        size="sm"
        className="h-10 leading-5 rounded-0 ms-0 text-gray-400 bg-black"
        onClick={() => setCloseConfirmModalVisible(true)}
      >
        Close balance
      </Button>
      <ConfirmationPopup
        isOpen={isCloseConfirmModalVisible}
        title="Confirm Close"
        confirmLabel="Confirm"
        message={
          <span>
            <p>{`Are you sure you want to close the Quarter ${
              chosenCycle
                ? ` - ${chosenCycle.mb_quarter} ${chosenCycle.mb_year}`
                : ""
            }?`}</p>
          </span>
        }
        onCancel={() => setCloseConfirmModalVisible(false)}
        onConfirm={async (values) => {
          const {data: response} = await CloseBalanceAPI({
            year: chosenCycle ? chosenCycle.mb_year : "",
            quarter: chosenCycle ? chosenCycle.mb_quarter : "",
            materialCode: activeMaterial,
            locationCode,
            createdBy: account.username,
          });

          if (response?.bioLcCoproEuMassBalanceClose?.statusCode === 200) {
            setCloseConfirmModalVisible(false);
            refereshChosenCycleData();
            toast.success(
              response?.bioLcCoproEuMassBalanceClose?.message ??
                "Successfully closed the quarter",
            );
          } else {
            toast.error(
              response?.bioLcCoproEuMassBalanceClose?.message ??
                "Something went wrong, failed to close",
            );
          }
          return response;
        }}
      />
    </>
  );
};

CloseBalance.propTypes = {
  chosenCycle: PropTypes.any.isRequired,
  activeMaterial: PropTypes.string.isRequired,
  locationCode: PropTypes.string.isRequired,
  account: PropTypes.any.isRequired,
  refereshChosenCycleData: PropTypes.func.isRequired,
};

export default CloseBalance;
