import {gql} from "@apollo/client";

export const COPRO_EU_GET_CERTIFICATE_OUTBOUND = gql`
  query bioLcCoproEuGetCertificateOutbound {
    bioLcCoproEuGetCertificateOutbound {
      certificateNumber
      recipientName
      productStatus
      saleStatus
      issueDate
      certificateType
      saleYear
      saleQtr
      materialCode
      materialName
      fromLocationCode
      locationName
      totalDeliveryQty
      productType
      outboundEuQtyId
      certificatePdfUrl
    }
  }
`;

export const SEND_CERTIFICATE = gql`
  mutation SendCertificateMutation($event: sendCertificateRequest!) {
    sendCertificate(event: $event) {
      statusCode
      message
    }
  }
`;
export default {COPRO_EU_GET_CERTIFICATE_OUTBOUND, SEND_CERTIFICATE};
