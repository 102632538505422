import moment from "moment";
import PropTypes from "prop-types";
import {formatNumber, useUserSettings} from "providers/userSettings";
import {useMemo, useState} from "react";
import {toast} from "react-toastify";
import {
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import {useQuery} from "@apollo/client";
import {LOCATION_YIELD} from "graphql/config-center/LocationYield.js";
import {UpdateDocAPI} from "graphql/es-co-processing/mass-balance/updateInboundDetails";
import parseAndRoundToTwoDecimal from "utils/numberUtil";

const OutboundDetail = ({
  data,
  rowId,
  subRowId,
  onSelectionChange = () => {},
}) => {
  const [currentIndex, setCurrentIndex] = useState(subRowId);
  const {outboundRecords} = data;
  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();

  const outboundRecord = useMemo(() => {
    const obRec = {...outboundRecords[currentIndex]};
    let calculationSteps = obRec.calculationSteps ?? "None";
    if (calculationSteps === "None") {
      obRec.calculationSteps = {};
      return obRec;
    }
    calculationSteps = JSON.parse(calculationSteps.replaceAll("'", '"'));
    obRec.calculationSteps =
      calculationSteps[obRec.unitToBeDisplayed ?? "kg CO2eq/dry-ton"];
    return obRec;
  }, [currentIndex, outboundRecords]);

  const {data: yieldDataRes, loading} = useQuery(LOCATION_YIELD);

  const yieldData =
    yieldDataRes?.bioLcCoproConfigCenterLocationYieldDetails?.Records?.filter(
      (item) =>
        item?.location_code ===
          data?.inboundRecord?.mbLocationCode.toString() &&
        item?.material_code === data?.inboundRecord?.materialCode,
    ).sort();

  const inboundRecord = useMemo(() => {
    const {inboundRecord} = data;
    const inRec = {...inboundRecord};
    let calculationSteps = inRec.calculationSteps ?? "None";
    if (calculationSteps === "None") {
      inRec.calculationSteps = {};
      return inRec;
    }
    calculationSteps = JSON.parse(calculationSteps.replaceAll("'", '"'));
    inRec.calculationSteps =
      calculationSteps[inRec.unitToBeDisplayed ?? "kg CO2eq/dry-ton"];
    return inRec;
  }, [data]);

  const [appliedYieldRatio, setAppliedYieldRatio] = useState(
    inboundRecord.appliedYieldRatio,
  );

  const notAllowedStatuses = [
    "ASSIGNED AND SOLD",
    "ASSIGNED AND SENT",
    "WRITTEN OFF",
    "LOCKED",
    "TRANSFERRED",
  ];
  const notAllowedItemsLength = data?.outboundRecords?.filter((item) =>
    notAllowedStatuses.includes(item.productStatus),
  )?.length;

  const handleYieldRatioChange = async (yieldRatio, yieldType) => {
    if (yieldRatio !== "") {
      setAppliedYieldRatio(yieldRatio);
      const yieldOptionSelected = yieldType?.split("-");
      const {__typename, ...restValues} = data.inboundRecord;
      const {data: response} = await UpdateDocAPI({
        event: {
          inboundEuQtyId: inboundRecord.inboundEuQtyId,
          mbLocationCode: inboundRecord.mbLocationCode,
          qtyProcessedIn: inboundRecord.qtyProcessedIn,
          appliedYieldRatio: yieldRatio,
          appliedYieldType:
            yieldOptionSelected[0]?.replace("Yield", "")?.trim() ?? "",
        },
      });
      if (response?.bioLcCoproEuMassBalanceCustomYield?.statusCode !== 200) {
        toast.error(response?.bioLcCoproEuMassBalanceCustomYield?.message);
      }
    }
  };
  return (
    <div className="flex flex-col h-96 w-full gap-4 items-stretch">
      <div className="flex flex-row justify-between items-center">
        <div className="text-uppercase text-xs fw-bold">
          {currentIndex + 1} of {outboundRecords.length}
        </div>
        <div className="flex flex-row justify-end gap-2 items-center">
          <Button
            color="link"
            size="sm"
            className="text-default fw-bold !pr-0"
            onClick={() => {
              setCurrentIndex(currentIndex - 1);
              onSelectionChange(`${rowId}_${currentIndex - 1}`);
            }}
            disabled={currentIndex === 0}
          >
            PREV
          </Button>
          <Button
            color="link"
            size="sm"
            className="text-default fw-bold !pr-0"
            onClick={() => {
              setCurrentIndex(currentIndex + 1);
              onSelectionChange(`${rowId}_${currentIndex + 1}`);
            }}
            disabled={currentIndex === outboundRecords.length - 1}
          >
            NEXT
          </Button>
        </div>
      </div>
      <div className="flex flex-row justify-between gap-2">
        <FormGroup>
          <Label for="ghgEecOut" className="fw-bold">
            Eec
          </Label>
          <Input
            type="text"
            id="ghgEecOut"
            data-test="ghgEecOut"
            readOnly
            value={formatNumber(
              parseAndRoundToTwoDecimal(outboundRecord.ghgEec),
              decimalFormat,
              0,
            )}
          />
        </FormGroup>
        <div className="h-full flex flex-row items-center">+</div>
        <FormGroup>
          <Label for="ghgElOut" className="fw-bold">
            El
          </Label>
          <Input
            type="text"
            id="ghgElOut"
            data-test="ghgElOut"
            readOnly
            value={formatNumber(
              parseAndRoundToTwoDecimal(outboundRecord.ghgEl),
              decimalFormat,
              0,
            )}
          />
        </FormGroup>
        <div className="h-full flex flex-row items-center">+</div>
        <FormGroup>
          <Label for="ghgEpOut" className="fw-bold">
            Ep
          </Label>
          <Input
            type="text"
            id="ghgEpOut"
            data-test="ghgEpOut"
            readOnly
            value={formatNumber(
              parseAndRoundToTwoDecimal(outboundRecord.ghgEp),
              decimalFormat,
              0,
            )}
          />
          <UncontrolledTooltip placement="bottom" target="ghgEpOut">
            {outboundRecord.calculationSteps?.ghg_ep ?? ""}
          </UncontrolledTooltip>
        </FormGroup>
        <div className="h-full flex flex-row items-center">+</div>
        <FormGroup>
          <Label for="ghgEtdOut" className="fw-bold">
            Etd
          </Label>
          <Input
            type="text"
            id="ghgEtdOut"
            data-test="ghgEtdOut"
            readOnly
            value={
              outboundRecord.ghgEtd === "DDV"
                ? outboundRecord.ghgEtd
                : formatNumber(
                    parseAndRoundToTwoDecimal(outboundRecord.ghgEtd),
                    decimalFormat,
                    0,
                  )
            }
          />
          <UncontrolledTooltip placement="bottom" target="ghgEtdOut">
            {outboundRecord.calculationSteps?.ghg_etd ?? ""}
          </UncontrolledTooltip>
        </FormGroup>
        <div className="h-full flex flex-row items-center">+</div>
        <FormGroup>
          <Label for="ghgEuOut" className="fw-bold">
            Eu
          </Label>
          <Input
            type="text"
            id="ghgEuOut"
            data-test="ghgEuOut"
            readOnly
            value={formatNumber(
              parseAndRoundToTwoDecimal(outboundRecord.ghgEu),
              decimalFormat,
              0,
            )}
          />
        </FormGroup>
        <div className="h-full flex flex-row items-center">-</div>
        <FormGroup>
          <Label for="ghgEscaOut" className="fw-bold">
            Esca
          </Label>
          <Input
            type="text"
            id="ghgEscaOut"
            data-test="ghgEscaOut"
            readOnly
            value={formatNumber(
              parseAndRoundToTwoDecimal(outboundRecord.ghgEsca),
              decimalFormat,
              0,
            )}
          />
        </FormGroup>
        <div className="h-full flex flex-row items-center">-</div>
        <FormGroup>
          <Label for="ghgEccsOut" className="fw-bold">
            Eccs
          </Label>
          <Input
            type="text"
            id="ghgEccsOut"
            data-test="ghgEccsOut"
            readOnly
            value={formatNumber(
              parseAndRoundToTwoDecimal(outboundRecord.ghgEccs),
              decimalFormat,
              0,
            )}
          />
        </FormGroup>
        <div className="h-full flex flex-row items-center">-</div>
        <FormGroup>
          <Label for="ghgEccrOut" className="fw-bold">
            Eccr
          </Label>
          <Input
            type="text"
            id="ghgEccrOut"
            data-test="ghgEccrOut"
            readOnly
            value={formatNumber(
              parseAndRoundToTwoDecimal(outboundRecord.ghgEccr),
              decimalFormat,
              0,
            )}
          />
        </FormGroup>
        <div className="h-full flex flex-row items-center">=</div>
        <FormGroup>
          <Label for="ghgTotalOut" className="fw-bold">
            Total
          </Label>
          <Input
            type="text"
            id="ghgTotalOut"
            className="min-w-[100px]"
            data-test="ghgTotalOut"
            readOnly
            value={
              outboundRecord.ghgTotal === "DDV" ||
              outboundRecord.ghgTotal === "TDV"
                ? outboundRecord.ghgTotal
                : formatNumber(
                    parseAndRoundToTwoDecimal(outboundRecord.ghgTotal),
                    decimalFormat,
                    0,
                  )
            }
          />
          <FormText>
            {outboundRecord.unitToBeDisplayed ?? "kg CO2eq/dry-ton"}
          </FormText>
        </FormGroup>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div className="flex flex-col">
          {inboundRecord?.mbLocationCode === 3 ? (
            <>
              <span className="">Yield %</span>
              <Input
                type="select"
                id="yieldRatio"
                data-test="yieldRatio"
                onChange={(e) =>
                  handleYieldRatioChange(
                    e.target.value,
                    e.target.options[e.target.selectedIndex].text,
                  )
                }
                value={appliedYieldRatio}
                disabled={!inboundRecord.yieldEnableFlag}
              >
                {yieldData?.map((yieldData) => (
                  <option
                    key={yieldData.yield_ratio}
                    value={yieldData.yield_ratio}
                  >
                    {`${yieldData.yield_type} Yield - ${yieldData.yield_ratio}`}
                  </option>
                ))}
              </Input>
            </>
          ) : (
            <>
              {" "}
              <span className="border-b">Yield %</span>
              <span className="py-1 fw-bold truncate ...">
                {inboundRecord.appliedYieldRatio ?? "-"}
              </span>
            </>
          )}
        </div>
        <div className="flex flex-col">
          <span className="border-b">Date of issuance</span>
          <span className="py-1 fw-bold truncate ...">
            {outboundRecord.issueDate != null
              ? moment(new Date(outboundRecord.issueDate)).format(dateFormat)
              : "-"}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="border-b">Name of recipient</span>
          <span className="py-1 fw-bold truncate ...">
            {outboundRecord.recipientName ?? "-"}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="border-b">Address of recipient</span>
          <span className="py-1 fw-bold truncate ...">
            {outboundRecord.recipientAddress ?? "-"}
          </span>
        </div>

        <div className="flex flex-col">
          <span className="border-b">Transport system</span>
          <span className="py-1 fw-bold truncate ...">
            {outboundRecord.motName ?? "-"}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="border-b">Vessel name</span>
          <span className="py-1 fw-bold truncate ...">
            {outboundRecord.motOut ?? "-"}
          </span>
        </div>
      </div>
    </div>
  );
};

OutboundDetail.propTypes = {
  data: PropTypes.object,
  rowId: PropTypes.string,
  subRowId: PropTypes.number,
  onSelectionChange: PropTypes.func,
};

export default OutboundDetail;
