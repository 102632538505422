import _ from "lodash";
import {
  BarChart,
  Bar,
  YAxis,
  XAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import PropTypes from "prop-types";
import {BAR_CHART, POUNDS_TO_METRIC_TONS} from "../constants/receipts";

const CustomTooltip = ({active, payload, label}) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-1 shadow">
        <p className="label fs-6">{`${label} : ${payload[0].value.toLocaleString()} MT`}</p>
      </div>
    );
  }

  return null;
};
CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.string,
};

const poundsToMetricTons = (lbsValue) => {
  if (!lbsValue) return 0;

  const valueInTons = Number(lbsValue) * POUNDS_TO_METRIC_TONS;

  return Math.round(valueInTons);
};

export const convertContractsToGraphData = (data) => {
  const graphDataByFeedstock = _(data)
    .groupBy("feedstock_type")
    .map((contracts, feedstock) => ({
      feedstock_type: feedstock,
      feedstock_qty: _.sumBy(contracts, (item) =>
        item?.feedstock_qty_uom === "LBS"
          ? poundsToMetricTons(item?.feedstock_qty)
          : Math.round(item?.feedstock_qty),
      ),
    }))
    .keyBy("feedstock_type")
    .value();

  const graphDataWithTotal = BAR_CHART.map((bar) => {
    if (bar.type === "Total") {
      return {
        ...bar,
        value: _.sumBy(Object.values(graphDataByFeedstock), "feedstock_qty"),
      };
    }

    return {
      ...bar,
      value: graphDataByFeedstock[bar.type]?.feedstock_qty,
    };
  });

  return graphDataWithTotal;
};

const SummaryBarChart = ({summaryData = []}) => {
  return (
    <ResponsiveContainer
      width={445}
      height={220}
      data-test="copro-summary-bar-chart"
    >
      <BarChart
        data={convertContractsToGraphData(summaryData)}
        barCategoryGap={8}
        margin={{right: 10, left: 10, bottom: 10}}
      >
        <Bar dataKey="value" barSize={20} />
        <XAxis
          dataKey="label"
          tick={{fontSize: 14}}
          tickLine={false}
          axisLine={false}
          dy={8}
        />
        <YAxis hide />
        <Tooltip content={<CustomTooltip />} cursor={{fill: "transparent"}} />
      </BarChart>
    </ResponsiveContainer>
  );
};

SummaryBarChart.propTypes = {summaryData: PropTypes.array};

export default SummaryBarChart;
