import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useForm, Controller, useFieldArray} from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Form,
  FormGroup,
  FormFeedback,
  Button,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import {Close} from "@bphxd/ds-core-react";
import {splitShipmentKeys} from "modules/co-processing/constants/shipments";
import InputNumeric from "modules/common/lnputNumeric";
import splitShipmentFields from "./formConfig";
import "./index.scss";

const FIELD_ORDER = [
  "SUB_TYPE",
  "ACTUAL_VOLUME",
  "RINS_QUALIFIED",
  "RENEWABLE_SPLIT_FLAG",
  "NOTES",
];

const SHIPMENT_INFO_ORDER = [
  "shipment_type",
  "shipment_date",
  "load_start_time",
];

const validateInput = (
  totalVolume,
  splitsVolume,
  setSplitsVolume,
  index,
  targetValue,
  setErrors,
  setSumError,
) => {
  const totalFloat = parseFloat(totalVolume);
  const valueFloat = parseFloat(targetValue.replace(/,/g, ""));
  const tempSplitsVolume = splitsVolume;
  tempSplitsVolume[index] = valueFloat;

  const sum = tempSplitsVolume.reduce((acc, element) => {
    const normElm =
      typeof element === "string" ? element.replace(/,/g, "") : element;
    return acc + parseFloat(normElm) || 0;
  }, 0.0);

  setSumError(totalFloat < sum);
  setSplitsVolume(tempSplitsVolume);
};

const renderInputs = {
  input: ({error, field, placeholder, type}) => (
    <InputGroup className="bg-white">
      <Input
        {...field}
        invalid={!!error}
        placeholder={placeholder}
        type={type}
      />
    </InputGroup>
  ),

  number: ({
    field,
    error,
    addOn,
    setError,
    totalVolume,
    splitsVolume,
    setSplitsVolume,
    index,
    value,
    setValue,
    sumError,
    setSumError,
    update,
    fields,
    ...config
  }) => {
    return (
      <InputGroup className="bg-white">
        <InputNumeric
          {...field}
          className="form-control"
          invalid={!!sumError}
          value={value}
          placeholder={config?.placeholder}
          decimals={2}
          onChange={(event) => {
            validateInput(
              totalVolume,
              splitsVolume,
              setSplitsVolume,
              index,
              event?.target?.value,
              setError,
              setSumError,
            );
            setValue(event?.target?.value);
          }}
          onBlur={(event) => {
            const field = fields[index];
            field.adjusted_volume_bbl = parseFloat(
              event?.target?.value?.replace(/,/g, "") || "0",
            );
            update(index, field);
          }}
          thousandSeparator
        />
        {addOn && (
          <InputGroupText className="bg-transparent">{addOn}</InputGroupText>
        )}
      </InputGroup>
    );
  },

  select: ({field, error, options, placeholder = "Select an option"}) => {
    return (
      <Input type="select" {...field} invalid={!!error}>
        <option key="none" disabled hidden value="">
          {`-- ${placeholder} --`}
        </option>

        {options?.map((option) => (
          <option key={option?.value || option} value={option?.value || option}>
            {option?.label || option}
          </option>
        ))}
      </Input>
    );
  },

  switch: ({field, error, info}) => {
    return (
      <FormGroup switch>
        <Input
          {...field}
          checked={field.value ?? false}
          id={`input-${field.name}`}
          invalid={!!error}
          type="switch"
        />
        <Label className="text-sm/4" color="muted" for={`input-${field.name}`}>
          {field.value ? "Yes" : "No"}
        </Label>
      </FormGroup>
    );
  },
};

const Field = ({
  control,
  errors,
  index,
  fieldName = "",
  totalVolume,
  splitsVolume,
  setSplitsVolume,
  startingValue,
  sumError,
  setSumError,
  update,
  fields,
}) => {
  const fieldConfig = splitShipmentFields?.[fieldName] ?? {};
  const {key} = fieldConfig;

  const [error, setError] = useState(errors?.[key]);
  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(startingValue);
  }, [setValue, startingValue]);

  if (!fieldConfig || !key) return null;

  const renderInput = renderInputs[fieldConfig?.type] || renderInputs.input;

  return (
    <FormGroup
      className={`split-shipment__field ${fieldConfig?.width || "col-md-6"}`}
    >
      <Label
        className={`text-nowrap ${
          fieldConfig?.isOptional ? "label-optional" : ""
        }`}
        htmlFor={key}
      >
        {fieldConfig.label}
      </Label>

      <Controller
        name={`batches_list.${index}.${key}`}
        control={control}
        rules={{
          ...(fieldConfig.type !== "switch" && {
            required: !fieldConfig.isOptional,
          }),
        }}
        render={({field}) =>
          renderInput({
            field,
            error,
            totalVolume,
            splitsVolume,
            setSplitsVolume,
            index,
            setError,
            value,
            setValue,
            sumError,
            setSumError,
            update,
            fields,
            ...fieldConfig,
          })
        }
      />

      {error && (
        <FormFeedback style={{display: "block"}} invalid="true">
          {error.message || "Expected information missing"}
        </FormFeedback>
      )}

      {sumError && key === splitShipmentKeys.ACTUAL_VOLUME && (
        <FormFeedback style={{display: "block"}} invalid="true">
          Value can not be greater than estimated shipment volume.
        </FormFeedback>
      )}
    </FormGroup>
  );
};

Field.propTypes = {
  fieldName: PropTypes.string,
  errors: PropTypes.object,
  control: PropTypes.object,
  index: PropTypes.number,
  totalVolume: PropTypes.number,
  splitsVolume: PropTypes.number,
  setSplitsVolume: PropTypes.func,
  startingValue: PropTypes.number,
  sumError: PropTypes.bool,
  setSumError: PropTypes.func,
  update: PropTypes.func,
  fields: PropTypes.any,
};

const SplitShipmentsModal = ({
  isModalOpen,
  toggleModal,
  cardData,
  allocateBatchesAndShipments,
}) => {
  const {
    handleSubmit,
    control,
    formState: {errors},
  } = useForm({
    defaultValues: {
      batches_list: cardData?.parts?.map((part, i) => ({
        owb_agg_shipment_id: cardData.shipment_id,
        shipment_type: cardData.shipment_type,
        allocated_shipment_id: `${cardData.shipment_id}_Part ${i + 1}`,
        shipment_sub_type: part.shipment_sub_type,
        adjusted_volume_bbl: part.total_actual_volume,
        renewable_shipment_flag: part.renewable_shipment_flag,
        credits_qualified: part.credits_qualified,
        additional_info: part.additional_info,
      })),
    },
  });

  let appended = [];

  const {fields, append, remove, update} = useFieldArray({
    control,
    name: "batches_list",
  });

  const submitSplit = (data) => {
    const transformedData = {
      batches_list: data.batches_list.map((part) => {
        const transformedPart = {
          owb_agg_shipment_id: part.owb_agg_shipment_id,
          shipment_type: part.shipment_type,
          adjusted_volume_bbl: part.adjusted_volume_bbl,
          shipment_sub_type: part.shipment_sub_type,
          renewable_shipment_flag: part.renewable_shipment_flag,
          credits_qualified: part.credits_qualified,
          additional_info: part.additional_info,
        };
        if (
          typeof transformedPart.adjusted_volume_bbl === "string" ||
          transformedPart.adjusted_volume_bbl instanceof String
        )
          transformedPart.adjusted_volume_bbl = parseFloat(
            part.adjusted_volume_bbl.replace(/,/g, ""),
          );
        return transformedPart;
      }),
    };

    allocateBatchesAndShipments({
      variables: transformedData,
    });
    toggleModal();
  };

  const setFields = () => {
    remove();
    appended = [];
    cardData?.parts?.forEach((shipmentPart, i) => {
      append({
        owb_agg_shipment_id: cardData.shipment_id,
        shipment_type: cardData.shipment_type,
        allocated_shipment_id: `${cardData.shipment_id}_Part ${i + 1}`,
        shipment_sub_type: shipmentPart.shipment_sub_type,
        adjusted_volume_bbl: shipmentPart.total_actual_volume ?? 0,
        renewable_shipment_flag: shipmentPart.renewable_shipment_flag,
        credits_qualified: shipmentPart.credits_qualified,
        additional_info: shipmentPart.additional_info,
      });
    });
  };

  const [splitsVolume, setSplitsVolume] = useState([0]);
  const [sumError, setSumError] = useState(null);

  useEffect(() => {
    const tempSplitVolumes = splitsVolume;
    fields.forEach((shipmentPart, index) => {
      if (tempSplitVolumes.length <= index) {
        tempSplitVolumes.push(parseFloat(shipmentPart.adjusted_volume_bbl));
      } else {
        tempSplitVolumes[index] = parseFloat(shipmentPart.adjusted_volume_bbl);
      }
      setSplitsVolume(tempSplitVolumes);
    });
  }, [fields, splitsVolume, setSplitsVolume]);

  return (
    <Modal className="split-shipment__modal" isOpen={isModalOpen}>
      <ModalHeader close={<Close onClick={toggleModal} />}>
        <div className="pl-3 fs-5">Split shipment</div>
      </ModalHeader>

      <ModalBody>
        <Form
          className="split-shipment__form"
          id="split-shipment-form"
          onSubmit={handleSubmit(submitSplit)}
        >
          <div className="row g-5">
            {/* -- Display Shipment info (shipment_id, shipment_type, date, load start time, end time)-- */}
            <FormGroup className="col-12">
              <span className="fs-5 form-text">{cardData?.shipment_id}</span>
            </FormGroup>
            {SHIPMENT_INFO_ORDER.map((key) => {
              let label = key.replace(/_/g, " ");
              label = label.charAt(0).toUpperCase() + label.slice(1);

              return (
                <FormGroup className="split-shipment__field col-md-6">
                  <Label htmlFor={key}>{label}</Label>
                  <div className="fs-6 form-text input-group bg-transparent">
                    {cardData?.[key] ?? "N/A"}
                  </div>
                </FormGroup>
              );
            })}

            {/*  --  SHIPMENT PARTS FORM FIELDS -- */}
            {fields.map((shipmentPart, index) => {
              return (
                <React.Fragment key={shipmentPart?.id}>
                  <div className="border-b pb-2">Part {index + 1}</div>

                  {FIELD_ORDER.map((identifier) => (
                    <Field
                      control={control}
                      name={`batches_list.${index}.${identifier}`}
                      key={identifier}
                      fieldName={identifier}
                      errors={errors}
                      index={index}
                      totalVolume={cardData?.total_estimated_volume}
                      splitsVolume={splitsVolume}
                      setSplitsVolume={setSplitsVolume}
                      startingValue={
                        shipmentPart?.total_actual_volume ||
                        shipmentPart?.adjusted_volume_bbl ||
                        0
                      }
                      sumError={sumError}
                      setSumError={setSumError}
                      update={update}
                      fields={fields}
                    />
                  ))}
                </React.Fragment>
              );
            })}
            <Button
              color="light"
              className="border-black rounded-0 col-4"
              onClick={() => {
                const part = {
                  owb_agg_shipment_id: cardData?.shipment_id,
                  shipment_sub_type: "",
                  adjusted_volume_bbl: 0,
                  renewable_shipment_flag: false,
                  credits_qualified: "",
                  additional_info: "",
                };
                append(part);
                appended.push(part);
              }}
            >
              Add another split
            </Button>
          </div>
        </Form>
      </ModalBody>

      <ModalFooter>
        <div className="split-shipment__btnGroup">
          <Button
            color="light"
            className="border-black"
            onClick={() => {
              setFields();
              toggleModal();
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="split-shipment__btn"
            form="split-shipment-form"
            disabled={sumError}
            type="submit"
          >
            Save
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default SplitShipmentsModal;

SplitShipmentsModal.propTypes = {
  allocateBatchesAndShipments: PropTypes.func,
  toggleModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool,
  cardData: PropTypes.object,
};
