import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {TANK_VOLUME} from "graphql/product-metrics";
import dateFunctions from "utils/helpers/dateFunctions";
import {useQuery} from "@apollo/client";
import {Spin} from "antd";
import DashboardLayout from "modules/common/DashboardLayout";
import tabsData from "./tabsData";

export const TankVolume = () => {
  const {data, loading, error} = useQuery(TANK_VOLUME);

  if (loading) {
    return (
      <div
        data-test="tankvolume-loading"
        className="flex flex-col items-center justify-center w-full h-64"
      >
        <Spin
          className="flex items-center content-center justify-center "
          spinning="true"
        ></Spin>
      </div>
    );
  }

  if (error) {
    return (
      <div
        data-test="tankvolume-error"
        className="flex flex-col items-center justify-center w-full h-64"
      >
        <h3>Something went wrong. Please try again after some time!</h3>
      </div>
    );
  }

  const totalData = data.getTankUsageShipmentVolume.data.items.filter(
    (item) =>
      item.totalType === "TOTAL" &&
      !dateFunctions.isDateMoreThanOneYear(item.yearMonth),
  );

  const qualfiedData = data.getTankUsageShipmentVolume.data.items.filter(
    (item) =>
      item.totalType === "QUALIFIED" &&
      !dateFunctions.isDateMoreThanOneYear(item.yearMonth),
  );

  const dataItems = qualfiedData.map((item, i) => {
    return {
      ...item,
      ...totalData[i],
      totalValue: parseFloat(totalData[i].totalValue),
      qualfiedValue: parseFloat(item.totalValue),
    };
  });

  return (
    <DashboardLayout pageTitle="Reporting" tabsData={tabsData}>
      <div
        className="pt-5"
        style={{
          height: "80vh",
        }}
        data-test="tank-volume-chart"
      >
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={dataItems}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="yearMonth" />
            <YAxis type="number" domain={[0, "dataMax"]} />
            <Tooltip />
            <Legend
              verticalAlign="top"
              align="right"
              height={36}
              iconType="circle"
            />
            <Line
              type="monotone"
              dataKey="totalValue"
              stroke="#0f62fe"
              activeDot={{r: 8}}
              name="Total Volume Shipped"
            />
            <Line
              type="monotone"
              dataKey="qualfiedValue"
              stroke="#da1e28"
              name="RINS Quilified Volume"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </DashboardLayout>
  );
};

export default TankVolume;
