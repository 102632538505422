import PropTypes from "prop-types";
import {Avatar as AAvatar} from "antd";
import "./index.scss";

const Avatar = ({initials, photoSrc, ...props}) => (
  <div className="bp-avatar">
    {photoSrc ? (
      <AAvatar {...props} src={photoSrc}></AAvatar>
    ) : (
      <AAvatar {...props}>
        <span className="text-lg">{initials}</span>
      </AAvatar>
    )}
  </div>
);

Avatar.propTypes = {
  initials: PropTypes.string.isRequired,
  photoSrc: PropTypes.string,
};

export default Avatar;
