import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {InputGroup, Input} from "reactstrap";
import {INPUT_METRIC, ERRORS} from "modules/co-processing/constants/shipments";
import {useLazyQuery} from "@apollo/client";
import {
  COPRO_US_SHIPMENTS_API_CHECK_SHIPMENT_ID,
  COPRO_US_SHIPMENTS_API_CHECK_BATCH_ID,
} from "graphql/coprocessing/shipments";

const MetricInput = ({
  value,
  dataKey,
  errorKey,
  placeholder,
  onChange,
  showMetric,
  maxValue,
  reportErrors,
  batchId = "",
  updateActualVolume,
}) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const [error, setError] = useState(null);

  const [checkShipmentId] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_CHECK_SHIPMENT_ID,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    },
  );

  const [checkBatchId] = useLazyQuery(COPRO_US_SHIPMENTS_API_CHECK_BATCH_ID, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const setEndingInvValidation = (inputValue) => {
    if (parseInt(inputValue, 10) !== maxValue) {
      reportErrors(errorKey, ERRORS.ENDING_INVENTORY_VALUE);
      return setError(true);
    }
    reportErrors(errorKey, "");
    return setError(false);
  };

  const validateInput = async (inputValue) => {
    setError(null);

    if (!inputValue || inputValue.trim().length === 0) {
      reportErrors(errorKey, "");
      return null;
    }

    if (dataKey.includes("inventory")) {
      return setEndingInvValidation(inputValue);
    }

    try {
      let data;
      if (dataKey.includes("shipment")) {
        const checkShipmentIdResponse = await checkShipmentId({
          variables: {shipmentId: inputValue, batchId},
        });
        data = checkShipmentIdResponse?.data?.bioLcCoproUsShipmentsApi;
      } else if (dataKey.includes("batch_id")) {
        const checkShipmentIdResponse = await checkBatchId({
          variables: {batchId: inputValue},
        });
        data = checkShipmentIdResponse?.data?.bioLcCoproUsShipmentsApi;
      } else if (dataKey === "total_actual_volume") {
        updateActualVolume(inputValue);
      }
      if (data?.error) {
        setError(true);
        reportErrors(errorKey, data.error);
      } else {
        // If no errors
        setError(false);
        reportErrors(errorKey, "");
      }
    } catch (error) {
      console.error("Validation error:", error);
      setError(true);
      reportErrors(errorKey, "An unexpected error occurred.");
    }
    return null;
  };

  const handleInputChange = (event) => {
    let targetValue = event?.target?.value;

    if (showMetric) {
      targetValue = targetValue.replace(/[^0-9.]/g, "");
      const numericValue = Number(targetValue);

      if (Number.isNaN(numericValue) || (maxValue && numericValue > maxValue)) {
        // If the value is not a number or exceeds the maxValue, don't update
        return;
      }
    }

    setInputValue(targetValue);
    onChange({target: {name: dataKey, value: targetValue}});
  };

  return (
    <div data-test="copro-metric-input" className="max-w-[205px]">
      <InputGroup
        className={`${showMetric ? "min-w-[169px]" : "min-w-[205px]"} ${
          !error ? "" : "border-2 border-red-500"
        } input-group-merge`}
      >
        <Input
          data-test="copro-metric-actual-input"
          className={`${
            showMetric ? "min-w-[169px]" : "min-w-[205px]"
          } text-xs`}
          name={dataKey}
          id={dataKey}
          value={inputValue ?? ""}
          placeholder={placeholder}
          onChange={(event) => handleInputChange(event)}
          onBlur={(event) => validateInput(event?.target?.value)}
          valid={!error && error != null}
        />
        {!!showMetric && (
          <div data-test="copro-metric-appended" className="input-group-append">
            <div className="input-group-text">{INPUT_METRIC}</div>
          </div>
        )}
      </InputGroup>
    </div>
  );
};

MetricInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dataKey: PropTypes.string.isRequired,
  errorKey: PropTypes.string,
  placeholder: PropTypes.string,
  showMetric: PropTypes.bool,
  maxValue: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  reportErrors: PropTypes.func,
  batchId: PropTypes.string,
  updateActualVolume: PropTypes.func,
};

export default MetricInput;
