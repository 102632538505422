import {Bioverse as bioverseConfig} from "content/navigations";
import bioverseSafConfig from "content/safnavigations";
import PropTypes from "prop-types";
import Navigation from "modules/navigation";
import useGlobalNotification from "providers/GlobalNotification/useGlobalNotification";
import {useCallback, useState, useEffect} from "react";
import menuFactory from "utils/helpers/menuFactory";
import {useLocation} from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header/Header";
import Notification from "./Notification";
import Side from "./Side";
import useRefreshSession from "./useRefreshSession";

const Layout = ({children}) => {
  const [activeItem, setActiveItem] = useState("");
  const [isSideOpen, setSideOpen] = useState(false);
  const handleToggleSide = () => setSideOpen((prevValue) => !prevValue);
  const handleCloseMenu = () => setSideOpen(false);

  const {notificationProps} = useGlobalNotification();

  useRefreshSession();
  const SafPages = [
    "/saflandingpage",
    "/documentpage",
    "/SAFdocumentpage",
    "/saf-sweden/mass-balance",
    "/saf/document-manager/incoming-documents",
  ];
  const location = useLocation();
  const {pathname} = location;
  const isSaf = SafPages.some((page) => pathname.includes(page));
  useEffect(() => {
    if (isSaf) {
      setSideOpen(false);
    }
  }, [isSaf]);

  const AppMenu = useCallback(() => {
    const menuConfig = isSaf ? bioverseSafConfig() : bioverseConfig();
    const linkProps = {onClick: handleCloseMenu};
    return (
      <Navigation
        menuConfig={menuConfig}
        linkProps={linkProps}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
      />
    );
  }, [activeItem, isSaf]);

  return (
    <div className="flex flex-col h-full overflow-hidden">
      <Header
        onSideMenuItemClick={setActiveItem}
        onMenuClick={handleToggleSide}
        onLinkClick={handleCloseMenu}
      />
      <Notification {...notificationProps} className="m-4" />
      <section className="flex flex-row w-screen h-full overflow-scroll overflow-x-hidden">
        <Side isOpen={isSideOpen} onClose={handleCloseMenu}>
          <AppMenu />
        </Side>
        <div className="flex-1 min-h-full min-w-0">{children}</div>
      </section>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
