import PropTypes from "prop-types";
import Title from "modules/common/Title";
import Tabs from "modules/common/Tabs";
import {Helmet} from "react-helmet";

const DashboardLayout = ({pageTitle, tabsData, children, ...props}) => (
  <div {...props}>
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
    <Title title={pageTitle} />
    <div className="w-full px-6 my-8 lg:px-10">
      {tabsData ? <Tabs className="my-8" data={tabsData} /> : null}
      {children}
    </div>
  </div>
);

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string.isRequired,
  menuTitle: PropTypes.string.isRequired,
  tabsData: PropTypes.arrayOf(PropTypes.object),
};

export default DashboardLayout;
