import {displayDate} from "modules/co-processing/helpers/dateHelper";
import PropTypes from "prop-types";
import {formatNumber} from "providers/userSettings";

const DataGridItem = ({data, metric, label, type}) => {
  const getDisplayedValue = () => {
    if (!data) {
      return "";
    }
    switch (type) {
      case "number":
        return `${formatNumber(data, "Imperial Style")} ${metric}`;
      case "date":
        return displayDate(data, true);
      default:
        return data;
    }
  };

  return (
    <div
      data-test="data-grid-item"
      className="grid grid-cols-2 place-content-between py-3 text-xs border-b"
    >
      <div>{getDisplayedValue()}</div>
      <div className="text-secondary text-right">{label}</div>
    </div>
  );
};

DataGridItem.propTypes = {
  data: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  metric: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
};

const DataGrid = ({data, title, dataColumn}) => (
  <div data-test="data-grid">
    <div className="text-xl pb-6">{title}</div>
    <div data-test="data-grid-column">
      {dataColumn.map((item, index) => (
        <DataGridItem
          key={index}
          data={data?.[item.key] ?? ""}
          metric={item.metric ?? ""}
          label={item.label}
          type={item.type}
        />
      ))}
    </div>
  </div>
);

DataGrid.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  dataColumn: PropTypes.array,
};

export default DataGrid;
