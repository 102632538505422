import tw from "twin.macro";
import {useLazyQuery} from "@apollo/client";
import {useCallback, useEffect, useState} from "react";
import {
  COPRO_US_SHIPMENTS_API_GET_BATCHES,
  COPRO_US_SHIPMENTS_API_GET_SHIPMENTS,
} from "graphql/coprocessing/shipments";

import {
  TITLES,
  BUTTONS,
  ITEMS_PER_PAGE,
} from "modules/co-processing/constants/shipments";
import ShipmentColumn from "modules/co-processing/components/Shipments/ShipmentColumn";

const ColumnWrapper = tw.div`w-1/2 px-2`;

const ShipmentView = () => {
  const [activeBatchesData, setActiveBatches] = useState([]);
  const [shipmentsData, setShipmentsData] = useState([]);
  const [shipmentDateRange, setShipmentDateRange] = useState([]);
  const [shipmentType, setShipmentType] = useState("");
  const [batchesOffset, setBatchesOffset] = useState(0);
  const [shipmentsOffset, setShipmentsOffset] = useState(0);
  const [mounted, setMounted] = useState(false);
  const [showMoreBatchesButton, setMoreBatchesButton] = useState(true);
  const [showMoreShipmentsButton, setMoreShipmentsButton] = useState(true);

  const [fetchBatches, {data: batchesDataRes}] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_GET_BATCHES,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        const dataArray =
          batchesDataRes?.bioLcCoproUsShipmentsApi?.body?.batches;
        if (!Array.isArray(dataArray) || dataArray.length === 0) {
          setMoreBatchesButton(false);
          return;
        }
        setActiveBatches([...activeBatchesData, ...dataArray]);
        setBatchesOffset(batchesOffset + 1);
      },
    },
  );

  const [fetchMoreShipments, {data: shipmentsDataRes}] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_GET_SHIPMENTS,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        const dataArray =
          shipmentsDataRes?.bioLcCoproUsShipmentsApi?.body?.shipments;
        if (!Array.isArray(dataArray) || dataArray.length === 0) {
          setMoreShipmentsButton(false);
          return;
        }
        setShipmentsData([...shipmentsData, ...dataArray]);
        setShipmentsOffset(shipmentsOffset + 1);
      },
    },
  );

  const [fetchFilteredShipments, {data: shipmentsDataFiltered}] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_GET_SHIPMENTS,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        const dataArray =
          shipmentsDataFiltered?.bioLcCoproUsShipmentsApi?.body?.shipments;
        setShipmentsData([...dataArray]);
        if (!Array.isArray(dataArray) || dataArray.length === 0) {
          setMoreShipmentsButton(false);
          return;
        }
        setMoreShipmentsButton(true);
        setShipmentsOffset(1);
      },
    },
  );

  const refreshData = useCallback(() => {
    fetchFilteredShipments({
      variables: {offset: 0, dateRange: shipmentDateRange, shipmentType},
    });
    fetchBatches({variables: {offset: 0}});
  }, [fetchFilteredShipments, shipmentDateRange, shipmentType, fetchBatches]);

  const loadMoreBatches = useCallback(() => {
    fetchBatches({variables: {offset: batchesOffset * ITEMS_PER_PAGE}});
  }, [fetchBatches, batchesOffset]);

  const loadMoreShipments = useCallback(() => {
    fetchMoreShipments({
      variables: {
        offset: shipmentsOffset * ITEMS_PER_PAGE,
        dateRange: shipmentDateRange,
        shipmentType,
      },
    });
  }, [fetchMoreShipments, shipmentsOffset, shipmentDateRange, shipmentType]);

  const filterDateRangeShipments = useCallback(
    (dateRange) => {
      setShipmentDateRange(dateRange);
      fetchFilteredShipments({variables: {offset: 0, dateRange, shipmentType}});
    },
    [fetchFilteredShipments, setShipmentDateRange, shipmentType],
  );

  const filterTypeShipments = useCallback(
    (shipmentType) => {
      setShipmentType(shipmentType);
      fetchFilteredShipments({
        variables: {offset: 0, dateRange: shipmentDateRange, shipmentType},
      });
    },
    [fetchFilteredShipments, setShipmentType, shipmentDateRange],
  );

  const refetchShipments = useCallback(
    () =>
      fetchFilteredShipments({
        variables: {
          offset: 0,
          dateRange: shipmentDateRange,
          shipmentType,
        },
      }),
    [fetchFilteredShipments, shipmentDateRange, shipmentType],
  );

  useEffect(() => {
    if (mounted) return;
    loadMoreBatches();
    loadMoreShipments();
    setMounted(true);
  }, [loadMoreBatches, loadMoreShipments, mounted, setMounted]);

  return (
    <div className="flex gap-4">
      <ColumnWrapper>
        <ShipmentColumn
          title={TITLES.ACTIVE_BATCHES}
          data={activeBatchesData}
          refreshData={refreshData}
          itemsPerPage={ITEMS_PER_PAGE}
          buttonText={BUTTONS.LOAD_MORE_BATCHES}
          loadMore={loadMoreBatches}
          showButton={showMoreBatchesButton}
        />
      </ColumnWrapper>
      <ColumnWrapper>
        <ShipmentColumn
          title={TITLES.SHIPMENTS}
          data={shipmentsData}
          refreshData={refreshData}
          itemsPerPage={ITEMS_PER_PAGE}
          buttonText={BUTTONS.LOAD_MORE_SHIPMENTS}
          loadMore={loadMoreShipments}
          showButton={showMoreShipmentsButton}
          refetchShipments={refetchShipments}
          filterDateRangeShipments={filterDateRangeShipments}
          filterTypeShipments={filterTypeShipments}
        />
      </ColumnWrapper>
    </div>
  );
};

export default ShipmentView;
