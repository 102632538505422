import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import {Link, useLocation} from "react-router-dom";
import {
  PAGE_TITLE,
  HEADER_COPRO,
} from "modules/co-processing/constants/coProcessing";

const PAGE_TITLES = {
  shipments: PAGE_TITLE.SHIPMENTS,
  "document-viewer": PAGE_TITLE.DOCUMENT_VIEWER,
  batches: PAGE_TITLE.BATCHES,
  details: PAGE_TITLE.DETAILS,
};

const generateBreadcrumbs = (pathname, id) => {
  const paths = pathname.split("/").filter((item) => !!item);
  const breadcrumbs = [];

  // Adds Co-Processing as the first breadcrumb
  breadcrumbs.push({title: HEADER_COPRO, to: "/co-processing", isLast: false});

  paths.forEach((path, index) => {
    const to = `/${paths.slice(0, index + 1).join("/")}`;
    const isLast = index === paths.length - 1;

    if (path === "co-processing") {
      if (paths.length === 1 || paths[1] === "document-viewer") {
        breadcrumbs.push({title: PAGE_TITLE.RECEIPTS, to, isLast: false});
      }
    } else {
      const title = PAGE_TITLES[path] || path;
      breadcrumbs.push({title, to, isLast});
    }
  });

  if (id) {
    breadcrumbs.push({title: id, to: "", isLast: true});
  }

  return breadcrumbs;
};

const CoproBreadcrumbs = () => {
  const {state, pathname} = useLocation();
  const id = state?.id || state?.batch_id || null;
  const breadcrumbs = generateBreadcrumbs(pathname, id);

  return (
    <Breadcrumb>
      {breadcrumbs.map(({title, to, isLast}, index) => (
        <BreadcrumbItem
          key={title}
          data-test="copro-us-breadcrumb"
          active={index !== 0}
        >
          {isLast || index === 0 ? title : <Link to={to}>{title}</Link>}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default CoproBreadcrumbs;
