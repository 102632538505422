import PropTypes from "prop-types";
import React from "react";
import {Link} from "react-router-dom";
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import "./index.css";

const Layout = ({title, breadcrumbs, subtitle, children}) => {
  return (
    <div className="bg-light-gray-100 pt-5 min-h-[70vh]">
      <Breadcrumb className="px-7">
        {breadcrumbs.map((item, index) => (
          <BreadcrumbItem key={index} active={index === breadcrumbs.length - 1}>
            {index === breadcrumbs.length - 1 ? (
              <span>{item.text}</span>
            ) : (
              <Link to={item.link}>{item.text}</Link>
            )}
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
      <h1 className="text-[32px] text-black fw-light pb-[20px] px-7">
        {title}
      </h1>
      {subtitle}
      <div className="px-7">
        <hr className="border-1" />
      </div>
      {children}
    </div>
  );
};

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
  breadcrumbs: PropTypes.array.isRequired,
  children: PropTypes.node,
};
export default Layout;
