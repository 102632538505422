import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

export const ASSIGN_TO_TRANSFER = gql`
  mutation AssignTransfer(
    $fromLocationCode: String!
    $toLocationCode: String!
    $inboundEuQtyId: String!
    $motName: String!
    $quantity: Float!
    $sdNumber: String!
    $createdBy: String!
    $dispatchDate: String!
    $vesselName: String
    $transportationEmissionId: String!
  ) {
    bioLcCoproEuMassBalanceTransfer(
      event: {
        fromLocationCode: $fromLocationCode
        toLocationCode: $toLocationCode
        inboundEuQtyId: $inboundEuQtyId
        motName: $motName
        quantity: $quantity
        sdNumber: $sdNumber
        createdBy: $createdBy
        dispatchDate: $dispatchDate
        vesselName: $vesselName
        transportationEmissionId: $transportationEmissionId
      }
    ) {
      message
      status
      statusCode
    }
  }
`;

export const AssignToTransferAPI = (data) =>
  client.mutate({
    mutation: ASSIGN_TO_TRANSFER,
    variables: {
      ...data,
    },
    refetchQueries: ["bioLcCoproEuMassBalanceAllRecords"],
  });

export default {
  ASSIGN_TO_TRANSFER,
  AssignToTransferAPI,
};
