import {sectionsType} from "content/navigations";
import {GetInTouch, SectionType} from "modules/common";
import Title from "modules/common/Title";
import {Helmet} from "react-helmet";

const Dashboard = () => {
  return (
    <>
      <Helmet>
        <title>BioVerse - Biofuels Value Chain</title>
      </Helmet>
      <div>
        <Title title="Biofuels Value Chain" />
        <main className="px-6 py-10 lg:px-10">
          <SectionType
            items={sectionsType.filter((item) => item.isVisible)}
            isDashboard
          />
          <GetInTouch />
        </main>
      </div>
    </>
  );
};

export default Dashboard;
