import React from "react";
import {OperatingCondition, SplitBatch} from "../FormElements";

const StepTwo = () => {
  return (
    <>
      <OperatingCondition />
      <SplitBatch />
    </>
  );
};

export default StepTwo;
