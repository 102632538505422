import {Input} from "reactstrap";
import {useEffect, useState} from "react";
import {useQuery} from "@apollo/client";
import {TRANSPORTATION_EMISSIONS} from "graphql/config-center/TransportationEmissions.js";
import {LOCATION_MASTER} from "graphql/config-center/LocationMaster.js";
import {useFormContext, Controller} from "react-hook-form";
import PropTypes from "prop-types";
import FormField from "modules/common/FormFieldNew.js";
import DatePicker from "modules/common/DatePickerNew";
import {useUserSettings} from "providers/userSettings";
import dateFunctions from "utils/helpers/dateFunctions";

const RecipientDetails = ({data, rowData}) => {
  const {
    formState: {errors},
    trigger,
    watch,
    setValue,
  } = useFormContext();

  const [nonlegalEntitiesList, setnonlegalEntities] = useState([]);

  const [motDetails, setMOTDetails] = useState([]);

  const watchRecipientName = watch("recipientName");
  const watchRecipientReceiptName = watch("recipientReceiptName");
  const watchRecipientAddress = watch("recipientAddress");

  const {data: modeofTransportationDetails, Motloading} = useQuery(
    TRANSPORTATION_EMISSIONS,
  );

  useEffect(() => {
    const motDetailsList = [
      ...new Set(
        modeofTransportationDetails?.bioLcCoproConfigCenterTransportationEmission.Records.filter(
          (objitem) =>
            objitem.receiving_location_code === watchRecipientReceiptName &&
            objitem.sending_location_code ===
              rowData?.inboundRecord?.mbLocationCode.toString(),
        ).map((obj) => obj.mot_name),
      ),
    ];
    setMOTDetails(motDetailsList);
  }, [
    watchRecipientReceiptName,
    modeofTransportationDetails?.bioLcCoproConfigCenterTransportationEmission
      .Records,
    rowData?.inboundRecord?.mbLocationCode,
  ]);

  const {data: locationDetails, loading: locationloading} =
    useQuery(LOCATION_MASTER);
  const {userSettings = {dateFormat: "m/d/Y"}} = useUserSettings();
  const dateFormat = dateFunctions.convertDateFormattoUser(
    userSettings.dateFormat,
  );

  const legalEntities =
    locationDetails?.bioLcCoproConfigCenterLocationMasterDetails?.Records?.filter(
      (obj) => obj.location_type === "Legal Entity",
    )?.sort((a, b) =>
      a.legal_entity?.trim().localeCompare(b.legal_entity?.trim()),
    );

  useEffect(() => {
    const locDetails =
      locationDetails?.bioLcCoproConfigCenterLocationMasterDetails?.Records.find(
        (obj) => obj.location_code === watchRecipientName,
      );
    const nonlegalEntities =
      locationDetails?.bioLcCoproConfigCenterLocationMasterDetails?.Records?.filter(
        (obj) =>
          obj.location_type !== "Legal Entity" &&
          obj.legal_entity === locDetails?.legal_entity,
      )?.sort((a, b) =>
        a.location_name?.trim().localeCompare(b.location_name?.trim()),
      );
    setnonlegalEntities(nonlegalEntities);
  }, [
    watchRecipientName,
    locationDetails?.bioLcCoproConfigCenterLocationMasterDetails?.Records,
  ]);

  useEffect(() => {
    const locationAddresses =
      locationDetails?.bioLcCoproConfigCenterLocationMasterDetails?.Records?.find(
        (objloc) => objloc.location_code === watchRecipientName,
      );
    setValue("recipientAddress", locationAddresses?.location_desc);
  }, [
    setValue,
    watchRecipientName,
    locationDetails?.bioLcCoproConfigCenterLocationMasterDetails?.Records,
  ]);

  useEffect(() => {
    const locationAddresses =
      locationDetails?.bioLcCoproConfigCenterLocationMasterDetails?.Records?.find(
        (objloc) => objloc.location_code === watchRecipientReceiptName,
      );

    if (watchRecipientReceiptName === "Same as recipient address") {
      setValue("recipientReceiptAddress", watchRecipientAddress);
    } else {
      setValue("recipientReceiptAddress", locationAddresses?.location_desc);
    }
  }, [
    setValue,
    watchRecipientReceiptName,
    watchRecipientAddress,
    locationDetails?.bioLcCoproConfigCenterLocationMasterDetails?.Records,
  ]);
  return (
    <div className="grid sm:grid-cols-1 text-left md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-x-4 gap-y-4 ">
      <FormField
        label="Name of recipient"
        errorMessage={errors.recipientName?.message}
      >
        <Controller
          name="recipientName"
          defaultValue={data?.recipientName || undefined}
          render={({field}) => (
            <Input
              {...field}
              type="select"
              id="recipientName"
              data-test="recipientName"
              invalid={!!errors.recipientName}
              disabled={locationloading}
            >
              <option key="none" value="">
                --Select an option--
              </option>
              {legalEntities?.map((locdetails) => (
                <option
                  key={locdetails.location_code}
                  value={locdetails.location_code}
                >
                  {locdetails.legal_entity}
                </option>
              ))}
            </Input>
          )}
        />
      </FormField>
      <FormField
        label="Name of Receipt / receiving point"
        errorMessage={errors.recipientReceiptName?.message}
      >
        <Controller
          name="recipientReceiptName"
          defaultValue={data?.recipientReceiptName || undefined}
          render={({field}) => (
            <Input
              {...field}
              type="select"
              id="recipientReceiptName"
              data-test="recipientReceiptName"
              invalid={!!errors.recipientReceiptName}
              disabled={locationloading}
            >
              <option key="none" value="">
                --Select an option--
              </option>
              <option
                key="sameAsRecipientAddress"
                value="Same as recipient address"
              >
                Same as recipient address
              </option>
              {nonlegalEntitiesList?.map((locdetails) => (
                <option
                  key={locdetails.location_code}
                  value={locdetails.location_code}
                >
                  {`${locdetails.location_name} - ${locdetails.location_type}`}
                </option>
              ))}
            </Input>
          )}
        />
      </FormField>

      <FormField
        label="Address of recipient"
        errorMessage={errors.recipientAddress?.message}
      >
        <Controller
          name="recipientAddress"
          defaultValue={data?.recipientAddress || undefined}
          render={({field}) => (
            <Input
              {...field}
              type="textarea"
              id="recipientAddress"
              data-test="recipientAddress"
              invalid={!!errors.recipientAddress}
              maxLength={100}
              disabled
            />
          )}
        />
      </FormField>

      <FormField
        label="Address of Receipt / receiving point"
        errorMessage={errors.recipientReceiptAddress?.message}
      >
        <Controller
          name="recipientReceiptAddress"
          defaultValue={data?.recipientReceiptAddress || undefined}
          render={({field}) => (
            <Input
              {...field}
              type="textarea"
              id="recipientReceiptAddress"
              data-test="recipientReceiptAddress"
              invalid={!!errors.recipientReceiptAddress}
              maxLength={100}
              disabled
            />
          )}
        />
      </FormField>
      <FormField
        label="Date of dispatch"
        errorMessage={errors.dispatchDate?.message}
      >
        <Controller
          name="dispatchDate"
          render={({field: {ref, onChange, value, disabled}}) => (
            <DatePicker
              data-test="dispatchDate"
              placeholder="Date of dispatch"
              id="dispatchDate"
              name="dispatchDate"
              invalid={!!errors.dispatchDate}
              options={{
                allowInput: true,
                mode: "single",
                defaultDate: value,
                dateFormat,
                altFormat: dateFormat,
                onChange: (selectedDates) => onChange(selectedDates[0]),
              }}
            />
          )}
        />
      </FormField>

      <FormField
        label="Mode of Transportation"
        errorMessage={errors.motRecipient?.message}
      >
        <Controller
          name="motRecipient"
          defaultValue={data?.motRecipient || undefined}
          render={({field}) => (
            <Input
              {...field}
              type="select"
              id="motRecipient"
              data-test="motRecipient"
              invalid={!!errors.motRecipient}
              disabled={Motloading}
            >
              <option key="none" value="">
                --Select an option--
              </option>
              {motDetails?.map((mot_name) => (
                <option key={mot_name} value={mot_name}>
                  {mot_name}
                </option>
              ))}
            </Input>
          )}
        />
      </FormField>
      <FormField
        label="Contract Number"
        errorMessage={errors.contractNumber?.message}
      >
        <Controller
          name="contractNumber"
          defaultValue={data?.contractNumber || undefined}
          render={({field}) => (
            <Input
              {...field}
              type="text"
              id="contractNumber"
              data-test="contractNumber"
              invalid={!!errors.contractNumber}
              maxLength={50}
            />
          )}
        />
      </FormField>

      <FormField label="Vessel Name" errorMessage={errors.vesselName?.message}>
        <Controller
          name="vesselName"
          defaultValue={data?.vesselName || undefined}
          render={({field}) => (
            <Input
              {...field}
              type="text"
              id="vesselName"
              data-test="vesselName"
              invalid={!!errors.vesselName}
              maxLength={20}
            />
          )}
        />
      </FormField>
    </div>
  );
};

RecipientDetails.propTypes = {
  data: PropTypes.object,
  rowData: PropTypes.object,
};

export default RecipientDetails;
