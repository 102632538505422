import {useQuery} from "@apollo/client";
import moment from "moment";
import {useParams} from "react-router-dom";
import {singleDateSort} from "modules/common/Table";
import {useCallback, useState} from "react";
import {useUserSettings, applyDateFormat} from "providers/userSettings";
import SummaryTable from "modules/common/SummaryTable";
import {HEADER_MOT_VALIDATION_REPORT} from "modules/gy-enablement/constants/Headers";
import {GET_FIELD_OPTIONS} from "graphql/gy-enablement/MOT/manualAdjustment";
import {TAXED_OUTBOUND_TRANSACTION_LEVEL_SUMMARY} from "graphql/gy-enablement/MOT/summaryDashboard";
import {paginationSizeMOT, paginationPageSizeOptions} from "constants/config";
import {
  BasePageLayout,
  BasePageLayoutContent,
} from "modules/common/BasePageLayout";
import BioBreadcrumb from "modules/common/Breadcrumb";
import {motTabDataTransactionLevelItems} from "../../../content/tabData";
import {multiLevelBreadcrumbItems} from "../../../content/breadCrumbData";
import {getTransactionalLevelTableCols} from "../../../content/motSummaryColumns";
import "../../common/index.scss";
import CeemasValidationFilter from "./components/CeemasValidationFilter";

const CeemasValidationTransactionDetails = () => {
  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();
  const transactionalLevelTableCols =
    getTransactionalLevelTableCols(decimalFormat);
  const [pageNumber, setPage] = useState(1);
  const [pageSizeNumber, setPageSize] = useState(paginationSizeMOT);
  const {filterDetails} = useParams();
  const filterParamDetails = JSON.parse(filterDetails);

  const onLoadPayload = {
    company: filterParamDetails?.company,
    complianceyear: filterParamDetails?.complianceyear,
    compliancemonth: filterParamDetails?.compliancemonth,
    ed: filterParamDetails?.ed,
    legalentity: filterParamDetails?.legalentity,
    material: filterParamDetails?.material,
    plant: filterParamDetails?.plant,
    date: filterParamDetails?.date,
    balancegroup: filterParamDetails?.balancegroup,
    motProfile: filterParamDetails?.motProfile,
  };
  const [filterValues, setFilterValues] = useState(onLoadPayload);
  const getMultilevelBreadCrumbItems = (payload) => {
    const multiLevelBreadcrumbItms = multiLevelBreadcrumbItems(payload);
    const paramDetails = JSON.stringify({
      company: payload?.company,
      complianceyear: payload?.complianceyear,
      compliancemonth: payload?.compliancemonth,
      ed: payload?.ed,
      legalentity: payload.legalentity,
      material: payload?.material,
      plant: payload?.plant,
      date: payload?.date,
      balancegroup: payload?.balancegroup,
      motProfile: payload?.motProfile,
    });

    multiLevelBreadcrumbItms?.push({
      linkname: "Transactional Level",
      link: `/gy-enablement/ceemas-mot-validation-transaction-level/${encodeURIComponent(
        paramDetails,
      )}`,
    });

    return multiLevelBreadcrumbItms;
  };
  const multilevelBreadCrumbItemsArray =
    getMultilevelBreadCrumbItems(onLoadPayload);

  const [breadcrumbitems, setBreadcrumbitems] = useState(
    multilevelBreadCrumbItemsArray,
  );

  const motTabData = motTabDataTransactionLevelItems(filterParamDetails);

  const {data: optionsDataPayload, loading: optionsLoading} =
    useQuery(GET_FIELD_OPTIONS);

  const {
    data: taxedOutboundData,
    loading: taxedOutboundDataLoading,
    refetch: refetchTaxedOutboundSummary,
  } = useQuery(TAXED_OUTBOUND_TRANSACTION_LEVEL_SUMMARY, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: onLoadPayload,
  });

  const optionsData = optionsDataPayload?.getFieldOptionsAurora?.data;
  const taxedOutboundDataItems = taxedOutboundData?.getOutboundLevelRequest;

  const handleOnFilter = useCallback(
    (values) => {
      const payload = {
        company: values.company || "",
        ed: values.ed || "",
        legalentity: values.legalentity || "",
        plant: values.plant || "",
        compliancemonth: values.compliancemonth
          ? moment(values.compliancemonth).format("MM")
          : "",
        complianceyear: moment(values.complianceyear).format("YYYY"),
        material: values.material || "",
        balancegroup: values.balancegroup || "",
        motProfile: values.motProfile || "",
      };

      refetchTaxedOutboundSummary(payload);
      setFilterValues(payload);
      const fetchMultilevelBreadCrumbItemsArray =
        getMultilevelBreadCrumbItems(payload);
      setBreadcrumbitems(fetchMultilevelBreadCrumbItemsArray);
    },
    [refetchTaxedOutboundSummary],
  );
  const handlePageChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const taxedOutboundDataItemsWithFilterDetails = taxedOutboundDataItems?.map(
    (element) => ({
      ...element,
      filterDetails: filterValues,
    }),
  );

  const formatedDateWithLoadDate = applyDateFormat(
    taxedOutboundDataItemsWithFilterDetails,
    ["loadDate"],
    dateFormat,
    "DD-MM-YYYY",
    [],
    decimalFormat,
    0,
  );
  const formatedData = applyDateFormat(
    formatedDateWithLoadDate,
    ["disDate"],
    dateFormat,
  );

  return (
    <BasePageLayout
      pageTitle={HEADER_MOT_VALIDATION_REPORT}
      tabsData={motTabData}
    >
      <CeemasValidationFilter
        onFilter={handleOnFilter}
        data={optionsData}
        loading={optionsLoading}
        defaultValues={{
          company: filterParamDetails?.company,
          complianceyear: moment(filterParamDetails?.complianceyear),
          compliancemonth:
            filterParamDetails?.compliancemonth === ""
              ? ""
              : moment(
                  `${filterParamDetails?.complianceyear}-${filterParamDetails?.compliancemonth}`,
                ),
          ed: filterParamDetails?.ed,
          legalentity: filterParamDetails?.legalentity,
          material: filterParamDetails?.material,
          plant: filterParamDetails?.plant,
          balancegroup: filterParamDetails?.balancegroup,
          motProfile: filterParamDetails?.motProfile,
        }}
      />

      <BasePageLayoutContent>
        <BioBreadcrumb breadcrumbitems={breadcrumbitems} />
        <div className="grid grid-cols-1 mt-10 ">
          <SummaryTable
            tableHeading="Transactional Details"
            columns={transactionalLevelTableCols.map((column) =>
              singleDateSort(column, dateFormat),
            )}
            dataSource={formatedData ?? []}
            loading={taxedOutboundDataLoading}
            data-test="taxed-outbound-transaction-level-table"
            pagination={{
              current: pageNumber,
              pageSize: pageSizeNumber,
              onChange: (page, pageSize) => handlePageChange(page, pageSize),
              showSizeChanger: true,
              total: taxedOutboundDataItemsWithFilterDetails?.length,
              showTotal: (total) => `Total ${total} items`,
              position: ["bottomLeft"],
              defaultPageSize: pageSizeNumber,
              pageSizeOptions: paginationPageSizeOptions,
            }}
          />
        </div>
      </BasePageLayoutContent>
    </BasePageLayout>
  );
};

export default CeemasValidationTransactionDetails;
