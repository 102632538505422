import {useQuery} from "@apollo/client";
import {
  Alert24,
  Info24,
  CircleClose24,
  Checklist24,
} from "@bphxd/ds-core-react/lib/icons";
import {Spinner} from "reactstrap";
import Layout from "../../es-co-processing/components/Layout";
import TaskComponent from "../components/TaskComponent/TaskComponent";
import {SAF_GET_NOTIFICATION} from "../../../graphql/saf/GetNotification";

const SAFLandingPage = () => {
  const {data, loading} = useQuery(SAF_GET_NOTIFICATION, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const records = data?.bioLcSafGetTasks?.data;
  const filteredRecords = (targetState) =>
    records?.filter((record) => record.state === targetState);

  const allTasksDone = !loading && records?.every((obj) => obj.total === 0);

  const targetStates = {
    REQUIRE_ATTENTION: "requires_attention",
    READY_TO_ASSIGN: "ready_to_assign",
    READY_TO_ACCEPT: "ready_to_accept",
    FAILED_TO_PROCESS: "failed_to_process",
  };

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: "SAF", link: "/SAFLandingPage"},
  ];

  const requireAttentionTotal =
    data && records && filteredRecords(targetStates.REQUIRE_ATTENTION)[0].total;
  const readyToAccept =
    data && records && filteredRecords(targetStates.READY_TO_ACCEPT)[0].total;
  const readyToAssign =
    data && records && filteredRecords(targetStates.READY_TO_ASSIGN)[0].total;
  const failedToProcess =
    data && records && filteredRecords(targetStates.FAILED_TO_PROCESS)[0].total;

  const taskNotification = data &&
    records && [
      {
        id: 1,
        text:
          requireAttentionTotal === 1
            ? "1 document requires your attention"
            : `${requireAttentionTotal} documents require your attention`,
        link: "/saflandingpage",
        icon: <Alert24 className="text-danger" />,
        taskItems: filteredRecords(targetStates.REQUIRE_ATTENTION)[0].documents,
        header: filteredRecords(targetStates.REQUIRE_ATTENTION)[0].state,
        latestDocument: filteredRecords(targetStates.REQUIRE_ATTENTION)[0]
          .latestDocument,
        total: filteredRecords(targetStates.REQUIRE_ATTENTION)[0].total,
      },
      {
        id: 2,
        text:
          readyToAccept === 1
            ? "1 document ready to accept"
            : `${readyToAccept} documents ready to accept`,
        link: "/saflandingpage",
        icon: <Info24 className="text-warning" />,
        taskItems: filteredRecords(targetStates.READY_TO_ACCEPT)[0].documents,
        header: filteredRecords(targetStates.READY_TO_ACCEPT)[0].state,
        latestDocument: filteredRecords(targetStates.READY_TO_ACCEPT)[0]
          .latestDocument,
        total: filteredRecords(targetStates.READY_TO_ACCEPT)[0].total,
      },
      {
        id: 3,
        text:
          readyToAssign === 1
            ? "1 document ready to assign"
            : `${readyToAssign} documents ready to assign`,
        link: "/saflandingpage",
        icon: <Info24 className="text-primary" />,
        taskItems: filteredRecords(targetStates.READY_TO_ASSIGN)[0].documents,
        header: filteredRecords(targetStates.READY_TO_ASSIGN)[0].state,
        latestDocument: filteredRecords(targetStates.READY_TO_ASSIGN)[0]
          .latestDocument,
        total: filteredRecords(targetStates.READY_TO_ASSIGN)[0].total,
      },
      {
        id: 4,
        text:
          failedToProcess === 1
            ? "1 document failed to process"
            : `${failedToProcess} documents failed to process`,
        link: "/saflandingpage",
        icon: <CircleClose24 className="text-failed" />,
        taskItems: filteredRecords(targetStates.FAILED_TO_PROCESS)[0].documents,
        header: filteredRecords(targetStates.FAILED_TO_PROCESS)[0].state,
        latestDocument: filteredRecords(targetStates.FAILED_TO_PROCESS)[0]
          .latestDocument,
        total: filteredRecords(targetStates.FAILED_TO_PROCESS)[0].total,
      },
    ];

  // Code to loop through the taskNotification array and display the text and link
  const taskDisplay =
    !loading && data && records
      ? taskNotification.map((item) => {
          return <TaskComponent item={item} key={item.id} />;
        })
      : "";

  return (
    <Layout title="SAF Dashboard" breadcrumbs={breadcrumbItems}>
      <section className="flex flex-col justify-stretch items-start w-full pb-6 px-7">
        <h4 className="fs-4 pb-3">Tasks</h4>
        {loading && (
          <div className="flex flex-row items-center justify-center flex-1">
            <Spinner size="sm" />
          </div>
        )}
        {!loading && !allTasksDone ? (
          <div className="flex flex-row flex-wrap flex-1">{taskDisplay}</div>
        ) : (
          !loading && (
            <div className="flex flex-row">
              <div className="pt-0">
                <Checklist24 className="text-danger" />
              </div>
              <div className="drop-down-name">
                All done! You have no outstanding tasks
              </div>
            </div>
          )
        )}
      </section>
      <section className="flex flex-col pt-8 gap-4 px-7">
        <h3 className="fs-3">Your Documents</h3>

        {loading && <Spinner size="sm" />}
        <hr className="border-1 mb-2" />
      </section>
    </Layout>
  );
};

export default SAFLandingPage;
